import JsSha from 'jssha';

export const createCi360LoginIdHash = (tenantShortName: string, tenantId: string, crmId: string) => {
  // Hashing crmId twice using SHA384, first with tennant short name as salt and then with tenantId as salt.
  const shaObj = new JsSha('SHA-384', 'TEXT', { encoding: 'UTF8' });
  shaObj.update(`${crmId}${tenantShortName}`);
  const intermediateHash = shaObj.getHash('HEX');

  const shaObj2 = new JsSha('SHA-384', 'TEXT', { encoding: 'UTF8' });
  shaObj2.update(`${intermediateHash}${tenantId}`);
  return shaObj2.getHash('HEX');
};
