import { createAsyncThunk } from '@reduxjs/toolkit';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { RootState } from '../configure-store';

export const fetchVoipUsage = createAsyncThunk<
  MinesiderBackend.VoipUsage[],
  string,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('voipUsage/fetch', async (period, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const crmId = currentState.userContext.selectedCustomerLocation?.customerCrmId;
  if (!crmId) {
    throw new Error('[voip-usage-thunks] No customerId found');
  }
  return minesiderBackendService
    .getVoipUsage(crmId, period)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse));
});
