import { createAsyncThunk } from '@reduxjs/toolkit';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

interface WifiProps {
  customerId: string;
  servicePointId: string;
  wifiNetwork: MinesiderBackend.UpdateWifiNetwork[];
}

export const setWifiSettings = createAsyncThunk<
  MinesiderBackend.CustomerNetwork,
  WifiProps,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('network/setWifiSettings', async ({ servicePointId, wifiNetwork: settings, customerId }, thunkApi) =>
  minesiderBackendService
    .setWifiSettings(servicePointId, customerId, settings)
    .then((network) => {
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'success',
          heading: i18n.t('pages.network.advanced.wifi.saved'),
          role: 'status',
        }),
      );
      return network;
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'critical',
          heading: i18n.t('pages.network.advanced.wifi.errors.save'),
          role: 'status',
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);

interface SingleWifiProps {
  customerId: string;
  wifiId: string;
  servicePointId: string;
  networkConfig: MinesiderBackend.UpdateSingleWifiNetwork;
}

export const setSingleNetworkSetting = createAsyncThunk<
  MinesiderBackend.CustomerNetwork,
  SingleWifiProps,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('network/setSingleNetworkSetting', async ({ servicePointId, networkConfig, customerId, wifiId }, thunkApi) =>
  minesiderBackendService
    .setWifiSetting(servicePointId, customerId, wifiId, networkConfig)
    .then((network) => {
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'success',
          heading: i18n.t('pages.network.advanced.wifi.saved'),
          role: 'status',
        }),
      );
      return network;
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'critical',
          heading: i18n.t('pages.network.advanced.wifi.errors.save'),
          role: 'status',
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);

export const setManagedWifiSetting = createAsyncThunk<
  MinesiderBackend.CustomerNetwork,
  SingleWifiProps,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('network/setManagedWifiSetting', async ({ servicePointId, networkConfig, customerId, wifiId }, thunkApi) =>
  minesiderBackendService
    .setManagedWifiSetting(servicePointId, customerId, wifiId, networkConfig)
    .then((net) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('features.toast.save.success'),
          alertType: 'success',
          role: 'status',
        }),
      );
      return net;
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('features.toast.save.rejected'),
          alertType: 'critical',
          role: 'alert',
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);

export interface NetworkProps {
  customerId: string;
  servicePointId: string;
}

export const fetchNetworkSettings = createAsyncThunk<
  MinesiderBackend.CustomerNetwork,
  NetworkProps,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('network/getNetworkSettings', async (props, thunkApi) =>
  minesiderBackendService
    .getNetworkSettings(props.servicePointId, props.customerId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const fetchFixedWirelessAccessStatus = createAsyncThunk<
  MinesiderBackend.FixedWirelessAccessStatus,
  NetworkProps,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('network/getFixedWirelessAccessStatus', async (props, thunkApi) =>
  minesiderBackendService
    .getFixedWirelessAccessStatus(props.customerId, props.servicePointId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

interface UpdateNetworkProps {
  customerId: string;
  servicePointId: string;
  network: MinesiderBackend.UpdateNetwork;
}

export const updateNetworkSettings = createAsyncThunk<
  MinesiderBackend.CustomerNetwork,
  UpdateNetworkProps,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('network/updateNetworkSettings', async ({ customerId, servicePointId, network }, thunkApi) =>
  minesiderBackendService
    .updateNetwork(customerId, servicePointId, network)
    .then((net) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('features.toast.save.success'),
          alertType: 'success',
          role: 'status',
        }),
      );
      return net;
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('features.toast.save.rejected'),
          alertType: 'critical',
          role: 'alert',
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);

interface UpdateAccessPointProps {
  customerId: string;
  servicePointId: string;
  id: number;
  name: string;
  inheritFromCpe: boolean;
}

export const updateWifiAccessPoint = createAsyncThunk<
  MinesiderBackend.CustomerNetwork,
  UpdateAccessPointProps,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('network/updateAccessPoint', async ({ customerId, servicePointId, id, name, inheritFromCpe }, thunkApi) =>
  minesiderBackendService
    .updateWifiAccessPoint(customerId, servicePointId, id, { inheritFromCpe, name })
    .then((aps) => {
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'success',
          role: 'status',
          heading: i18n.t('pages.network.advanced.altiboxWifiOveralt.deviceList.accessPointUpdatedMessage'),
        }),
      );
      return aps;
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'success',
          role: 'status',
          heading: i18n.t('pages.network.advanced.altiboxWifiOveralt.deviceList.accessPointUpdateErrorMessage'),
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);
