import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { getUserNeedToConfirm } from 'app/store/actions/user-context-thunks';
import { FetchStatus } from 'app/store/root-types';
import { CustomerContext } from 'app/store/types/user-context-types';
import { timeout } from 'app/utils/fetch-utils';
import { useEffect, useState } from 'react';

export const useContactDetailsVerification = (userContext: CustomerContext, timeToLive: number) => {
  const dispatch = useAppDispatch();
  const { crmId } = userContext;
  const [endOfLife, setEndOfLife] = useState(false);
  const { fetchStatus, needToVerify, data } = useAppSelector((state) => state.userContext.contactDetailsConfirmed);
  const { dismissedVerificationPrompt } = useAppSelector((state) => state.core);

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED && !dismissedVerificationPrompt) {
      timeout(dispatch(getUserNeedToConfirm(crmId)), timeToLive).catch(() => {
        setEndOfLife(true);
      });
    }
  }, [dispatch, fetchStatus]);

  return {
    contactDetails: data,
    contactNeedToVerify: needToVerify,
    timedOut: endOfLife,
    fetchStatus,
  };
};
