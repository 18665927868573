import { useTranslation } from 'react-i18next';
import { DeviceInfo } from './device-info';
import { useAppSelector } from 'app/hooks/redux-thunk';

interface HgwProps {
  hgwType: string;
}

export const HgwDevice = ({ hgwType }: HgwProps) => {
  const { t } = useTranslation();
  const { data: equipmentEnrichment } = useAppSelector((state) => state.enrichment.equipment);
  const crmEnrichment = equipmentEnrichment?.find((eq) => eq.provSpecificationId === hgwType);
  const hgwModelName = crmEnrichment?.friendlyName || hgwType;
  const equipment = {
    deivceName: t('pages.network.wifiSettings.hgwModel', { hgwModel: hgwModelName }),
    description: crmEnrichment?.description,
    customerServiceUrl: crmEnrichment?.customerServiceUrl,
    userManual: crmEnrichment?.userManual,
    images: crmEnrichment?.images,
  };

  return (
    <DeviceInfo
      deivceName={equipment.deivceName}
      description={equipment.description}
      images={equipment.images}
      customerServiceUrl={equipment.customerServiceUrl}
      userManual={equipment.userManual}
    />
  );
};
