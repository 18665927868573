interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
  title?: string;
  ariaLabel?: string;
  variant: 'accordion' | 'long' | 'short';
  direction: 'up' | 'right' | 'down' | 'left';
}

interface Variant {
  path: JSX.Element;
  props: {
    width: string;
    height: string;
    viewBox: string;
    fill?: string;
    stroke?: string;
  };
  direction: {
    up: number;
    right: number;
    down: number;
    left: number;
  };
}

export const Arrow = ({ color, ariaLabel, className, title, variant, direction, ...rest }: Props) => {
  const selectedColor = color;

  const long: Variant = {
    path: (
      <path
        d="M10 19L3 12M10 5L3 12M3 12L21 12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={selectedColor}
      />
    ),
    props: {
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
    },
    direction: {
      left: 0,
      up: 90,
      right: 180,
      down: 270,
    },
  };

  const accordion: Variant = {
    path: (
      <path d="M17 10L12 15L7 10" stroke={selectedColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    ),
    props: {
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
    },
    direction: {
      down: 0,
      left: 90,
      up: 180,
      right: 270,
    },
  };

  const short: Variant = {
    path: (
      <path
        d="M10 7L15 12L10 17"
        stroke={selectedColor || 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    props: {
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
    },
    direction: {
      right: 0,
      down: 90,
      left: 180,
      up: 270,
    },
  };

  const getSelectedVariant = () => {
    switch (variant) {
      case 'long':
        return long;
      case 'accordion':
        return accordion;
      case 'short':
      default:
        return short;
    }
  };

  const selectedDirection = getSelectedVariant().direction[direction];

  return (
    <svg
      className={className}
      aria-label={ariaLabel}
      aria-hidden={!ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotateZ(${selectedDirection}deg)` }}
      {...getSelectedVariant().props}
      {...rest}
    >
      {title && <title>{title}</title>}
      {getSelectedVariant().path}
    </svg>
  );
};
