import { Typography } from 'app/component-library-wave';
import { FdsButton } from '@lyse-as/formds-react';
import { FetchStatus } from 'app/store/root-types';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { postponeInvoice } from 'app/store/actions/invoice-thunks';
import { useTranslation } from 'react-i18next';
import styles from './invoice-postpone.module.scss';

interface Props {
  invoiceId: string;
  fetchStatus: FetchStatus;
}

export const ConfirmStep = ({ invoiceId, fetchStatus }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(postponeInvoice({ invoiceId }));
  };

  const handleBack = () => {
    history.back();
  };

  return (
    <>
      <Typography component="h1" variant="formds-altibox-header-tertiary" bold={true}>
        {t('pages.invoice.invoicePostpone.name')}
      </Typography>
      <div className={styles.messageContainer}>
        <Typography component="p" variant="formds-body">
          {t('pages.invoice.invoicePostpone.rules')}
        </Typography>
      </div>
      <div className={styles.buttonContainer}>
        <FdsButton
          variant="primary"
          disabled={fetchStatus === FetchStatus.PENDING}
          onClick={handleClick}
          fullWidth={true}
        >
          {t('pages.invoice.invoicePostpone.confirm')}
        </FdsButton>
        <FdsButton variant="secondary" onClick={handleBack} fullWidth={true}>
          {t('pages.invoice.invoicePostpone.cancel')}
        </FdsButton>
      </div>
    </>
  );
};
