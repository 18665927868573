import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Typography } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { ContainerFixed } from 'app/components/container';
import { FetchStatus } from 'app/store/root-types';

import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { Devices } from '../../devices/devices';
import { Spinner } from 'app/components/spinner';
import { wifiClearUpdate } from 'app/store/actions/network-actions';
import { useLoadNetworkSettings } from 'app/hooks/use-network';
import { AwoIntro } from '../awo-intro/awo-intro';
import { SsId } from '../ssid/ssid';

import styles from './altibox-wifi-overalt.module.scss';

export const AltiboxWifiOveralt: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { customerCrmId: customerId, servicePointId, customerType } = props.userContext;

  const {
    data: networkData,
    fetchStatus,
    updateWifiSettings: { fetchStatus: wifiFetchStatus },
  } = useAppSelector((state) => state.network);

  const { isError, errorMessage: toastMessage } = useLoadNetworkSettings({
    servicePointId,
    customerId,
  });

  useEffect(() => {
    dispatch(wifiClearUpdate());
  }, []);
  if (
    fetchStatus === FetchStatus.NOT_STARTED ||
    fetchStatus === FetchStatus.PENDING ||
    wifiFetchStatus === FetchStatus.PENDING ||
    !networkData
  ) {
    return <Spinner />;
  }

  const showAlert = isError && toastMessage;
  const alertType = isError ? 'critical' : 'success';
  const { wifiSettings: wifiIds, regexValidations, fieldValues } = networkData.hgw;

  return (
    <ContainerFixed isNarrow={false} className={styles.awoPage}>
      {showAlert && (
        <div className={styles.toast}>
          <Alert
            alertType={alertType}
            heading={toastMessage}
            headingElement="h2"
            isExpandable={false}
            isDismissable={true}
            onClose={() => dispatch(wifiClearUpdate())}
          />
        </div>
      )}
      <AwoIntro networkData={networkData} />
      <div className={styles.awoWifiSettings}>
        <Typography
          component="h2"
          variant="headline5"
          maxBreakpoint={Breakpoint.TABLET}
          className={styles.awoWifiHeading}
        >
          {t('pages.network.advanced.altiboxWifiOveralt.awoWifiSettingsHeading')}
        </Typography>

        <SsId
          customerId={customerId}
          servicePointId={servicePointId}
          wifiIds={wifiIds}
          regexValidations={regexValidations}
          fieldValues={fieldValues}
          uiHideBorder={true}
          uiHideToggle={true}
        />
      </div>

      {networkData?.hgw.type && (
        <div className={styles.deviceContainer}>
          <Devices
            customerId={customerId}
            customerType={customerType}
            servicePointId={servicePointId}
            networkData={networkData}
          />
        </div>
      )}
    </ContainerFixed>
  );
};
