import React, { useCallback } from 'react';

import { Field, inputChange } from './form-utils';

export function useChangeHandler<T, F>(setForm: React.Dispatch<React.SetStateAction<F>>) {
  return useCallback(
    (value: T, field: (f: Field<T>) => Partial<F>, validator?: (value: T) => string | undefined) => {
      setForm((formState) => ({
        ...formState,
        ...field(inputChange(value, validator)),
      }));
    },
    [setForm],
  );
}
