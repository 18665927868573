import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { RootState } from '../configure-store';

// Thunks
export const readServicePointDeliveryStatus = createAsyncThunk<
  MinesiderBackend.ServicePointDeliveryStatus,
  void,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('deliveryStatus/readServicePointDeliveryStatus', async (_, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const servicePointId = currentState.userContext.selectedCustomerLocation!.servicePointId;
  if (!servicePointId) {
    return Promise.reject('No servicepoint ID defined in request.');
  }
  return minesiderBackendService
    .getServicePointDeliveryStatus(servicePointId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse));
});

export const createServicePointCustomerDiggingStatus = createAsyncThunk<
  MinesiderBackend.CustomerDiggingStatus,
  boolean,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('dealiveryStatus/createServicePointCustomerDiggingStatus', async (diggingStatus, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const servicePointId = currentState.userContext.selectedCustomerLocation!.servicePointId;
  if (!servicePointId) {
    return Promise.reject('No servicepoint ID defined in request.');
  }
  const newDiggingStatus = { customerDiggingDone: diggingStatus };
  return minesiderBackendService
    .postServicePointCustomerDiggingStatus(servicePointId, newDiggingStatus)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse));
});
