import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Typography } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { saveContactDetails } from 'app/store/actions/contact-details-thunks';
import { FetchStatus } from 'app/store/root-types';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { useContactDetails } from 'app/hooks/use-contact-details';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import { verifyContactDetails } from 'app/store/actions/user-context-thunks';
import { ContactDetailsForm } from './contact-details-form';

import styles from './contact-details-edit.module.scss';

export const ContactDetailsEdit: ActiveCustomerLocationPage = (props) => {
  const { userContext } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();
  const { contactDetails, fetchStatus, displayName, displayContactId } = useContactDetails(userContext);
  const updateContactDetails = useAppSelector((state) => state.contactDetails.updateContactDetails);

  useEffect(() => {
    if (fetchStatus === FetchStatus.FULFILLED && userContext.role !== 'PRIMARY') {
      goToPath(navigationService.PAGES.contactDetails.url);
    }
  }, [fetchStatus]);

  const handleSubmit = (details: MinesiderBackend.CustomerDetails) =>
    dispatch(saveContactDetails({ customerId: userContext.customerCrmId, contactDetails: details })).then(() =>
      dispatch(verifyContactDetails(userContext.crmId)),
    );

  return (
    <ContainerFixed isNarrow={true} className={styles.container}>
      <Typography component="h1" variant="headline4" maxBreakpoint={Breakpoint.TABLET} className={styles.header}>
        {t('pages.contactDetailsEdit.name')}
      </Typography>
      <>
        {(fetchStatus === FetchStatus.NOT_STARTED || fetchStatus === FetchStatus.PENDING) && <Spinner />}
        {(fetchStatus === FetchStatus.REJECTED || (fetchStatus === FetchStatus.FULFILLED && !contactDetails)) && (
          <Alert
            alertType="warning"
            heading={t('pages.contactDetailsCommon.fetchingFailed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
        {fetchStatus === FetchStatus.FULFILLED && contactDetails && (
          <ContactDetailsForm
            initialValues={contactDetails}
            displayContactId={displayContactId}
            displayName={displayName}
            fetchStatus={updateContactDetails.fetchStatus}
            onSubmit={handleSubmit}
          />
        )}
      </>
    </ContainerFixed>
  );
};
