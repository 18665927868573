import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography } from 'app/component-library-wave';

import { setToAddress } from 'app/store/actions/relocation-actions';
import { CustomerLocationContext, CustomerType } from 'app/store/types/user-context-types';
import { MatrikkelAddress } from 'app/store/root-types';

import { LocationSiteName } from 'app/components/location-sitename/location-sitename';
import { LocationIcon } from 'app/components/location-icon/location-icon';

import styles from './relocation-address.module.scss';

interface Props {
  address: CustomerLocationContext | MatrikkelAddress;
  isDestination: boolean;
  customerType?: CustomerType;
}

export const RelocationAddress = ({ address, isDestination, customerType }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clearLocation = () => {
    dispatch(setToAddress(null));
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerLeft}>
        <LocationIcon className={styles.house} customerType={customerType || CustomerType.PRIVAT} role="presentation" />
        <div className={styles.addressWrapper}>
          <Typography className={styles.title} variant="headline5" component="h2" maxBreakpoint={Breakpoint.MOBILE}>
            {isDestination
              ? t('pages.relocationForm.addressStep.toAddressLabel')
              : t('pages.relocationForm.addressStep.fromAddressLabel')}
          </Typography>
          <Typography data-hj-suppress={true} variant="uiText2" component="p" className={styles.streetaddress}>
            {isDestination
              ? (address as MatrikkelAddress)?.address
              : `${(address as CustomerLocationContext).address.streetaddress}, ${
                  (address as CustomerLocationContext).address.postalcode
                } ${(address as CustomerLocationContext).address.city}`}
          </Typography>
          {!isDestination && <LocationSiteName location={address as CustomerLocationContext} />}
        </div>
      </div>
      {isDestination && (
        <Button onClick={clearLocation} buttonType={ButtonType.SECONDARY}>
          {t('pages.relocationForm.addressStep.changeToAddressLabel')}
        </Button>
      )}
    </div>
  );
};
