import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Alert, Breakpoint, Button, ButtonType, Typography, UiComponentSize } from 'app/component-library-wave';

import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { InactiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { clearUsersFetchStatus } from 'app/store/actions/users-actions';
import { deleteUserResponse, getUsersResponse } from 'app/store/actions/users-thunks';

import { Card } from 'app/components/card';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';

import styles from './user-delete-page.module.scss';

export const UserDeletePage: InactiveCustomerLocationPage = () => {
  const { t } = useTranslation();
  const { id: idParam } = useParams();
  const { goToPath } = useAppNavigation();
  const id = idParam!;
  const customerCrmId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const crmId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.crmId);
  const users = useAppSelector((state) => state.users.users);
  const userFetchStatus = useAppSelector((state) => state.users.fetchStatus);
  const userDeleteStatus = useAppSelector((state) => state.users.deleteUser.fetchStatus);
  const dispatch = useAppDispatch();

  const getUser = (userId: string) => users?.find((u) => u.id === userId);
  const userToDelete = getUser(id);
  const myUser = crmId ? getUser(crmId) : undefined;
  const email = userToDelete?.email;
  const canDelete =
    (['PRIMARY', 'ADMIN'].includes(myUser?.role ?? '') || myUser?.id === id) &&
    userToDelete &&
    userToDelete.role !== 'PRIMARY';

  useEffect(() => {
    if (customerCrmId && userFetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(getUsersResponse(customerCrmId));
    }
  }, [customerCrmId, userFetchStatus, dispatch]);

  useEffect(() => {
    if (userFetchStatus === FetchStatus.REJECTED) {
      return () => {
        dispatch(clearUsersFetchStatus());
      };
    }
  }, [dispatch, userFetchStatus]);

  useEffect(() => {
    if (userDeleteStatus === FetchStatus.FULFILLED) {
      goToPath(navigationService.PAGES.users.url);
    }
  }, [userDeleteStatus]);

  const handleCancel = () => goToPath(navigationService.PAGES.users.url);

  const handleDeleteUser = () => dispatch(deleteUserResponse({ user: userToDelete!, crmCustomerId: customerCrmId! }));

  return (
    <ContainerFixed isNarrow={true}>
      <>
        <Typography component="h1" variant="headline4" className={styles.heading} maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.userDelete.heading')}
        </Typography>
        {(userFetchStatus === FetchStatus.PENDING || userDeleteStatus === FetchStatus.PENDING) && <Spinner />}
        {(userFetchStatus === FetchStatus.REJECTED || (!canDelete && userFetchStatus === FetchStatus.FULFILLED)) && (
          <Alert
            alertType="warning"
            heading={t('pages.userDelete.errorMessages.fetchingFailed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
        {canDelete && userFetchStatus === FetchStatus.FULFILLED && userDeleteStatus !== FetchStatus.PENDING && (
          <>
            <Card className={styles.card}>
              <div>
                <Trans i18nKey="pages.userDelete.confirmationMessage">
                  <Typography component="p" variant="paragraph1" maxBreakpoint={Breakpoint.TABLET}>
                    placeholdertext
                  </Typography>
                  <Typography
                    component="p"
                    variant="paragraph1"
                    maxBreakpoint={Breakpoint.TABLET}
                    className={styles.paragraphWithEmail}
                  >
                    placeholdertext
                  </Typography>
                  {{ email }}
                </Trans>
              </div>
            </Card>
            {userDeleteStatus === FetchStatus.REJECTED && (
              <Alert
                alertType="warning"
                heading={t('pages.userDelete.errorMessages.deletingFailed')}
                headingElement="strong"
                isExpandable={false}
                role="alert"
                className={styles.alert}
              />
            )}
            <div className={styles.buttonBar}>
              <Button buttonType={ButtonType.SECONDARY} onClick={handleCancel} uiComponentSize={UiComponentSize.LARGE}>
                {t('pages.userDelete.buttons.cancel.name')}
              </Button>
              <Button
                buttonType={ButtonType.PRIMARY_B}
                onClick={handleDeleteUser}
                uiComponentSize={UiComponentSize.LARGE}
              >
                {t('pages.userDelete.buttons.deleteUser.name')}
              </Button>
            </div>
          </>
        )}
      </>
    </ContainerFixed>
  );
};
