import { createSlice, Slice } from '@reduxjs/toolkit';
import { ToastMessageState, InternalToastMessage } from '../root-types';
import { addToastMessage, removeToastMessage } from '../actions/toast-message-actions';
import { v4 as uuidv4 } from 'uuid';

const TOAST_MESSAGE_TIMEOUT = 3000;

const initialState: ToastMessageState = { messagesList: [] };

const toastMessageSlice: Slice<ToastMessageState> = createSlice({
  name: 'toastMessageSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addToastMessage, (state, action) => {
      const message: InternalToastMessage = {
        id: uuidv4(),
        payload: action.payload,
        expire: Date.now() + TOAST_MESSAGE_TIMEOUT,
      };
      state.messagesList = [...state.messagesList, message];
    });
    builder.addCase(removeToastMessage, (state, action) => {
      state.messagesList = state.messagesList.filter((message) => message.id !== action.payload);
    });
  },
});

const { reducer } = toastMessageSlice;

export { reducer };
