import { DetailsHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { Breakpoint, Typography } from 'app/component-library-wave';

import { Collapsible } from 'app/components/collapsible';
import arrow from 'app/media/icons/accordion/arrow-up.svg';
import arrowDown from 'app/media/icons/accordion/arrow-down.svg';

import styles from './faq-item.module.scss';

export interface LinkType {
  label: string;
  url: string;
}

interface Props extends DetailsHTMLAttributes<HTMLDetailsElement> {
  question: string;
  answers: string[];
  links?: LinkType[];
  last: boolean;
  index: number;
  renderAsHTML?: boolean;
}

const createFaqItem = () => (props: Props) => {
  const { question, answers, links, index, renderAsHTML } = props;
  const [isOpen, setOpen] = useState(false);

  const componentClassNames = classNames(
    props.className,
    styles.questionContainer,
    props.last ? styles.last : null,
    isOpen ? styles.isOpen : null,
  );

  return (
    <>
      <dt className={componentClassNames}>
        <button
          type="button"
          onClick={() => setOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls={`faq${index}-answer`}
        >
          <Typography component="p" variant="uiText2" bold={true} maxBreakpoint={Breakpoint.TABLET}>
            {question}
          </Typography>
          <img className={styles.arrow} src={isOpen ? arrowDown : arrow} alt="" />
        </button>
      </dt>
      <dd className={styles.answerContainer}>
        <Collapsible isOpen={isOpen} id={`faq${index}-answer`}>
          {answers.map((paragraph, key) => (
            <Typography
              className={styles.answer}
              component="p"
              variant="paragraph2"
              key={key}
              renderAsHTML={renderAsHTML}
              maxBreakpoint={Breakpoint.TABLET}
            >
              {paragraph}
            </Typography>
          ))}
          {links &&
            links.map((link: LinkType, linkIndex: number) => (
              <Typography
                className={classNames(styles.answer, styles.link)}
                component="p"
                variant="paragraph2"
                renderAsHTML={true}
                key={linkIndex}
                maxBreakpoint={Breakpoint.TABLET}
              >
                {`<a href=${link.url}>
                ${link.label}
              </a>`}
              </Typography>
            ))}
        </Collapsible>
      </dd>
    </>
  );
};

export const FaqItem = createFaqItem();
