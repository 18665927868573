import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

interface DiscountApiParams {
  customerId: string;
  discountType: string;
}

export const getDiscountStatus = createAsyncThunk<
  MinesiderBackend.DiscountStatus,
  DiscountApiParams,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('discount/getDiscountStatus', async ({ customerId, discountType }) =>
  minesiderBackendService.getDiscountStatus(customerId, discountType),
);
