import classnames from 'classnames';
import styles from './info-circle.module.scss';

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
  title?: string;
  ariaLabel?: string;
  variant: 'filled' | 'outlined';
}

export const InfoCircle = ({ color, ariaLabel, className, title, role, variant, ...rest }: Props) => {
  const filledPath = (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11 6C11 6.55228 10.5523 7 10 7C9.44771 7 9 6.55228 9 6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6ZM10 9C10.5523 9 11 9.44772 11 10V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V10C9 9.44772 9.44771 9 10 9Z"
    />
  );
  const filledProps = {
    width: '32',
    height: '32',
    viewBox: '0 0 26 26',
  };

  const outlinedPath = (
    <path
      d="M12 12V17M12.01 8H11.99M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
  const outLineProps = {
    width: '24',
    height: '32',
    viewBox: '0 0 24 24',
  };

  const props = variant === 'outlined' ? outLineProps : filledProps;
  const colorClass = variant === 'outlined' ? styles.outlined : styles.filled;

  return (
    <svg
      className={classnames(colorClass, className)}
      aria-label={ariaLabel}
      aria-hidden={!ariaLabel}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role={role}
      {...props}
      {...rest}
    >
      {title && <title>{title}</title>}
      {variant === 'outlined' ? outlinedPath : filledPath}
    </svg>
  );
};
