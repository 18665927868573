import React from 'react';
import classNames from 'classnames';

import styles from './grid.module.scss';

type SmallColumns = 1 | 2;
type MediumColumns = SmallColumns | 3 | 4 | 5 | 6;
type LargeColumns = MediumColumns | 7 | 8 | 9 | 10 | 11 | 12;

export interface ColProps {
  sm: SmallColumns;
  md: MediumColumns;
  lg: LargeColumns;
  children?: React.ReactNode;
}

export const Col: React.FC<ColProps> = ({ sm, md, lg, children }) => {
  return (
    <div className={classNames(styles[`col-lg-${lg}`], styles[`col-md-${md}`], styles[`col-sm-${sm}`])}>{children}</div>
  );
};
