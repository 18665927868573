import { useTranslation } from 'react-i18next';

import { Variant } from 'app/component-library-wave/components/typography/types';
import { DescriptionList, DescriptionListPair } from 'app/components/description-list/description-list';
import { iso8601ToShortDate } from 'app/utils/date-utils';

import styles from './invoice-lines.module.scss';

interface Props {
  invoicelines: MinesiderBackend.InvoiceUsage[];
  taxIncludedSum?: string;
}

const getBilledForPeriod = (invoiceline: MinesiderBackend.InvoiceUsage, label: string) => {
  if (!invoiceline.billedForStart) return null;

  const period = invoiceline.billedForEnd
    ? `${iso8601ToShortDate(invoiceline.billedForStart)} - ${iso8601ToShortDate(invoiceline.billedForEnd)}`
    : iso8601ToShortDate(invoiceline.billedForStart);
  return (
    <span className={styles.billedForPeriod}>
      {label} {period}
    </span>
  );
};

export const InvoiceLines = ({ invoicelines, taxIncludedSum }: Props) => {
  const { t } = useTranslation();

  const dlList: DescriptionListPair[] = invoicelines.map((invoiceline) => {
    return {
      label: {
        value: (
          <p>
            <span className={styles.billText}>{invoiceline.billText}</span>
            {getBilledForPeriod(invoiceline, t('pages.invoiceDetails.invoiceLines.period'))}
            {invoiceline.startTime && (
              <span className={styles.startTime}>
                {t('pages.invoiceDetails.invoiceLines.startTime')} {invoiceline.startTime}
              </span>
            )}
          </p>
        ),
      },
      description: {
        value: invoiceline.taxIncludedAmount,
      },
    };
  });

  const total = [
    {
      label: {
        value: t('pages.invoiceDetails.invoiceLines.total'),
        variant: 'formds-common-subtitle-secondary' as Variant,
      },
      description: {
        value: taxIncludedSum,
      },
    },
  ];

  return <DescriptionList data={dlList.concat(total)} />;
};
