import { FdsIcon } from '@lyse-as/formds-react';
import { useTranslation } from 'react-i18next';
import styles from './settlement-sate.module.scss';
import { dueDateExceeded } from 'app/utils/date-utils';

export type SettlementState = 'Open' | 'Settled' | 'PartiallyPaid' | 'Overdue';

const COMMON_SEMANTIC_RED_80 = '#ca3629';
const COMMON_SEMANTIC_ORANGE_100 = '#db7b10';
const COMMON_SEMANTIC_GREEN_80 = '#1a8642';

type IconProps = Omit<SettlementIcon, 'colorValue' | 'getIcon'>;

const Icon = ({ iconName, className, i18nKey }: IconProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.settlementState}>
      <span>{t(i18nKey)}</span>
      <FdsIcon name={iconName} className={className} />
    </div>
  );
};

interface SettlementIcon {
  iconName: string;
  className: string;
  i18nKey: string;
  colorValue: string;
  getIcon: () => JSX.Element;
}

interface Props {
  settlementState: SettlementState;
  dueDate?: string;
}

export const settlementIconStates = ({ settlementState, dueDate }: Props) => {
  let state = settlementState;
  const values: SettlementIcon = {
    i18nKey: '',
    iconName: 'watch_later_filled',
    className: styles.open,
    colorValue: COMMON_SEMANTIC_ORANGE_100,
    getIcon: () => <></>,
  };

  if (settlementState !== 'Settled' && dueDate && dueDateExceeded(dueDate)) {
    state = 'Overdue';
  }

  switch (state) {
    case 'Open':
      values.i18nKey = 'pages.invoice.details.settlementState.open';
      values.className = styles.open;
      values.colorValue = COMMON_SEMANTIC_ORANGE_100;
      values.iconName = 'watch_later_filled';
      break;
    case 'PartiallyPaid':
      values.i18nKey = 'pages.invoice.details.settlementState.partiallyPaid';
      values.className = styles.open;
      values.colorValue = COMMON_SEMANTIC_ORANGE_100;
      values.iconName = 'watch_later_filled';
      break;
    case 'Settled':
      values.i18nKey = 'pages.invoice.details.settlementState.settled';
      values.className = styles.settled;
      values.colorValue = COMMON_SEMANTIC_GREEN_80;
      values.iconName = 'check_circle_filled';
      break;
    case 'Overdue':
    default:
      values.i18nKey = 'pages.invoice.details.settlementState.overdue';
      values.className = styles.overdue;
      values.colorValue = COMMON_SEMANTIC_RED_80;
      values.iconName = 'error_filled';
      break;
  }
  values.getIcon = () => <Icon className={values.className} iconName={values.iconName} i18nKey={values.i18nKey} />;
  return values;
};
