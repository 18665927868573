import { useMedia } from 'app/hooks';

import styles from './header.module.scss';
import { HeaderLogo, Screen } from './header-logo';
import { SpinnerForSuspense } from 'app/components/spinner';

export const HeaderForSuspense = () => {
  const isDesktop = useMedia<Screen>(['(min-width: 768px)'], [Screen.Md], Screen.Sm) >= Screen.Md;
  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerInner}>
          <HeaderLogo isDesktop={isDesktop} />
        </div>
      </header>
      <SpinnerForSuspense />
    </>
  );
};
