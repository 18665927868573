import React from 'react';
import { Typography, Alert, Breakpoint } from 'app/component-library-wave';

import { i18n } from 'app/i18n/i18n';
import { formatDate } from 'app/utils/date-utils';
import { OperationalMessageDetails } from 'app/store/root-types';

import styles from './operational-message-alert.module.scss';

interface Props {
  message: OperationalMessageDetails;
}

export const OperationalMessageAlert: React.FC<Props> = (props) => {
  const { message } = props;
  const titleKey = `features.operationalMessages.DYNAMIC_KEYS.${message.webTemplate}.title`;
  const descriptionKey = `features.operationalMessages.DYNAMIC_KEYS.${message.webTemplate}.description`;

  const customTitle = message.webSummary || '';
  const customDescription = message.webDescription || '';
  const title = !customTitle && i18n.exists(titleKey) ? i18n.t(titleKey) : customTitle;
  const description = !customDescription && i18n.exists(descriptionKey) ? i18n.t(descriptionKey) : customDescription;

  return title && description ? (
    <aside>
      <Alert heading={title} headingElement="h2" alertType="warning" role="status">
        <Typography
          className={styles.description}
          variant="paragraph2"
          component="div"
          maxBreakpoint={Breakpoint.TABLET}
        >
          {description}
        </Typography>
        <div className={styles.flexContainer}>
          {message.dateOfOccurence && (
            <div className={styles.column}>
              <Typography
                className={styles.columnHeader}
                variant="uiText3"
                component="div"
                bold={true}
                maxBreakpoint={Breakpoint.TABLET}
              >
                {i18n.t('features.operationalMessages.created').toString()}
              </Typography>
              <Typography variant="paragraph3" component="div" maxBreakpoint={Breakpoint.TABLET}>
                {formatDate(message.dateOfOccurence)}
              </Typography>
            </div>
          )}
          {message.affectedProducts.length > 0 && (
            <div className={styles.column}>
              <Typography
                className={styles.columnHeader}
                variant="uiText3"
                component="div"
                bold={true}
                maxBreakpoint={Breakpoint.TABLET}
              >
                {i18n.t('features.operationalMessages.affectedProducts').toString()}
              </Typography>
              <Typography variant="paragraph3" component="div" maxBreakpoint={Breakpoint.TABLET}>
                {message.affectedProducts}
              </Typography>
            </div>
          )}
        </div>
      </Alert>
    </aside>
  ) : null;
};
