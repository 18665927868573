import styles from './spinner.module.scss';

interface Props {
  marginVertical?: boolean;
}

export const SpinnerForSuspense = (props: Props) => {
  const { marginVertical } = props;
  return (
    <div className={styles.noTextContainer}>
      <div role="status" className={`${styles.spinner} ${marginVertical ? styles.marginVertical : ''}`} />
    </div>
  );
};
