import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography } from 'app/component-library-wave';

import { FetchStatus } from 'app/store/root-types';

import {
  FormModel,
  toFormModel,
  toNewRuleFormModel,
  toValueModel,
  isFormValid,
  isPortValid,
  validName,
  getNumber,
} from '../firewall-setting-utils';
import { isEmpty, isLastOctetValid, isNumber } from '../../network-settings/network-settings-utils';
import { useChangeHandler } from '../use-change-handler';
import { LanCard } from './lan-card';
import { FirewallRulePort } from './firewall-rule-port';
import { FirewallRulePortType } from './firewall-rule-portType';
import { FirewallRuleName } from './firewall-rule-name';
import { ButtonsContainer } from 'app/components/container';
import { Spinner } from 'app/components/spinner';

import styles from './firewall-rule-form.module.scss';

interface Props {
  title: string;
  initialValues?: MinesiderBackend.PortForwarding;
  customerNetwork: MinesiderBackend.CustomerNetwork;
  fetchStatus: FetchStatus;
  onSubmit: (values: MinesiderBackend.UpdatePortForwarding) => void;
  onCancel: () => void;
  button: string;
}

export const FirewallRuleForm: React.FC<Props> = (props) => {
  const { title, initialValues, customerNetwork, onSubmit, onCancel, button, fetchStatus } = props;
  const { t } = useTranslation();
  const existingRules = customerNetwork?.networkSettings?.portForwardings || [];
  const invalidPort = t('pages.firewall.validation.invalidPortFrom');
  const invalidInternalPortTo = t('pages.firewall.validation.invalidInternalPortTo');
  const invalidExtPortTo = t('pages.firewall.validation.invalidExtPortTo');
  const invalidNumber = t('pages.firewall.validation.invalidNumber');
  const portFromSmallerPortTo = t('pages.firewall.validation.portFromSmallerPortTo');
  const invalidIntPortTo = t('pages.firewall.validation.invalidIntPortTo');
  const internalPortTo = (extFrom: string, extTo: string, intFrom: number) => {
    const extToNumber = Number.isNaN(getNumber(extTo)) ? getNumber(extFrom) : getNumber(extTo);
    const intTo = extToNumber - getNumber(extFrom) + Number(intFrom);
    return Number.isNaN(intTo) ? undefined : intTo;
  };
  const [form, setForm] = useState<FormModel>(
    initialValues ? toFormModel(customerNetwork, initialValues) : toNewRuleFormModel(customerNetwork),
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      const validatedForm: FormModel = {
        name: {
          ...form.name,
          isTouched: true,
          error: validateName(form.name.value),
        },
        type: {
          ...form.type,
          isTouched: true,
        },
        extPortFrom: {
          ...form.extPortFrom,
          isTouched: true,
          error: validPortFrom(form.extPortFrom.value),
        },
        extPortTo: {
          ...form.extPortTo,
          isTouched: true,
          error: validPortTo(form.extPortTo.value),
        },
        intPort: {
          ...form.intPort,
          isTouched: true,
          error: validIntPort(form.intPort.value),
        },
        intIpLastOctet: {
          ...form.intIpLastOctet,
          isTouched: true,
          error: validateIp(form.intIpLastOctet.value),
        },
        intIpFirstOctets: '',
      };
      setForm(validatedForm);
      if (isFormValid(validatedForm)) {
        onSubmit(toValueModel(form));
      }
    },
    [onSubmit, form, isEmpty, setForm],
  );
  const handleChange = useChangeHandler<string, FormModel>(setForm);

  const validateName = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return t('pages.firewall.validation.emptyName');
      }
      if (isNumber(value) || !validName(value)) {
        return t('pages.firewall.validation.validateName');
      }
      if (!!existingRules.find((forwarding) => forwarding.name === value && forwarding.id !== initialValues?.id)) {
        return t('pages.firewall.validation.usedName');
      }
    },
    [t, existingRules, initialValues],
  );

  const validPortFrom = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return invalidPort;
      }
      if (!isNumber(value)) {
        return invalidNumber;
      }
      return isPortValid(value) ? undefined : invalidPort;
    },
    [invalidPort, invalidNumber],
  );

  const validPortTo = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return undefined;
      }
      if (!isNumber(value)) {
        return invalidNumber;
      }
      if (Number(form.extPortFrom.value) > Number(value)) {
        return portFromSmallerPortTo;
      }
      return isPortValid(value) ? undefined : invalidExtPortTo;
    },
    [invalidExtPortTo, form.extPortFrom, invalidNumber],
  );
  useEffect(() => {
    setForm((f) => ({ ...f, extPortTo: { ...f.extPortTo, error: validPortTo(f.extPortTo.value) } }));
  }, [validPortTo, setForm]);

  const validIntPort = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return invalidInternalPortTo;
      }
      if (!isNumber(value)) {
        return invalidNumber;
      }
      const port = internalPortTo(form.extPortFrom.value, form.extPortTo.value, Number(value));
      if (!!port && !isPortValid(port?.toString())) {
        return invalidIntPortTo;
      }
      return isPortValid(value) ? undefined : invalidInternalPortTo;
    },
    [invalidInternalPortTo, form.extPortFrom, form.extPortTo, invalidIntPortTo],
  );

  const getIpAddressArray = useCallback(() => {
    const split = customerNetwork.networkSettings?.routerSettings?.ipAddress?.split('.') ?? [];
    if (split.length < 4) {
      return [];
    }
    return split.map((i, index) => {
      if (index === 3) {
        return form.intIpLastOctet.error ? i : form.intIpLastOctet.value;
      }
      return i;
    });
  }, [initialValues, form.intIpLastOctet]);
  const ipAddressArray = getIpAddressArray();
  const validateIp = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return t('pages.firewall.validation.invalidIp');
      }
      return isLastOctetValid(value) ? undefined : t('pages.firewall.validation.invalidIp');
    },

    [t],
  );

  return (
    <form onSubmit={handleSubmit} className={styles.formContainer}>
      <Typography component="h2" variant="headline4" maxBreakpoint={Breakpoint.TABLET} className={styles.heading}>
        {title}
      </Typography>
      <FirewallRuleName form={form} setForm={setForm} validateName={validateName} />
      <FirewallRulePortType form={form} setForm={setForm} />
      <>
        {customerNetwork.networkSettings?.routerSettings?.canUpdateRouterSettings && (
          <LanCard
            network={customerNetwork}
            form={form}
            handleChangeString={handleChange}
            validateIp={validateIp}
            ipAddressArray={ipAddressArray}
          />
        )}
      </>
      <FirewallRulePort
        form={form}
        validPortFrom={validPortFrom}
        setForm={setForm}
        validPortTo={validPortTo}
        validIntPort={validIntPort}
      />
      <ButtonsContainer className={styles.buttonContainer}>
        <div className={styles.buttonRow}>
          {fetchStatus === FetchStatus.PENDING && <Spinner />}
          {fetchStatus !== FetchStatus.PENDING && (
            <>
              <Button buttonType={ButtonType.SECONDARY} type="button" onClick={onCancel}>
                {t('pages.network.advanced.settings.buttons.cancel')}
              </Button>
              <Button buttonType={ButtonType.PRIMARY_B} type="submit" className={styles.saveButton}>
                {button}
              </Button>
            </>
          )}
        </div>
      </ButtonsContainer>
    </form>
  );
};
