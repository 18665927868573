import Cookies from 'js-cookie';

export const ACCESS_TOKEN_COOKIE = 'sso_access_token';
export const SESSION_TICKET_COOKIE = 'sessionTicketApi';
export const USER_COOKIE = 'user';
export const USER_CONTEXT_COOKIE = 'user_context';
export const SELECTED_LOCATION_COOKIE = 'locationPicker_SelectedLocation';
export const LOGIN_REDIRECT_URL = 'loginRedirectUrl';
export const AUTH_STATE_PARAM = 'authRequestStateParam';
export const WPBOX_SSOLOGIN_REDIRECT_URL = 'wpboxSsoLoginRedirectUrl'; // Target URL from Wpbox using the login callback page on Minesider. Cookie set in /web-apps/altibox/js/altibox/src/auth/sso.ts

export enum CookieCategory {
  NECESSARY = 'cookie_cat_necessary',
  STATISTIC = 'cookie_cat_statistic',
  FUNCTIONAL = 'cookie_cat_functional',
  MARKETING = 'cookie_cat_marketing',
  UNCLASSIFIED = 'cookie_cat_unclassified',
}

// Cookies requiring consent
// Cookie name
export const COOKIE_INFORMATION_CONSENT = 'CookieInformationConsent';

interface ThirdPartyScript {
  categories: CookieCategory[];
  src?: string;
  children?: React.ReactNode;
}

// https://support.cookieinformation.com/en/articles/5444629-third-party-cookie-blocking

export const thirdPartyCookieInlineScript = ({
  children: javascript,
  categories,
  ...rest
}: {
  children: string;
  categories: CookieCategory[];
}) => {
  const cookieCategories = categories
    .map((category) => `CookieInformation.getConsentGivenFor('${category}')`)
    .join(' && ');

  return (
    <script {...rest}>
      {`
        window.addEventListener('CookieInformationConsentGiven', function(event) {
          if (${cookieCategories}) {
              {${javascript}}
          } else {
            console.log("Third party script not loaded due to rejected consent: ${cookieCategories}")
          }
      }, false);
    `}
    </script>
  );
};

export function thirdPartyCookieExternalScript({ src, categories, children: javascript, ...rest }: ThirdPartyScript) {
  const cookieCategories = categories.map((category) => `"${category}"`).join(',');
  return (
    <>
      <script async={true} src="" data-consent-src={src} data-category-consent={cookieCategories} {...rest} />
      <script>{`${javascript}`}</script>
    </>
  );
}

export function clearAuthCookies(): void {
  return [
    SESSION_TICKET_COOKIE,
    ACCESS_TOKEN_COOKIE,
    USER_COOKIE,
    USER_CONTEXT_COOKIE,
    SELECTED_LOCATION_COOKIE,
    LOGIN_REDIRECT_URL,
    AUTH_STATE_PARAM,
  ].forEach((cookie) => Cookies.remove(cookie));
}
