import { config } from 'app/config/config';
import { TabList } from 'app/features/tabbed-content/tabbed-content';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { navigationService } from '../service/navigation/navigation-service';
import { Page, Service } from 'app/service/navigation/pages/page-types';
import { ActiveCustomerLocationContext, CustomerType } from 'app/store/types/user-context-types';
import { commonDanishPartnerOnlyVisibility } from 'app/service/navigation/pages/pages';

export const CONTEXT_PATH: string = config.app.contextPath;

export const useAppNavigation = () => {
  const navigate = useNavigate();

  const goToPath = (to: string) => {
    if (to.startsWith('http://') || to.startsWith('https://')) {
      window.location.assign(to);
    } else {
      navigate(to);
    }
  };
  const goToHomePage = () => navigate(CONTEXT_PATH);

  return { goToPath, goToHomePage };
};

export interface WithAppNavigation {
  navigate: NavigateFunction;
}

export const startLogout = (options?: { isMaintenance?: boolean; isPassiveLogout?: boolean }) => {
  const searchParams = new URLSearchParams();
  if (options?.isMaintenance) {
    searchParams.append('maintenance', 'true');
  }
  if (options?.isPassiveLogout) {
    searchParams.append('passiveLogout', 'true');
  }
  const appendParams = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
  window.location.replace(navigationService.PAGES.logout.url + appendParams);
};

export const resolveAltiboxFrontPageDomain = () =>
  window.location.origin.endsWith('.dk') ? 'altibox.dk' : 'altibox.no';

export const resolveAltiboxCustomerServiceUrlFromTld = () =>
  window.location.origin.endsWith('.dk')
    ? 'https://www.altibox.dk/kundeservice'
    : 'https://www.altibox.no/privat/kundeservice';

export const resolveLocaleFromTld = () => (window.location.origin.endsWith('.dk') ? 'da' : 'no');

export const resolveForgerockUrl = ({
  url,
  locale: localeProp,
  returnPage,
}: {
  url: string;
  locale?: string;
  returnPage?: Page;
}) => {
  const locale = localeProp ? `&locale=${localeProp}` : '';
  const returnUrl = returnPage ? `&returnurl=${window.origin}${returnPage.url}` : '';

  return `${url}${locale}${returnUrl}`;
};

const menuEnterprise = {
  tv: [],
  internet: [
    navigationService.PAGES.enterpriseInternetSettingsOverview,
    navigationService.PAGES.enterpriseLineOverview,
    navigationService.PAGES.enterpriseLineStatus,
    navigationService.PAGES.enterpriseWifiSettings,
    navigationService.PAGES.enterpriseGuestWifi,
    navigationService.PAGES.enterpriseFirewall,
    navigationService.PAGES.enterpriseDDOS,
    navigationService.PAGES.enterpriseExtendedSecurity,
    navigationService.PAGES.enterpriseUpsale,
  ],
  telephone: [navigationService.PAGES.enterpriseVOIP],
  account: [navigationService.PAGES.enterpriseMyProducts, navigationService.PAGES.enterpriseInvoice],
};

export const menuInternet = [
  navigationService.PAGES.internetSettingsOverviewDk,
  navigationService.PAGES.internetSettingsOverview,
  navigationService.PAGES.meshNetworkStore,
  navigationService.PAGES.meshNetworkStoreDK,
  navigationService.PAGES.hgwUserManual,
  navigationService.PAGES.xboxStore,
  navigationService.PAGES.voipUsage,
  ...menuEnterprise.internet,
];

export const menuTelephone = [...menuEnterprise.telephone];

export const menuTv = [
  navigationService.PAGES.foc,
  navigationService.PAGES.tvStore,
  navigationService.PAGES.tvStoreOpenNet,
  navigationService.PAGES.modellAStore,
  navigationService.PAGES.changePin,
  navigationService.PAGES.movieRentalHistory,
  navigationService.PAGES.streamingServices,
  ...menuEnterprise.tv,
];

export const menuAccount = [
  navigationService.PAGES.users,
  navigationService.PAGES.contactDetails,
  navigationService.PAGES.myProfileDk,
  navigationService.PAGES.voipDK,
  navigationService.PAGES.relocation,
  navigationService.PAGES.relocationFormVikenFiber,
  navigationService.PAGES.relocationFormLyse,
  navigationService.PAGES.myProducts,
  navigationService.PAGES.myProductsDkEnterprise,
  navigationService.PAGES.invoiceGMS,
  navigationService.PAGES.invoiceOpenNet,
  navigationService.PAGES.invoiceArchiveSiebel,
  navigationService.PAGES.invoiceInformation,
  navigationService.PAGES.invoiceLegacy,
  navigationService.PAGES.invoiceLyse,
  navigationService.PAGES.orderDK,
  navigationService.PAGES.orderTrackerOpenNet,
  navigationService.PAGES.myUsageDK,
  navigationService.PAGES.voip,
  navigationService.PAGES.consent,
  navigationService.PAGES.consentLyse,
  navigationService.PAGES.newsletterSubscriptions,
  navigationService.PAGES.email,
  ...menuEnterprise.account,
];

const internetSettingsTabs: TabList = {
  overview: {
    i18n: 'features.tabbedContent.internetSettings.overview',
    page: navigationService.PAGES.internetSettingsOverview,
  },
  network: {
    i18n: 'features.tabbedContent.internetSettings.networkSettings',
    page: navigationService.PAGES.internetSettingsNetworkSettings,
  },
  portForwarding: {
    i18n: 'features.tabbedContent.internetSettings.portForwarding',
    page: navigationService.PAGES.internetSettingsPortForwarding,
  },
};

const enterpriseInternetSettingsTabs: TabList = {
  overview: {
    i18n: 'features.tabbedContent.internetSettings.overview',
    page: navigationService.PAGES.enterpriseInternetSettingsOverview,
  },
  network: {
    i18n: 'features.tabbedContent.internetSettings.networkSettings',
    page: navigationService.PAGES.enterpriseInternetSettingsNetworkSettings,
  },
  portForwarding: {
    i18n: 'features.tabbedContent.internetSettings.portForwarding',
    page: navigationService.PAGES.internetSettingsPortForwarding,
  },
};

const managedWifiTabs: TabList = {
  overview: {
    i18n: 'features.tabbedContent.internetSettings.overview',
    page: navigationService.PAGES.enterpriseInternetSettingsOverview,
  },
  network: {
    i18n: 'features.tabbedContent.internetSettings.networkSettings',
    page: navigationService.PAGES.enterpriseInternetSettingsNetworkSettings,
  },
  portForwarding: {
    i18n: 'pages.enterprise.firewall.name',
    page: navigationService.PAGES.enterpriseFirewall,
  },
};

export const voipSettingsTabs: TabList = {
  overview: {
    i18n: 'features.tabbedContent.voipSettings.overview',
    page: navigationService.PAGES.voipSettingsOverview,
  },
  forwarding: {
    i18n: 'features.tabbedContent.voipSettings.forwarding',
    page: navigationService.PAGES.voipSettingsForwarding,
  },
  calls: {
    i18n: 'features.tabbedContent.voipSettings.calls',
    page: navigationService.PAGES.voipSettingsCalls,
  },
  barring: {
    i18n: 'features.tabbedContent.voipSettings.barring',
    page: navigationService.PAGES.voipSettingsBarring,
  },
};

interface PageFilteringData {
  userContext: ActiveCustomerLocationContext;
  networkData?: MinesiderBackend.CustomerNetwork;
}

export const internetSettingsTabList = ({ userContext, networkData }: PageFilteringData) => {
  const danishPartnersNumbers = commonDanishPartnerOnlyVisibility.partners as string[];
  const isDanish = danishPartnersNumbers.includes(userContext.partnerNumber);

  if (userContext.customerType === CustomerType.BEDRIFT && !isDanish) {
    return userContext.services.includes(Service.ManagedWifi) ? managedWifiTabs : enterpriseInternetSettingsTabs;
  } else {
    return networkData && networkData.hgw && networkData.hgw.bridge
      ? { overview: internetSettingsTabs.overview }
      : internetSettingsTabs;
  }
};
