import React from 'react';

import styles from './desktop-max-typography.module.scss';
import { TypographyBase } from '../typography-base';
import { InnerTypographyProps } from '../types';

const DesktopMaxTypography: React.FC<InnerTypographyProps> = (props) => {
  return <TypographyBase styles={styles} {...props} />;
};

export { DesktopMaxTypography };
