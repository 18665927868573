import classNames from 'classnames';
import React, { FieldsetHTMLAttributes, InputHTMLAttributes, useState } from 'react';

import { UiComponentSize, UiComponentState } from '../../types/global';
import { HelpText } from '../help-text/help-text';
import { Typography } from '../typography';

import styles from './radio-button.module.scss';

interface RadioButtonInput extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}
export interface RadioButtonProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  options: RadioButtonInput[];
  getSelectedValue: (value: string) => void;
  groupname: string;
  selectedValue?: string;
  legend?: string;
  legendLabel?: string;
  helpText?: string;
  uiComponentState?: UiComponentState;
  uiComponentSize?: UiComponentSize.SMALL | UiComponentSize.MEDIUM;
}

const renderOption = (option: RadioButtonInput, props: RadioButtonProps) => {
  const { value, label, type, name, checked, onFocus, onBlur, disabled, className, ...rest } = option;
  const { uiComponentState, uiComponentSize, groupname, selectedValue } = props;

  const [focused, setFocused] = useState(false);
  const focus = () => setFocused(true);
  const blur = () => setFocused(false);

  const rowCssClass = classNames(
    styles['radioRow'],
    focused ? styles.focused : null,
    uiComponentState === UiComponentState.DISABLED ? styles.disabled : null,
    uiComponentState === UiComponentState.SUCCESS ? styles.success : null,
    uiComponentState === UiComponentState.ERROR ? styles.error : null,
  );

  return (
    <div className={rowCssClass} key={`radiokey-${groupname}-${value}`}>
      <input
        type="radio"
        name={groupname}
        id={`radio-${groupname}-${value}`}
        value={value}
        checked={value === selectedValue}
        onFocus={focus}
        onBlur={blur}
        className={classNames(styles.radioInput, className)}
        disabled={uiComponentState === UiComponentState.DISABLED}
        readOnly
        {...rest}
      />
      <Typography
        component="label"
        variant={uiComponentSize === UiComponentSize.SMALL ? 'uiText4' : 'uiText3'}
        htmlFor={`radio-${groupname}-${value}`}
        className={styles.radioLabel}
      >
        {label}
      </Typography>
    </div>
  );
};

export const RadioButton = (props: RadioButtonProps) => {
  const {
    legend,
    legendLabel,
    helpText,
    uiComponentSize,
    uiComponentState,
    options,
    selectedValue,
    className,
    getSelectedValue,
    ...rest
  } = props;

  return (
    <fieldset className={classNames(styles.fieldset, className)} {...rest}>
      {legend && (
        <Typography
          component="legend"
          bold
          variant={uiComponentSize === UiComponentSize.SMALL ? 'uiText4' : 'uiText3'}
          className={styles.legend}
        >
          {legend}
        </Typography>
      )}
      {legendLabel && (
        <Typography
          component="span"
          italic
          variant={uiComponentSize === UiComponentSize.SMALL ? 'uiText4' : 'uiText3'}
          className={styles.optional}
        >
          {legendLabel}
        </Typography>
      )}
      <div onChange={(e: React.ChangeEvent<HTMLInputElement>) => getSelectedValue(e.target.value)}>
        {options.map((option) => renderOption(option, props))}
      </div>

      {helpText && (
        <div className={styles.helpTextContainer}>
          <HelpText helpText={helpText} uiComponentState={uiComponentState} />
        </div>
      )}
    </fieldset>
  );
};
