import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Grid, GridWidth, Breakpoint, Typography } from 'app/component-library-wave';

import { ContainerFixed } from 'app/components/container';
import { HiddenH1 } from 'app/components/hidden-h1';
import { DeliveryStatusTimeline, ItemList, List, DeliveryFaq } from 'app/features/delivery-status-timeline';
import { NewCustomerLocationPage } from 'app/pages/page-wrapper';
import { readServicePointDeliveryStatus } from 'app/store/actions/delivery-status-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import styles from './delivery-status.module.scss';
import { LocationPicker } from '../../components/location-picker';
import { Spinner } from '../../components/spinner';
import { Welcome } from '../welcome/welcome';
import i18next from 'i18next';

interface IntroductionItems {
  [index: string]: (string | ItemList)[];
}

export const DeliveryStatus: NewCustomerLocationPage = ({ userContext }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { deliveryStatus, fetchDone } = useAppSelector((state) => state.deliveryStatus);

  useEffect(() => {
    dispatch(readServicePointDeliveryStatus());
  }, [dispatch]);

  const introductionItems: IntroductionItems = {
    '1-1': [],
    '2-1': [
      t('pages.deliveryStatus.introductions.step2-1.ingress.paragraph1'),
      t('pages.deliveryStatus.introductions.step2-1.ingress.paragraph2'),
    ],
    '2-2': [
      t('pages.deliveryStatus.introductions.step2-2.ingress.paragraph1'),
      t('pages.deliveryStatus.introductions.step2-2.ingress.paragraph2'),
    ],
    '2-3': [
      t('pages.deliveryStatus.introductions.step2-3.ingress.paragraph1'),
      t('pages.deliveryStatus.introductions.step2-3.ingress.paragraph2'),
    ],
    '3-1': [
      t('pages.deliveryStatus.introductions.step3-1.ingress.paragraph1'),
      t('pages.deliveryStatus.introductions.step3-1.ingress.paragraph2'),
    ],
    '3-2': [
      t('pages.deliveryStatus.introductions.step3-2.ingress.paragraph1'),
      {
        heading: t('pages.deliveryStatus.introductions.step3-2.list.heading'),
        items: [
          t('pages.deliveryStatus.introductions.step3-2.list.items.item1'),
          t('pages.deliveryStatus.introductions.step3-2.list.items.item2'),
          t('pages.deliveryStatus.introductions.step3-2.list.items.item3'),
        ],
      },
    ],
    '4-1': [
      t('pages.deliveryStatus.introductions.step4-1.ingress.paragraph1'),
      t('pages.deliveryStatus.introductions.step4-1.ingress.paragraph2'),
    ],
    '5-1': [],
  };

  const isObject = (value: string | ItemList) => typeof value === 'object' && value !== null;

  if (!deliveryStatus) {
    return fetchDone ? <Welcome selectedLocation={userContext} /> : <Spinner marginVertical={true} />;
  }

  const currentStep = deliveryStatus.step.combinedStep;
  const introductionsHeading = i18next.exists(
    `pages.deliveryStatus.introductions.DYNAMIC_KEYS.headings.step${currentStep}`,
  )
    ? t(`pages.deliveryStatus.introductions.DYNAMIC_KEYS.headings.step${currentStep}`)
    : null;

  return (
    <ContainerFixed>
      <Grid width={GridWidth.NARROW}>
        <Col sm={2} md={6} lg={12}>
          <HiddenH1>{t('pages.deliveryStatus.hiddenHeading')}</HiddenH1>
          <Typography className={styles.heading1} component="p" variant="headline1" maxBreakpoint={Breakpoint.TABLET}>
            {t('pages.deliveryStatus.heading')}
          </Typography>
          <LocationPicker selectedLocation={userContext} />
        </Col>
      </Grid>

      <DeliveryStatusTimeline deliveryStatus={deliveryStatus} />
      <ContainerFixed isNarrow={true}>
        {introductionsHeading && (
          <Typography className={styles.heading2} component="h2" variant="headline1" maxBreakpoint={Breakpoint.MOBILE}>
            {introductionsHeading}
          </Typography>
        )}
        <>
          {introductionItems[currentStep].map((item: string | ItemList, index: number) =>
            isObject(item) ? (
              <List list={item as ItemList} key={index} />
            ) : (
              <Typography
                className={styles.ingress}
                component="p"
                variant="paragraph2"
                key={index}
                maxBreakpoint={Breakpoint.TABLET}
              >
                {item as string}
              </Typography>
            ),
          )}
        </>
        <Typography className={styles.heading2} component="h2" variant="headline1" maxBreakpoint={Breakpoint.MOBILE}>
          {t('pages.deliveryStatus.faq.heading')}
        </Typography>
        <DeliveryFaq currentStep={currentStep} />
      </ContainerFixed>
    </ContainerFixed>
  );
};
