declare global {
  interface Window {
    CookieConsent?: {
      renew: () => void;
      show: () => void;
    };
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';

import { LogoAltiboxNegativeText } from 'app/media';
import { CustomerType } from 'app/store/types/user-context-types';
import { ContainerFixed } from 'app/components/container';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { resolveAltiboxCustomerServiceUrlFromTld, resolveAltiboxFrontPageDomain } from 'app/utils/navigation-utils';
import { Partner } from 'app/utils/partner-utils';
import { LogoType, PartnerLogo } from 'app/components/partner-logo/partner-logo';

import styles from './footer.module.scss';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const customerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  const altiboxFrontPageBaseDomain = resolveAltiboxFrontPageDomain();
  const altiboxFrontPageBaseUrl = `https://${altiboxFrontPageBaseDomain}`;

  const altiboxFrontPageDomain =
    customerLocation?.customerType === CustomerType.BEDRIFT &&
    customerLocation.partnerNumber !== Partner.DK_AltiboxOpenNet
      ? 'altibox.no/bedrift'
      : altiboxFrontPageBaseDomain;
  const altiboxFrontPageUrl =
    customerLocation?.customerType === CustomerType.BEDRIFT &&
    customerLocation.partnerNumber !== Partner.DK_AltiboxOpenNet
      ? 'https://www.altibox.no/bedrift'
      : altiboxFrontPageBaseUrl;

  const renewCookieConsentWindow = () => {
    if (window.CookieConsent && typeof window.CookieConsent.renew === 'function') {
      window.CookieConsent.renew();
    }
  };

  let altiboxCustomerServiceUrl = resolveAltiboxCustomerServiceUrlFromTld();

  if (
    customerLocation?.customerType === CustomerType.BEDRIFT &&
    customerLocation.partnerNumber !== Partner.DK_AltiboxOpenNet
  ) {
    altiboxCustomerServiceUrl = 'https://www.altibox.no/bedrift/kundeservice';
  }

  const FooterLogo = () =>
    customerLocation ? (
      <div className={styles.partnerCoBranding}>
        <PartnerLogo
          logoType={LogoType.CO_FONT_PARTNER_NEGATIVE_H}
          className={styles.logo}
          logoLink={customerLocation.partnerWebsite}
        />
      </div>
    ) : (
      <a href="/">
        <LogoAltiboxNegativeText className={styles.logoAltibox} role="img" title={altiboxFrontPageDomain} />
      </a>
    );

  return (
    <footer data-testid="footer" className={styles.footer}>
      <div className={styles.loggedInUser}>
        {customerLocation && (
          <ul className={styles.userAddressList}>
            <Typography
              data-hj-suppress={true}
              component="li"
              variant="uiText2"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.userAddressListItem}
            >{`${customerLocation.firstName} ${customerLocation.lastName}`}</Typography>
            {customerLocation.address.streetaddress && (
              <Typography
                data-hj-suppress={true}
                component="li"
                variant="uiText2"
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.userAddressListItem}
              >
                {customerLocation.address.streetaddress}
              </Typography>
            )}
            <Typography
              component="li"
              variant="uiText2"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.userAddressListItem}
            >
              {t('features.footer.customerNumber')}{' '}
              {customerLocation.crmSystem === 'SIEBEL'
                ? customerLocation.customerNumber
                : customerLocation.customerCrmId}
            </Typography>
          </ul>
        )}
      </div>

      <ContainerFixed className={styles.desktopRowReverse} isNarrow={false}>
        <nav className={styles.footerNavigation} aria-label={t('features.footer.aria-bottom-menu')}>
          <ul className={styles.footerNavigationList}>
            <li className={styles.footerNavigationListItem}>
              <a className={styles.footerNavigationListItemLink} href={altiboxCustomerServiceUrl}>
                <Typography
                  component="span"
                  variant="uiText2"
                  className={styles.linkText}
                  bold={true}
                  maxBreakpoint={Breakpoint.TABLET}
                >
                  {t('features.footer.customer-service')}
                </Typography>
              </a>
            </li>
            <li className={styles.footerNavigationListItem}>
              <a className={styles.footerNavigationListItemLink} href={altiboxFrontPageUrl}>
                <Typography
                  component="span"
                  variant="uiText2"
                  className={styles.linkText}
                  bold={true}
                  maxBreakpoint={Breakpoint.TABLET}
                >
                  {altiboxFrontPageDomain}
                </Typography>
              </a>
            </li>
            {customerLocation && customerLocation.partnerWebsite && (
              <li className={styles.footerNavigationListItem}>
                <a className={styles.footerNavigationListItemLink} href={customerLocation.partnerWebsite}>
                  <Typography
                    component="span"
                    variant="uiText2"
                    className={styles.linkText}
                    bold={true}
                    maxBreakpoint={Breakpoint.TABLET}
                  >
                    {customerLocation.partnerName}
                  </Typography>
                </a>
              </li>
            )}
            <li className={styles.footerNavigationListItem}>
              <a
                className={styles.footerNavigationListItemLink}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  renewCookieConsentWindow();
                }}
              >
                <Typography
                  component="span"
                  variant="uiText2"
                  className={styles.linkText}
                  bold={true}
                  maxBreakpoint={Breakpoint.TABLET}
                >
                  {t('features.footer.cookieInformation')}
                </Typography>
              </a>
            </li>
          </ul>
        </nav>
        <FooterLogo />
      </ContainerFixed>
    </footer>
  );
};
