import React from 'react';

import { Typography } from 'app/component-library-wave';

import styles from './modal.module.scss';

interface Props {
  heading: string;
  children?: React.ReactNode;
}

export const Modal: React.FC<Props> = ({ heading, children }) => (
  <div className={styles.modalOverlay}>
    <div className={styles.modalCard} role="dialog" aria-labelledby="modalHeading" aria-modal="true">
      <Typography id="modalHeading" className={styles.modalHeading} component="h4" variant="headline4">
        {heading}
      </Typography>
      {children}
    </div>
  </div>
);
