import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Breakpoint, Typography } from 'app/component-library-wave';
import { Page } from 'app/service/navigation/pages/page-types';
import { Arrow, ExternalArrow } from 'app/media/';
import { config } from 'app/config/config';

import styles from './navigation.module.scss';

export interface LinkSection {
  title?: string;
  pages: Page[];
}

interface TranslatedLinkSection {
  title?: string;
  links: Link[];
}

interface Link {
  url: string;
  name: string;
  description?: string;
  openInNewTab?: boolean;
}
interface Props {
  section: LinkSection;
}

const CONTEXT_PATH = config.app.contextPath;

const linkContent = (link: Link) => (
  <>
    <div>
      <Typography component="div" variant="uiText2" bold={true} maxBreakpoint={Breakpoint.TABLET}>
        {link.name}
      </Typography>
      {link.description && (
        <Typography
          className={styles.linkDescription}
          component="div"
          variant="uiText2"
          maxBreakpoint={Breakpoint.TABLET}
        >
          {link.description}
        </Typography>
      )}
    </div>
    <div className={styles.navLinkCol2}>
      {link.openInNewTab ? <ExternalArrow /> : <Arrow direction="right" variant="short" />}
    </div>
  </>
);

const linkTarget = (link: Link) => (link.openInNewTab ? '_blank' : '_self');

const renderSection = (section: TranslatedLinkSection) => {
  const classnames = classNames(styles.navigation);

  // Why is this a <div> instead of a <nav>?
  // <nav> should contain navigation blocks that are usually recurring in the site’s pages (i.e. not the main content).
  // A NavigationSection contains main content that happens to be navigational.

  return (
    <div className={classnames}>
      <section>
        {section.title && (
          <Typography
            component="h2"
            variant="headline5"
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.sectionHeader}
          >
            {section.title}
          </Typography>
        )}
        <ul className={styles.sectionList}>
          {section.links.map((link) => (
            <li key={link.name}>
              {link.url.startsWith(CONTEXT_PATH) ? (
                <NavLink to={link.url} className={styles.navLink}>
                  {linkContent(link)}
                </NavLink>
              ) : (
                <a href={link.url} className={styles.navLink} target={linkTarget(link)}>
                  {linkContent(link)}
                </a>
              )}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export const Navigation: React.FC<Props> = ({ section }) => {
  const { t } = useTranslation();
  const TranslatedLinkSection = {
    title: section.title,
    links: section.pages.map((page) => ({
      url: page.url,
      name: t(page.i18n),
      description: page.description ? t(page.description) : undefined,
      openInNewTab: page.openInNewTab,
    })),
  };

  return renderSection(TranslatedLinkSection);
};
