import { ReactElement } from 'react';

export enum Breakpoint {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export enum UiComponentState {
  SUCCESS = 'ComponentStateSuccess',
  ERROR = 'ComponentStateError',
  DISABLED = 'ComponentStateDisabled',
}

export enum UiComponentSize {
  SMALL = 'ComponentSizeSmall',
  MEDIUM = 'ComponentSizeMedium',
  LARGE = 'ComponentSizeLarge',
}

export enum ButtonType {
  PRIMARY_A = 'PrimaryA',
  PRIMARY_B = 'PrimaryB',
  SECONDARY = 'Secondary',
  TERTIARY = 'Tertiary',
}
export type IconWrapper = {
  icon: ReactElement;
  placeIconBeforeText?: boolean;
};

export enum HeadingLevel {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum Theme {
  NORMAL = 'Normal',
  DARK = 'Dark',
}
