import { getString, isNumber } from '../network-settings/network-settings-utils';
import { ProtocolType } from './firewall-rule-form/firewall-rule-portType';
import { Field } from './form-utils';

export interface FormModel {
  name: Field<string>;
  type: Field<string>;
  extPortFrom: Field<string>;
  extPortTo: Field<string>;
  intPort: Field<string>;
  intIpLastOctet: Field<string>;
  intIpFirstOctets: string;
}

export const getNumberString = (value: number | undefined) => (value ? value.toString(10) : '');
export const getNumber = (value: string) => Number.parseInt(value, 10);
export const validName = (value: string | undefined) => !!(value ?? '').match(/^\w+$/);
export const isPortValid = (value: string | undefined) =>
  isNumber(value) && Number(value) >= 1 && Number(value) <= 65535;
export const trimLeadingZeros = (value: string) => (isNumber(value) ? Number(value).toString() : value);
export const getIpFirstOctets = (ip: string) => {
  const ipSplit = ip.split('.');
  return `${ipSplit[0]}.${ipSplit[1]}.${ipSplit[2]}`;
};

export const toFormModel: (
  customerNetwork: MinesiderBackend.CustomerNetwork,
  portForwarding: MinesiderBackend.PortForwarding,
) => FormModel = (customerNetwork, portForwarding) => {
  const lanIp = customerNetwork.networkSettings?.routerSettings?.ipAddress;
  const intIpSplit = portForwarding.internalIp.split('.');
  const intIpFirstOctets = getIpFirstOctets(portForwarding.internalIp || lanIp || '');

  return {
    name: { value: getString(portForwarding.name) },
    type: { value: getString(portForwarding.type) },
    extPortFrom: { value: getNumberString(portForwarding.externalPortFrom) },
    extPortTo: { value: getNumberString(portForwarding.externalPortTo) },
    intPort: { value: getNumberString(portForwarding.internalPort) },
    intIpLastOctet: { value: getString(intIpSplit[3]) },
    intIpFirstOctets,
  };
};

export const toNewRuleFormModel: (customerNetwork: MinesiderBackend.CustomerNetwork) => FormModel = (
  customerNetwork,
) => {
  const lanIp = customerNetwork.networkSettings?.routerSettings?.ipAddress;
  const intIpFirstOctets = getIpFirstOctets(lanIp || '');

  return {
    name: { value: '' },
    type: { value: ProtocolType.TCP },
    extPortFrom: { value: '' },
    extPortTo: { value: '' },
    intPort: { value: '' },
    intIpLastOctet: { value: '' },
    intIpFirstOctets,
  };
};

export const toValueModel: (form: FormModel) => MinesiderBackend.UpdatePortForwarding = (form) => ({
  name: form.name.value,
  type: form.type.value,
  internalIp: `${form.intIpFirstOctets}.${form.intIpLastOctet.value}`,
  externalPortFrom: getNumber(form.extPortFrom.value),
  externalPortTo: getNumber(form.extPortTo.value),
  internalPort: getNumber(form.intPort.value),
});

export const isFormValid = (form: FormModel) =>
  !(
    form.name.error ||
    form.type.error ||
    form.intIpLastOctet.error ||
    form.extPortFrom.error ||
    form.extPortTo.error ||
    form.intPort.error
  );
