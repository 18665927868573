import { createSlice, Slice } from '@reduxjs/toolkit';

import {
  fetchStreamingService,
  getStreamingServices,
  setFetchStatus,
} from 'app/store/actions/streaming-service-actions';
import { startProvAuth } from 'app/store/actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { FetchStatus, StreamingState } from 'app/store/root-types';

const initialState: StreamingState = {
  fetchStatus: FetchStatus.NOT_STARTED,
  streamingServicesFetchStatus: FetchStatus.NOT_STARTED,
};

const streamingSlice: Slice<StreamingState> = createSlice({
  name: 'streamingService',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);

    builder.addCase(setFetchStatus, (state, action) => {
      state.fetchStatus = action.payload;
    });
    builder.addCase(getStreamingServices.pending, (state) => {
      state.streamingServicesFetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getStreamingServices.fulfilled, (state, action) => {
      state.streamingServices = action.payload;
      state.streamingServicesFetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(getStreamingServices.rejected, (state) => {
      state.streamingServicesFetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(fetchStreamingService.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(fetchStreamingService.fulfilled, (state, action) => {
      state.fetchStatus = FetchStatus.FULFILLED;
      state.streamingService = action.payload;
    });
    builder.addCase(fetchStreamingService.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

const { reducer } = streamingSlice;

export { reducer };
