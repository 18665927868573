import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'app/component-library-wave';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { InactiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { clearUsersFetchStatus } from 'app/store/actions/users-actions';
import { createUserResponse, getUsersResponse } from 'app/store/actions/users-thunks';
import { FetchStatus } from 'app/store/root-types';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { topLevelDomainDetection } from 'app/utils/language-utils';

import { ContainerFixed } from 'app/components/container';
import { CrmUser, UserForm } from './user-form';
import { Spinner } from 'app/components/spinner';

const topLevelDomain = topLevelDomainDetection();
const getCountryCode = () => {
  switch (topLevelDomain) {
    case 'nb':
      return '+47';
    case 'da':
      return '+45';
    default:
      return '';
  }
};

export const UserCreatePage: InactiveCustomerLocationPage = () => {
  const { t } = useTranslation();
  const userCreateStatus = useAppSelector((state) => state.users.createUser.fetchStatus);
  const crmCustomerId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const crmId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.crmId);
  const users = useAppSelector((state) => state.users.users);
  const userFetchStatus = useAppSelector((state) => state.users.fetchStatus);
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();

  const getUser = (userId: string) => users?.find((u) => u.id === userId);
  const myUser = crmId ? getUser(crmId) : undefined;
  const isPrimaryOrAdmin = ['PRIMARY', 'ADMIN'].includes(myUser?.role ?? '');

  const user: MinesiderBackend.CrmUser = {
    id: '',
    active: true,
    firstName: '',
    lastName: '',
    email: '',
    mobileCountryCode: getCountryCode(),
    mobileNumber: '',
    phoneCountryCode: getCountryCode(),
    role: 'USER',
    preferredContactMethod: 'EMAIL_MY_MESSAGES',
    dateOfBirth: '',
  };
  const handleSubmit = (u: CrmUser) => dispatch(createUserResponse(u));

  useEffect(() => {
    if (crmCustomerId && userFetchStatus === FetchStatus.NOT_STARTED && userCreateStatus === FetchStatus.NOT_STARTED) {
      dispatch(getUsersResponse(crmCustomerId));
    }
  }, [crmCustomerId, dispatch, userFetchStatus, userCreateStatus]);

  useEffect(() => {
    if (userCreateStatus === FetchStatus.FULFILLED) {
      dispatch(clearUsersFetchStatus());
      goToPath(navigationService.PAGES.users.url);
    }
  }, [dispatch, userCreateStatus, crmCustomerId]);

  useEffect(() => {
    if (userFetchStatus === FetchStatus.REJECTED) {
      return () => {
        dispatch(clearUsersFetchStatus());
      };
    }
  }, [dispatch, userFetchStatus]);

  return (
    <ContainerFixed isNarrow={true}>
      <>
        {userFetchStatus === FetchStatus.PENDING && <Spinner />}
        {userFetchStatus === FetchStatus.REJECTED && (
          <Alert
            alertType="warning"
            heading={t('pages.userCreate.errorMessages.fetchingFailed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
        {userFetchStatus === FetchStatus.FULFILLED && !isPrimaryOrAdmin && (
          <Alert
            alertType="warning"
            heading={t('pages.userCreate.errorMessages.creationNotAllowed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
        {userFetchStatus === FetchStatus.FULFILLED && isPrimaryOrAdmin && (
          <UserForm
            heading={t('pages.userCreate.heading')}
            initialValues={user}
            onSubmit={handleSubmit}
            canEditRole={true}
            submitButtonName={t('pages.userCreate.buttons.save.name')}
            isLoading={userCreateStatus === FetchStatus.PENDING}
            error={
              userCreateStatus === FetchStatus.REJECTED ? t('pages.userCreate.errorMessages.creationFailed') : undefined
            }
            canEditDateOfBirth={true}
          />
        )}
      </>
    </ContainerFixed>
  );
};
