import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';
import { Arrow } from 'app/media';

import { ContainerFixed } from 'app/components/container';

import styles from '../common.module.scss';

export const ResubscribeInformation = () => {
  const { t } = useTranslation();

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.information}>
        <Typography
          variant="headline5"
          component="p"
          className={styles.informationHeadline}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.inactive.informationHeadline')}
        </Typography>
        <Typography
          variant="paragraph2"
          component="p"
          className={styles.informationParagraph}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.inactive.information')}
        </Typography>
        <a className={styles.cta} href="/jatakk/">
          {t('pages.inactive.cta')}
          <Arrow className={styles.arrow} role="presentation" direction="left" variant="long" />
        </a>
      </div>
    </ContainerFixed>
  );
};
