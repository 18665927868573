import { ContainerFixed } from 'app/components/container';
import { VoipRejection, VoipSettings } from 'app/store/root-types';
import { formatVoipNumber } from 'app/utils/string-util';
import { Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';
import { NoRuleCard } from './no-rule-card';
import { RuleCard } from './rule-card';
import { FdsButton } from '@lyse-as/formds-react';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import styles from '../voip-common.module.scss';

export const RejectionCard = ({
  location,
  settings,
  isEditable,
  phone,
  hideHeader,
}: {
  location: MinesiderBackend.VoipLocation;
  settings: VoipSettings;
  isEditable?: boolean;
  phone: string;
  hideHeader?: boolean;
}) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();

  const numberSettings = settings[phone];
  const rejections = (numberSettings?.settings?.incoming?.rejects as VoipRejection[]) || [];
  const rejectionCards = rejections?.map((rejection) => {
    const card = [
      {
        header: t('pages.voip.rejection.rejectFromLabel'),
        description: rejection.from?.map((from) => formatVoipNumber(from)).join(', ') || '',
      },
    ];
    return {
      card,
      ruleAppId: rejection.appGeneratedId,
    };
  });
  return (
    <ContainerFixed className={styles.sectionContainer} key={phone + location.address}>
      {!hideHeader && (
        <Typography component="h4" variant="formds-lead" className={styles.header} bold={true}>
          {t('pages.voip.rejection.rejectionFor', { voipNumber: formatVoipNumber(phone) })}
        </Typography>
      )}
      {rejections.length === 0 && <NoRuleCard message={t('pages.voip.rejection.emptyList')} key={location.address} />}

      <ul>
        {rejectionCards.map((rejection, i) => (
          <li key={phone + i}>
            <RuleCard
              entries={rejection.card}
              ruleAppId={rejection.ruleAppId}
              phone={phone}
              deleteButton={isEditable ? t('pages.voip.rejection.cta.delete') : null}
            />
          </li>
        ))}
      </ul>

      {isEditable && (
        <FdsButton
          className={styles.newRuleButton}
          variant="secondary"
          onClick={() => goToPath(navigationService.PAGES.voipSettingsRejectionNewRule.url)}
        >
          {t('pages.voip.rejection.rules.name')}
        </FdsButton>
      )}
    </ContainerFixed>
  );
};
