import { Alert } from 'app/component-library-wave';
import { ContainerFixed } from 'app/components/container/container';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { getEmailAccountResponse } from 'app/store/actions/email-thunks';
import { FetchStatus } from 'app/store/root-types';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import styles from './email-inactive-warning.module.scss';

interface Props {
  showlinkToEmailPage?: boolean;
  className?: string;
}

export const EmailInactiveWarning = ({ showlinkToEmailPage, className }: Props) => {
  const { t } = useTranslation();
  const { fetchStatus, emailAccounts } = useAppSelector((state) => state.email);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(getEmailAccountResponse());
    }
  }, [fetchStatus]);

  const showBanner = emailAccounts && emailAccounts.filter((x) => !x.active).length > 0;
  if (!showBanner) {
    return null;
  }

  const alertExpandContent = showlinkToEmailPage ? (
    <Trans i18nKey="features.email.inactiveAccountsWarningBannerExpandedContent">
      Du kan slette inaktive e-postkontoer på
      <NavLink to={navigationService.PAGES.email.url} className={styles.emailPageLink}>
        siden for e-postkontoer
      </NavLink>
    </Trans>
  ) : null;

  return (
    <ContainerFixed isNarrow={false} className={classNames(styles.inactiveWarningBanner, className)}>
      <Alert
        alertType="warning"
        heading={t('features.email.inactiveAccountsWarningBanner', { emailPageUrl: navigationService.PAGES.email.url })}
        headingElement="strong"
        isDismissable={true}
        isInitiallyExpanded={showlinkToEmailPage}
        isExpandable={showlinkToEmailPage === true}
        role="alert"
      >
        {alertExpandContent}
      </Alert>
    </ContainerFixed>
  );
};
