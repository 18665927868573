import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { startProvAuth } from 'app/store/actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { DeliveryStatusState } from 'app/store/root-types';
import {
  createServicePointCustomerDiggingStatus,
  readServicePointDeliveryStatus,
} from 'app/store/actions/delivery-status-actions';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';

const initialState: DeliveryStatusState = {
  fetchDone: false,
};

const deliveryStatusSlice: Slice<DeliveryStatusState> = createSlice({
  name: 'deliveryStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      readServicePointDeliveryStatus.fulfilled,
      (state, action: PayloadAction<MinesiderBackend.ServicePointDeliveryStatus>) => {
        state.deliveryStatus = {
          step: {
            mainStep: action.payload.step.mainStep,
            subStep: action.payload.step.subStep,
            combinedStep: `${action.payload.step.mainStep}-${action.payload.step.subStep}`,
          },
          excavationPlannedStartDate: action.payload.excavationPlannedStartDate,
          excavationPlannedFinishDate: action.payload.excavationPlannedFinishDate,
          installationPlannedStartDate: action.payload.installationPlannedStartDate,
          installationPlannedFinishDate: action.payload.installationPlannedFinishDate,
          connectionPlannedStartDate: action.payload.connectionPlannedStartDate,
          connectionPlannedFinishDate: action.payload.connectionPlannedFinishDate,
          activationDate: action.payload.activationDate,
          customerDiggingDone: action.payload.diggingDone,
        };
        state.fetchDone = true;
      },
    );
    builder.addCase(readServicePointDeliveryStatus.rejected, (state) => {
      state.fetchDone = true;
    });
    builder.addCase(createServicePointCustomerDiggingStatus.fulfilled, (state) => {
      if (state.deliveryStatus) {
        state.deliveryStatus.customerDiggingDone = !state.deliveryStatus.customerDiggingDone;
      }
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(selectCustomerLocation, () => initialState);
  },
});

const { reducer } = deliveryStatusSlice;

export { reducer };
