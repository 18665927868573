import React, { ReactHTML, useEffect, useState } from 'react';
import { Alert, Breakpoint, HeadingLevel, Typography, UiComponentSize } from 'app/component-library-wave';
import { Variant } from 'app/component-library-wave/components/typography/types';
import classNames from 'classnames';

import { HelpCircle } from 'app/media';

import styles from './helper.module.scss';

interface Props {
  heading: string;
  id?: string;
  text?: string;
  textVariant?: Variant;
  textComponent?: keyof ReactHTML;
  maxBreakpoint?: Breakpoint;
  className?: string;
  classNameAlert?: string;
  classNameExpanded?: string;
  children: React.ReactNode;
  alertHeadingElement: HeadingLevel;
  handleChildrenCallback?: (helpElement?: JSX.Element) => void;
}

export const Helper: React.FC<Props> = (props) => {
  const {
    heading,
    id,
    text,
    textVariant,
    textComponent,
    maxBreakpoint,
    className,
    classNameAlert,
    classNameExpanded,
    children,
    alertHeadingElement,
    handleChildrenCallback,
    ...rest
  } = props;
  const [showHelpAlert, setShowHelpAlert] = useState(false);
  const hasText = text && textVariant && textComponent;

  useEffect(() => {
    if (!handleChildrenCallback) {
      return;
    }
    if (showHelpAlert) {
      handleChildrenCallback(HelpText());
    } else {
      handleChildrenCallback();
    }
  }, [showHelpAlert]);

  const toggle = () => {
    setShowHelpAlert((show) => !show);
  };

  const HelpText = () => (
    <Alert
      heading={heading}
      headingElement={alertHeadingElement}
      alertType="info"
      isInitiallyExpanded={true}
      isExpandable={false}
      isDismissable={true}
      hideIcon={true}
      uiComponentSize={UiComponentSize.SMALL}
      onClose={() => setShowHelpAlert(false)}
      className={classNames(styles.alert, classNameAlert)}
    >
      {children}
    </Alert>
  );

  return (
    <div className={showHelpAlert ? classNameExpanded : className}>
      <div className={styles.textWithButton}>
        {hasText && (
          <Typography
            variant={textVariant}
            component={textComponent}
            bold={true}
            maxBreakpoint={maxBreakpoint}
            className={styles.paddingRight}
            id={id}
            {...rest}
          >
            {text}
          </Typography>
        )}
        <button
          type="button"
          aria-hidden={heading === undefined}
          aria-label={heading}
          onClick={toggle}
          aria-expanded={showHelpAlert}
          className={styles.helpButton}
        >
          {showHelpAlert ? (
            <HelpCircle variant="filled" className={styles.fillBlue} />
          ) : (
            <HelpCircle variant="outlined" className={styles.strokeBlue} />
          )}
        </button>
      </div>
      {children && showHelpAlert && !handleChildrenCallback && <HelpText />}
    </div>
  );
};
