import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react/types/errorboundary';

interface Props {
  children: ReactNode;
  fallback: FallbackRender;
}

export const FatalErrorBoundary: React.FC<Props> = (props) => (
  <Sentry.ErrorBoundary fallback={props.fallback}>{props.children}</Sentry.ErrorBoundary>
);
