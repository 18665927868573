import classNames from 'classnames';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Breakpoint } from '../../types/global';
import { HelpText, HelpTextProps } from '../help-text/help-text';
import { Typography } from '../typography';

import styles from './toggle.module.scss';
import { DynamicProperties } from '../input/types';

interface TogglePropsInner extends InputHTMLAttributes<HTMLInputElement> {
  isOn: boolean;
  toggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  label: string;
  textOn: string;
  textOff: string;
  isDisabled?: boolean;
  hideButton?: boolean;
}

export type ToggleProps = TogglePropsInner & HelpTextProps;

export const Toggle = ({
  label,
  id,
  toggle,
  isOn,
  textOn,
  textOff,
  isDisabled,
  hideButton,
  helpText,
  uiComponentState,
  ...rest
}: ToggleProps) => {
  const [focused, setFocused] = useState(false);
  const focus = () => setFocused(true);
  const blur = () => setFocused(false);

  useEffect(() => {
    if (isDisabled) {
      setFocused(false);
    }
  }, [isDisabled]);

  const rowCssClass = classNames(
    styles['toggle-container-row'],
    focused ? styles.focused : null,
    isDisabled ? styles.disabled : null,
  );
  const helpTextId = `${id}HelpText`;

  const dynamicAttributes: DynamicProperties = {};
  if (helpText) {
    dynamicAttributes['aria-describedby'] = helpTextId;
  }

  return (
    <React.Fragment>
      <div className={rowCssClass}>
        <Typography
          variant="uiText2"
          component="label"
          htmlFor={id}
          maxBreakpoint={Breakpoint.TABLET}
          aria-label={rest['aria-label']}
          className={styles.label}
        >
          {label}
        </Typography>
        {!hideButton && (
          <div className={styles['toggle-container']}>
            <div className={styles.toggle}>
              <input
                id={id}
                className={styles.toggleInput}
                onChange={toggle}
                type="checkbox"
                checked={isOn}
                onFocus={focus}
                onBlur={blur}
                {...dynamicAttributes}
                disabled={isDisabled}
                {...rest}
              />
              <span className={`${styles.track} ${isOn ? '' : styles.off}`} />
              <span className={`${styles.thumb} ${isOn ? '' : styles.off}`} />
            </div>

            <Typography component="span" variant="uiText2" className={styles.label} maxBreakpoint={Breakpoint.TABLET}>
              {isOn ? textOn : textOff}
            </Typography>
          </div>
        )}
      </div>
      <HelpText id={helpTextId} helpText={helpText} uiComponentState={uiComponentState} />
    </React.Fragment>
  );
};
