import { FC, useEffect, useRef, useState } from 'react';

import { Typography } from 'app/component-library-wave';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination, Zoom } from 'swiper/modules';
import { CloseIcon, MaximizeIcon, MinusIcon, PlusIcon } from 'app/media';
import classnames from 'classnames';
import styles from './image-swiper.module.scss';

import 'swiper/swiper-bundle.css';

export interface SwiperImage {
  url: string;
  imgAlt?: string;
  caption?: string;
}
interface Props {
  swiperImages: SwiperImage[];
}

export const ImageSwiper: FC<Props> = ({ swiperImages }: Props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [manualZoomLevel, setManualZoomLevel] = useState(1);
  const [wasManualZoomIn, setWasManualZoomIn] = useState<boolean>();

  const closeButton = useRef<HTMLDivElement>(null);
  const openButton = useRef<HTMLDivElement>(null);
  const plusButton = useRef<HTMLDivElement>(null);
  const minusButton = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperRef>(null);
  const dummyRef = useRef<HTMLDivElement>(null);

  let clickCount = 0;
  const minZoomLevel = 1;
  const maxZoomLevel = 3;
  const zoomStep = 0.2;
  const ESCAPE_KEY = 27;

  useEffect(() => {
    /* When entering fullscreen, we set constraints to the main
       body to avoid user scrolling the DOM in the background.
       Applied in app.tsx using redux for state handling.
       Buth when removing the constraint, user is back at the top.
       Also adding styles to body here to prevent re-render the react components.
      */

    if (isFullscreen) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed'; // iOS.
      return;
    }

    swiperRef.current?.swiper.zoom.out();
    openButton.current?.focus();
  }, [isFullscreen]);

  useEffect(
    () =>
      // Reset zoom on component dismount.
      () => {
        resetZoom();
      },

    [],
  );

  useEffect(() => {
    if (wasManualZoomIn) {
      plusButton.current?.focus();
      return;
    }
    minusButton.current?.focus();
  }, [manualZoomLevel]);

  function resetZoom() {
    document.body.style.height = '';
    document.body.style.overflow = 'visible';
    document.body.style.position = 'static';
  }

  const handleKeyPress = (_swiper: unknown, code: string) => {
    if (!isFullscreen) {
      return;
    }
    if (parseInt(code, 10) === ESCAPE_KEY) {
      toggleFullscreen();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, action: () => void) => {
    switch (e.code) {
      case 'Enter':
      case 'Space':
      case 'NumpadEnter':
        action();
        e.preventDefault();
        return;
      default:
        return;
    }
  };

  const handleMouseClick = () => {
    clickCount++;
    setTimeout(() => {
      if (clickCount === 1) {
        toggleFullscreen();
      }
      if (clickCount > 1) {
        setManualZoomLevel(swiperRef.current?.swiper.zoom.scale || 1);
      }
      clickCount = 0;
    }, 300);
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      setScrollOffset(window.scrollY);
    }
    if (isFullscreen) {
      // Scrolling down to last position puts the user back to their previous state.
      resetZoom();
      window.scrollTo(0, scrollOffset);
    }
    setIsFullscreen((current) => !current);
  };

  const zoomIn = () => {
    if (manualZoomLevel + zoomStep > maxZoomLevel) {
      return;
    }
    const transformationElement = getActiveImageContainer();
    transformationElement.style.setProperty('transform', `scale(${manualZoomLevel + zoomStep})`);
    setWasManualZoomIn(true);
    setManualZoomLevel((currentZoomLevel) => currentZoomLevel + zoomStep);
  };

  const zoomOut = () => {
    if (manualZoomLevel - zoomStep < minZoomLevel) {
      return;
    }
    const transformationElement = getActiveImageContainer();
    transformationElement.style.setProperty('transform', `scale(${manualZoomLevel - zoomStep})`);
    setWasManualZoomIn(false);
    setManualZoomLevel((currentZoomLevel) => currentZoomLevel - zoomStep);
  };

  const getActiveImageContainer = () =>
    swiperRef.current?.swiper.wrapperEl.childNodes[swiperRef.current?.swiper.activeIndex].firstChild?.firstChild
      ?.firstChild as HTMLDivElement;

  const OpenCloseIcons = () =>
    isFullscreen ? (
      <div
        className={styles.iconClose}
        onClick={toggleFullscreen}
        role="button"
        onKeyDown={(e) => handleKeyDown(e, toggleFullscreen)}
        ref={closeButton}
        tabIndex={0}
        onBlur={(e) => {
          if (e.relatedTarget === dummyRef.current) {
            plusButton.current?.focus();
          }
        }}
      >
        <CloseIcon />
      </div>
    ) : (
      <div
        className={styles.iconMaximize}
        onClick={toggleFullscreen}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => handleKeyDown(e, toggleFullscreen)}
        ref={openButton}
      >
        <MaximizeIcon />
      </div>
    );

  const PlusMinusIcons = () =>
    isFullscreen ? (
      <>
        <div
          className={styles.iconZoomIn}
          tabIndex={0}
          onClick={zoomIn}
          role="button"
          onBlur={(e) => {
            if (e.relatedTarget !== minusButton.current) {
              closeButton.current?.focus();
            }
          }}
          ref={plusButton}
          onKeyDown={(e) => {
            handleKeyDown(e, zoomIn);
          }}
        >
          <PlusIcon />
        </div>
        <div
          className={styles.iconZoomOut}
          tabIndex={0}
          onClick={zoomOut}
          role="button"
          ref={minusButton}
          onKeyDown={(e) => {
            handleKeyDown(e, zoomOut);
          }}
        >
          <MinusIcon />
        </div>
      </>
    ) : null;

  const Controls = () => (
    <div className={styles.controlsContainer}>
      <PlusMinusIcons />
      <OpenCloseIcons />
      <div ref={dummyRef} tabIndex={isFullscreen ? 0 : -1} />
    </div>
  );

  const numberOfImg = swiperImages.filter((img) => img.url);
  return (
    <Swiper
      modules={[Pagination, Keyboard, Navigation, Zoom]}
      spaceBetween={50}
      loop={numberOfImg.length > 1}
      navigation={{ enabled: true }}
      loopAddBlankSlides={true}
      slidesPerView={1}
      ref={swiperRef}
      pagination={{ clickable: true, type: 'bullets' }}
      keyboard={{
        enabled: true,
        onlyInViewport: true,
      }}
      onKeyPress={handleKeyPress}
      onClick={handleMouseClick}
      zoom={true}
      className={`${isFullscreen ? styles.fullScreen : styles.swiper}`}
    >
      {swiperImages.map((swiperImage, index) => (
        <SwiperSlide key={index}>
          <div className={classnames(styles.imageContainer, isFullscreen ? styles.imageZoomedInContainer : undefined)}>
            <div className="swiper-zoom-container">
              <img
                src={swiperImage.url}
                alt={swiperImage.imgAlt}
                className={isFullscreen ? styles.imgFullScreen : styles.swiperImage}
              />
            </div>
            {isFullscreen && (
              <div className={styles.imageText}>
                {swiperImage.caption && (
                  <Typography component="p" variant="uiText3" bold={true} className={styles.imageTitle}>
                    {swiperImage.caption}
                  </Typography>
                )}

                {swiperImage.imgAlt && (
                  <Typography component="p" variant="paragraph3" bold={false}>
                    {swiperImage.imgAlt}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </SwiperSlide>
      ))}
      <Controls />
    </Swiper>
  );
};
