import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { config } from 'app/config/config';
import { ParamUtils } from 'app/utils/param-utils';
import { LanguageSwitcher } from 'app/features/language-switcher/language-switcher';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { enableTranslatorMode } from 'app/store/actions/core-actions';
import { CookieCategory, thirdPartyCookieInlineScript } from 'app/utils/cookies';

export const HelmetHead: React.FC = () => {
  const lng = ParamUtils.getQueryParam('lng') || 'nb';
  const lngBar = ParamUtils.getQueryParam('lngBar');
  const translatorMode = useAppSelector((state) => state.core.isTranslatorMode);
  const dispatch = useAppDispatch();

  const [showLanguageBar, setShowLanguageBar] = useState(lngBar !== null);

  useEffect(() => {
    if (showLanguageBar) {
      dispatch(enableTranslatorMode());
    }
  }, []);

  if (showLanguageBar) {
    return <LanguageSwitcher initialLocizeLanguage={lng} setShowLanguageBar={setShowLanguageBar} />;
  }

  if (translatorMode || process.env.NODE_ENV === 'development') {
    return null;
  }

  return (
    <Helmet>
      <script
        id="CookieConsent"
        src="https://policy.app.cookieinformation.com/uc.js"
        data-culture="NB"
        data-gcm-version="2.0"
        type="text/javascript"
      ></script>
      {thirdPartyCookieInlineScript({
        categories: [CookieCategory.MARKETING],
        children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${config.gtm.gtmId}');`,
      })}
    </Helmet>
  );
};
