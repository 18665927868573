import { useEffect } from 'react';
import { Button, ButtonType, Typography as T } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { Trans, useTranslation } from 'react-i18next';

import { useAppNavigation } from 'app/utils/navigation-utils';
import { toFormModel, toValueModel } from '../../network-settings/network-settings-utils';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { fetchNetworkSettings, updateNetworkSettings } from 'app/store/actions/network-thunks';
import { Spinner } from 'app/components/spinner';
import { FetchStatus } from 'app/store/root-types';
import { HiddenH1 } from 'app/components/hidden-h1';
import { ContainerFixed } from 'app/components/container';
import { FALLBACK_SELFCARE_HGW_URL } from 'app/pages/internet/network-common';
import { useLoadEquipmentEnrichment } from 'app/hooks/use-equipment';

import styles from './bridge-mode-confirmation.module.scss';

export const BridgeModeConfirmation: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const { data: equipmentEnrichment } = useAppSelector((state) => state.enrichment.equipment);
  const dispatch = useAppDispatch();
  const customerId = props.userContext.customerCrmId;
  const servicePointId = props.userContext.servicePointId;
  const {
    data: networkData,
    fetchStatus,
    updateNetworkSettings: { fetchStatus: updateFetchStatus },
  } = useAppSelector((state) => state.network);
  const { fetchStatus: enrichmentFetchStatus } = useLoadEquipmentEnrichment();

  const isOn = networkData?.hgw.bridge;

  const hgwEnrichment = equipmentEnrichment?.find(
    (equipment) => equipment.provSpecificationId === networkData?.hgw.type,
  );

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchNetworkSettings({ servicePointId, customerId }));
    }
  }, [fetchStatus, customerId]);

  if (enrichmentFetchStatus === FetchStatus.PENDING || enrichmentFetchStatus === FetchStatus.NOT_STARTED) {
    return <Spinner />;
  }

  const toggleBridgeMode = () => {
    if (!networkData?.wifiSettings || !networkData.hgw.canToggleBridge) {
      return;
    }

    const updatedCustomerNetwork: MinesiderBackend.CustomerNetwork = {
      ...networkData,
      hgw: {
        ...networkData.hgw,
        bridge: !networkData.hgw.bridge,
      },
    };
    const formModel = toFormModel(updatedCustomerNetwork);

    const updateNetwork = toValueModel(formModel, networkData);
    dispatch(updateNetworkSettings({ customerId, servicePointId, network: updateNetwork })).then(() =>
      goToPath(navigationService.PAGES.internetSettingsOverview.url),
    );
  };
  const Buttons = () => (
    <div className={styles.button}>
      {updateFetchStatus === FetchStatus.PENDING && <Spinner />}
      {updateFetchStatus !== FetchStatus.PENDING && (
        <>
          <Button buttonType={ButtonType.PRIMARY_B} type="submit" onClick={toggleBridgeMode}>
            {isOn
              ? t('pages.network.advanced.settings.sections.bridge.buttonOff')
              : t('pages.network.advanced.settings.sections.bridge.buttonOn')}
          </Button>
          <Button
            buttonType={ButtonType.SECONDARY}
            type="button"
            onClick={() => goToPath(navigationService.PAGES.internetSettingsOverview.url)}
          >
            {t('pages.network.advanced.settings.buttons.cancel')}
          </Button>
        </>
      )}
    </div>
  );

  const BridgeModeOn = () => (
    <>
      <T component="h2" variant="headline3" id="confirmationLabel" className={styles.header}>
        {t('pages.network.advanced.settings.helpTexts.bridge.confirmation.turnOff.heading')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subHeader}>
        {t('pages.network.advanced.settings.helpTexts.bridge.confirmation.turnOff.paragraph.content1')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subHeader}>
        {t('pages.network.advanced.settings.helpTexts.bridge.confirmation.turnOff.paragraph.content2')}
      </T>
    </>
  );

  const BridgeModeOff = () => (
    <>
      <T component="h2" variant="headline2" id="confirmationLabel" className={styles.header}>
        {t('pages.network.advanced.settings.helpTexts.bridge.confirmation.turnOn.heading')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subParagraph}>
        {t('pages.network.advanced.settings.helpTexts.bridge.paragraph1.content')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subParagraph}>
        {t('pages.network.advanced.settings.helpTexts.bridge.paragraph2.content')}
      </T>
      {(hgwEnrichment?.bridgeModePortLabel && (
        <>
          <T component="p" variant="paragraph1" className={styles.subParagraph}>
            {t('pages.network.advanced.settings.helpTexts.bridge.port.known', {
              bridgeModePortLabel: hgwEnrichment.bridgeModePortLabel,
            })}
          </T>
          {hgwEnrichment?.bridgeModeImage?.imageUrl && (
            <a href={hgwEnrichment.bridgeModeImage.imageUrl}>
              <img
                alt={hgwEnrichment.bridgeModeImage.altText}
                src={hgwEnrichment.bridgeModeImage.imageUrl}
                className={styles.portImage}
              />
            </a>
          )}
        </>
      )) || (
        <T component="p" variant="paragraph1" className={styles.subParagraph}>
          <Trans i18nKey={'pages.network.advanced.settings.helpTexts.bridge.port.unknown'}>
            placeholdertext
            <a href={hgwEnrichment?.customerServiceUrl || FALLBACK_SELFCARE_HGW_URL} />
          </Trans>
        </T>
      )}
    </>
  );

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1 focusHeading={false}>{t('pages.network.advanced.name').toString()}</HiddenH1>
      <div className={styles.messageContainer}>
        {isOn ? <BridgeModeOn /> : <BridgeModeOff />}
        <Buttons />
      </div>
    </ContainerFixed>
  );
};
