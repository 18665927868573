import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Breakpoint, HeadingLevel, Input, Typography } from 'app/component-library-wave';

import { ErrorMessages } from 'app/features/form/error-messages';
import { Field, uiComponentState } from 'app/features/form/form-utils';

import { Card } from 'app/components/card';
import { Helper } from 'app/components/helper/helper';

import { IpAddressWithSeparator } from '../../network-common';
import { FormModel } from './network-settings-utils';

import styles from './network-settings-form.module.scss';

interface Props {
  network: MinesiderBackend.CustomerNetwork;
  form: FormModel;
  handleChangeString: (
    value: string,
    field: (f: Field<string>) => Partial<FormModel>,
    validator?: (value: string) => string | undefined,
  ) => void;
  validateLastOctetIp: (value: string) => string | undefined;
  validateThirdOctetIp: (value: string) => string | undefined;
  ipAddressArray: string[];
}

export const LanCard: React.FC<Props> = (props) => {
  const { network, form, handleChangeString, validateLastOctetIp, validateThirdOctetIp, ipAddressArray } = props;
  const { t } = useTranslation();

  const lanIpAddressThirdPartChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeString(e.currentTarget.value, (f) => ({ lanIpAddressThirdPart: f }), validateThirdOctetIp),
    [validateThirdOctetIp],
  );
  const lanIpAddressLastPartChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeString(e.currentTarget.value, (f) => ({ lanIpAddressLastPart: f }), validateLastOctetIp),
    [validateLastOctetIp],
  );

  return (
    <Card className={styles.card}>
      <div>
        <div className={styles.helpNotice}>
          <Helper
            text={t('pages.network.advanced.settings.sections.lan.name')}
            textVariant="uiText2"
            textComponent="h3"
            heading={t('pages.network.advanced.settings.helpTexts.lan.title')}
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="paragraph3" component="p">
              {t('pages.network.advanced.settings.helpTexts.lan.content')}
            </Typography>
          </Helper>
        </div>
        <div className={styles.helperLabel}>
          <Helper
            text={t('pages.network.advanced.settings.sections.lan.gateway')}
            textVariant="uiText2"
            textComponent="label"
            heading={t('pages.network.advanced.settings.helpTexts.gateway.title')}
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="paragraph3" component="p" className={styles.alertParagraph}>
              {t('pages.network.advanced.settings.helpTexts.gateway.paragraph1')}
            </Typography>
            <Typography variant="paragraph3" component="p">
              <Trans i18nKey="pages.network.advanced.settings.helpTexts.gateway.paragraph2" />
            </Typography>
          </Helper>
        </div>
        <div className={styles.ipAddress}>
          <IpAddressWithSeparator ipAddressArray={ipAddressArray} numbersToShow={2} />
          <Input
            id="ipAddressThirdPart"
            label=""
            value={form.lanIpAddressThirdPart.value}
            onChange={lanIpAddressThirdPartChanged}
            uiComponentState={uiComponentState(form.lanIpAddressThirdPart)}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.smallInputField}
            data-testid="ip-address-third-part-input"
            maxLength={3}
          />
          <Input
            id="ipAddressLastPart"
            label=""
            value={form.lanIpAddressLastPart.value}
            onChange={lanIpAddressLastPartChanged}
            uiComponentState={uiComponentState(form.lanIpAddressLastPart)}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.smallInputField}
            data-testid="ip-address-last-part-input"
            maxLength={3}
          />
        </div>
        <ErrorMessages
          fields={[form.lanIpAddressThirdPart.error ? form.lanIpAddressThirdPart : form.lanIpAddressLastPart]}
        />
      </div>
      <div>
        <div className={styles.helperLabel}>
          <Helper
            text={t('pages.network.advanced.settings.sections.lan.netmask')}
            textVariant="uiText2"
            textComponent="label"
            heading={t('pages.network.advanced.settings.helpTexts.netmask.title')}
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="paragraph3" component="p">
              {t('pages.network.advanced.settings.helpTexts.netmask.content')}
            </Typography>
          </Helper>
        </div>
        <Typography variant="uiText1" component="div" maxBreakpoint={Breakpoint.TABLET}>
          <IpAddressWithSeparator
            ipAddressArray={network.networkSettings?.routerSettings?.netmask.split('.')!}
            numbersToShow={4}
          />
        </Typography>
      </div>
    </Card>
  );
};
