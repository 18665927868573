import { useReducer } from 'react';
import 'core-js/actual/array/with';

export type ConfigAction =
  | { index: number; type: 'networkSsid'; value: string }
  | { index: number; type: 'encProtocol'; value: string }
  | { index: number; type: 'networkPassword'; value: string }
  | { index: number; type: 'isSSidHidden'; value: boolean }
  | { index: number; type: 'channel'; value: number }
  | { index: number; type: 'channelWidth'; value: string }
  | { index: number; type: 'mode'; value: string }
  | { index: number; type: 'toggleEnabled' }
  | { index: number; type: 'reset' }
  | { index: number; type: 'saved' };

export interface WifiBandConfig {
  data: MinesiderBackend.UpdateWifiNetwork & { readonly isMesh: boolean };
  formMetadata: {
    isTouched?: boolean;
    isValidNetworkSsid: boolean;
    isValidNetworkPassword: boolean;
  };
}

interface SsidReducer {
  wifiSettings: MinesiderBackend.WifiNetwork[];
  isCustomerRepresentative?: boolean;
  regexSsids: { [encryptionType: string]: string };
  regexPasswords: { [encryptionType: string]: string };
}

export const useSsidConfigReducer = ({
  wifiSettings,
  isCustomerRepresentative,
  regexSsids,
  regexPasswords,
}: SsidReducer) => {
  const selectedEncryptionProtocol = 'WPA2';
  const regexSsid = new RegExp(regexSsids[selectedEncryptionProtocol] ?? regexSsids.default);
  const regexPassword = new RegExp(regexPasswords[selectedEncryptionProtocol] ?? regexPasswords.default);

  const initialConfig: WifiBandConfig[] = wifiSettings.map((setting) => ({
    data: {
      radioBand: setting.radioBand,
      type: setting.type,
      isMesh: setting.isMesh,

      id: setting.id,
      ssid: setting.ssid,
      encKey: isCustomerRepresentative ? '' : setting.encKey || '',
      mode: setting.mode,
      enabled: setting.enabled,
      encProtocol: setting.encProtocol,

      // Advanced settings:
      isSSidHidden: setting.isSSidHidden,
      channel: setting.channel,
      channelWidth: setting.channelWidth,
    },

    formMetadata: {
      isValidNetworkPassword: isCustomerRepresentative ?? regexPassword.test(setting.encKey || ''),
      isValidNetworkSsid: regexSsid.test(setting.ssid),
      isTouched: false,
    },
  }));

  const configReducer = (state: WifiBandConfig[], action: ConfigAction) => {
    switch (action.type) {
      case 'encProtocol':
        return state.with(action.index, {
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: true,
          },
          data: {
            ...state[action.index].data,
            encProtocol: action.value,
          },
        });

      case 'networkSsid':
        return state.with(action.index, {
          formMetadata: {
            ...state[action.index].formMetadata,
            isValidNetworkSsid: regexSsid.test(action.value),
            isTouched: true,
          },
          data: {
            ...state[action.index].data,
            ssid: action.value,
          },
        });

      case 'networkPassword': {
        let networkPassword = action.value;
        let validNetworkPassword = regexPassword.test(networkPassword);
        if (isCustomerRepresentative) {
          networkPassword = action.value ?? '';
          validNetworkPassword = networkPassword ? regexPassword.test(networkPassword) : true;
        }
        return state.with(action.index, {
          data: {
            ...state[action.index].data,
            encKey: networkPassword,
          },
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: true,
            isValidNetworkPassword: validNetworkPassword,
          },
        });
      }

      case 'isSSidHidden': {
        return state.with(action.index, {
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: true,
          },
          data: {
            ...state[action.index].data,
            isSSidHidden: action.value,
          },
        });
      }
      case 'channel': {
        return state.with(action.index, {
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: true,
          },
          data: {
            ...state[action.index].data,
            channel: action.value,
          },
        });
      }
      case 'mode': {
        return state.with(action.index, {
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: true,
          },
          data: {
            ...state[action.index].data,
            mode: action.value,
          },
        });
      }
      case 'channelWidth': {
        return state.with(action.index, {
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: true,
          },
          data: {
            ...state[action.index].data,
            channelWidth: action.value,
          },
        });
      }

      case 'toggleEnabled': {
        return state.with(action.index, {
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: true,
          },
          data: {
            ...state[action.index].data,
            enabled: !state[action.index].data.enabled,
          },
        });
      }

      case 'saved': {
        return state.with(action.index, {
          ...state[action.index],
          formMetadata: {
            ...state[action.index].formMetadata,
            isTouched: false,
          },
        });
      }

      case 'reset':
        return initialConfig;

      default:
        return state;
    }
  };

  const [configState, configDispatch] = useReducer(configReducer, initialConfig);

  return { configState, configDispatch };
};
