import { createSlice, isAnyOf, Slice } from '@reduxjs/toolkit';
import { FetchStatus, VoipSettingsState } from '../root-types';
import { setGlobalError } from '../actions/core-actions';
import {
  updateVoipSettings,
  fetchVoipLocations,
  fetchVoipSettings,
  fetchVoipBarringSettings,
  updateVoipBarringSettings,
} from '../actions/voip-settings-thunks';
import { sanitizePhoneNumberForApi } from 'app/utils/string-util';
import { deleteVoipRule, resetVoipUpdateStatus, selectVoipSettings } from '../actions/voip-settings-actions';
import { v4 as uuid } from 'uuid';

const initialState: VoipSettingsState = {
  locations: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  settings: {
    data: {},
  },
  updateSettings: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  barring: {
    data: {},
  },
};

const voipSettingsSlice: Slice<VoipSettingsState> = createSlice({
  name: 'userContext',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setGlobalError, () => initialState);
    builder.addCase(fetchVoipLocations.fulfilled, (state, action) => {
      const sanitizedData = action.payload.map((location) => {
        return { ...location, phoneNumbers: location.phoneNumbers?.map((phone) => sanitizePhoneNumberForApi(phone)) };
      });
      state.locations.fetchStatus = FetchStatus.FULFILLED;
      state.locations.data = sanitizedData;
    });
    builder.addCase(fetchVoipLocations.rejected, (state) => {
      state.locations.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(fetchVoipLocations.pending, (state) => {
      state.locations.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(selectVoipSettings, (state, action) => {
      state.updateSettings.selectedPhoneNumber = action.payload;
      if (!action.payload) {
        return;
      }
      state.settings.data = {
        ...state.settings.data,
        [action.payload]: {
          ...state.settings.data[action.payload],
          fetchStatus: FetchStatus.PENDING,
        },
      };
    });

    builder.addCase(updateVoipSettings.fulfilled, (state) => {
      state.updateSettings.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(updateVoipSettings.rejected, (state) => {
      state.updateSettings.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(resetVoipUpdateStatus, (state) => {
      state.updateSettings.deleteRule = undefined;
      state.updateSettings.fetchStatus = FetchStatus.NOT_STARTED;
    });
    builder.addCase(deleteVoipRule, (state, action) => {
      state.updateSettings.deleteRule = action.payload;
    });
    builder.addCase(fetchVoipBarringSettings.pending, (state, action) => {
      state.updateSettings.fetchStatus = FetchStatus.PENDING;
      state.barring.data = {
        ...state.barring.data,
        [action.meta.arg]: {
          ...state.barring.data[action.meta.arg],
          fetchStatus: FetchStatus.PENDING,
        },
      };
    });
    builder.addCase(fetchVoipBarringSettings.rejected, (state, action) => {
      state.updateSettings.fetchStatus = FetchStatus.REJECTED;
      state.barring.data = {
        ...state.barring.data,
        [action.meta.arg]: {
          ...state.barring.data[action.meta.arg],
          fetchStatus: FetchStatus.REJECTED,
        },
      };
    });
    builder.addCase(fetchVoipSettings.rejected, (state, action) => {
      state.settings.data = {
        ...state.settings.data,
        [action.meta.arg]: {
          ...state.settings.data[action.meta.arg],
          fetchStatus: FetchStatus.REJECTED,
        },
      };
    });
    builder.addMatcher(isAnyOf(updateVoipSettings.pending, updateVoipBarringSettings.pending), (state) => {
      state.updateSettings.fetchStatus = FetchStatus.PENDING;
    });
    builder.addMatcher(
      isAnyOf(fetchVoipBarringSettings.fulfilled, updateVoipBarringSettings.fulfilled),
      (state, action) => {
        if (!action.payload.phoneNumber) {
          return;
        }
        const phone = sanitizePhoneNumberForApi(action.payload.phoneNumber);
        state.barring.data = {
          ...state.barring.data,
          [phone]: {
            fetchStatus: FetchStatus.FULFILLED,
            settings: {
              ...action.payload,
              phoneNumber: phone,
            },
          },
        };
        state.updateSettings.fetchStatus = FetchStatus.FULFILLED;
      },
    );
    builder.addMatcher(isAnyOf(fetchVoipSettings.fulfilled, updateVoipSettings.fulfilled), (state, action) => {
      const phone = sanitizePhoneNumberForApi(action.payload.phoneNumber || (action.meta.arg as string));
      const forwardRules = action.payload.forwarding?.rules?.map((rule) => {
        return {
          ...rule,
          appGeneratedId: uuid(),
        };
      });
      const rejectionRules = action.payload.incoming?.rejects?.map((rule) => {
        return {
          ...rule,
          appGeneratedId: uuid(),
        };
      });

      state.settings.data = {
        ...state.settings.data,
        [phone]: {
          fetchStatus: FetchStatus.FULFILLED,
          settings: {
            ...action.payload,
            phoneNumber: phone,
            forwarding: {
              ...action.payload.forwarding,
              rules: forwardRules,
            },
            incoming: {
              ...action.payload.incoming,
              rejects: rejectionRules,
            },
          },
        },
      };
    });
  },
});

export const { reducer } = voipSettingsSlice;
