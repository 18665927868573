import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { networkUpdated } from './network-actions';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

interface CreateFirewallRule {
  customerId: string;
  servicePointId: string;
  rule: MinesiderBackend.UpdatePortForwarding;
}

interface UpdateFirewallRule {
  customerId: string;
  servicePointId: string;
  ruleId: number;
  rule: MinesiderBackend.UpdatePortForwarding;
}

interface DeleteFirewallRule {
  customerId: string;
  servicePointId: string;
  rule: MinesiderBackend.PortForwarding;
}

export interface RuleAndNetwork {
  ruleName: string;
  network: MinesiderBackend.CustomerNetwork;
}

export const createFirewallRule = createAsyncThunk<
  RuleAndNetwork,
  CreateFirewallRule,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('firewall/createFirewallRule', async ({ customerId, servicePointId, rule }, thunkApi) =>
  minesiderBackendService
    .createFirewallRule(customerId, servicePointId, rule)
    .then((network) => {
      thunkApi.dispatch(networkUpdated(network));
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.firewall.toast.firewallRuleCreated', { firewallRule: rule.name }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return { network, ruleName: rule.name };
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.firewall.toast.firewallRuleCreateFailed'),
          alertType: 'critical',
          role: 'alert',
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);

export const updateFirewallRule = createAsyncThunk<
  RuleAndNetwork,
  UpdateFirewallRule,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('firewall/updateFirewallRule', async ({ customerId, servicePointId, ruleId, rule }, thunkApi) =>
  minesiderBackendService
    .updateFirewallRule(customerId, servicePointId, ruleId, rule)
    .then((network) => {
      thunkApi.dispatch(networkUpdated(network));
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.firewall.toast.firewallRuleChanged', { firewallRule: rule.name }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return { network, ruleName: rule.name };
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.firewall.toast.firewallRuleUpdateFailed'),
          alertType: 'critical',
          role: 'alert',
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);

export const deleteFirewallRule = createAsyncThunk<
  RuleAndNetwork,
  DeleteFirewallRule,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('firewall/deleteFirewallRule', async ({ customerId, servicePointId, rule }, thunkApi) =>
  minesiderBackendService
    .deleteFirewallRule(customerId, servicePointId, rule.id)
    .then((network) => {
      thunkApi.dispatch(networkUpdated(network));
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'success',
          heading: i18n.t('pages.firewall.toast.firewallRuleDeleted', { firewallRule: rule.name }),
          role: 'status',
        }),
      );
      return { network, ruleName: rule.name };
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.firewall.toast.firewallRuleDeleteFailed'),
          alertType: 'critical',
          role: 'alert',
        }),
      );
      return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
    }),
);
