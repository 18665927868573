import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

export const fetchContactDetails = createAsyncThunk('contactDetails/fetchContactDetails', async (customerId: string) =>
  minesiderBackendService.getCustomerDetails(customerId),
);

interface ContactDetailsParams {
  customerId: string;
  contactDetails: MinesiderBackend.CustomerDetails;
}

export const saveContactDetails = createAsyncThunk<
  void,
  ContactDetailsParams,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('contactDetails/saveContactDetails', async ({ customerId, contactDetails }, thunkApi) => {
  const result = minesiderBackendService.updateCustomerDetails(customerId, contactDetails);
  result.then(() => {
    thunkApi.dispatch(
      addToastMessage({
        heading: i18n.t('pages.contactDetails.toast.contactDetailsUpdated'),
        alertType: 'success',
        role: 'status',
      }),
    );
  });
});
