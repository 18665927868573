export class ParamUtils {
  public static getDomParam(name: string): string | null {
    const root: HTMLElement | null = document.querySelector<HTMLElement>('#root');

    return root?.dataset[name] || null;
  }

  public static getQueryParam(name: string): string | null {
    const params = new URLSearchParams(window.location.search);

    return params.get(name);
  }
}
