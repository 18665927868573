import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Breakpoint, Button, ButtonType } from 'app/component-library-wave';

import { setCurrentStep, setToAddress } from 'app/store/actions/relocation-actions';
import { LocationSearch } from 'app/components/location-search/location-search';
import { RelocationStepId } from 'app/store/slices/relocations-slice';
import { RelocationAddress } from 'app/pages/relocation/components/relocation-address/relocation-address';
import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './relocation-step-address.module.scss';

export const RelocationStepAddress = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fromAddress = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const toAddress = useAppSelector((state) => state.relocation.toAddress);

  return (
    <div className={styles.locationSelectorContainer}>
      {fromAddress && (
        <Typography
          id="locationListHeader"
          className={styles.title}
          variant="headline5"
          component="h2"
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.relocationForm.addressStep.fromSelectedHeader')}
        </Typography>
      )}

      {fromAddress ? (
        <RelocationAddress address={fromAddress} isDestination={false} customerType={fromAddress.customerType} />
      ) : (
        <Typography component="p" variant="paragraph1">
          {t('pages.relocationForm.addressStep.fromAddressUnselected')}
        </Typography>
      )}

      <div className={styles.toAddressContainer}>
        <Typography className={styles.title} variant="headline5" component="h2" maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.relocationForm.addressStep.toUnselectedHeader')}
        </Typography>
        {toAddress ? (
          <RelocationAddress address={toAddress} isDestination={true} />
        ) : (
          <LocationSearch onSelectLocation={(address) => dispatch(setToAddress(address))} />
        )}
      </div>

      <div className={styles.nextStepContainer}>
        <Button
          onClick={() => dispatch(setCurrentStep(RelocationStepId.DATES))}
          buttonType={ButtonType.PRIMARY_B}
          disabled={!(fromAddress && toAddress)}
        >
          {t('pages.relocationForm.stepsNavigation.next')}
        </Button>
      </div>
    </div>
  );
};
