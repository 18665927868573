import { HeadingLevel, Select } from 'app/component-library-wave';
import { Helper } from 'app/components/helper/helper';

import styles from '../ssid-form.module.scss';

interface Option {
  label: string;
  value: string;
}

interface SectionProps {
  label: string;
  helperHeading: string;
  uniqueName: string;
  helperContent: JSX.Element;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  selectedValue?: string | number;
}

export const SsidSelect = ({
  label,
  helperHeading,
  uniqueName,
  helperContent,
  onChange,
  selectedValue,
  options,
}: SectionProps) => {
  const labelId = `${uniqueName}Label`;
  const helpTextId = `${uniqueName}HelpText`;
  return (
    <section>
      <Helper
        heading={helperHeading}
        text={label}
        textVariant="uiText2"
        textComponent="h3"
        alertHeadingElement={HeadingLevel.H4}
        id={labelId}
        classNameExpanded={styles.selectInputHelper}
      >
        <div id={helpTextId} className={styles.informationText}>
          {helperContent}
        </div>
      </Helper>
      <Select
        className={styles.selectInputLabel}
        onChange={onChange}
        value={selectedValue}
        aria-labelledby={labelId}
        aria-describedby={helpTextId}
        label={''}
        options={options}
      />
    </section>
  );
};
