import { createSlice, Slice } from '@reduxjs/toolkit';

import { logout } from '../actions/auth-thunks';
import { getMovieRentalHistory } from 'app/store/actions/movie-rental-history-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { FetchStatus, MovieRentalHistoryState } from 'app/store/root-types';

const initialState: MovieRentalHistoryState = {
  fetchStatus: FetchStatus.NOT_STARTED,
};

const movieRentalHistorySlice: Slice<MovieRentalHistoryState> = createSlice({
  name: 'movieRentalHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(getMovieRentalHistory.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getMovieRentalHistory.fulfilled, (state, action) => {
      state.fetchStatus = FetchStatus.FULFILLED;
      state.rentedMovies = action.payload;
    });
    builder.addCase(getMovieRentalHistory.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
      state.rentedMovies = undefined;
    });
  },
});

const { reducer } = movieRentalHistorySlice;

export { reducer };
