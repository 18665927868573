import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { FdsTextField } from '@lyse-as/formds-react';

import { ContainerFixed } from 'app/components/container';
import { ContainerLayoutMode } from 'app/components/container/container';
import { Typography as T } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { CustomerPage } from 'app/pages/page-wrapper';
import { getInvoicePaymentAgreementParameters } from 'app/store/actions/invoice-thunks';
import { NetsAvtaleGiroForm, NetsAvtaleType } from './nets-avtalegiro-form';
import { ParamUtils } from 'app/utils/param-utils';
import { FetchStatus } from 'app/store/root-types';
import { navigationService } from 'app/service/navigation/navigation-service';

import styles from './invoice-avtalegiro.module.scss';

export const InvoiceAvtaleGiro: CustomerPage = () => {
  const { t } = useTranslation();
  const { paymentAgreement } = useAppSelector((state) => state.invoice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInvoicePaymentAgreementParameters());
  }, []);

  // The following test account may be used for testing in test environment(MTF):
  const avtaleGiroTestAccount: MinesiderBackend.PaymentAgreement = {
    ...paymentAgreement.data,
    companyAccountNo: '11110500019',
    kid: '285332022028875',
  };

  const useAvtaleGiroTestAccount = ParamUtils.getQueryParam('test');

  return (
    <ContainerFixed mode={ContainerLayoutMode.Medium} className={styles.pageContainer}>
      <T variant="formds-altibox-subtitle-primary" component="h1" bold={true} className={styles.pageHeading}>
        {t('pages.invoice.invoiceAvtaleGiro.heading')}
      </T>
      <T variant="formds-body" component="p" className={styles.paragraph}>
        {t('pages.invoice.invoiceAvtaleGiro.avtaleGiroDescription')}
      </T>
      <T variant="formds-body" component="p" className={styles.paragraph}>
        {t('pages.invoice.invoiceAvtaleGiro.amountLimit')}
      </T>

      {paymentAgreement.data && (
        <NetsAvtaleGiroForm
          paymentAgreement={useAvtaleGiroTestAccount ? avtaleGiroTestAccount : paymentAgreement.data}
          returnUrl={`${window.origin}${navigationService.PAGES.invoiceAvtaleGiroReceipt.url}`}
          netsAvtaleType={NetsAvtaleType.AvtaleGiro}
          disableSubmitButton={paymentAgreement.fetchStatus !== FetchStatus.FULFILLED}
        >
          <FdsTextField
            label={t('pages.invoice.invoiceAvtaleGiro.amountLimitInputLabel')}
            name="amountLimit"
            description={t('pages.invoice.invoiceAvtaleGiro.amountLimitInputDescription')}
            className={styles.amountLimitInput}
            required={true}
            maxLength={7}
          />
        </NetsAvtaleGiroForm>
      )}

      <T variant="formds-common-subtitle-primary" component="h2" bold={true} className={styles.eFakturaHeading}>
        {t('pages.invoice.invoiceAvtaleGiro.eFakturaHeading')}
      </T>
      <T variant="formds-body" component="p" className={styles.eFakturaInformation}>
        {t('pages.invoice.invoiceAvtaleGiro.eFakturaInformation')}
      </T>
    </ContainerFixed>
  );
};

/*   private getExistingAvtaleText = () => {
    switch (AvtaleHelper.getExistingAvtaleType(this.state.paymentInfo)) {
      case NetsAvtaleType.AvtaleGiro:
        return txt["AvtaleGiro agreement exists"];
      case NetsAvtaleType.eFaktura:
        return txt["EFaktura agreement exists"];
      case NetsAvtaleType.AvtaleGiroAndEFaktura:
        return txt["AvtaleGiro and eFaktura agreements exist"];
      case NetsAvtaleType.None:
        return null;
      default:
        return null;
    }
  }; */
