import React from 'react';

import { config } from 'app/config/config';

export const GtmNoscript: React.FC = () => {
  const iframe = () => ({
    __html: `<iframe src="//www.googletagmanager.com/ns.html?id=${config.gtm.gtmId}"
                            height="-1" width="0" style="display:none;visibility:hidden"></iframe>`,
  });
  return <noscript dangerouslySetInnerHTML={iframe()} />;
};
