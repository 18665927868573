import classNames from 'classnames';

import { SSIDForm } from './ssid-form/ssid-form';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { ErrorTypes, FetchStatus, FriendlyErrorMessage } from 'app/store/root-types';
import { Spinner } from 'app/components/spinner';
import { areBandsIdentical, combineConfig, getAvailableNetworkModes } from '../../../network-common';
import { useSsidConfigReducer } from './ssid-form/ssid-reducer';
import { Button, ButtonType, HeadingLevel, UiComponentSize } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';
import { setManagedWifiSetting, setWifiSettings } from 'app/store/actions/network-thunks';
import { raiseGlobalError } from 'app/store/actions/core-actions';
import { WifiToggle } from '../wifi-toggle/wifi-toggle';
import { useEffect, useState } from 'react';
import { HiddenH1 } from 'app/components/hidden-h1';

import styles from './ssid.module.scss';
import commonStyles from 'app/pages/internet/network-common.module.scss';

const DEFAULT_REGEXP_SSID = '^[a-zA-Z_0-9/ @.-]{2,32}$';
const DEFAULT_REGEXP_PASSWORD = '^[A-Za-z0-9]{8,63}$';

interface Props {
  servicePointId: string;
  customerId: string;
  wifiIds: string[];
  uiHideEncryption?: boolean;
  uiShowAdvanced?: boolean;
  uiHideBasic?: boolean;
  uiHideBorder?: boolean;
  uiHideToggle?: boolean;
  regexValidations: {
    [name: string]: MinesiderBackend.RegexValidation;
  };
  fieldValues: {
    [name: string]: MinesiderBackend.FieldValues;
  };
}
export const SsId = ({
  servicePointId,
  customerId,
  wifiIds,
  uiHideEncryption,
  uiShowAdvanced,
  uiHideBasic,
  uiHideBorder,
  uiHideToggle,
  regexValidations,
  fieldValues,
}: Props) => {
  const {
    data: networkData,
    fetchStatus,
    updateWifiSettings: { fetchStatus: wifiFetchStatus },
  } = useAppSelector((state) => state.network);

  const { isCsr } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const wifiSettings = wifiIds.map((id) => networkData?.wifiSettings.find((wifiid) => wifiid.id === id)!);

  const [isCombined, setIsCombined] = useState(true);

  useEffect(() => {
    if (!networkData) {
      return;
    }
    setIsCombined(areBandsIdentical(networkData, wifiIds));
  }, [networkData]);

  const { configState, configDispatch } = useSsidConfigReducer({
    isCustomerRepresentative: isCsr,
    regexPasswords: { ...regexValidations?.enc_key?.regexes, default: DEFAULT_REGEXP_PASSWORD },
    regexSsids: { ...regexValidations?.ssid?.regexes, default: DEFAULT_REGEXP_SSID },
    wifiSettings,
  });

  if (
    fetchStatus === FetchStatus.NOT_STARTED ||
    fetchStatus === FetchStatus.PENDING ||
    wifiFetchStatus === FetchStatus.PENDING ||
    !networkData
  ) {
    return <Spinner />;
  }

  const hasInvalidValues = () =>
    configState.some((config) => {
      if (
        !config.formMetadata.isValidNetworkPassword ||
        !config.formMetadata.isValidNetworkSsid ||
        !servicePointId ||
        !customerId
      ) {
        return true;
      }
      return false;
    });

  const isManagedWifi = configState.some((config) => config.data.type === 'OFFICE' || config.data.type === 'GUEST');
  const onSubmitSettings = (e: React.FormEvent<HTMLFormElement>, index: number) => {
    e.preventDefault();

    if (!configState[index].formMetadata.isTouched || hasInvalidValues()) {
      return;
    }

    const newConfig = configState.map((config) => {
      if (isCsr && (config.data.encKey === '' || config.data.encKey?.split('').every((char) => char === '*'))) {
        delete config.data.encKey;
      }
      return config.data;
    });

    const settingsSaved = (status: 'fulfilled' | 'rejected') => {
      if (status === 'fulfilled') {
        configDispatch({ type: 'saved', index });
      }
    };

    if (isCombined) {
      try {
        const combinedWifiConfig = combineConfig({
          wifiIds,
          enableCombined: isCombined,
          newConfig,
          isMesh: wifiSettings.some((setting) => setting.isMesh),
        });
        dispatch(
          setWifiSettings({
            customerId,
            servicePointId,
            wifiNetwork: combinedWifiConfig,
          }),
        ).then((result) => {
          settingsSaved(result.meta.requestStatus);
        });
      } catch (error) {
        dispatch(
          raiseGlobalError({
            message: FriendlyErrorMessage.UNABLE_TO_COMBINE_NETWORK,
            type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
          }),
        );
      }
      return;
    }

    if (isManagedWifi) {
      const baseConfig = configState[0];
      const managedWifiSettings: MinesiderBackend.UpdateSingleWifiNetwork = {
        ...baseConfig.data,
        isSSidHidden: baseConfig.data.isSSidHidden || false,
      };

      dispatch(
        setManagedWifiSetting({
          servicePointId,
          customerId,
          wifiId: baseConfig.data.id,
          networkConfig: managedWifiSettings,
        }),
      ).then((result) => {
        settingsSaved(result.meta.requestStatus);
      });
      return;
    }

    dispatch(
      setWifiSettings({
        servicePointId,
        customerId,
        wifiNetwork: newConfig,
      }),
    ).then((result) => {
      settingsSaved(result.meta.requestStatus);
    });
  };

  return (
    <div className={isCombined ? styles.combinedContainer : styles.multipleContainer}>
      <ul className={classNames(styles.ssidList)}>
        {configState.map((config, index) => {
          const availableNetworkModes = uiShowAdvanced
            ? getAvailableNetworkModes({ networkData, radioBand: config.data.radioBand })
            : null;

          if (isCombined && config.data.radioBand !== 2) {
            return null;
          }

          const ARIA_HEADER =
            config.data.radioBand === 2
              ? t('pages.network.advanced.wifi.band24Aria')
              : t('pages.network.advanced.wifi.band5Aria');

          return (
            <li key={config.data.id} className={styles.container}>
              {!isCombined && (
                <HiddenH1 headingLevel={HeadingLevel.H2} focusHeading={false}>
                  {ARIA_HEADER}
                </HiddenH1>
              )}
              {!uiHideToggle && (
                <WifiToggle
                  isCombined={isCombined}
                  isCsr={isCsr}
                  config={config}
                  servicePointId={servicePointId}
                  customerId={customerId}
                  configDispatch={configDispatch}
                  configIndex={index}
                  uiHideToggleButton={uiHideBasic || config.data.type === 'OFFICE'}
                  className={classNames(styles.toggleContainer, uiHideBorder && styles.hideBorderToggle)}
                />
              )}
              <SSIDForm
                config={config}
                configIndex={index}
                configDispatch={configDispatch}
                fieldValues={fieldValues}
                isCombined={isCombined}
                availableNetworkModes={availableNetworkModes}
                uiHideBasic={uiHideBasic}
                uiHideEncryption={uiHideEncryption}
                uiShowAdvanced={uiShowAdvanced}
                className={classNames(styles.formContainer, uiHideBorder && styles.hideBorder)}
                key={config.data.id + 'form'}
              />

              <div className={classNames(styles.buttonContainer, uiHideBorder && styles.hideBorder)}>
                <form onSubmit={(e) => onSubmitSettings(e, index)}>
                  <Button
                    buttonType={ButtonType.PRIMARY_B}
                    uiComponentSize={UiComponentSize.MEDIUM}
                    aria-disabled={!config.formMetadata.isTouched || hasInvalidValues()}
                    className={classNames(
                      styles.button,
                      (!config.formMetadata.isTouched || hasInvalidValues()) && commonStyles.buttonDisabled,
                    )}
                    type="submit"
                  >
                    {t('pages.network.ssid.buttonSave')}
                  </Button>
                </form>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
