import React, { ReactElement, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Typography } from 'app/component-library-wave';

import { getSubscriptions, updateSubscriptions } from 'app/store/actions/subscription-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { CustomerType } from 'app/store/types/user-context-types';

import { ContainerFixed } from 'app/components/container';
import { navigationService } from 'app/service/navigation/navigation-service';
import { SubscriptionItem, SubscriptionGroup } from 'app/features/subscriptions';
import { Spinner } from 'app/components/spinner';

import styles from './subscriptions.module.scss';

export enum SubscriptionTypes {
  NEWSLETTER_ALTIBOX_MOVIES_SERIES = 'subNewsletterAltiboxMoviesSeries',
  NEWLETTERS = 'subNewsletters',
  MAGAZINE = 'subMagazine',
  OPERATIONAL_MESSAGES = 'operationalMessages',
}

interface Subscription {
  id: SubscriptionTypes;
  name: string;
  description: string;
  frequency: string;
  user?: ReactElement;
}

interface SubscriptionConfig {
  subscriptionGroup: string;
  subscriptions: Subscription[];
}

export const Subscriptions: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { subscriptions, getSubscriptionsFetchStatus, updateSubscriptionsStatus } = useAppSelector(
    (state) => state.subscriptions,
  );
  const customerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  const subscriptionConfigEnterprise: SubscriptionConfig[] = [
    {
      subscriptionGroup: t('features.subscriptions.subscriptionGroup.newsLetters.name'),
      subscriptions: [
        {
          id: SubscriptionTypes.NEWLETTERS,
          name: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNewsEnterprise.name'),
          description: t(
            'features.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNewsEnterprise.description',
          ),
          frequency: t(
            'features.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNewsEnterprise.frequency',
          ),
        },
      ],
    },
  ];

  const subscriptionConfigPrivate: SubscriptionConfig[] = [
    {
      subscriptionGroup: t('features.subscriptions.subscriptionGroup.newsLetters.name'),
      subscriptions: [
        {
          id: SubscriptionTypes.NEWSLETTER_ALTIBOX_MOVIES_SERIES,
          name: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.movieNews.name'),
          description: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.movieNews.description'),
          frequency: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.movieNews.frequency'),
        },
        {
          id: SubscriptionTypes.NEWLETTERS,
          name: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNews.name'),
          description: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNews.description'),
          frequency: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNews.frequency'),
        },
        {
          id: SubscriptionTypes.OPERATIONAL_MESSAGES,
          name: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.notifications.name'),
          description: t(
            'features.subscriptions.subscriptionGroup.newsLetters.subscriptions.notifications.description',
          ),
          frequency: t('features.subscriptions.subscriptionGroup.newsLetters.subscriptions.notifications.frequency'),
          user: (
            <Trans i18nKey="features.subscriptions.subscriptionGroup.newsLetters.subscriptions.notifications.user">
              placeholdertext
              <a className={styles.myprofilelink} href={navigationService.PAGES.users.url}>
                placeholdertext
              </a>
            </Trans>
          ),
        },
      ],
    },
    {
      subscriptionGroup: t('features.subscriptions.subscriptionGroup.magazines.name'),
      subscriptions: [
        {
          id: SubscriptionTypes.MAGAZINE,
          name: t('features.subscriptions.subscriptionGroup.magazines.subscriptions.offersTipsTricks.name'),
          description: t(
            'features.subscriptions.subscriptionGroup.magazines.subscriptions.offersTipsTricks.description',
          ),
          frequency: t('features.subscriptions.subscriptionGroup.magazines.subscriptions.offersTipsTricks.frequency'),
        },
      ],
    },
  ];

  const subscriptionConfig =
    customerLocation?.customerType === CustomerType.BEDRIFT ? subscriptionConfigEnterprise : subscriptionConfigPrivate;

  const handleToggle = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const subscription = event.currentTarget.id;
    const newSubscriptions = { ...subscriptions };
    switch (subscription) {
      case SubscriptionTypes.NEWLETTERS:
        newSubscriptions.subNewsletters = !subscriptions.subNewsletters;
        break;
      case SubscriptionTypes.MAGAZINE:
        newSubscriptions.subMagazine = !subscriptions.subMagazine;
        break;
      case SubscriptionTypes.NEWSLETTER_ALTIBOX_MOVIES_SERIES:
        newSubscriptions.subNewsletterAltiboxMoviesSeries = !subscriptions.subNewsletterAltiboxMoviesSeries;
        break;
      case SubscriptionTypes.OPERATIONAL_MESSAGES:
        newSubscriptions.operationalMessages = !subscriptions.operationalMessages;
        break;
    }
    dispatch(updateSubscriptions(newSubscriptions));
  };

  useEffect(() => {
    if (getSubscriptionsFetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(getSubscriptions());
    }
  }, [getSubscriptionsFetchStatus]);

  return (
    <ContainerFixed isNarrow={true} className={styles.container}>
      <div>
        <Typography variant="headline4" component="h1" className={styles.heading} maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.newsletterSubscriptions.name')}
        </Typography>
        <Typography variant="headline5" component="h2" className={styles.question} maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.newsletterSubscriptions.question')}
        </Typography>
        <Typography variant="paragraph1" component="p" className={styles.answer} maxBreakpoint={Breakpoint.TABLET}>
          <Trans i18nKey="pages.newsletterSubscriptions.answer">
            placeholdertext
            <a className={styles.myprofilelink} href={navigationService.PAGES.users.url}>
              placeholdertext
            </a>
          </Trans>
        </Typography>
      </div>

      <>
        {getSubscriptionsFetchStatus === FetchStatus.PENDING && <Spinner />}
        {getSubscriptionsFetchStatus === FetchStatus.REJECTED && (
          <Alert
            heading={t('features.subscriptions.errorMessages.getSubscriptionsFailed')}
            headingElement="strong"
            alertType="warning"
            role="alert"
            isExpandable={false}
          />
        )}
        {getSubscriptionsFetchStatus === FetchStatus.FULFILLED && (
          <div className={styles.options}>
            {subscriptionConfig.map((config) => (
              <SubscriptionGroup name={config.subscriptionGroup} key={config.subscriptionGroup}>
                {config.subscriptions.map(({ id, name, description, frequency, user }) => (
                  <SubscriptionItem
                    id={id}
                    description={description}
                    name={name}
                    frequency={frequency}
                    user={user}
                    toggleState={subscriptions[id]}
                    toggleHandler={handleToggle}
                    key={id}
                    isPending={updateSubscriptionsStatus === FetchStatus.PENDING}
                  />
                ))}
              </SubscriptionGroup>
            ))}
          </div>
        )}
        {updateSubscriptionsStatus === FetchStatus.REJECTED && (
          <Alert
            heading={t('features.subscriptions.errorMessages.subscriptionUpdateFailed')}
            headingElement="strong"
            alertType="warning"
            role="alert"
            isExpandable={false}
          />
        )}
      </>
    </ContainerFixed>
  );
};
