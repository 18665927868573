import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import { Typography } from 'app/component-library-wave';
import { Variant } from 'app/component-library-wave/components/typography/types';
import styles from './description-list.module.scss';

export type DescriptionListPair = {
  label: Entry;
  description: Entry;
};

type Entry = {
  value: React.ReactNode;
  variant?: Variant;
  isBold?: boolean;
};

interface Props extends HTMLAttributes<HTMLInputElement> {
  data: DescriptionListPair[];
}

export const DescriptionList: React.FC<Props> = ({ className, data }) => (
  <dl className={classnames(styles.container, className)}>
    {data.map((entry, i) => {
      return (
        <div key={typeof entry.label.value === 'string' ? entry.label.value : i} className={styles.entry}>
          <Typography
            component="dt"
            variant={entry.label.variant || 'formds-body'}
            bold={entry.label.isBold !== false}
            className={styles.label}
          >
            {entry.label.value}
          </Typography>
          <Typography
            component="dd"
            variant={entry.description.variant || 'formds-body'}
            bold={entry.description.isBold}
            className={styles.description}
          >
            {entry.description.value}
          </Typography>
        </div>
      );
    })}
  </dl>
);
