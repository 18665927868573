import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { getInvoicePdfLink } from 'app/store/actions/invoice-thunks';
import { FetchStatus } from 'app/store/root-types';
import { useEffect } from 'react';

export const useDownloadInvoicePdf = () => {
  const { fetchStatus, url } = useAppSelector((state) => state.invoice.invoicePdfLink);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchStatus === FetchStatus.FULFILLED) {
      if (!url) {
        return;
      }
      window.open(url);
    }
  }, [fetchStatus]);

  const downloadPdf = (invoiceId?: string) => {
    if (!invoiceId) {
      return;
    }
    dispatch(getInvoicePdfLink(invoiceId));
  };

  return { downloadPdf, downloadFetchStatus: fetchStatus };
};
