import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { startProvAuth } from 'app/store/actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { OperationalMessageState } from 'app/store/root-types';
import { fetchOperationalMessages } from 'app/store/actions/operational-messages-actions';

const initialState: OperationalMessageState = {
  isFetching: false,
};

const operationalMessageSlice: Slice<OperationalMessageState> = createSlice({
  name: 'operationalMessage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOperationalMessages.pending, (state) => {
      state.isFetching = true;
      return state;
    });
    builder.addCase(
      fetchOperationalMessages.fulfilled,
      (state, action: PayloadAction<MinesiderBackend.OperationalMessage[]>) => {
        state.isFetching = false;
        state.messages = action.payload.map((message: MinesiderBackend.OperationalMessage) => ({
          key: message.key ? message.key : '',
          webSummary: message.webSummary ? message.webSummary : '',
          webDescription: message.webDescription ? message.webDescription : '',
          webTemplate: message.webTemplate ? message.webTemplate : '',
          partners: message.partners ? message.partners : [],
          affectedProducts: message.affectedProducts ? message.affectedProducts : [],
          dateOfOccurence: message.errorOccurred ? message.errorOccurred : '',
        }));
        return state;
      },
    );
    builder.addCase(fetchOperationalMessages.rejected, (state) => {
      state.isFetching = false;
      return state;
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
  },
});

const { reducer } = operationalMessageSlice;

export { reducer };
