import React from 'react';

import styles from './panel.module.scss';

interface Props {
  onClick: () => void;
  children?: React.ReactNode;
}

export const Panel: React.FC<Props> = (props) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      props.onClick();
    }
  };
  return (
    <div role="button" tabIndex={0} onKeyPress={handleKeyPress} onClick={props.onClick} className={styles.panel}>
      {props.children}
    </div>
  );
};
