import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FdsPasscodeCustomEvent } from '@lyse-as/formds-core/';
import { FdsPasscode, FdsLoader, FdsButton, FdsAlert } from '@lyse-as/formds-react/';

import { ContainerFixed } from 'app/components/container';
import { getPin, setPin } from 'app/store/actions/pin-thunks';
import { pinChanged, setCurrentPinState } from 'app/store/actions/pin-actions';
import { FetchStatus, PinLifecycleState } from 'app/store/root-types';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { Breakpoint, Typography } from 'app/component-library-wave';

import styles from './change-pin.module.scss';

export const ChangePin = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fetchStatus = useAppSelector((state) => state.pin.fetchStatus);
  const customerId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const pinData = useAppSelector((state) => state.pin.data);

  const { pin, currentState } = useAppSelector((state) => state.pin);

  useEffect(() => {
    if (customerId) {
      dispatch(getPin(customerId));
    }
  }, []);

  const handlePinChange = (target: FdsPasscodeCustomEvent<number[]>) => {
    dispatch(pinChanged(target.detail.filter((code) => !isNaN(code)).join('')));

    return false;
  };

  const changePinForm = () => (
    <div className={styles.changePinFormContainer}>
      {currentState === PinLifecycleState.SAVING ? (
        <FdsLoader size="lg" className={styles.loader} />
      ) : (
        <>
          <div className={styles.inputContainer}>
            <FdsPasscode
              label={t('pages.changePin.inputLabel')}
              fieldsLabel={t('')}
              description={t('pages.changePin.inputHelpText')}
              autoFocus={true}
              onPasscodeChange={handlePinChange}
              inputMode="numeric"
              fields={4}
              mask={false}
              className={styles.variant}
            />
          </div>
          <div className={styles.buttonContainer}>
            <FdsButton
              onClick={handleSetState(PinLifecycleState.INITIAL)}
              variant="secondary"
              className={styles.cancelButton}
            >
              {t('pages.changePin.buttonCancel')}
            </FdsButton>
            <FdsButton onClick={handleSaveClick} className={styles.confirmButton}>
              {t('pages.changePin.buttonSave')}
            </FdsButton>
          </div>
        </>
      )}
    </div>
  );

  const handleSetState =
    (newState: PinLifecycleState): (() => void) =>
    () =>
      dispatch(setCurrentPinState(newState));

  const handleSaveClick = () => {
    if (pin && pin.length === 4) {
      const setPinRequest = {
        newPin: pin,
      };
      dispatch(setPin(setPinRequest));
    } else {
      dispatch(setCurrentPinState(PinLifecycleState.ERROR));
    }
  };

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.changePinContainer}>
        {fetchStatus === FetchStatus.REJECTED && (
          <FdsAlert variant="warning" role="alert">
            {t(`pages.product.errorMessage.getProductsFailed`)}
          </FdsAlert>
        )}
        {fetchStatus === FetchStatus.FULFILLED && (
          <>
            <Typography
              variant="headline5"
              component="h1"
              className={styles.changePinHeadline}
              maxBreakpoint={Breakpoint.TABLET}
            >
              {currentState === PinLifecycleState.SAVED ? t('pages.changePin.savedTitle') : t('pages.changePin.title')}
            </Typography>
            {pinData?.pin && (
              <Typography
                variant="uiText1"
                bold={true}
                component="p"
                className={styles.changePinHeadline}
                maxBreakpoint={Breakpoint.TABLET}
              >
                {t('pages.changePin.showPin', { code: pinData.pin })}
              </Typography>
            )}
            <Typography
              variant="paragraph2"
              component="p"
              className={styles.changePinParagraph}
              maxBreakpoint={Breakpoint.TABLET}
            >
              {t('pages.changePin.body')}
            </Typography>
            {currentState === PinLifecycleState.EDITING ||
            currentState === PinLifecycleState.ERROR ||
            currentState === PinLifecycleState.SAVING ? (
              changePinForm()
            ) : (
              <FdsButton
                className={styles.confirmButton}
                onClick={handleSetState(PinLifecycleState.EDITING)}
                variant="primary"
              >
                {t('pages.changePin.buttonNew')}
              </FdsButton>
            )}
          </>
        )}
      </div>
    </ContainerFixed>
  );
};
