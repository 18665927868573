import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';

import { Typography } from '../typography';

import styles from './select.module.scss';
import commonStyles from '../../common.module.scss';

interface Options {
  value: string | number;
  label: string;
}
export interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  multiple?: boolean;
  disabled?: boolean;
  id?: string;
  label: string;
  options: Options[];
  hideLabel?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  className,
  multiple,
  disabled,
  id,
  label,
  options,
  hideLabel,
  ...rest
}) => {
  const labelClasses = classNames(styles.title, hideLabel ? commonStyles.screenReaderOnly : null);
  const componentClass = classNames(
    styles.selectContainer,
    multiple ? styles.multiple : null,
    disabled ? styles.disabled : null,
  );

  const selectId = id ?? v4();

  return (
    <div className={className}>
      <Typography component="label" variant="uiText2" className={labelClasses} htmlFor={selectId} bold={true}>
        {label}
      </Typography>

      <div className={componentClass}>
        <select id={selectId} multiple={multiple} className={styles.select} disabled={disabled} {...rest}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
