import { useState } from 'react';
import { Button, ButtonType, Input } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/hooks/redux-thunk';
import { useUpdateAccessPoint } from 'app/hooks/use-network';
import { FetchStatus } from 'app/store/root-types';
import { Spinner } from 'app/components/spinner';
import { Device } from './device-list';
import { DeviceInfo } from './device-info';

import styles from './device-list.module.scss';
import commonStyles from 'app/pages/internet/network-common.module.scss';

interface AccessPointProps {
  item: Device;
}

export const AccessPointDevices = ({ item }: AccessPointProps) => {
  const { t } = useTranslation();
  const { data: networkData, fetchStatus: customerNetworkFetchStatus } = useAppSelector((state) => state.network);
  const { itemBeingUpdated, updateAccessPointDevice } = useUpdateAccessPoint();
  const [name, setName] = useState(item.name);
  const { fetchStatus } = useAppSelector((state) => state.network.updateAccessPoints);
  const { data: equipmentEnrichment } = useAppSelector((state) => state.enrichment.equipment);
  const disableButton = name === item.name;

  const saveDeviceName = (itemId: number) => {
    updateAccessPointDevice({
      id: itemId,
      name,
      inheritFromCpe: true,
    });
  };

  if (customerNetworkFetchStatus === FetchStatus.PENDING) {
    return <Spinner />;
  }
  const accessPointExtended = networkData?.accessPoints.find((ap) => ap.accessPointType);

  const accessPointExtendedInfo = equipmentEnrichment?.find(
    (ap) => ap.provSpecificationId === accessPointExtended?.deviceType,
  );
  const accessPointTypeName = accessPointExtendedInfo?.friendlyName || accessPointExtended?.deviceType;
  const equipment = {
    deivceName: accessPointTypeName,
    description: accessPointExtendedInfo?.description,
    customerServiceUrl: accessPointExtendedInfo?.customerServiceUrl,
    userManual: accessPointExtendedInfo?.userManual,
    images: accessPointExtendedInfo?.images,
  };

  return (
    <>
      <DeviceInfo
        deivceName={equipment.deivceName}
        description={equipment.description}
        images={equipment.images}
        customerServiceUrl={equipment.customerServiceUrl}
        userManual={equipment.userManual}
      />

      <Input
        label={t('pages.network.advanced.altiboxWifiOveralt.deviceList.accesspointNameInputLabel')}
        value={name}
        className={styles.accessPointNameInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        id={`name-${item.id}`}
      />
      {fetchStatus === FetchStatus.PENDING && item.id === itemBeingUpdated ? (
        <Spinner />
      ) : (
        <Button
          buttonType={ButtonType.PRIMARY_B}
          aria-disabled={disableButton}
          className={disableButton ? commonStyles.buttonDisabled : ''}
          onClick={() => (disableButton ? null : saveDeviceName(item.id))}
        >
          {t('pages.network.advanced.altiboxWifiOveralt.deviceList.updateAccessPointNameButton')}
        </Button>
      )}
    </>
  );
};
