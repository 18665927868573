import { useEffect } from 'react';
import { Alert } from 'app/component-library-wave';

import { OperationalMessageAlert } from 'app/components/operational-message-alert/operational-message-alert';
import { i18n } from 'app/i18n/i18n';
import { isActiveLocation } from 'app/store/types/user-context-types';
import { fetchOperationalMessages } from 'app/store/actions/operational-messages-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { Spinner } from 'app/components/spinner';

import styles from './operational-messages.module.scss';

export const OperationalMessages = () => {
  const dispatch = useAppDispatch();
  const selectedCustomerLocationContext = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const { messages, isFetching } = useAppSelector((state) => state.operationalMessages);

  useEffect(() => {
    if (selectedCustomerLocationContext && isActiveLocation(selectedCustomerLocationContext)) {
      dispatch(fetchOperationalMessages(selectedCustomerLocationContext.siteId));
    }
  }, [dispatch, selectedCustomerLocationContext]);

  const renderAlert = () => {
    if (isFetching) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }

    if (!messages) {
      return null;
    } else if (messages.length > 0) {
      return messages.map((message) => <OperationalMessageAlert key={message.key} message={message} />);
    } else {
      return (
        <aside>
          <Alert
            heading={i18n.t('features.operationalMessages.noMessages')}
            headingElement="p"
            alertType="info"
            isExpandable={false}
            role="status"
          />
        </aside>
      );
    }
  };

  return (
    <div data-testid="operational-messages" className={styles.container}>
      {renderAlert()}
    </div>
  );
};
