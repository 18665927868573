import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography } from 'app/component-library-wave';

import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { useAnnounceNavigation } from 'app/hooks';
import { FetchStatus } from 'app/store/root-types';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { fetchConsent, saveConsent } from 'app/store/actions/consent-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import styles from './consent.module.scss';

export const Consent: ActiveCustomerLocationPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [consentRadio, setConsentRadio] = useState<boolean | undefined>();
  const { announceElement } = useAnnounceNavigation();

  const consent = useAppSelector((state) => state.consent.consent);
  const fetchStatus = useAppSelector((state) => state.consent.fetchStatus);
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchConsent());
    }
  }, [dispatch, fetchStatus]);

  useEffect(() => {
    setConsentRadio(consent);
    setEditMode(consent === undefined);
  }, [consent]);

  const handleConsentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentRadio(event.currentTarget.value === 'agree');
  };
  const handleClick = () => {
    if (editMode) {
      if (consentRadio === undefined) {
        return;
      }
      if (consent !== consentRadio) {
        dispatch(saveConsent(consentRadio));
      }
    }
    setEditMode(!editMode);
  };

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.consentContainer}>
        <Typography
          variant="headline4"
          component="h1"
          className={styles.consentHeader}
          ref={announceElement}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.consent.name')}
        </Typography>
        <Typography
          component="p"
          variant="paragraph2"
          className={styles.consentParagraph}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.consent.body.paragraph1')}
        </Typography>
        <Typography
          component="p"
          variant="paragraph2"
          className={styles.consentParagraph}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.consent.body.paragraph2', { partnerName: selectedCustomerLocation?.partnerLegalName })}
        </Typography>
        <Typography
          component="p"
          variant="paragraph2"
          bold={true}
          className={styles.consentParagraph}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.consent.importantDisclaimer')}
        </Typography>

        <div className={styles.radioContainer}>
          {editMode && fetchStatus !== FetchStatus.PENDING && (
            <>
              <div className={styles.radioRow}>
                <input
                  type="radio"
                  id="consent-agree"
                  name="consent"
                  value="agree"
                  checked={consentRadio === true}
                  onChange={handleConsentChanged}
                  className={styles.radioInput}
                />
                <label htmlFor="consent-agree" className={styles.radioLabel}>
                  {t('pages.consent.radio.agree')}
                </label>
              </div>
              <div className={styles.radioRow}>
                <input
                  type="radio"
                  id="consent-disagree"
                  name="consent"
                  value="disagree"
                  checked={consentRadio === false}
                  onChange={handleConsentChanged}
                  className={styles.radioInput}
                />
                <label htmlFor="consent-disagree" className={styles.radioLabel}>
                  <Trans i18nKey="pages.consent.radio.disagree" />
                </label>
              </div>
            </>
          )}
          {consent !== undefined && !editMode && fetchStatus !== FetchStatus.PENDING && (
            <Typography
              component="p"
              variant="uiText2"
              className={styles.yourSelectionDescription}
              aria-live="polite"
              maxBreakpoint={Breakpoint.TABLET}
            >
              <Trans
                i18nKey={
                  consent
                    ? 'pages.consent.yourSelectionDescription.agree'
                    : 'pages.consent.yourSelectionDescription.disagree'
                }
              />
            </Typography>
          )}
          {fetchStatus === FetchStatus.PENDING && <Spinner />}
          {fetchStatus === FetchStatus.REJECTED && <p>{t('errors.consent.apiRequestRejected')}</p>}
        </div>
        <div className={styles.desktopCenterContainer}>
          {fetchStatus !== FetchStatus.PENDING && (
            <Button className={styles.confirmButton} onClick={handleClick} buttonType={ButtonType.PRIMARY_B}>
              {editMode ? t('pages.consent.confirm') : t('pages.consent.confirmEditMode')}
            </Button>
          )}
        </div>
      </div>
    </ContainerFixed>
  );
};
