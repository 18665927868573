import { Typography } from 'app/component-library-wave';

import styles from './product-item.module.scss';

interface Props {
  name: string;
}

export const ProductItem: React.FC<Props> = ({ name }) => (
  <li className={styles.item}>
    <Typography variant="paragraph2" component="p" className={styles.productItem}>
      {name}
    </Typography>
  </li>
);
