import React from 'react';

import { FaqItem } from './faq-item/faq-item';

export interface FaqItemType {
  question: string;
  answers: string[];
  links?: { label: string; url: string }[];
}

interface Props extends React.HTMLAttributes<HTMLDListElement> {
  items: FaqItemType[];
  renderAsHTML?: boolean;
}

export const Faq: React.FC<Props> = ({ items, renderAsHTML, ...rest }) => (
  <dl {...rest}>
    {items.map((item, key) => (
      <FaqItem
        question={item.question}
        answers={item.answers}
        links={item.links}
        key={item.question}
        index={key}
        last={key === items.length - 1}
        renderAsHTML={renderAsHTML}
      />
    ))}
  </dl>
);
