import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Input, Typography, UiComponentState, HeadingLevel } from 'app/component-library-wave';

import { uiComponentState } from '../form-utils';
import { FormModel, getNumber } from '../firewall-setting-utils';
import { Card } from 'app/components/card';
import { useChangeHandler } from '../use-change-handler';
import { ErrorMessages } from 'app/features/form';
import { Helper } from 'app/components/helper/helper';

import styles from './firewall-rule-form.module.scss';

interface Props {
  form: FormModel;
  validPortFrom: () => string | undefined;
  setForm: Dispatch<SetStateAction<FormModel>>;
  validPortTo: () => string | undefined;
  validIntPort: () => string | undefined;
}

export const FirewallRulePort: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { form, validPortFrom, setForm, validPortTo, validIntPort } = props;
  const handleChange = useChangeHandler<string, FormModel>(setForm);
  const extPortFromChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ extPortFrom: f }), validPortFrom);

  const extPortToChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ extPortTo: f }), validPortTo);

  const intPortChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ intPort: f }), validIntPort);

  const internalPortTo = (extFrom: string, extTo: string, intFrom: string) => {
    const extToNumber = Number.isNaN(getNumber(extTo)) ? getNumber(extFrom) : getNumber(extTo);
    const intTo = extToNumber - getNumber(extFrom) + getNumber(intFrom);
    return Number.isNaN(intTo) ? undefined : intTo;
  };
  return (
    <Card className={styles.card}>
      <div>
        <div className={styles.portsHeading}>
          <Helper
            heading={t('pages.firewall.explainMessages.portNumbersExplainHeading')}
            text={t('pages.firewall.createNewFirewallRule.portLimits.heading')}
            textVariant="uiText2"
            textComponent="h3"
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="uiText3" component="p">
              {t('pages.firewall.explainMessages.portNumbersExplain')}
            </Typography>
          </Helper>
        </div>
        <div className={styles.portFieldsRow}>
          <Input
            id="ext-port-from"
            label={t('pages.firewall.createNewFirewallRule.portLimits.externalPortFrom')}
            value={form.extPortFrom.value}
            onChange={extPortFromChanged}
            uiComponentState={uiComponentState(form.extPortFrom)}
            maxBreakpoint={Breakpoint.TABLET}
            maxLength={5}
          />
          <div>
            <div className={styles.externalPortToLabel}>
              <Typography
                component="label"
                variant="uiText2"
                bold={true}
                maxBreakpoint={Breakpoint.TABLET}
                htmlFor="ext-port-to"
                className={uiComponentState(form.extPortTo) === UiComponentState.ERROR ? styles.errorLabel : undefined}
              >
                {t('pages.firewall.createNewFirewallRule.portLimits.externalPortTo')}
              </Typography>
              <Typography
                component="span"
                variant="uiText2"
                italic={true}
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.optional}
                htmlFor="externalPortTo"
              >
                {t('pages.contactDetailsEdit.optional')}
              </Typography>
            </div>
            <Input
              id="ext-port-to"
              label={t('pages.firewall.createNewFirewallRule.portLimits.externalPortTo')}
              hideLabel={true}
              value={form.extPortTo.value}
              onChange={extPortToChanged}
              uiComponentState={uiComponentState(form.extPortTo)}
              maxBreakpoint={Breakpoint.TABLET}
              data-testid="ext-port-to-input"
              maxLength={5}
            />
          </div>
        </div>
        <ErrorMessages fields={[form.extPortFrom]} className={styles.errorPort} />
        <ErrorMessages fields={[form.extPortTo]} className={styles.errorPort} />
      </div>
      <div>
        <div className={styles.portFieldsRow}>
          <Input
            id="int-port-from"
            label={t('pages.firewall.createNewFirewallRule.portLimits.internalPortFrom')}
            value={form.intPort.value}
            onChange={intPortChanged}
            uiComponentState={uiComponentState(form.intPort)}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.field}
            maxLength={5}
          />
          <div className={styles.internalPortTo}>
            <Typography
              component="h4"
              variant="uiText2"
              maxBreakpoint={Breakpoint.TABLET}
              bold={true}
              htmlFor="int-port-to"
              className={styles.internalPortToLabel}
            >
              {t('pages.firewall.createNewFirewallRule.portLimits.internalPortTo')}
            </Typography>
            <Typography
              id="int-port-to"
              component="p"
              variant="uiText2"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.number}
            >
              {internalPortTo(form.extPortFrom.value, form.extPortTo.value, form.intPort.value)}
            </Typography>
          </div>
        </div>
        <ErrorMessages fields={[form.intPort]} className={styles.errorPort} />
      </div>
    </Card>
  );
};
