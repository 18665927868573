import { Trans } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';

import { Header } from 'app/features/header';
import { Footer } from 'app/features/footer';
import { EmojiSeeNoEvilMonkey } from 'app/media';

import styles from './non-retryable-system-error.module.scss';

interface Props {
  message: string;
  image?: JSX.Element;
}

export const NonRetryableSystemError = ({ message, image }: Props) => (
  <div>
    <Header />
    <div className={styles.container}>
      <div className={styles.imageContainer}>{image || <EmojiSeeNoEvilMonkey />}</div>

      <Typography className={styles.heading} variant="headline1" component="h1" maxBreakpoint={Breakpoint.TABLET}>
        <Trans i18nKey="errors.nonRetryableSystemError.heading">
          Vi klarer ikke å vise <span className={styles.noWrap}>Mine Sider</span>
        </Trans>
      </Typography>

      <Typography className={styles.message} variant="paragraph1" component="p" maxBreakpoint={Breakpoint.TABLET}>
        {message}
      </Typography>
    </div>
    <Footer />
  </div>
);
