import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

interface ChangePasswordParameters {
  emailAccountId: string;
  password: string;
}
interface ChangeSenderNameParameters {
  emailAccountId: string;
  emailAccountName: MinesiderBackend.EmailAccountSetSenderName;
}
export interface ActivateEmailAccountParameters {
  emailAccountId: string;
  firstname: string;
  lastname: string;
  password: string;
}
export interface EmailAccountDeleted {
  emailAddress: string;
  disabled: boolean;
}

export const getEmailAccountResponse = createAsyncThunk('email/getEmailAccountResponse', async (_, thunkApi) => {
  const emailAccounts = minesiderBackendService.getEmailAccountResponse();
  emailAccounts
    .then((result) => result)
    .catch(() => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.email.accountsFetchRejected'),
          alertType: 'warning',
          role: 'alert',
        }),
      );
    });
  return emailAccounts;
});

export const createNewEmailAccount = createAsyncThunk<
  MinesiderBackend.EmailAccount, // Return type of the payload creator
  MinesiderBackend.EmailAccountCreate, // First argument to the payload creator
  { rejectValue: MinesiderBackend.ErrorResponse } // Types for ThunkAPI
>('email/createNewEmailAccount', async (emailAccount, thunkApi) =>
  minesiderBackendService
    .createNewEmailAccount(emailAccount)
    .then((account) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.email.toast.emailAccountCreated', { emailAddress: account.email }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return account;
    })
    .catch((error) => {
      let heading = i18n.t('pages.email.createNewAccount.validation.accountWasNotCreatedSuccessfully');
      if (error.status === 409) {
        heading = i18n.t('pages.email.createNewAccount.validation.accountAlreadyExistOnUser');
      }
      if (error.status === 425) {
        heading = i18n.t('pages.email.createNewAccount.validation.accountCreationIsTooSoon');
      }
      thunkApi.dispatch(
        addToastMessage({
          alertType: 'critical',
          heading,
          role: 'status',
        }),
      );
      return thunkApi.rejectWithValue({ ...error.data, errorCode: error.status });
    }),
);

export const changeEmailAccountPassword = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  ChangePasswordParameters,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/changePassword', async ({ emailAccountId, password }, thunkApi) =>
  minesiderBackendService
    .changeEmailAccountPassword(emailAccountId, { password })
    .then((account) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.email.toast.passwordChanged', { emailAddress: account.email }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return account;
    })
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const changeEmailAccountSenderName = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  ChangeSenderNameParameters,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/changeSenderName', async ({ emailAccountId, emailAccountName }, thunkApi) =>
  minesiderBackendService
    .changeEmailAccountName(emailAccountId, emailAccountName)
    .then((account) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.email.toast.senderNameChanged', {
            emailAddress: account.email,
          }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return account;
    })
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const deleteEmailAccount = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  MinesiderBackend.EmailAccount,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/deleteEmailAccount', async (emailAccount, thunkApi) =>
  minesiderBackendService
    .deleteEmailAccount(emailAccount.id.toString())
    .then(() => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.email.toast.emailAccountDeleted', {
            emailAddress: emailAccount.email,
          }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return emailAccount;
    })
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const activateEmailAccount = createAsyncThunk<
  MinesiderBackend.EmailAccount,
  ActivateEmailAccountParameters,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('email/activateEmailAccount', async (activateEmailAccountParameters, thunkApi) =>
  minesiderBackendService
    .activateEmailAccount(activateEmailAccountParameters)
    .then((account) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.email.toast.emailAccountActivated', {
            emailAddress: account.email,
          }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return account;
    })
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);
