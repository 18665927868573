import { Field } from 'app/features/form/form-utils';
import { toIso8601 } from 'app/utils/string-util';
import { Role } from 'app/store/types/user-context-types';

import { CrmUser } from './user-form';

interface FormFields<T> {
  email: T;
  firstName: T;
  lastName: T;
  mobileCountryCode: T;
  mobilePhoneNumber: T;
  homePhoneCountryCode: T;
  homePhoneNumber: T;
  preferredContactMethod: T;
  role: T;
  dateOfBirth: {
    year: T;
    month: T;
    day: T;
  };
}

export type FormModel = FormFields<Field<string>>;
export type PreferredContactMethod = Exclude<MinesiderBackend.CrmUser['preferredContactMethod'], undefined>;

export const isNumber = (value: string | undefined) => !!(value ?? '').match(/^\d+$/);
export const isEmpty = (value: string | undefined) => (value ?? '').trim().length === 0;
export const isNameValid = (value: string) => value.length <= 64;
export const isCountryCodeValid = (value: string) => !!value.match(/^\+\d+$/);
export const formatCountryCode = (value: string) => {
  if (value.startsWith('00')) {
    return value.replace('00', '+');
  }
  return !value.startsWith('+') && isNumber(value) ? `+${value}` : value;
};

export const toFormModel: (values: MinesiderBackend.CrmUser) => FormModel = (values) => {
  const dateOfBirth = values.dateOfBirth ? new Date(values.dateOfBirth) : undefined;
  return {
    email: { value: values.email || '' },
    firstName: { value: values.firstName || '' },
    lastName: { value: values.lastName || '' },
    mobileCountryCode: { value: values.mobileCountryCode || '' },
    mobilePhoneNumber: { value: values.mobileNumber || '' },
    homePhoneCountryCode: { value: values.phoneCountryCode || '' },
    homePhoneNumber: { value: values.phoneNumber || '' },
    preferredContactMethod: { value: values.preferredContactMethod || '' },
    role: { value: values.role || '' },
    dateOfBirth: {
      year: { value: dateOfBirth?.getFullYear().toString() || '' },
      month: { value: dateOfBirth ? (dateOfBirth.getMonth() + 1).toString() : '' },
      day: { value: dateOfBirth?.getDate().toString() || '' },
    },
  };
};

export const toValueModel: (customerCrmId: string, form: FormModel) => CrmUser = (customerCrmId, form) => ({
  customerCrmId,
  role: form.role.value as Role,
  email: form.email.value,
  firstName: form.firstName.value,
  lastName: form.lastName.value,
  mobileCountryCode: form.mobileCountryCode.value,
  mobileNumber: form.mobilePhoneNumber.value,
  phoneCountryCode: isEmpty(form.homePhoneCountryCode.value) ? undefined : form.homePhoneCountryCode.value,
  phoneNumber: isEmpty(form.homePhoneNumber.value) ? undefined : form.homePhoneNumber.value,
  preferredContactMethod: form.preferredContactMethod.value as PreferredContactMethod,
  dateOfBirth: toIso8601(form.dateOfBirth.year.value, form.dateOfBirth.month.value, form.dateOfBirth.day.value),
});

export const isFormValid = (form: FormModel) =>
  !(
    form.email.error ||
    form.firstName.error ||
    form.lastName.error ||
    form.dateOfBirth.year.error ||
    form.dateOfBirth.month.error ||
    form.dateOfBirth.day.error ||
    form.role.error ||
    form.mobileCountryCode.error ||
    form.mobilePhoneNumber.error ||
    form.homePhoneCountryCode.error ||
    form.homePhoneNumber.error ||
    form.preferredContactMethod.error
  );
