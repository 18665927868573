import { Breakpoint, Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/hooks/redux-thunk';
import { CustomerType } from 'app/store/types/user-context-types';
import { DeviceImage } from 'app/features/device-image/device-image';
import { FetchStatus } from 'app/store/root-types';
import { CustomerServiceMessage } from '../customer-service-message/customer-service-message';
import { isTwoBox } from 'app/utils/device-utils';
import { Spinner } from 'app/components/spinner';

import styles from './hgw-and-two-box-intro.module.scss';

interface Props {
  customerType: CustomerType;
}

export const HgwAndTwoBoxIntro = ({ customerType }: Props) => {
  const { t } = useTranslation();
  const { data: networkData, fetchStatus: customerNetworkFetchStatus } = useAppSelector((state) => state.network);
  const equipmentEnrichment = useAppSelector((state) => state.enrichment.equipment.data);
  const { isCsr } = useAppSelector((state) => state.auth);

  if (customerNetworkFetchStatus === FetchStatus.PENDING || !equipmentEnrichment) {
    return <Spinner />;
  }

  const hgwModel = networkData?.hgw.type;
  const hgwModelInfo = equipmentEnrichment?.find((e) => e.provSpecificationId === hgwModel);
  const hgwModelName = hgwModelInfo?.friendlyName || hgwModel;

  const accessPointExtended = networkData?.accessPoints.find((ap) => ap.accessPointType === 'WIFI_EXTENDED');
  const accessPointExtendedInfo = equipmentEnrichment?.find(
    (ap) => ap.provSpecificationId === accessPointExtended?.deviceType,
  );
  const hgwImages = hgwModelInfo?.images;
  const accessPointImages = accessPointExtendedInfo?.images;
  const images = hgwImages ? accessPointImages?.concat(hgwImages) : undefined;

  const productTitle =
    customerType === CustomerType.BEDRIFT
      ? t('pages.network.wifiSettings.pageHeadingEnterprise')
      : t('pages.network.wifiSettings.pageHeading');

  const getProductIntroData = () => {
    if (isTwoBox(networkData)) {
      const accessPointTypeName = accessPointExtendedInfo?.friendlyName || accessPointExtended?.deviceType;

      return {
        title: t('pages.network.wifiSettings.twoBox.header'),
        deviceName: t('pages.network.wifiSettings.twoBox.model', {
          hgwModel: hgwModelName,
          additionalAccessPoint: accessPointTypeName,
        }),
        description: t('pages.network.wifiSettings.twoBox.info'),
        info: t('pages.network.wifiSettings.twoBox.readMore'),
        style: styles.titleTwoBox,
      };
    }

    return {
      title: productTitle,
      deviceName: t('pages.network.wifiSettings.hgwModel', {
        hgwModel: hgwModelName,
      }),
      description: hgwModelInfo?.description ?? t('pages.network.wifiSettings.hgwInfo'),
      info: t('pages.network.wifiSettings.hgwIntroReadMore'),
      style: styles.pageTitle,
    };
  };

  const productInfo = getProductIntroData();
  return (
    <>
      {isCsr && <CustomerServiceMessage displayCannotCombineWifi={true} />}
      <DeviceImage
        className={styles.desktopTwoColumns}
        imageContainer={styles.imageContainer}
        imageColumn={styles.imageColumn}
        equipmentImages={isTwoBox(networkData) ? images : hgwModelInfo?.images}
        imageDefaultTextIfNoCaption={t('pages.network.hgwImage.name', { hgwModel: hgwModelName })}
      >
        <div className={styles.heading}>
          <Typography
            component="h2"
            variant="headline2"
            maxBreakpoint={Breakpoint.TABLET}
            className={productInfo.style}
          >
            {productInfo.title}
          </Typography>
        </div>
        <div className={styles.textColumn}>
          <Typography component="h3" variant="headline5" className={styles.deviceName}>
            {productInfo.deviceName}
          </Typography>
          <Typography component="p" variant="paragraph1" className={styles.deviceInfo}>
            {productInfo.description}
          </Typography>
          <Typography variant="uiText2" component="p" bold={true} className={styles.linkText}>
            <a href={hgwModelInfo?.customerServiceUrl} className={styles.moreInfoLink}>
              {productInfo.info}
            </a>
          </Typography>
        </div>
      </DeviceImage>
    </>
  );
};
