import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'app/component-library-wave';

import { InactiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { clearUsersFetchStatus } from 'app/store/actions/users-actions';
import { getUsersResponse, updateUserResponse } from 'app/store/actions/users-thunks';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';

import { ContainerFixed } from 'app/components/container';
import { CrmUser, UserForm } from './user-form';
import { Spinner } from 'app/components/spinner';

export const UserEditPage: InactiveCustomerLocationPage = () => {
  const { t } = useTranslation();
  const crmCustomerId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const crmId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.crmId);
  const allUsers = useAppSelector((state) => state.users.users);
  const userFetchStatus = useAppSelector((state) => state.users.fetchStatus);
  const userUpdateStatus = useAppSelector((state) => state.users.updateUser.fetchStatus);
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();

  const getUser = (userId: string) => allUsers?.find((u) => u.id === userId);
  const userToEdit = crmId ? getUser(crmId) : undefined;

  useEffect(() => {
    if (crmCustomerId && userFetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(getUsersResponse(crmCustomerId));
    }
  }, [crmCustomerId, userFetchStatus, dispatch]);

  useEffect(() => {
    if (userUpdateStatus === FetchStatus.FULFILLED) {
      goToPath(navigationService.PAGES.users.url);
    }
  }, [userUpdateStatus]);

  useEffect(() => {
    if (userFetchStatus === FetchStatus.REJECTED) {
      return () => {
        dispatch(clearUsersFetchStatus());
      };
    }
  }, [dispatch, userFetchStatus]);

  const handleSubmit = (crmUser: CrmUser) => {
    const { role, customerCrmId, ...user } = crmUser;
    dispatch(updateUserResponse({ id: crmId!, user }));
  };

  return (
    <ContainerFixed isNarrow={true}>
      <>
        {userFetchStatus === FetchStatus.PENDING && <Spinner />}
        {(userFetchStatus === FetchStatus.REJECTED || (userFetchStatus === FetchStatus.FULFILLED && !userToEdit)) && (
          <Alert
            alertType="warning"
            heading={t('pages.userEdit.errorMessages.fetchingFailed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
        {userFetchStatus === FetchStatus.FULFILLED && userToEdit && (
          <UserForm
            heading={t('pages.userEdit.heading')}
            initialValues={userToEdit}
            onSubmit={handleSubmit}
            submitButtonName={t('pages.userEdit.buttons.save.name')}
            isLoading={userUpdateStatus === FetchStatus.PENDING}
            error={userUpdateStatus === FetchStatus.REJECTED ? t('pages.userEdit.errorMessages.editFailed') : undefined}
            canEditDateOfBirth={false}
          />
        )}
      </>
    </ContainerFixed>
  );
};
