import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// There will only be one initial application load
let isInitialRender = true;

/**
 * React hook; focuses an HTML Element when navigating, but not on initial application load (which would be impolite). Use for announcing navigation changes (e.g. on page titles). Do not use more than once per page, though.
 *
 * @example
 * const { announceElement } = useAnnounceNavigation<HTMLHeadingElement>();
 * return (<h1 ref={announceElement}>Title</h1>);
 */
export const useAnnounceNavigation = <T extends HTMLElement>() => {
  const { pathname } = useLocation();
  const announceElement = useRef<T>(null);

  useEffect(() => {
    if (!isInitialRender) {
      if (announceElement.current) {
        announceElement.current.tabIndex = -1;
        announceElement.current.focus();
      }
    }

    isInitialRender = false;
  }, [pathname]);

  return { announceElement };
};
