import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { RootState } from '../configure-store';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

export const getSubscriptions = createAsyncThunk<
  MinesiderBackend.CrmUserSubscriptions,
  void,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('subscriptions/getSubscriptions', async (_, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const crmId = currentState.userContext.selectedCustomerLocation!.crmId;
  return minesiderBackendService
    .getSubscriptions(crmId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse));
});

export const updateSubscriptions = createAsyncThunk<
  MinesiderBackend.CrmUserSubscriptions,
  MinesiderBackend.CrmUserSubscriptions,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('subscriptions/updateSubscriptions', async (updatedSubscriptions, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const crmId = currentState.userContext.selectedCustomerLocation!.crmId;
  return minesiderBackendService
    .updateSubscriptions(crmId, updatedSubscriptions)
    .then((subscriptions) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('features.toast.save.success'),
          alertType: 'success',
          role: 'status',
        }),
      );
      return subscriptions;
    })
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse));
});
