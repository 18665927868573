import { createSlice, Slice } from '@reduxjs/toolkit';

import { startProvAuth } from 'app/store/actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import * as relocationActions from 'app/store/actions/relocation-actions';

export enum RelocationMovingDates {
  SAME = 'same dates',
  DIFFERENT = 'different dates',
}

export enum RelocationStepId {
  LOCATION = 0,
  DATES = 1,
  SUMMARY = 2,
  RECEIPT = 3,
}

export enum RelocationStatus {
  FILLING_OUT = 'fillingOut',
  SUBMITTING = 'submitting',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export interface RelocationState {
  searchResults: MinesiderBackend.Address[];
  toAddress: { address: string | undefined; cadastreId: number | undefined } | null;
  movingDateOption: RelocationMovingDates;
  movingOutDate: string | null;
  movingInDate: string | null;
  currentStep: RelocationStepId;
  status: RelocationStatus;
}

const initialState: RelocationState = {
  searchResults: [],
  toAddress: null,
  movingDateOption: RelocationMovingDates.SAME,
  movingOutDate: null,
  movingInDate: null,
  currentStep: RelocationStepId.LOCATION,
  status: RelocationStatus.FILLING_OUT,
};

export const relocationSlice: Slice<RelocationState> = createSlice({
  name: 'relocation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(relocationActions.setToAddress, (state, action) => {
      state.toAddress = action.payload;
    });
    builder.addCase(relocationActions.setMovingDateOption, (state, action) => {
      state.movingDateOption = action.payload;
    });
    builder.addCase(relocationActions.setMovingOutDate, (state, action) => {
      state.movingOutDate = action.payload;
    });
    builder.addCase(relocationActions.setMovingInDate, (state, action) => {
      state.movingInDate = action.payload;
    });
    builder.addCase(relocationActions.setCurrentStep, (state, action) => {
      state.currentStep = action.payload;
    });
    builder.addCase(relocationActions.setChangeOfAddress.pending, (state) => {
      state.status = RelocationStatus.SUBMITTING;
      state.currentStep = RelocationStepId.RECEIPT;
    });
    builder.addCase(relocationActions.setChangeOfAddress.fulfilled, (state) => {
      state.status = RelocationStatus.SUCCESS;
    });
    builder.addCase(relocationActions.setChangeOfAddress.rejected, (state) => {
      state.status = RelocationStatus.FAILURE;
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
  },
});

const { reducer } = relocationSlice;

export { reducer };
