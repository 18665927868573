import env from '@beam-australia/react-env';

import { CONFIG_ATTRIBUTES } from './config-attributes';

const config = {
  auth: {
    clientId: env(CONFIG_ATTRIBUTES.CLIENT_ID),
    scope: env(CONFIG_ATTRIBUTES.SCOPE),
    authenticationCallbackUrl: env(CONFIG_ATTRIBUTES.AUTH_CALLBACK_URL),
    authorizationUrl: env(CONFIG_ATTRIBUTES.AUTHORIZATION_URL),
    logoutUrl: env(CONFIG_ATTRIBUTES.LOGOUT_URL),
    loggedOutRedirectUrl: env(CONFIG_ATTRIBUTES.LOGGED_OUT_REDIRECT_URL),
    oauthProvider: env(CONFIG_ATTRIBUTES.OAUTH_PROVIDER),
    changePassword: env(CONFIG_ATTRIBUTES.CHANGE_PASSWORD_URL),
    changeMultiFactorAuthentication: env(CONFIG_ATTRIBUTES.CHANGE_MULTIFACTORAUTHENTICATION_URL),
    testUserPw: env(CONFIG_ATTRIBUTES.TEST_USER_PW),
  },
  app: {
    contextPath: '/minesider',
    maintenanceUrl: env(CONFIG_ATTRIBUTES.MAINTENANCE_URL),
    userInformationVerificationTimeout: env(CONFIG_ATTRIBUTES.USERINFORMATION_VERIFICATION_FETCH_TIMEOUT),
    voipApiBaseUrl: env(CONFIG_ATTRIBUTES.VOIP_API_BASE_URL),
  },
  gtm: {
    gtmId: env(CONFIG_ATTRIBUTES.GTM_ID),
  },
  sentry: {
    enabled: env(CONFIG_ATTRIBUTES.SENTRY_ENABLED),
    dsn: env(CONFIG_ATTRIBUTES.SENTRY_DSN),
    tracingRate: env(CONFIG_ATTRIBUTES.SENTRY_TRACING_RATE),
    environment: env(CONFIG_ATTRIBUTES.PUPPET_ENVIRONMENT),
  },
  ci360: {
    tenantShortName: env(CONFIG_ATTRIBUTES.CI360_TENANT_SHORT_NAME),
    tenantId: env(CONFIG_ATTRIBUTES.CI360_TENANT_ID),
  },
  partnerConfig: {
    lyse: {
      relocationUrl: env(CONFIG_ATTRIBUTES.LYSE_RELOCATION_URL),
      invoiceUrl: env(CONFIG_ATTRIBUTES.LYSE_INVOICE_URL),
      consentUrl: env(CONFIG_ATTRIBUTES.LYSE_CONSENT_URL),
    },
  },
  ecomBroadbandWidget: {
    dataUrl: env(CONFIG_ATTRIBUTES.ECOM_BROADBAND_WIDGET_DATA_URL),
    redirectUrl: env(CONFIG_ATTRIBUTES.ECOM_BROADBAND_WIDGET_REDIRECT_URL),
  },
  locize: {
    apiKey: env(CONFIG_ATTRIBUTES.LOCIZE_API_KEY),
    projectId: env(CONFIG_ATTRIBUTES.LOCIZE_PROJECT_ID),
    environment: env(CONFIG_ATTRIBUTES.PUPPET_ENVIRONMENT),
    version: env(CONFIG_ATTRIBUTES.LOCIZE_VERSION),
    supportedLanguages: ['nb', 'nn', 'da'],
  },
};

export { config };
