import { createSlice, Slice } from '@reduxjs/toolkit';

import { ProductState, FetchStatus } from '../root-types';
import { startProvAuth } from '../actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { getProduct } from '../actions/products-thunks';

const initialState: ProductState = {
  fetchStatus: FetchStatus.NOT_STARTED,
};

const productSlice: Slice<ProductState> = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);

    builder.addCase(getProduct.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.products = action.payload;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(getProduct.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

const { reducer } = productSlice;

export { reducer };
