import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { RelocationMovingDates, RelocationStatus, RelocationStepId } from 'app/store/slices/relocations-slice';

export interface RelocationData {
  siteId: string;
  from: {
    cadastreId: number; // matrikkelnummer
    address: string;
    when: string; // "YYYY-MM-DD"
  };
  to: {
    cadastreId: number | undefined;
    address: string | undefined;
    when: string;
  };
  userComment: string;
}

export const setChangeOfAddress = createAsyncThunk<
  RelocationData,
  RelocationData,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('relocation/setChangeOfAddress', async (relocationData: RelocationData, thunkApi) =>
  minesiderBackendService
    .submitRelocation(relocationData)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const setToAddress = createAction<{ address: string | undefined; cadastreId: number | undefined } | null>(
  'relocation/setToAddress',
);
export const setMovingDateOption = createAction<RelocationMovingDates>('relocation/setMovingDateOption');
export const setMovingOutDate = createAction<string | null>('relocation/setMovingOutDate');
export const setMovingInDate = createAction<string | null>('relocation/setMovingInDate');
export const setCurrentStep = createAction<RelocationStepId>('relocation/setCurrentStep');
export const setOrderStatus = createAction<RelocationStatus>('relocation/setOrderStatus');
