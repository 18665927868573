import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Input, Typography } from 'app/component-library-wave';

import { uiComponentState } from '../form-utils';
import { FormModel } from '../firewall-setting-utils';
import { Card } from 'app/components/card';
import { useChangeHandler } from '../use-change-handler';

import { ErrorMessages } from 'app/features/form';
import { InfoCircle } from 'app/media';

import styles from './firewall-rule-form.module.scss';

interface Props {
  form: FormModel;
  validateName: () => string | undefined;
  setForm: Dispatch<SetStateAction<FormModel>>;
}

export const FirewallRuleName: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { form, validateName, setForm } = props;
  const handleChange = useChangeHandler<string, FormModel>(setForm);
  const nameChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e.currentTarget.value, (f) => ({ name: f }), validateName);

  return (
    <Card className={styles.card}>
      <div>
        <Typography component="label" variant="uiText1" maxBreakpoint={Breakpoint.TABLET} bold={true}>
          {t('pages.firewall.createNewFirewallRule.ruleName')}
        </Typography>
        <Input
          id="name"
          label={t('pages.firewall.createNewFirewallRule.ruleName')}
          hideLabel={true}
          value={form.name.value}
          onChange={nameChanged}
          uiComponentState={uiComponentState(form.name)}
          maxBreakpoint={Breakpoint.TABLET}
          className={styles.name}
        />
        <ErrorMessages fields={[form.name]} className={styles.errorName} />
        <div className={styles.helpTextContainer}>
          <div className={styles.iconContainer}>
            <InfoCircle
              variant="filled"
              role="graphics-symbol"
              title={t('pages.firewall.explainMessages.explainRuleName')}
              className={styles.infoIcon}
            />
          </div>
          <Typography variant="uiText3" component="span" maxBreakpoint={Breakpoint.TABLET} id="nameHelpText">
            {t('pages.firewall.explainMessages.explainRuleName')}
          </Typography>
        </div>
      </div>
    </Card>
  );
};
