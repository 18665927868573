import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { log } from 'app/utils/logging-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import { config } from 'app/config/config';

import { ContainerFixed } from 'app/components/container';

import styles from './breadcrumbs.module.scss';

const IGNORED_BREADCRUMB_PATHS = [`${config.app.contextPath}/`, `${config.app.contextPath}/internett/innstillinger`];

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  if (location.pathname === navigationService.PAGES.home.url) {
    return null;
  }
  let pathname = location.pathname;

  const crumbs = [];
  do {
    crumbs.push(pathname);
    pathname = pathname.substring(0, pathname.lastIndexOf('/'));
  } while (pathname.indexOf('/') > -1);

  crumbs.reverse();
  if (crumbs[0] !== '/') {
    crumbs.unshift('/');
  }

  const navLinks = crumbs.map((path: string) => {
    const pathTemplate = navigationService.allPages.reduce(
      (prev, cur) => navigationService.reverseResolvePath(prev, cur.url),
      path,
    );

    const pageUrl = navigationService.allPages.find((a) => a.url === pathTemplate);
    if (!pageUrl) {
      if (path !== '/' && !IGNORED_BREADCRUMB_PATHS.includes(path)) {
        log('display name not found for path:', path);
      }
      return null;
    }

    return (
      <li key={path}>
        <NavLink to={path}>
          <div>{t(pageUrl.i18n).replace(' ', '\u00a0')}</div>
        </NavLink>
      </li>
    );
  });

  const filteredNavlinks = navLinks.filter((navlink) => navlink !== null);
  if (filteredNavlinks.length < 2) {
    return null;
  }

  return (
    <nav className={styles.breadcrumbs} aria-label={t('features.breadcrumbs.path')}>
      <ContainerFixed isNarrow={false}>
        <ol>
          {filteredNavlinks.length > 3 ? (
            <>
              {filteredNavlinks[0]}
              <li>
                <div>…</div>
              </li>
              {filteredNavlinks.slice(-2)}
            </>
          ) : (
            filteredNavlinks
          )}
        </ol>
      </ContainerFixed>
    </nav>
  );
};
