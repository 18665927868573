import { SVGProps } from 'react';

import { CustomerType } from 'app/store/types/user-context-types';
import { ReactComponent as House } from 'app/media/icons/house.svg';
import { ReactComponent as HouseEnterprise } from 'app/media/icons/house-enterprise.svg';

interface Props extends SVGProps<SVGSVGElement> {
  customerType: CustomerType;
}

export const LocationIcon = (props: Props) => {
  const { customerType, ...rest } = props;
  if (customerType === CustomerType.BEDRIFT) {
    return <HouseEnterprise {...rest} />;
  }
  return <House {...rest} />;
};
