import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Alert, Breakpoint, Button, ButtonType, Typography as T } from 'app/component-library-wave';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { CustomerPage } from 'app/pages/page-wrapper';
import { navigationService } from 'app/service/navigation/navigation-service';
import { deleteEmailAccount } from 'app/store/actions/email-thunks';
import { FetchStatus } from 'app/store/root-types';
import { useAppNavigation } from 'app/utils/navigation-utils';

import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner/spinner';
import { InfoCircle, Warning } from 'app/media';

import commonEmailCss from '../email-common.module.scss';
import styles from './delete-email-account.module.scss';

export const DeleteEmailAccount: CustomerPage = () => {
  const { emailAccountId } = useParams();
  const { emailAccounts } = useAppSelector((state) => state.email);
  const { fetchStatus } = useAppSelector((state) => state.email.deleteAccount);
  const contactMedium = useAppSelector((state) => state.partner.data?.contactMedium);
  const [accountDeleteConfirmed, setAccountDeleteConfirmed] = useState(false);
  const [validationErrorCssClass, setValidationErrorCssClass] = useState('');
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();

  const [editAccount, setEditAccount] = useState<MinesiderBackend.EmailAccount>();
  const { t } = useTranslation();

  useEffect(() => {
    const account = emailAccounts?.find((x) => x.id?.toString() === emailAccountId);
    if (account) {
      setEditAccount(account);
    } else {
      goToPath(navigationService.PAGES.email.url);
    }
  }, [emailAccounts]);

  useEffect(() => {
    if (fetchStatus === FetchStatus.FULFILLED) {
      goToPath(navigationService.PAGES.email.url);
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (accountDeleteConfirmed) {
      setValidationErrorCssClass('');
    }
  }, [accountDeleteConfirmed]);

  const submitDeleteEmailAccount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setValidationErrorCssClass(accountDeleteConfirmed ? '' : styles.showMissingConsent);
    if (accountDeleteConfirmed) {
      dispatch(deleteEmailAccount(editAccount!));
    }
  };

  return (
    <ContainerFixed isNarrow={true}>
      <div className={commonEmailCss.mobileEdge}>
        <T component="h1" variant="headline4" maxBreakpoint={Breakpoint.TABLET} className={commonEmailCss.heading}>
          {t(`pages.emailDeleteAccount.heading`, {
            emailAddress: editAccount?.email,
          })}
        </T>
        <div className={commonEmailCss.formBorder}>
          <div className={styles.importantHeadingRow}>
            <InfoCircle
              variant="filled"
              title={t('pages.emailDeleteAccount.importantHeadingIconLabel')}
              className={styles.infoIcon}
            />
            <T
              component="h2"
              variant="paragraph1"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.importantHeading}
              bold={true}
            >
              {t('pages.emailDeleteAccount.importantHeading')}
            </T>
          </div>
          <div className={styles.borderContainer}>
            <div className={styles.indentContainer}>
              <T
                component="p"
                variant="paragraph2"
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.importantMessage}
              >
                {t(`pages.emailDeleteAccount.importantMessage`)}
              </T>
              <T
                component="p"
                variant="paragraph2"
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.importantMessage}
              >
                {t('pages.email.emailAsAPaidSubscription')}
              </T>

              {contactMedium && (
                <a href={contactMedium} className={styles.emailInfoLink}>
                  {t('pages.emailDeleteAccount.moreInfoCustomLink')}
                </a>
              )}
              {!contactMedium && (
                <a href="/privat/kundeservice/e-post/" className={styles.emailInfoLink}>
                  {t('pages.emailDeleteAccount.moreInfoLink')}
                </a>
              )}
            </div>
          </div>
          <div className={styles.indentContainer}>
            <div className={validationErrorCssClass}>
              <T component="h2" variant="uiText3" bold={true} className={styles.confirmationHeading}>
                {t('pages.emailDeleteAccount.confirmationHeading', {
                  emailAddress: editAccount?.email,
                })}
              </T>

              <div className={styles.indentContainer}>
                <input
                  type="checkbox"
                  id="confirmAccountTermination"
                  checked={accountDeleteConfirmed}
                  onChange={() => setAccountDeleteConfirmed(!accountDeleteConfirmed)}
                  className={styles.confirmAccountTerminationCheckbox}
                />
                <label htmlFor="confirmAccountTermination" className={styles.confirmAccountTerminationLabel}>
                  {t('pages.emailDeleteAccount.confirmDeleteStatement')}
                </label>
              </div>

              <div className={styles.missingConsentContainer} aria-live="polite">
                <Warning className={styles.warningIcon} />
                <p className={styles.missingConsentMessage}>
                  {t('pages.emailDeleteAccount.missingTerminationConsentMessage')}
                </p>
                <T component="p" variant="paragraph2" maxBreakpoint={Breakpoint.TABLET} renderAsHTML={true}>
                  {t('pages.email.emailAsAPaidSubscription')}
                </T>
              </div>
            </div>
          </div>
        </div>

        {fetchStatus !== FetchStatus.PENDING && (
          <div className={commonEmailCss.buttonRow}>
            <Button
              buttonType={ButtonType.SECONDARY}
              className={commonEmailCss.cancel}
              onClick={(e) => {
                e.preventDefault();
                goToPath(navigationService.PAGES.email.url);
              }}
              type="button"
            >
              {t('pages.email.cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY_B} type="button" onClick={submitDeleteEmailAccount}>
              {t('pages.emailDeleteAccount.deleteAccountButton')}
            </Button>
          </div>
        )}

        {fetchStatus === FetchStatus.PENDING && <Spinner />}
        {fetchStatus === FetchStatus.REJECTED && (
          <Alert
            alertType="warning"
            heading="E-postkontoen ble ikke slettet."
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
      </div>
    </ContainerFixed>
  );
};
