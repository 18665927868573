import React from 'react';
import classNames from 'classnames';

import styles from './dynamic-card.module.scss';

interface Props {
  mainStep: number;
  children?: React.ReactNode;
}

export const DynamicCard: React.FC<Props> = (props) => (
  <div className={classNames(styles.dynamicCard, styles[`step${props.mainStep}Background`])}>{props.children}</div>
);
