import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { EnrichmentState, FetchStatus } from '../root-types';
import { fetchEquipment } from 'app/store/actions/enrichment-thunks';
import { selectCustomerLocation } from '../actions/user-context-actions';

const initialState: EnrichmentState = {
  equipment: { fetchStatus: FetchStatus.NOT_STARTED },
};

const enrichmentSlice: Slice<EnrichmentState> = createSlice({
  name: 'enrichment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchEquipment.fulfilled,
      (state, action: PayloadAction<MinesiderBackend.EquipmentEnrichment[]>) => {
        state.equipment = {
          fetchStatus: FetchStatus.FULFILLED,
          data: action.payload,
        };
        return state;
      },
    );
    builder.addCase(fetchEquipment.pending, (state) => {
      state.equipment.fetchStatus = FetchStatus.PENDING;
      return state;
    });
    builder.addCase(fetchEquipment.rejected, (state) => {
      state.equipment.fetchStatus = FetchStatus.REJECTED;
      return state;
    });
    builder.addCase(selectCustomerLocation, () => initialState);
  },
});

const { reducer } = enrichmentSlice;
export { reducer };
