import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import { Breakpoint, HeadingLevel } from '../../types/global';
import { Typography } from '../typography';
import { LinkArrow } from './icons';

import styles from './navigation-list.module.scss';

export type NavigationListItem = {
  label: string;
  subtitle?: string;
  url?: string; // use either 'url', or 'onButtonClick for linkType 'button'
  linkType?: 'a' | 'button';
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export interface NavigationListProps extends HTMLAttributes<HTMLElement> {
  links: NavigationListItem[];
  heading?: {
    text: string;
    headingLevel: HeadingLevel;
  };
}

const linkContent = (link: NavigationListItem) => {
  return (
    <React.Fragment>
      <div>
        <Typography component="div" variant="uiText2" bold={true} maxBreakpoint={Breakpoint.TABLET}>
          {link.label}
        </Typography>
        {link.subtitle && (
          <Typography
            className={styles.linkDescription}
            component="div"
            variant="uiText2"
            maxBreakpoint={Breakpoint.TABLET}
          >
            {link.subtitle}
          </Typography>
        )}
      </div>
      <div className={styles.linkArrow}>
        <LinkArrow title="" />
      </div>
    </React.Fragment>
  );
};

const renderLink = (link: NavigationListItem) => {
  return link.linkType === 'button' ? (
    <button onClick={link.onButtonClick} type="button" className={styles.link} role="link">
      {linkContent(link)}
    </button>
  ) : (
    <a href={link.url} className={styles.link}>
      {linkContent(link)}
    </a>
  );
};

export const NavigationList = (props: NavigationListProps) => {
  const { links, heading, className, ...rest } = props;
  if (links.length < 1) {
    return null;
  }

  return (
    <section className={classNames(styles.navigationContainer, className)} {...rest}>
      {heading && (
        <Typography
          component={heading.headingLevel}
          variant="headline5"
          maxBreakpoint={Breakpoint.TABLET}
          className={styles.sectionHeader}
        >
          {heading.text}
        </Typography>
      )}

      <ul className={styles.navigation}>
        {links.map((link) => (
          <li className={styles.navigationItem} key={`${link.label}${link.subtitle}`}>
            {renderLink(link)}
          </li>
        ))}
      </ul>
    </section>
  );
};
