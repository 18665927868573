import { createSlice, Slice } from '@reduxjs/toolkit';

import {
  authCallbackFailure,
  authCallbackSuccess,
  provAuthFailure,
  provAuthSuccess,
  startProvAuth,
} from 'app/store/actions/auth-actions';
import { logout } from 'app/store/actions/auth-thunks';
import { AuthState, FetchStatus } from 'app/store/root-types';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';

const initialState: AuthState = {
  sessionExpirationTimestamp: 0,
  fetchStatus: FetchStatus.NOT_STARTED,
};

const authSlice: Slice<AuthState> = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authCallbackFailure, (state, action) => {
      state.failureReason = action.payload;
      return state;
    });
    builder.addCase(authCallbackSuccess, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      state.sessionExpirationTimestamp = action.payload.sessionExpirationTimestamp;
      return state;
    });
    builder.addCase(provAuthFailure, (state, action) => {
      state.failureReason = action.payload;
      return state;
    });
    builder.addCase(provAuthSuccess, (state, action) => {
      state.sessionTicket = action.payload.sessionTicket;
      state.sessionExpirationTimestamp = action.payload.sessionExpirationTimestamp;
      state.isCsr = true;
      return state;
    });
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(selectCustomerLocation, (state, action) => {
      state.sessionTicket = action.payload.customerLocation.sessionTicket;
      return state;
    });
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      fetchStatus: FetchStatus.FULFILLED,
    }));
    builder.addCase(logout.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(logout.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

const { reducer } = authSlice;

export { reducer };
