import React from 'react';
import classNames from 'classnames';

import { Typography } from '../typography';
import { EyeOff, EyeOn } from './icons';
import { Breakpoint, UiComponentState } from '../../types/global';
import { BaseInputProps, DynamicProperties } from './types';
import { HelpText } from '../help-text/help-text';

import commonStyles from '../../common.module.scss';

export const InputBase: React.FC<BaseInputProps> = ({
  styles,
  label,
  id,
  isPassword,
  isHidden,
  helpText,
  hideLabel,
  onChange,
  onToggleHidden,
  uiComponentState,
  className,
  ...rest
}) => {
  const helpTextId = `${id}HelpText`;
  const dynamicAttributes: DynamicProperties = {};
  if (helpText) {
    dynamicAttributes['aria-describedby'] = helpTextId;
  }

  const labelClasses = classNames(
    styles.label,
    uiComponentState === UiComponentState.ERROR ? styles.error : null,
    hideLabel ? commonStyles.screenReaderOnly : null,
  );

  return (
    <div className={className}>
      <label htmlFor={id}>
        <Typography variant="uiText2" component="div" maxBreakpoint={Breakpoint.TABLET} className={labelClasses} bold>
          {label}
        </Typography>
      </label>
      <div className={styles.inputContainer}>
        <input
          id={id}
          type={isPassword && isHidden ? 'password' : 'text'}
          className={`${styles.input}${
            uiComponentState === UiComponentState.ERROR ? ' ' + styles.error : ''
          }${isPassword ? ' ' + styles.password : ''}`}
          onChange={onChange}
          {...dynamicAttributes}
          {...rest}
        />
        {isPassword && (
          <button type="button" className={styles.eye} onClick={onToggleHidden}>
            {isHidden ? <EyeOff /> : <EyeOn />}
          </button>
        )}
      </div>
      <HelpText id={helpTextId} uiComponentState={uiComponentState} helpText={helpText} />
    </div>
  );
};
