import React, { ReactHTML } from 'react';
// import { Breakpoint, Typography } from 'app/clib/';
import { Breakpoint, Typography } from 'app/component-library-wave';

import styles from './card.module.scss';
import classnames from 'classnames';

interface Props {
  header?: string;
  headingLevel?: keyof ReactHTML;
  className?: string;
  containerClassName?: string;
  children?: React.ReactNode;
}

export const Card: React.FC<Props> = (props) => {
  const { header, headingLevel, className, children, containerClassName } = props;

  return (
    <div className={classnames(styles.card, className)}>
      {header && headingLevel && (
        <Typography
          component={headingLevel}
          variant="headline4"
          maxBreakpoint={Breakpoint.TABLET}
          className={styles.header}
        >
          {header}
        </Typography>
      )}
      <div className={classnames(styles.cardContainer, containerClassName)}>{children}</div>
    </div>
  );
};
