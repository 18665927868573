import { Breakpoint, Typography } from 'app/component-library-wave';
import { Variant } from 'app/component-library-wave/components/typography/types';
import classNames from 'classnames';

import { AlertWarning, InfoCircle, Success, Warning } from 'app/media';

import styles from './icon-message.module.scss';

interface Props {
  status: 'success' | 'warning' | 'error' | 'info';
  text: string;
  textVariant?: Variant;
  className?: string;
}

export const IconMessage = (props: Props) => {
  const { status, text, textVariant, className } = props;
  return (
    <div className={classNames(styles.criteriaContainer, className)}>
      {status === 'success' && <Success className={styles.icon} />}
      {status === 'warning' && <Warning className={styles.icon} />}
      {status === 'error' && <AlertWarning className={styles.icon} />}
      {status === 'info' && <InfoCircle variant="filled" className={styles.icon} />}
      <Typography
        className={styles.criteria}
        component="div"
        variant={textVariant ?? 'uiText3'}
        maxBreakpoint={Breakpoint.TABLET}
      >
        {text}
      </Typography>
    </div>
  );
};
