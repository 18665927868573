import React from 'react';
import classNames from 'classnames';

import { Breakpoint, UiComponentState } from '../../types/global';
import { Error, Info, Disabled, Success } from './icons/index';
import { Typography } from '../typography';

import styles from './help-text.module.scss';

export interface HelpTextProps {
  id?: string;
  helpText?: string;
  uiComponentState?: UiComponentState;
}
export const HelpText = (props: HelpTextProps) => {
  const { helpText, uiComponentState, ...rest } = props;

  if (!helpText) {
    return null;
  }

  const helpTextClasses = classNames(
    styles.helpText,
    uiComponentState === UiComponentState.DISABLED ? styles.disabled : null,
  );

  return (
    <div className={styles.helpTextContainer}>
      <div className={styles.iconContainer}>{renderIcon(uiComponentState)}</div>
      <Typography
        variant="uiText3"
        component="span"
        className={helpTextClasses}
        maxBreakpoint={Breakpoint.TABLET}
        {...rest}
      >
        {helpText}
      </Typography>
    </div>
  );
};
function renderIcon(uiComponentState: UiComponentState | undefined) {
  switch (uiComponentState) {
    case UiComponentState.DISABLED:
      return <Disabled />;
    case UiComponentState.ERROR:
      return <Error />;
    case UiComponentState.SUCCESS:
      return <Success />;
    default:
      return <Info />;
  }
}
