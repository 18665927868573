import { FdsLink } from '@lyse-as/formds-react';
import { Typography } from 'app/component-library-wave';
import { Trans, useTranslation } from 'react-i18next';

import styles from 'app/pages/account/invoice/invoice.module.scss';

export const InvoiceCustomerServiceLink = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="formds-lead" component="h2" bold={true} className={styles.invoicingQuestionsHeader}>
        {t('pages.invoice.invoicingQuestionsHeader')}
      </Typography>
      <p className={styles.invoicingQuestions}>
        <Trans i18nKey={'pages.invoice.invoicingQuestions'}>
          <FdsLink href="https://www.altibox.no/privat/kundeservice/faktura/" />
        </Trans>
      </p>
    </>
  );
};
