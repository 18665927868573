import React from 'react';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { config } from 'app/config/config';
import { FetchStatus } from 'app/store/root-types';
import { CustomerPageProps } from 'app/pages/page-wrapper';
import { useUserInfo } from 'app/hooks/use-users-info';
import { UserEmailConfirmation } from '../primary-user-email-verification/primary-user-email-verification';
import { ContactDetailsVerification } from '../contact-details-verification/contact-details-verification';
import { useContactDetailsVerification } from 'app/hooks/use-contact-details-verification';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { CustomerType, isActiveLocation } from 'app/store/types/user-context-types';
import { commonDanishPartnerOnlyVisibility } from 'app/service/navigation/pages/pages';

import styles from './information-verification-prompt.module.scss';

interface Props extends CustomerPageProps {
  children?: React.ReactNode;
}

export const InformationVerificationPrompt = ({ children, userContext }: Props) => {
  const timeToLive = Number.parseInt(config.app.userInformationVerificationTimeout, 10) || 500;
  const { dismissedVerificationPrompt } = useAppSelector((state) => state.core);
  const isEnterprise = userContext.customerType === CustomerType.BEDRIFT;
  const canBePrompted =
    !isEnterprise &&
    isActiveLocation(userContext) &&
    !commonDanishPartnerOnlyVisibility.partners.some((partner) => partner === userContext.partnerNumber);

  const {
    userNeedToVerify,
    timedOut: userTimedOut,
    fetchStatus: userFetchStatus,
  } = useUserInfo(userContext, timeToLive);
  const {
    contactDetails,
    contactNeedToVerify,
    timedOut: contactTimedOut,
    fetchStatus: contactVerifyFetchStatus,
  } = useContactDetailsVerification(userContext, timeToLive);

  const isLoading =
    (!userTimedOut && (userFetchStatus === FetchStatus.NOT_STARTED || userFetchStatus === FetchStatus.PENDING)) ||
    (!contactTimedOut &&
      (contactVerifyFetchStatus === FetchStatus.NOT_STARTED || contactVerifyFetchStatus === FetchStatus.PENDING));

  const VerificationContainer = ({ children: components }: React.PropsWithChildren) => (
    <div
      className={styles.confirmation}
      role="dialog"
      aria-labelledby="confirmationLabel"
      aria-describedby="confirmationContent"
    >
      <ContainerFixed isNarrow={true} className={styles.container}>
        {components}
      </ContainerFixed>
    </div>
  );

  if (!canBePrompted || dismissedVerificationPrompt || (userTimedOut && contactTimedOut)) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <Spinner marginVertical={true} />;
  }

  if (userNeedToVerify) {
    return (
      <VerificationContainer>
        <UserEmailConfirmation userDetails={userNeedToVerify} />
      </VerificationContainer>
    );
  }

  if (contactDetails && contactNeedToVerify) {
    return (
      <VerificationContainer>
        <ContactDetailsVerification userContext={userContext} />
      </VerificationContainer>
    );
  }

  return <>{children}</>;
};
