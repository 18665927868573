import { Breakpoint, Typography } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { useEffect, useState } from 'react';
import { removeToastMessage } from 'app/store/actions/toast-message-actions';
import { ToastMessage as IToastMessage } from 'app/store/root-types';
import { Failure, InfoCircle, Success, Warning } from 'app/media';
import styles from './toast-message.module.scss';

export const ToastMessage = () => {
  const dispatch = useAppDispatch();
  const { messagesList } = useAppSelector((state) => state.toast);
  const [enableCheckForExpiredMessages, setEnableCheckForExpiredMessages] = useState(false);
  const INTERVAL = 1000;

  const now = Date.now();
  useEffect(() => {
    setInterval(() => {
      setEnableCheckForExpiredMessages(true);
    }, INTERVAL);
  }, []);

  useEffect(() => {
    if (enableCheckForExpiredMessages) {
      messagesList.forEach(({ id, expire }) => {
        if (expire - now < 0) {
          dispatch(removeToastMessage(id));
        }
        setEnableCheckForExpiredMessages(false);
      });
    }
  }, [messagesList, enableCheckForExpiredMessages]);

  const toast = ({ payload, id }: { payload: IToastMessage; id: string }) => {
    let icon;
    let iconClassName;
    switch (payload.alertType) {
      case 'warning':
        iconClassName = styles.iconWarning;
        icon = <Warning />;
        break;
      case 'critical':
        iconClassName = styles.iconFailure;
        icon = <Failure />;
        break;
      case 'info':
        iconClassName = styles.iconInfo;
        icon = <InfoCircle variant="filled" />;
        break;
      case 'success':
      default:
        iconClassName = styles.iconSuccess;
        icon = <Success />;
        break;
    }
    return (
      <div key={id} className={`${styles.toast} ${styles[payload.alertType]}`} role={payload.role} aria-live="polite">
        <div className={iconClassName}>{icon}</div>
        <Typography
          variant="uiText2"
          bold={true}
          component="div"
          className={styles.message}
          maxBreakpoint={Breakpoint.MOBILE}
        >
          {payload.heading}
        </Typography>
      </div>
    );
  };

  return <div className={styles.toastContainer}>{messagesList?.map(({ payload, id }) => toast({ payload, id }))}</div>;
};
