const pendingPostfix = 'pending';
const fulfilledPostfix = 'fulfilled';
const rejectedPostfix = 'rejected';

export interface ThunkActionTypes {
  typePrefix: string;
  pending: string;
  fulfilled: string;
  rejected: string;
}

export const createAsyncThunkActionTypes = (typePrefix: string): ThunkActionTypes => ({
  typePrefix,
  pending: `${typePrefix}/${pendingPostfix}`,
  fulfilled: `${typePrefix}/${fulfilledPostfix}`,
  rejected: `${typePrefix}/${rejectedPostfix}`,
});
