import React, { useState } from 'react';

import { DeliveryStatus } from 'app/store/root-types';
import { useMedia } from 'app/hooks';

import { DynamicCard, Introduction, Milestone, CalloutBox, CustomerDiggingStatus, DeliveryStatusPartnerLogo } from './';

import styles from './delivery-status-timeline.module.scss';

enum ScreenSize {
  Sm,
  Md,
  Lg,
}

export enum DeliveryStatusSteps {
  step1_1 = '1-1',
  step2_1 = '2-1',
  step2_2 = '2-2',
  step2_3 = '2-3',
  step3_1 = '3-1',
  step3_2 = '3-2',
  step4_1 = '4-1',
  step5_1 = '5-1',
}

interface Props {
  deliveryStatus: DeliveryStatus;
}

export const DeliveryStatusTimeline: React.FC<Props> = (props) => {
  const screenSize = useMedia<ScreenSize>(['(min-width: 768px)'], [ScreenSize.Md], ScreenSize.Sm);
  const { deliveryStatus } = props;
  const currentStep = deliveryStatus.step.combinedStep;
  const [activeCaptionId, setActiveCaptionId] = useState<number | null>(null);
  const milestoneIds = [1, 2, 3, 4, 5];

  const handleActiveCaptionId = (milestoneId: number | null) =>
    milestoneId === activeCaptionId ? setActiveCaptionId(null) : setActiveCaptionId(milestoneId);

  return (
    <div id="delivery-status-timeline">
      <DynamicCard mainStep={deliveryStatus.step.mainStep}>
        <Introduction deliveryStatus={deliveryStatus} />
        {currentStep === DeliveryStatusSteps.step3_2 && (
          <CustomerDiggingStatus customerDiggingDone={deliveryStatus.customerDiggingDone} />
        )}
        <ol className={styles.timeline}>
          {milestoneIds.map((milestoneId) => (
            <Milestone
              milestoneId={milestoneId}
              toggleCaption={() => handleActiveCaptionId(milestoneId)}
              activeCaption={activeCaptionId === milestoneId}
              activeCaptionId={activeCaptionId}
              deliveryStatus={deliveryStatus}
              key={milestoneId}
            />
          ))}
        </ol>
        {activeCaptionId && screenSize >= ScreenSize.Md && (
          <CalloutBox activeId={activeCaptionId} deliveryStatus={deliveryStatus} />
        )}
        <DeliveryStatusPartnerLogo />
      </DynamicCard>
    </div>
  );
};
