import {
  ActiveCustomerLocationContext,
  Customer,
  CustomerLocationContext,
  UserContextCookie,
  UserCookie,
  isActiveLocation,
  Location,
} from 'app/store/types/user-context-types';
import { SELECTED_LOCATION_COOKIE, SESSION_TICKET_COOKIE, USER_CONTEXT_COOKIE, USER_COOKIE } from 'app/utils/cookies';
import Cookies from 'js-cookie';

export const setUserContextCookies = (
  customerLocation: CustomerLocationContext,
  contextUser: MinesiderBackend.User | undefined,
  access: string | undefined,
) => {
  let siteId = '';
  if (isActiveLocation(customerLocation)) {
    siteId = customerLocation.siteId.toString();
    Cookies.set(SELECTED_LOCATION_COOKIE, siteId);
  } else {
    Cookies.remove(SELECTED_LOCATION_COOKIE);
  }
  Cookies.set(SESSION_TICKET_COOKIE, `${customerLocation.sessionTicket}`);

  const username = contextUser?.username || '';

  const userCookie: UserCookie = {
    customerName: `${customerLocation.firstName} ${customerLocation.lastName}`,
    username,
    availableUsernames: [username],
    crmId: customerLocation.crmId || '',
    assetId: '',
    partnerId: parseInt(customerLocation.partnerNumber, 10),
    partnerName: customerLocation.partnerName,
    partnerLegalName: customerLocation.partnerLegalName,
    customerNumber: customerLocation.customerNumber,
    customerCrmId: customerLocation.customerCrmId,
    sessionTicket: customerLocation.sessionTicket,
    type: customerLocation.customerType,
    crmSystem: customerLocation.crmSystem || '',
  };
  const userLocation: Location = {
    servicePointId: customerLocation.servicePointId || '',
    siteId,
    city: customerLocation.address.city,
    country: customerLocation.address.country,
    postalCode: customerLocation.address.postalcode,
    streetaddress: customerLocation.address.streetaddress,
    dwellingUnitNumber: customerLocation.address.dwellingUnitNumber || '',
    services: (customerLocation as ActiveCustomerLocationContext).services || [],
  };
  const userCustomer: Customer = {
    firstName: customerLocation.firstName,
    lastName: customerLocation.lastName,
    billingAccountId: customerLocation.customerCrmId,
    partnerId: parseInt(customerLocation.partnerNumber, 10),
    crmUserId: customerLocation.crmId || '',
  };
  const userContextCookie: UserContextCookie = {
    customer: userCustomer,
    location: userLocation,
    username,
    sessionTicket: customerLocation.sessionTicket,
    accessToken: access || '',
    role: customerLocation.role,
  };
  Cookies.set(USER_COOKIE, `${JSON.stringify(userCookie)}`);
  Cookies.set(USER_CONTEXT_COOKIE, `${JSON.stringify(userContextCookie)}`);
};
