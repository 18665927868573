export enum Partner {
  DK_Ikast = '2003',
  DK_MES = '2004',
  DK_NOE = '2005',
  DK_TH = '2006',
  DK_TME = '2007',
  DK_MVB = '2008',
  DK_Grindsted = '2009',
  DK_AltiboxOpenNet = '2010',
  NO_Afiber = '1038',
  NO_AltiboxBedrift = '1030',
  NO_Altifiber = '1021',
  NO_BergenFiber = '1024',
  NO_Bruse = '1040',
  NO_DrangedalKraft = '1039',
  NO_EidsivaBredbaandAS = '1044',
  NO_EidsivaBredbaand = '1032',
  NO_EvinyBreiband = '1036',
  NO_FinnaasKraftlag = '1035',
  NO_FitjarKraftlag = '1034',
  NO_HardangerNett = '1037',
  NO_HaugalandKraft = '1003',
  NO_IstadFiber = '1031',
  NO_KleppEnergi = '1008',
  NO_KrageroeBredbaand = '1015',
  NO_LofotkraftBredbaand = '1011',
  NO_Lyse = '1001',
  NO_MidtTelemarkBreiband = '1042',
  NO_Nettstar = '1045',
  NO_NotoddenEnergi = '1019',
  NO_NordkraftFiber = '1025',
  NO_NordvestFiber = '1033',
  NO_NTE = '1009',
  NO_SandefjordBredbaand = '1004',
  NO_Signal = '1026',
  NO_Stroeyma = '1043',
  NO_Telefiber = '1041',
  NO_VerdalKabelTV = '1046',
  NO_VesteraalskraftBredbaand = '1006',
  NO_VikenFiber = '1014',
}

export const getDKPartners = () => Object.values(Partner).filter((partnerId) => partnerId.startsWith('2'));
