import { RestService, RestServiceConfig } from './rest-service';

class CancelSource {
  public readonly service: RestService;
  public readonly signal: AbortSignal;

  private readonly abortController: AbortController;

  constructor(configOverrides: Partial<RestServiceConfig> = {}) {
    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
    const config: RestServiceConfig = {
      abortSignal: this.abortController.signal,
      ...configOverrides,
    };

    this.service = new RestService(config);
  }

  public readonly cancel = (): void => this.abortController.abort();
}

export { CancelSource };
