import { combineReducers, Reducer } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { reducer as userContextReducer } from 'app/store/slices/user-context-slice';
import { reducer as operationalMessagesReducer } from 'app/store/slices/operational-messages-slice';
import { reducer as coreReducer } from 'app/store/slices/core-slice';
import { reducer as deliveryStatusReducer } from 'app/store/slices/delivery-status-slice';
import { reducer as enrichmentReducer } from 'app/store/slices/enrichment-slice';
import { reducer as consentReducer } from 'app/store/slices/consent-slice';
import { reducer as pinReducer } from 'app/store/slices/pin-slice';
import { reducer as subscriptionsReducer } from 'app/store/slices/subscriptions-slice';
import { reducer as relocationReducer } from 'app/store/slices/relocations-slice';
import { reducer as streamingReducer } from 'app/store/slices/streaming-slice';
import { reducer as emailReducer } from 'app/store/slices/email-slice';
import { reducer as networkReducer } from 'app/store/slices/network-slice';
import { reducer as discountReducer } from 'app/store/slices/discounts-slice';
import { reducer as productReducer } from 'app/store/slices/products-slice';
import { reducer as contactDetailsReducer } from 'app/store/slices/contact-details-slice';
import { reducer as firewallReducer } from 'app/store/slices/firewall-slice';
import { reducer as toastReducer } from 'app/store/slices/toast-message-slice';
import { reducer as usersReducer } from 'app/store/slices/users-slice';
import { reducer as movieRentalHistoryReducer } from 'app/store/slices/movie-rental-history-slice';
import { reducer as voipUsageReducer } from 'app/store/slices/voip-slice';
import { reducer as partnerReducer } from 'app/store/slices/partner-slice';
import { reducer as authReducer } from './slices/auth-slice';
import { reducer as voipSettingsReducer } from './slices/voip-settings-slice';
import { reducer as invoiceReducer } from './slices/invoice-slice';

const userContextPersistConfig = {
  key: 'userContext',
  storage,
  blacklist: ['isFetchingData'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['fetchStatus'],
};

const combinedReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  userContext: persistReducer(userContextPersistConfig, userContextReducer),
  operationalMessages: operationalMessagesReducer,
  core: coreReducer,
  deliveryStatus: deliveryStatusReducer,
  enrichment: enrichmentReducer,
  consent: consentReducer,
  pin: pinReducer,
  subscriptions: subscriptionsReducer,
  products: productReducer,
  firewall: firewallReducer,
  relocation: relocationReducer,
  streaming: streamingReducer,
  email: emailReducer,
  network: networkReducer,
  discount: discountReducer,
  contactDetails: contactDetailsReducer,
  users: usersReducer,
  movieRentalHistory: movieRentalHistoryReducer,
  voipUsage: voipUsageReducer,
  partner: partnerReducer,
  toast: toastReducer,
  voipSettings: voipSettingsReducer,
  invoice: invoiceReducer,
});

const rootReducer: Reducer = (state, action) => combinedReducer(state, action);

export { rootReducer };
