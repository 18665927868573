import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import {
  ActiveCustomerLocationContext,
  InactiveCustomerLocationContext,
  isActiveLocation,
  isInactiveCustomer,
  isNewLocation,
  MainContext,
  NewCustomerLocationContext,
} from 'app/store/types/user-context-types';
import {
  pages,
  RoutableActiveCustomerLocationPage,
  RoutableNewCustomerLocationPage,
  RoutableInactiveCustomerLocationPage,
  Redirect,
} from 'app/service/navigation/routes';
import {
  ActiveCustomerLocationPage,
  InactiveCustomerLocationPage,
  NewCustomerLocationPage,
} from 'app/pages/page-wrapper';
import { MainMenu } from 'app/features/main-menu';
import { NonRetryableSystemError } from 'app/pages/error/non-retryable-system-error';
import { Ci360IdentityTag } from '../ci360-identity-tag';
import { InformationVerificationPrompt } from 'app/features/information-verification-prompt/information-verification-prompt';

interface Props {
  context: MainContext;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const renderActiveCustomerLocationContextPage = (
  context: ActiveCustomerLocationContext,
  { url, i18n, component: Component, ...rest }: RoutableActiveCustomerLocationPage,
) => <Route path={url} element={<Component userContext={context} {...rest} />} key={i18n} />;

const renderNewCustomerLocationContextPage = (
  context: NewCustomerLocationContext,
  { url, i18n, component: Component, ...rest }: RoutableNewCustomerLocationPage,
) => <Route path={url} element={<Component userContext={context} {...rest} />} key={i18n} />;

const renderInactiveCustomerLocationContextPage = (
  context: InactiveCustomerLocationContext,
  { url, i18n, component: Component, ...rest }: RoutableInactiveCustomerLocationPage,
) => <Route path={url} element={<Component userContext={context} {...rest} />} key={i18n} />;

const renderRedirectRoute = ({ oldUrl, newUrl }: Redirect) => (
  <Route path={oldUrl} element={<Navigate replace={true} to={newUrl} />} key={newUrl} />
);

const getInactiveCustomerPages = () => {
  const shared = pages.sharedPages.map((sharedPage) => ({
    ...sharedPage,
    component: sharedPage.component as InactiveCustomerLocationPage,
  }));
  return pages.inactivePages.concat(shared);
};
const getActiveCustomerPages = () => {
  const shared = pages.sharedPages.map((sharedPage) => ({
    ...sharedPage,
    component: sharedPage.component as ActiveCustomerLocationPage,
  }));
  return pages.activePages.concat(shared);
};
const getNewCustomerPages = () => {
  const shared = pages.sharedPages.map((sharedPage) => ({
    ...sharedPage,
    component: sharedPage.component as NewCustomerLocationPage,
  }));
  return pages.newPages.concat(shared);
};

const ContextSwitch: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { context } = props;

  let pageRoutes;
  if (isInactiveCustomer(context)) {
    pageRoutes = getInactiveCustomerPages().map((page) => renderInactiveCustomerLocationContextPage(context, page));
  } else if (isActiveLocation(context)) {
    pageRoutes = getActiveCustomerPages().map((page) => renderActiveCustomerLocationContextPage(context, page));
  } else if (isNewLocation(context)) {
    pageRoutes = getNewCustomerPages().map((page) => renderNewCustomerLocationContextPage(context, page));
  } else {
    pageRoutes = (
      <NonRetryableSystemError message={t('errors.nonRetryableSystemError.NO_ACCOUNTS_FOUND_FOR_CUSTOMER')} />
    );
  }

  const redirectRoutes = pages.redirects.map((page) => renderRedirectRoute(page));

  return (
    <>
      {process.env.NODE_ENV !== 'development' ? <Ci360IdentityTag context={context} ci360={window.ci360} /> : null}
      <InformationVerificationPrompt userContext={context}>
        <MainMenu context={context} />
        <main className={'page'} id="content">
          <SentryRoutes>
            {pageRoutes}
            {redirectRoutes}
          </SentryRoutes>
        </main>
      </InformationVerificationPrompt>
    </>
  );
};

export { ContextSwitch };
