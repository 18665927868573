import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { startProvAuth } from '../actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { clearEmailForms } from '../actions/email-actions';
import {
  activateEmailAccount,
  changeEmailAccountPassword,
  changeEmailAccountSenderName,
  createNewEmailAccount,
  deleteEmailAccount,
  getEmailAccountResponse,
} from '../actions/email-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';

import { EmailState, FetchStatus } from '../root-types';

const initialState: EmailState = {
  fetchStatus: FetchStatus.NOT_STARTED,
  createNew: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  changeSenderName: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  changePassword: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  deleteAccount: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  activateAccount: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
};

const emailSlice: Slice<EmailState> = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(getEmailAccountResponse.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(
      getEmailAccountResponse.fulfilled,
      (state, action: PayloadAction<MinesiderBackend.EmailServiceDetails>) => {
        state.emailAccounts = action.payload.accounts;
        state.domain = action.payload.settings?.domain;
        state.fetchStatus = FetchStatus.FULFILLED;
      },
    );
    builder.addCase(getEmailAccountResponse.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });

    builder.addCase(createNewEmailAccount.pending, (state) => {
      state.createNew = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(createNewEmailAccount.fulfilled, (state) => {
      state.fetchStatus = FetchStatus.NOT_STARTED;
      state.createNew = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(createNewEmailAccount.rejected, (state) => {
      state.createNew = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(clearEmailForms, (state) => {
      state.createNew = initialState.createNew;
      state.changePassword = initialState.changePassword;
      state.changeSenderName = initialState.changeSenderName;
      state.deleteAccount = initialState.deleteAccount;
      state.activateAccount = initialState.activateAccount;
    });
    builder.addCase(changeEmailAccountPassword.pending, (state) => {
      state.changePassword = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(changeEmailAccountPassword.rejected, (state) => {
      state.changePassword = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(changeEmailAccountPassword.fulfilled, (state) => {
      state.changePassword = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(changeEmailAccountSenderName.pending, (state) => {
      state.changeSenderName = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(changeEmailAccountSenderName.rejected, (state) => {
      state.changeSenderName = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(changeEmailAccountSenderName.fulfilled, (state) => {
      state.changeSenderName = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(deleteEmailAccount.pending, (state) => {
      state.deleteAccount = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(deleteEmailAccount.rejected, (state) => {
      state.deleteAccount = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(deleteEmailAccount.fulfilled, (state) => {
      state.deleteAccount = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(activateEmailAccount.pending, (state) => {
      state.activateAccount = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(activateEmailAccount.rejected, (state) => {
      state.activateAccount = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(activateEmailAccount.fulfilled, (state) => {
      state.activateAccount = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
  },
});

const { reducer } = emailSlice;

export { reducer };
