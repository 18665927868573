import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Button, ButtonType, Typography } from 'app/component-library-wave';

import { useChangeHandler } from 'app/features/form/use-change-handler';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { clearContactDetailsForm } from 'app/store/actions/contact-details-actions';
import { FetchStatus } from 'app/store/root-types';
import { emailAddressIsValid } from 'app/utils/validators';

import { Card } from 'app/components/card/card';
import { IconMessage } from 'app/components/icon-message/icon-message';
import { Spinner } from 'app/components/spinner';

import { BillingAddressSection } from './billing-address-section';
import { ContactAddressSection } from './contact-address-section';
import { ContactDetailsSection } from './contact-details-section';
import {
  AddressType,
  FormModel,
  getString,
  isFormValid,
  isEmpty,
  isNumber,
  isZipCodeValid,
  toFormModel,
  toValueModel,
  validateRequired,
} from './contact-details-utils';
import { iso8601ToDayFullMonthAndYear } from 'app/utils/date-utils';

import styles from './contact-details-form.module.scss';

interface Props {
  initialValues: MinesiderBackend.CustomerDetails;
  displayName?: string;
  displayContactId?: string;
  fetchStatus: FetchStatus;
  onSubmit: (customerDetails: MinesiderBackend.CustomerDetails) => void;
}

export const ContactDetailsForm: React.FC<Props> = (props) => {
  const { initialValues, fetchStatus, onSubmit, displayName, displayContactId } = props;
  const { t } = useTranslation();
  const dateOfBirth = useAppSelector((state) => state.contactDetails.contactDetails?.individualOwner?.dateOfBirth);
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();

  const [form, setForm] = useState<FormModel>(toFormModel(initialValues));
  const [errorMessage, setErrorMessage] = useState<string>();
  const showBillingAddressSection = !!initialValues.billingAddress || initialValues.syncBillingAddress;

  const errorInvalidEmail = t('pages.contactDetailsEdit.validation.invalidEmail');
  const errorEmptyMobileNumber = t('pages.contactDetailsEdit.validation.emptyMobileNumber');
  const errorInvalidMobileNumber = t('pages.contactDetailsEdit.validation.invalidMobileNumber');
  const errorEmptyHouseNr = t('pages.contactDetailsEdit.validation.emptyHouseNr');
  const errorInvalidHouseNr = t('pages.contactDetailsEdit.validation.invalidHouseNr');
  const errorEmptyZip = t('pages.contactDetailsEdit.validation.emptyZip');
  const errorInvalidZip = t('pages.contactDetailsEdit.validation.invalidZip');

  useEffect(() => {
    if (fetchStatus === FetchStatus.REJECTED) {
      setErrorMessage(t('pages.contactDetailsEdit.savingFailed'));
    } else {
      setErrorMessage(undefined);
    }
  }, [fetchStatus, setErrorMessage]);

  const validateEmail = useCallback(
    (value?: string) => (value && emailAddressIsValid(value) ? undefined : errorInvalidEmail),
    [errorInvalidEmail],
  );
  const validateMobileNumber = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return errorEmptyMobileNumber;
      }
      return isNumber(value) ? undefined : errorInvalidMobileNumber;
    },
    [errorEmptyMobileNumber, errorInvalidMobileNumber],
  );
  const validateHouseNumber = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return errorEmptyHouseNr;
      }
      return isNumber(value) ? undefined : errorInvalidHouseNr;
    },
    [errorEmptyHouseNr, errorInvalidHouseNr],
  );
  const validateZipCode = useCallback(
    (value?: string) => {
      if (isEmpty(value)) {
        return errorEmptyZip;
      }
      return isZipCodeValid(value) ? undefined : errorInvalidZip;
    },
    [errorEmptyZip, errorInvalidZip],
  );

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const validatedForm: FormModel = {
        emailAddress: { ...form.emailAddress, isTouched: true, error: validateEmail(form.emailAddress.value) },
        mobileCountryCode: {
          ...form.mobileCountryCode,
          isTouched: true,
          error: validateRequired(
            form.mobileCountryCode.value,
            t('pages.contactDetailsEdit.validation.invalidMobileNumber'),
          ),
        },
        mobileNumber: {
          ...form.mobileNumber,
          isTouched: true,
          error: validateMobileNumber(form.mobileNumber.value),
        },
        phoneCountryCode: form.phoneCountryCode
          ? {
              value: form.phoneCountryCode.value,
              isTouched: true,
              error:
                !isEmpty(form.phoneNumber?.value) &&
                (isEmpty(form.phoneCountryCode.value) || !isNumber(form.phoneNumber?.value))
                  ? t('pages.contactDetailsEdit.validation.invalidHomePhoneNumber')
                  : undefined,
            }
          : undefined,
        phoneNumber: form.phoneNumber,
        syncBillingAddress: form.syncBillingAddress,
        billingAddress:
          form.syncBillingAddress.value === 'false' && showBillingAddressSection
            ? {
                type: {
                  value: getString(form.billingAddress?.type.value),
                  isTouched: true,
                },
                city: {
                  value: getString(form.billingAddress?.city.value),
                  isTouched: true,
                  error: validateRequired(
                    form.billingAddress?.city.value,
                    t('pages.contactDetailsEdit.validation.emptyCity'),
                  ),
                },
                zip: {
                  value: getString(form.billingAddress?.zip.value),
                  isTouched: true,
                  error: validateZipCode(form.billingAddress?.zip.value),
                },
                street: {
                  value: getString(form.billingAddress?.street.value),
                  isTouched: true,
                  error: validateRequired(
                    form.billingAddress?.street.value,
                    t('pages.contactDetailsEdit.validation.emptyStreet'),
                  ),
                },
                houseNr: {
                  value: getString(form.billingAddress?.houseNr.value),
                  isTouched: true,
                  error: validateHouseNumber(form.billingAddress?.houseNr.value),
                },
                houseLetter: form.billingAddress?.houseLetter,
                postbox:
                  form.billingAddress?.type.value === AddressType.POSTBOX
                    ? {
                        value: getString(form.billingAddress.postbox?.value),
                        isTouched: true,
                        error: validateRequired(
                          form.billingAddress.postbox?.value,
                          t('pages.contactDetailsEdit.validation.emptyPostbox'),
                        ),
                      }
                    : undefined,
              }
            : undefined,
        contactAddress: {
          type: {
            ...form.contactAddress.type,
            isTouched: true,
          },
          city: {
            ...form.contactAddress.city,
            isTouched: true,
            error: validateRequired(form.contactAddress.city.value, t('pages.contactDetailsEdit.validation.emptyCity')),
          },
          zip: {
            ...form.contactAddress.zip,
            isTouched: true,
            error: validateZipCode(form.contactAddress.zip.value),
          },
          street: {
            ...form.contactAddress.street,
            isTouched: true,
            error: validateRequired(
              form.contactAddress.street.value,
              t('pages.contactDetailsEdit.validation.emptyStreet'),
            ),
          },
          houseNr: {
            ...form.contactAddress.houseNr,
            isTouched: true,
            error: validateHouseNumber(form.contactAddress.houseNr.value),
          },
          houseLetter: form.contactAddress.houseLetter,
          dwellingUnit: form.contactAddress.dwellingUnit,
          postbox:
            form.contactAddress.type.value === AddressType.POSTBOX
              ? {
                  value: getString(form.contactAddress.postbox?.value),
                  isTouched: true,
                  error: validateRequired(
                    form.contactAddress.postbox?.value,
                    t('pages.contactDetailsEdit.validation.emptyPostbox'),
                  ),
                }
              : undefined,
        },
      };

      setForm(validatedForm);
      if (isFormValid(validatedForm)) {
        onSubmit(toValueModel(validatedForm, initialValues));
      } else {
        setErrorMessage(t('pages.contactDetailsEdit.validation.invalidForm'));
      }
    },
    [form, initialValues, isEmpty, onSubmit, setForm, setErrorMessage],
  );

  const handleChange = useChangeHandler<string, FormModel>(setForm);

  return (
    <form className={styles.form}>
      <Card>
        <div className={styles.formSection}>
          <Typography
            component="h2"
            variant="uiText1"
            bold={true}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.sectionTitle}
          >
            {t('pages.contactDetailsCommon.customerRelationships')}
          </Typography>
          <Typography component="div" variant="uiText1" maxBreakpoint={Breakpoint.TABLET} className={styles.name}>
            {displayName}
          </Typography>
          {!initialValues.isEnterprise && (
            <div className={styles.dateOfBirth}>
              <div className={styles.hint}>
                <IconMessage
                  className={styles.iconMessage}
                  status="info"
                  text={t('pages.contactDetailsEdit.customerRelationshipsInfo')}
                />
              </div>
            </div>
          )}
          <Typography
            component="div"
            variant="uiText2"
            maxBreakpoint={Breakpoint.TABLET}
            bold={true}
            className={styles.name}
          >
            {initialValues.isEnterprise ? t('pages.contactDetails.organizationNumber') : t('pages.contactDetails.born')}
            <Typography component="div" variant="uiText2" maxBreakpoint={Breakpoint.TABLET}>
              <div className={styles.datepicker}>
                {initialValues.isEnterprise ? displayContactId : iso8601ToDayFullMonthAndYear(dateOfBirth || '')}
              </div>
            </Typography>
          </Typography>
        </div>
        <ContactDetailsSection
          values={form}
          handleChange={handleChange}
          validateEmail={validateEmail}
          validateMobileNumber={validateMobileNumber}
          className={styles.formSection}
        />
        <ContactAddressSection
          values={form}
          handleChange={handleChange}
          validateZipCode={validateZipCode}
          validateHouseNumber={validateHouseNumber}
          className={styles.formSection}
        />
        <BillingAddressSection
          values={form}
          handleChange={handleChange}
          validateZipCode={validateZipCode}
          validateHouseNumber={validateHouseNumber}
          className={styles.formSection}
        />
      </Card>
      {errorMessage && (
        <Alert
          alertType="warning"
          heading={errorMessage}
          headingElement="strong"
          isExpandable={false}
          role="alert"
          className={styles.alert}
        />
      )}
      <div className={styles.buttonRow}>
        {fetchStatus === FetchStatus.PENDING && <Spinner />}
        {fetchStatus !== FetchStatus.PENDING && (
          <>
            <Button
              buttonType={ButtonType.SECONDARY}
              type="button"
              onClick={(event) => {
                event.preventDefault();
                goToPath(navigationService.PAGES.contactDetails.url);
                dispatch(clearContactDetailsForm());
              }}
            >
              {t('pages.contactDetailsEdit.buttons.cancel')}
            </Button>
            <Button
              buttonType={ButtonType.PRIMARY_B}
              type="submit"
              onClick={handleSubmit}
              className={styles.saveButton}
            >
              {t('pages.contactDetailsEdit.buttons.save')}
            </Button>
          </>
        )}
      </div>
    </form>
  );
};
