import { createAction, Dispatch } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { GlobalError } from 'app/store/root-types';

// Types
// Action creators
export const setGlobalError = createAction<GlobalError>('SET_GLOBAL_ERROR');
export const clearGlobalError = createAction<undefined>('CLEAR_GLOBAL_ERROR');
export const enableTranslatorMode = createAction<undefined>('ENABLE_TRANSLATOR_MODE');
export const dismissVerificationPrompt = createAction<undefined>('DISMISS_VERIFICATION_PROMPT');

// Thunks
export const raiseGlobalError = (globalError: GlobalError) => async (dispatch: Dispatch) => {
  Sentry.captureException(globalError);

  dispatch(
    setGlobalError({
      type: globalError.type, // consider checking "error instanceof NonRetryableSystemError" when introducing new error types
      message: globalError.message,
      error: globalError.error,
    }),
  );
};
