import { useAppDispatch } from 'app/hooks/redux-thunk';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography as T } from 'app/component-library-wave';
import { verifyContactDetails } from 'app/store/actions/user-context-thunks';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import { CustomerPage } from 'app/pages/page-wrapper';
import { dismissVerificationPrompt } from 'app/store/actions/core-actions';
import { useContactDetails } from 'app/hooks/use-contact-details';
import { FetchStatus } from 'app/store/root-types';
import { Spinner } from 'app/components/spinner';
import classNames from 'classnames';

import styles from './contact-details-verification.module.scss';

export const ContactDetailsVerification: CustomerPage = ({ userContext }) => {
  const { goToPath } = useAppNavigation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { contactDetails, fetchStatus } = useContactDetails(userContext);
  const { crmId } = userContext;

  if (fetchStatus === FetchStatus.PENDING) {
    return <Spinner />;
  }

  const mobilePhoneRegex = /^[4|9]\d{7}$/;
  const landlineRegex = /^[1-3|5-8]\d{7}$/;

  const isMobilePhoneNumberValid = contactDetails?.mobileNumber
    ? mobilePhoneRegex.test(contactDetails.mobileNumber)
    : false;
  const isLandlineNumberValid = contactDetails?.phoneNumber ? landlineRegex.test(contactDetails.phoneNumber) : true;
  const isMissingInformation =
    !contactDetails?.emailAddress ||
    !contactDetails.mobileNumber ||
    !isMobilePhoneNumberValid ||
    !isLandlineNumberValid;

  const reviewDetails = () => {
    dispatch(dismissVerificationPrompt());
    goToPath(navigationService.PAGES.contactDetailsEdit.url);
  };

  return (
    <div className={styles.container}>
      <>
        <T
          component="p"
          variant="headline5"
          id="confirmationLabel"
          className={styles.title}
          bold={true}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {isMissingInformation
            ? t('pages.contactDetailsVerify.missingInformation')
            : t('pages.contactDetailsVerify.isInformationCorrect')}
        </T>

        <div className={styles.userDetail} id="confirmationContent">
          <T component="label" className={styles.label} variant="paragraph1" maxBreakpoint={Breakpoint.TABLET}>
            {t('pages.contactDetailsVerify.email')}:
          </T>
          <T
            component="span"
            className={classNames(styles.variable, !contactDetails?.emailAddress ? styles.invalidValue : null)}
            variant="uiText1"
            bold={true}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {contactDetails?.emailAddress || t('pages.contactDetailsVerify.thisIsMissing')}
          </T>
        </div>
        <div className={styles.userDetail}>
          <T component="label" className={styles.label} variant="uiText1" maxBreakpoint={Breakpoint.TABLET}>
            {isMobilePhoneNumberValid
              ? t('pages.contactDetailsEdit.mobileNumber')
              : t('pages.contactDetailsVerify.mobileNumberInvalid')}
          </T>
          <T
            component="span"
            className={classNames(styles.variable, !isMobilePhoneNumberValid ? styles.invalidValue : null)}
            variant="uiText1"
            bold={true}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {contactDetails?.mobileNumber
              ? `(${contactDetails.mobileCountryCode}) ${contactDetails?.mobileNumber}`
              : t('pages.contactDetailsVerify.thisIsMissing')}
          </T>
        </div>
        {!isLandlineNumberValid && (
          <div className={styles.userDetail}>
            <T component="label" className={styles.label} variant="uiText1" maxBreakpoint={Breakpoint.TABLET}>
              {isMobilePhoneNumberValid
                ? t('pages.contactDetailsEdit.homePhoneNumber')
                : t('pages.contactDetailsVerify.landlineNumberInvalid')}
            </T>
            <T
              component="span"
              className={classNames(styles.variable, !isMobilePhoneNumberValid ? styles.invalidValue : null)}
              variant="uiText1"
              bold={true}
              maxBreakpoint={Breakpoint.TABLET}
            >
              {`(${contactDetails?.phoneCountryCode}) ${contactDetails?.phoneNumber}`}
            </T>
          </div>
        )}
      </>
      <div className={styles.buttons}>
        <Button onClick={() => reviewDetails()} buttonType={ButtonType.SECONDARY}>
          {t('pages.contactDetailsVerify.buttons.edit')}
        </Button>
        {!isMissingInformation && (
          <Button
            onClick={() => (crmId ? dispatch(verifyContactDetails(crmId)) : null)}
            buttonType={ButtonType.PRIMARY_B}
          >
            {t('pages.contactDetailsVerify.buttons.confirm')}
          </Button>
        )}

        <Button onClick={() => dispatch(dismissVerificationPrompt())} buttonType={ButtonType.TERTIARY}>
          {t('pages.contactDetailsVerify.buttons.ignore')}
        </Button>
      </div>
    </div>
  );
};
