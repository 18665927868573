import deepEqual from 'deep-equal';
import { t } from 'i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';
import { ChannelWidth, NetworkModesMapped } from 'app/store/types/network-types';

import styles from './network-common.module.scss';
export interface WifiNetworkMap {
  [index: string]: MinesiderBackend.WifiNetwork;
}

export interface PartialConfig {
  ssid: string;
  isSSidHidden: boolean;
  encKey?: string;
  encProtocol?: string;
  enabled: boolean;
}
interface CombineConfig {
  wifiIds: string[];
  enableCombined?: boolean;
  newConfig: MinesiderBackend.UpdateWifiNetwork[];
  isMesh?: boolean;
}

export const FALLBACK_SELFCARE_HGW_URL =
  'https://www.altibox.no/privat/kundeservice/hjelp-til-internett/hjemmesentral/';

export const combineConfig = ({ wifiIds, enableCombined, newConfig, isMesh }: CombineConfig) => {
  const baseConfig = newConfig.find((config) => config.radioBand === 2 && wifiIds.includes(config.id));

  if (!baseConfig) {
    throw new Error(t('pages.network.advanced.wifi.errors.notFound'));
  }

  const setSsidName = (wifi: MinesiderBackend.UpdateWifiNetwork) => {
    // If isMesh, the network is Altibox Overalt, and cannot split the network, so return the same name.
    if (enableCombined || isMesh) {
      return commonConfig.ssid;
    }

    // When splitting up the network, there is no setting value that determines this, so we differentiate by ie SSID name.
    if (wifi.radioBand !== 2) {
      return `${wifi.ssid.substring(0, 25)}_${wifi.radioBand}GHz`;
    }
    return baseConfig.ssid;
  };

  const setEnabled = () => {
    if (enableCombined) {
      return true;
    }
    if (baseConfig.enabled !== undefined) {
      return baseConfig.enabled;
    }
    return true;
  };

  const commonConfig: PartialConfig = {
    ssid: baseConfig.ssid,
    isSSidHidden: baseConfig.isSSidHidden || false,
    encKey: baseConfig.encKey,
    encProtocol: baseConfig.encProtocol,
    enabled: setEnabled(),
  };

  // Copy settings from 2.4GHz to the rest of the settings for that HGW or Access Point.
  const settings: MinesiderBackend.UpdateWifiNetwork[] = [];
  wifiIds.forEach((ref) => {
    const wifi = newConfig.find((config) => config.id === ref);
    if (!wifi) {
      return;
    }
    settings.push({
      ...wifi,
      ...commonConfig,
      ssid: setSsidName(wifi),
    });
  });
  return settings;
};

export const areBandsIdentical = (networkData: MinesiderBackend.CustomerNetwork, wifiIds: string[]) => {
  const settings: PartialConfig[] = [];

  if (wifiIds.length === 1) {
    return false;
  }

  wifiIds.forEach((id) => {
    const wifi = networkData.wifiSettings.find((wifiSetting) => wifiSetting.id === id);
    if (wifi) {
      settings.push({
        ssid: wifi.ssid,
        isSSidHidden: wifi.isSSidHidden,
        encKey: wifi.encKey,
        encProtocol: wifi.encProtocol,
        enabled: wifi.enabled,
      });
    }
  });

  return settings.every((setting) => deepEqual(setting, settings[0]));
};

export type AvaliableNetworkModes = {
  id: string;
  displayName: string;
  channelWidths: ChannelWidth;
}[];

export const getAvailableNetworkModes = ({
  networkData,
  radioBand = '2',
}: {
  networkData: MinesiderBackend.CustomerNetwork;
  radioBand?: string | number;
}): AvaliableNetworkModes => {
  if (
    !networkData.hgw.fieldValues.channel ||
    !networkData.hgw.fieldValues.channel.values ||
    networkData.hgw.type === 'CPL7'
  ) {
    return [];
  }

  const settings = JSON.parse(
    JSON.parse(JSON.stringify(networkData.hgw.fieldValues.channel.values[radioBand])),
  ) as NetworkModesMapped;

  return Object.entries(settings).map((setting) => ({
    ...setting[1],
    id: setting[0],
  }));
};

interface IpAddressWithSeparatorProps {
  ipAddressArray: string[];
  numbersToShow: number;
}
export const IpAddressWithSeparator: React.FC<IpAddressWithSeparatorProps> = (props) => {
  const { ipAddressArray, numbersToShow: showFirstNumbers } = props;

  return (
    <div className={styles.ipAddress}>
      {ipAddressArray.slice(0, showFirstNumbers).map((i, index) => (
        <Typography key={index} variant="uiText1" component="span" maxBreakpoint={Breakpoint.TABLET}>
          {i}
        </Typography>
      ))}
    </div>
  );
};
