import { ImageSwiper, SwiperImage } from 'app/features/image-swiper/image-swiper';

interface Props {
  children?: React.ReactNode;
  className: string;
  imageContainer: string;
  imageColumn: string;
  equipmentImages?: MinesiderBackend.EquipmentImage[];
  imageDefaultTextIfNoCaption?: string;
}
export const DeviceImage = ({
  children,
  className,
  imageContainer,
  imageColumn,
  equipmentImages,
  imageDefaultTextIfNoCaption,
}: Props) => {
  const enrichedImages: SwiperImage[] =
    equipmentImages?.map((image) => ({
      url: image.imageUrl,
      imgAlt: image.altText,
      caption: image.caption || imageDefaultTextIfNoCaption,
    })) || [];

  return (
    <div className={className}>
      {enrichedImages.length > 0 && (
        <div className={imageColumn}>
          <div className={imageContainer}>{<ImageSwiper swiperImages={enrichedImages} />}</div>
        </div>
      )}
      {children}
    </div>
  );
};
