import { FdsIcon } from '@lyse-as/formds-react';
import { useEffect, useState } from 'react';
import styles from './copy-button.module.scss';

type CopyButtonProps = {
  value: string;
  title: string;
};

export const CopyButton: React.FC<CopyButtonProps> = ({ value, title }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(value);
  };

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={handleCopy} title={title} aria-label={title}>
        <FdsIcon name="content_copy" className={!copied ? styles.copyIcon : styles.hidden} />
        <FdsIcon name="check" className={copied ? styles.checkIcon : styles.hidden} />
      </button>
    </div>
  );
};
