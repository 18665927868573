import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { fetchEquipment } from 'app/store/actions/enrichment-thunks';
import { useTranslation } from 'react-i18next';

export const useLoadEquipmentEnrichment = () => {
  const { fetchStatus, data: equipmentEnrichment } = useAppSelector((state) => state.enrichment.equipment);
  const equipmentList = useAppSelector((state) => state.network.data?.equipmentList);
  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED && equipmentList) {
      const list = equipmentList.map((equipment) => equipment.specification);
      dispatch(fetchEquipment({ languageKey: i18n.language, equipmentList: list as string[] }));
    }
  }, [fetchStatus, equipmentList]);

  return { fetchStatus, equipmentEnrichment };
};
