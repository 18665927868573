import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { FetchStatus } from 'app/store/root-types';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

export const setFetchStatus = createAction<FetchStatus>('streamingService/fetchStatus');

interface Props {
  portalId: string;
  siteId: number;
}

export const fetchStreamingService = createAsyncThunk<
  MinesiderBackend.TvServiceContent,
  Props,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('streamingService/fetchStreamingService', async ({ portalId, siteId }: Props, thunkAPI) =>
  minesiderBackendService.getStreamingService(siteId, portalId).catch((error) => thunkAPI.rejectWithValue(error)),
);

export const getStreamingServices = createAsyncThunk<
  MinesiderBackend.TvServiceContent[],
  number,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('streamingService/getStreamingServices', async (siteId, thunkAPI) =>
  minesiderBackendService.getStreamingServices(siteId).catch((error) => thunkAPI.rejectWithValue(error)),
);
