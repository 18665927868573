import { CustomerLocationContext } from './types/user-context-types';

export interface AuthState {
  accessToken?: string;
  idToken?: string;
  sessionTicket?: string;
  sessionExpirationTimestamp: number;
  failureReason?: string;
  isCsr?: boolean;
  fetchStatus: FetchStatus;
}

export enum DeviceType {
  HOME_GATEWAY = 'HOME_GATEWAY',
  WIFI = 'WIFI',
  ACCESS_POINT = 'ACCESS_POINT',
  FIXED_WIRELESS_ACCESS_MODEM = 'FIXED_WIRELESS_ACCESS_MODEM',
  FIXED_WIRELESS_ACCESS_SIMCARD = 'FWA_SIM_CARD',
  SET_TOP_BOX = 'STB',
  WIFI_ROUTER = 'WIFI_ROUTER',
}

export interface UserContextState {
  user?: MinesiderBackend.User;
  selectedCustomerLocation?: CustomerLocationContext;
  customerLocations: CustomerLocationContext[];
  changingLocation: boolean;
  isFetchingData: boolean;
  contactDetailsConfirmed: {
    data?: MinesiderBackend.ContactDetailsConfirmation;
    needToVerify: boolean;
    fetchStatus: FetchStatus;
  };
}

export interface OperationalMessageDetails {
  key: string;
  webSummary: string;
  webDescription: string;
  webTemplate: string;
  partners: string[];
  affectedProducts: string[];
  dateOfOccurence: string;
}

export interface OperationalMessageState {
  isFetching: boolean;
  messages?: OperationalMessageDetails[];
}

export interface CoreState {
  error?: GlobalError;
  isTranslatorMode?: boolean;
  dismissedVerificationPrompt: boolean;
}

export interface GlobalError {
  type: ErrorTypes;
  message: string | FriendlyErrorMessage;
  error?: Error;
}

export enum FriendlyErrorMessage {
  BROWSER_COOKIES_DISABLED = 'BROWSER_COOKIES_DISABLED',
  NO_LOCATIONS_FOUND_FOR_CUSTOMER = 'NO_LOCATIONS_FOUND_FOR_CUSTOMER',
  NO_ACCOUNTS_FOUND_FOR_CUSTOMER = 'NO_ACCOUNTS_FOUND_FOR_CUSTOMER',
  UNKNOWN_CUSTOMER_STATUS = 'UNKNOWN_CUSTOMER_STATUS',
  UKNOWN_ERROR = 'UNKNOWN_ERROR',
  UNABLE_TO_COMBINE_NETWORK = 'UNABLE_TO_COMBINE_NETWORK',
}

export enum ErrorTypes {
  AUTHENTICATION_SESSION_ERROR = 'AUTHENTICATION_SESSION_ERROR',
  NON_RETRYABLE_SYSTEM_ERROR = 'NON_RETRYABLE_SYSTEM_ERROR',
}

export interface DeliveryStatusState {
  deliveryStatus?: DeliveryStatus;
  fetchDone: boolean;
}

export interface DeliveryStatus {
  step: {
    combinedStep: string;
    mainStep: number;
    subStep: number;
  };
  excavationPlannedStartDate?: string;
  excavationPlannedFinishDate?: string;
  installationPlannedStartDate?: string;
  installationPlannedFinishDate?: string;
  connectionPlannedStartDate?: string;
  connectionPlannedFinishDate?: string;
  activationDate?: string;
  customerDiggingDone: boolean;
}

export interface EnrichmentState {
  equipment: {
    fetchStatus: FetchStatus;
    data?: MinesiderBackend.EquipmentEnrichment[];
  };
}

export interface PartnerState {
  fetchStatus: FetchStatus;
  data?: MinesiderBackend.CmsPartner;
}
export interface ConsentState {
  consent?: boolean;
  fetchStatus: FetchStatus;
}

export interface ContactDetailsState {
  contactDetails?: MinesiderBackend.CustomerDetails;
  fetchStatus: FetchStatus;
  updateContactDetails: {
    fetchStatus: FetchStatus;
  };
}

export enum FetchStatus {
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export interface PinState {
  fetchStatus: FetchStatus;
  data?: MinesiderBackend.CustomerPin;
  currentState: PinLifecycleState;
  pin: string;
}

interface NetworkError {
  code?: string;
  message: string;
}

export interface NetworkSettingsState {
  fetchStatus: FetchStatus;
  toastMessage?: ToastMessage;
  updateWifiSettings: {
    error?: NetworkError;
    fetchStatus: FetchStatus;
  };
  updateNetworkSettings: {
    error?: NetworkError;
    fetchStatus: FetchStatus;
  };
  data?: MinesiderBackend.CustomerNetwork;
  updateAccessPoints: {
    itemBeingUpdated?: number;
    fetchStatus: FetchStatus;
  };
  fixedWirelessAccess: {
    fetchStatus: FetchStatus;
    data?: MinesiderBackend.FixedWirelessAccessStatus;
  };
}

export interface VoipForward extends MinesiderBackend.ForwardRule {
  appGeneratedId?: string;
}

export interface VoipRejection extends MinesiderBackend.Reject {
  appGeneratedId?: string;
}

export type VoipSettings = {
  [index: string]: {
    fetchStatus: FetchStatus;
    error?: MinesiderBackend.ErrorResponse;
    settings: MinesiderBackend.VoipSettings;
  };
};

export type VoipBarring = {
  [index: string]: {
    fetchStatus: FetchStatus;
    error?: MinesiderBackend.ErrorResponse;
    settings: MinesiderBackend.VoipSelfcareSettings;
  };
};

export interface VoipSettingsState {
  locations: {
    fetchStatus: FetchStatus;
    error?: MinesiderBackend.ErrorResponse;
    data?: MinesiderBackend.VoipLocation[];
  };
  updateSettings: {
    deleteRule?: string;
    selectedPhoneNumber?: string;
    fetchStatus: FetchStatus;
    error?: MinesiderBackend.ErrorResponse;
  };
  settings: {
    data: VoipSettings;
  };
  barring: {
    data: VoipBarring;
  };
}

export enum PinLifecycleState {
  INITIAL = 'INITIAL',
  EDITING = 'EDITING',
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  ERROR = 'ERROR',
}

export interface SubscriptionState {
  getSubscriptionsFetchStatus: FetchStatus;
  updateSubscriptionsStatus: FetchStatus;
  subscriptions: {
    [key: string]: boolean;
    subNewsletters: boolean;
    subMagazine: boolean;
    subNewsletterAltiboxMoviesSeries: boolean;
    operationalMessages: boolean;
  };
}

export enum RelocationMovingDates {
  SAME = 'same dates',
  DIFFERENT = 'different dates',
}

export enum RelocationStepId {
  LOCATION = 0,
  DATES = 1,
  SUMMARY = 2,
  RECEIPT = 3,
}

export enum RelocationStatus {
  FILLING_OUT = 'fillingOut',
  SUBMITTING = 'submitting',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export interface RelocationState {
  searchResults: MinesiderBackend.Address[];
  fromAddress: CustomerLocationContext | null;
  toAddress: MatrikkelAddress | null;
  siteId: number | null;
  movingDateOption: RelocationMovingDates;
  movingOutDate: string | null;
  movingInDate: string | null;
  currentStep: RelocationStepId;
  status: RelocationStatus;
}
export interface MatrikkelAddress {
  address?: string;
  cadastreId?: number;
}

export interface StreamingState {
  fetchStatus: FetchStatus;
  streamingServicesFetchStatus: FetchStatus;
  streamingServices?: MinesiderBackend.TvServiceContent[];
  streamingService?: MinesiderBackend.TvServiceContent;
}
export interface ToastMessageState {
  messagesList: InternalToastMessage[];
}
export interface InternalToastMessage {
  id: string;
  payload: ToastMessage;
  expire: number;
}

export interface ToastMessage {
  heading: string;
  alertType: 'info' | 'success' | 'warning' | 'critical';
  role: 'alert' | 'status';
}
export interface EmailState {
  fetchStatus: FetchStatus;
  createNew: {
    fetchStatus: FetchStatus;
  };
  changePassword: {
    fetchStatus: FetchStatus;
  };
  changeSenderName: {
    fetchStatus: FetchStatus;
  };
  deleteAccount: {
    fetchStatus: FetchStatus;
  };
  activateAccount: {
    fetchStatus: FetchStatus;
  };
  emailAccounts?: MinesiderBackend.EmailAccount[];
  domain?: string;
}

export interface DiscountState {
  fetchStatus: FetchStatus;
  discountStatus?: MinesiderBackend.DiscountStatus;
}
export interface ProductState {
  fetchStatus: FetchStatus;
  products?: MinesiderBackend.Product[];
}

export interface FirewallState {
  createFirewallRule: {
    fetchStatus: FetchStatus;
  };
  updateFirewallRule: {
    fetchStatus: FetchStatus;
  };
  deleteFirewallRule: {
    fetchStatus: FetchStatus;
  };
}
export interface UsersState {
  fetchStatus: FetchStatus;
  createUser: {
    fetchStatus: FetchStatus;
  };
  updateUser: {
    fetchStatus: FetchStatus;
  };
  deleteUser: {
    fetchStatus: FetchStatus;
  };
  resendVerification: {
    fetchStatus: FetchStatus;
    resendUserId?: string;
  };
  users?: MinesiderBackend.CrmUser[];
}

export interface MovieRentalHistoryState {
  fetchStatus: FetchStatus;
  rentedMovies?: MinesiderBackend.VodUsage[];
  period?: string;
}

export interface VoipUsageState {
  fetchStatus: FetchStatus;
  data?: MinesiderBackend.VoipUsage[];
}

export interface InvoiceState {
  fetchStatus: FetchStatus;
  paymentInformation?: MinesiderBackend.PaymentInformation;
  postponeState: {
    fetchStatus: FetchStatus;
    selectedInvoice?: string;
    errorCode?: string;
    data?: MinesiderBackend.InvoicePostpone;
  };
  invoicePdfLink: {
    fetchStatus: FetchStatus;
    errorCode?: string;
    url?: string;
  };
  invoices: MinesiderBackend.Invoice[];
  invoiceLines: {
    invoiceId?: string;
    invoiceLines: MinesiderBackend.InvoiceUsage[];
  };
  paymentAgreement: {
    fetchStatus: FetchStatus;
    data?: MinesiderBackend.PaymentAgreement;
    errorCode?: string;
  };
}
