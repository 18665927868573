import { createSlice, Slice } from '@reduxjs/toolkit';

import { logout } from '../actions/auth-thunks';
import { fetchVoipUsage } from 'app/store/actions/voip-usage-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { FetchStatus, VoipUsageState } from 'app/store/root-types';

const initialState: VoipUsageState = {
  fetchStatus: FetchStatus.NOT_STARTED,
};

const voipUsageSlice: Slice<VoipUsageState> = createSlice({
  name: 'voipUsage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(fetchVoipUsage.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(fetchVoipUsage.fulfilled, (state, action) => {
      state.fetchStatus = FetchStatus.FULFILLED;
      state.data = action.payload;
    });
    builder.addCase(fetchVoipUsage.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

const { reducer } = voipUsageSlice;

export { reducer };
