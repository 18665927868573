import { useTranslation } from 'react-i18next';

import styles from './spinner.module.scss';
import classNames from 'classnames';

interface Props {
  marginVertical?: boolean;
  className?: string;
}

export const Spinner = (props: Props) => {
  const { marginVertical, className } = props;
  const { t } = useTranslation();
  const spinnerClasses = classNames(styles.spinner, marginVertical ? styles.marginVertical : '', className);
  return <div role="status" aria-label={t('components.spinner.loadingAriaLabel')} className={spinnerClasses} />;
};
