import { Card } from 'app/components/card';
import { Typography } from 'app/component-library-wave';
import { formatVoipNumber } from 'app/utils/string-util';
import styles from '../voip-common.module.scss';

export const NumberCard = ({ location }: { location: MinesiderBackend.VoipLocation }) => {
  return (
    <Card containerClassName={styles.card}>
      {location.phoneNumbers?.map((phone) => (
        <Typography key={phone} component="h4" variant="formds-lead" bold={true}>
          {formatVoipNumber(phone)}
        </Typography>
      ))}
      <Typography component="p" variant="formds-body">
        {location.address}, {location.postalCode}
      </Typography>
    </Card>
  );
};
