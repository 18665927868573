/* eslint-disable guard-for-in */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';
import { LocationPanel } from 'app/components/location-panel/';
import { CrmSystem, CustomerLocationContext } from 'app/store/types/user-context-types';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { customLanguageDetector } from 'app/utils/language-utils';
import { nestGroupsBy } from 'app/utils/array-utils';

import styles from './location-list.module.scss';
interface Props {
  selectLocation: (customerLocation: CustomerLocationContext) => void;
}

interface PartnerMap {
  [index: string]: CustomerLocationMap;
}
interface CustomerLocationMap {
  [index: number]: CustomerLocationContext[];
}

const locale = customLanguageDetector.lookup();

function getLocationSortingString(location: CustomerLocationContext) {
  return [
    location.partnerName,
    location.customerCrmId,
    location.address?.streetaddress || '',
    location.address?.dwellingUnitNumber || '',
  ].join('-');
}

function groupLocations(locations: CustomerLocationContext[]) {
  return nestGroupsBy(locations, ['partnerLegalName', 'customerCrmId']);
}

const LocationList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { selectLocation } = props;

  const onSelectLocation =
    (customerLocation: CustomerLocationContext): (() => void) =>
    () => {
      selectLocation(customerLocation);
    };

  const customerLocations = useAppSelector((state) => state.userContext.customerLocations);

  const activeOrNewCustomerLocations = customerLocations.filter(
    (customerLocation) =>
      (customerLocation.status === 'ACTIVE' || customerLocation.status === 'NEW') &&
      customerLocation.crmSystem !== CrmSystem.NONE,
  );

  const inactiveCustomerLocations = customerLocations.filter(
    (customerLocation) => customerLocation.status === 'INACTIVE',
  );

  const customerLocationList = activeOrNewCustomerLocations
    .concat(inactiveCustomerLocations)
    .toSorted((locationA, locationB) => {
      const a = getLocationSortingString(locationA);
      const b = getLocationSortingString(locationB);
      return a.localeCompare(b, locale ?? 'nb', { sensitivity: 'base' });
    });

  const mapByPartnerCustomerId = groupLocations(customerLocationList);

  return (
    <div className={styles.container}>
      {Object.entries(mapByPartnerCustomerId).map((partnerMap) => {
        const [partnerName, customers] = partnerMap as [string, PartnerMap];

        return (
          <div key={partnerName} className={styles.partnerContainer}>
            <Typography
              className={styles.partnerTitle}
              variant="headline5"
              component="h2"
              maxBreakpoint={Breakpoint.TABLET}
            >
              {partnerName}
            </Typography>
            {Object.entries(customers).map((customer) => {
              const [customerCrmId, mappedLocations] = customer as [string, CustomerLocationMap];

              return (
                <React.Fragment key={partnerName + customerCrmId}>
                  <div className={styles.customerContainer}>
                    <Typography
                      className={styles.customerTitle}
                      variant="uiText2"
                      component="h3"
                      bold={true}
                      maxBreakpoint={Breakpoint.TABLET}
                    >
                      {`${t('components.locationList.customerNumber')} ${customerCrmId}`}
                    </Typography>
                    {Object.entries(mappedLocations).map((mappedLoc) => {
                      const [i, location] = mappedLoc;
                      return (
                        <React.Fragment
                          key={i + location.partnerLegalName + location.customerCrmId + location.servicePointId}
                        >
                          <ul aria-describedby="locationListHeader" className={styles.locationList}>
                            {
                              <li
                                key={`${location.customerCrmId}-${location.servicePointId}`}
                                className={styles.locationListItem}
                              >
                                <LocationPanel location={location} onClick={onSelectLocation(location)} />
                              </li>
                            }
                          </ul>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export { LocationList };
