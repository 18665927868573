import { Breakpoint, Typography } from 'app/component-library-wave';

import { CrmSystem, CustomerLocationContext, isActiveLocation } from 'app/store/types/user-context-types';

import styles from './location-sitename.module.scss';
import { useAppSelector } from 'app/hooks/redux-thunk';

interface Props {
  location: CustomerLocationContext;
}

export const LocationSiteName = ({ location }: Props) => {
  const customerLocations = useAppSelector((state) => state.userContext.customerLocations);

  const activeCustomerLocations = customerLocations.filter(
    (customerLocation) => customerLocation.status === 'ACTIVE' && customerLocation.crmSystem !== CrmSystem.NONE,
  );

  const showDwellingUnitNumber = !addressIsUnique(activeCustomerLocations, location.address.streetaddress);
  const showSiteName =
    !addressIsUnique(activeCustomerLocations, location.address.streetaddress, location.address.dwellingUnitNumber) &&
    isActiveLocation(location);

  return showDwellingUnitNumber || showSiteName ? (
    <Typography
      component="em"
      variant="uiText2"
      className={styles.dwellingUnitNumberSiteName}
      maxBreakpoint={Breakpoint.TABLET}
    >
      {showDwellingUnitNumber && location.address.dwellingUnitNumber}
      {showDwellingUnitNumber && showSiteName && ' - '}
      {showSiteName && location.siteName}
    </Typography>
  ) : null;
};

const addressIsUnique = (
  allLocations: CustomerLocationContext[],
  streetaddress: string,
  dwellingUnitNumber?: string,
) => {
  const streetaddressIsUnique =
    allLocations.filter((location) => location.address.streetaddress === streetaddress).length < 2;
  if (streetaddressIsUnique) {
    return true;
  }

  if (!dwellingUnitNumber) {
    return false;
  }

  return (
    allLocations.filter(
      (location) =>
        location.address.streetaddress === streetaddress && location.address.dwellingUnitNumber === dwellingUnitNumber,
    ).length < 2
  );
};
