import React, { useEffect, useState } from 'react';

import { useMedia } from 'app/hooks';

import styles from './buttons-container.module.scss';
import classNames from 'classnames';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

enum ScreenSize {
  Sm,
  Md,
  Lg,
}

export const ButtonsContainer: React.FC<Props> = (props) => {
  const { children, className } = props;

  const [bottom, setBottom] = useState<number>();
  const mainMenu = document.getElementById('main-menu');
  const screenSize = useMedia<ScreenSize>(['(min-width: 768px)'], [ScreenSize.Md], ScreenSize.Sm);

  useEffect(() => {
    if (screenSize === ScreenSize.Sm) {
      setBottom((mainMenu && mainMenu.getBoundingClientRect().height) || 0);
    } else {
      setBottom(undefined);
    }
  }, [mainMenu, screenSize, setBottom]);

  return (
    <div className={classNames(styles.container, className)} style={{ bottom }}>
      {children}
    </div>
  );
};
