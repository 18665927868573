import { Typography } from 'app/component-library-wave';
import { FdsButton } from '@lyse-as/formds-react';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { useTranslation } from 'react-i18next';
import styles from './invoice-postpone.module.scss';

export const RejectedPostpone = () => {
  const { errorCode } = useAppSelector((state) => state.invoice.postponeState);
  const { t } = useTranslation();

  const handleBack = () => {
    history.back();
  };

  return (
    <>
      <Typography component="h1" variant="formds-altibox-subtitle-primary" bold={true}>
        {t('pages.invoice.invoiceErrors.name')}
      </Typography>
      <div className={styles.messageContainer}>
        <Typography component="p" variant="formds-body">
          {t(`pages.invoice.invoiceErrors.DYNAMIC_KEYS.${errorCode}`)}
        </Typography>
      </div>
      <div className={styles.buttonContainer}>
        <FdsButton iconLeft="arrow_back" variant="secondary" onClick={handleBack} fullWidth={true}>
          {t('pages.invoice.invoicePostpone.back')}
        </FdsButton>
      </div>
    </>
  );
};
