import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Input, RadioButton, Typography, UiComponentState } from 'app/component-library-wave';

import { ErrorMessages } from 'app/features/form/error-messages';
import { Field, helpText, uiComponentState } from 'app/features/form/form-utils';

import { IconMessage } from 'app/components/icon-message';

import { AddressType, FormModel, validateRequired } from './contact-details-utils';

import styles from './contact-details-form.module.scss';

interface Props {
  values: FormModel;
  handleChange: (
    value: string,
    field: (f: Field<string>) => Partial<FormModel>,
    validator?: (value: string) => string | undefined,
  ) => void;
  validateZipCode: (value?: string) => string | undefined;
  validateHouseNumber: (value?: string) => string | undefined;
  className?: string;
}

export const ContactAddressSection: React.FC<Props> = (props) => {
  const { values: form, handleChange, className, validateZipCode, validateHouseNumber } = props;
  const { t } = useTranslation();

  const typeChanged = useCallback(
    (v: string) => handleChange(v, (f) => ({ contactAddress: { ...form.contactAddress, type: f } })),
    [form],
  );

  const streetChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({ contactAddress: { ...form.contactAddress, street: f } }),
        (v) => validateRequired(v, t('pages.contactDetailsEdit.validation.emptyStreet')),
      ),
    [form],
  );

  const houseNrChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({ contactAddress: { ...form.contactAddress, houseNr: f } }),
        validateHouseNumber,
      ),
    [form],
  );

  const houseLetterChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({ contactAddress: { ...form.contactAddress, houseLetter: f } }),
        (v) => (!v || v.match(/^[A-Za-z]$/) ? undefined : t('pages.contactDetailsEdit.validation.invalidHouseLetter')),
      ),
    [form],
  );

  const zipCodeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({ contactAddress: { ...form.contactAddress, zip: f } }),
        validateZipCode,
      ),
    [form],
  );

  const cityChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({ contactAddress: { ...form.contactAddress, city: f } }),
        (v) => validateRequired(v, t('pages.contactDetailsEdit.validation.emptyCity')),
      ),
    [form],
  );

  const dwellingUnitChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(e.currentTarget.value, (f) => ({
        contactAddress: { ...form.contactAddress, dwellingUnit: f },
      })),
    [form],
  );

  const postboxChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({
          contactAddress: { ...form.contactAddress, postbox: f },
        }),
        (v) => validateRequired(v, t('pages.contactDetailsEdit.validation.emptyPostbox')),
      ),
    [form],
  );

  return (
    <div className={className}>
      <Typography
        component="h2"
        variant="uiText1"
        bold={true}
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.sectionTitle}
      >
        {t('pages.contactDetailsCommon.contactAddress')}
      </Typography>
      <div className={styles.hint}>
        <IconMessage
          className={styles.iconMessage}
          status="info"
          text={t('pages.contactDetailsEdit.contactAddress.info')}
        />
      </div>
      <div className={styles.formRow}>
        <Typography component="div" variant="uiText2" bold={true} maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.contactDetailsCommon.addressType')}
        </Typography>
        <RadioButton
          groupname="address-type"
          options={[
            {
              label: t('pages.contactDetailsEdit.addressTypeRadio.address'),
              value: AddressType.ADDRESS,
            },
            {
              label: t('pages.contactDetailsCommon.postbox'),
              value: AddressType.POSTBOX,
            },
          ]}
          selectedValue={form.contactAddress.type.value}
          getSelectedValue={typeChanged}
          uiComponentState={uiComponentState(form.contactAddress.type)}
          helpText={helpText(form.contactAddress.type)}
        />
      </div>
      {form.contactAddress.type.value === AddressType.ADDRESS && (
        <>
          <div className={styles.formRow}>
            <Input
              id="contactAddressStreet"
              label={t('pages.contactDetailsCommon.street')}
              value={form.contactAddress.street.value}
              onChange={streetChanged}
              uiComponentState={uiComponentState(form.contactAddress.street)}
              helpText={helpText(form.contactAddress.street)}
              maxBreakpoint={Breakpoint.TABLET}
            />
          </div>
          <div className={styles.formRow}>
            <div className={styles.houseRow}>
              <Input
                id="contactAddressHouseNr"
                label={t('pages.contactDetailsCommon.houseNr')}
                value={form.contactAddress.houseNr.value}
                onChange={houseNrChanged}
                uiComponentState={uiComponentState(form.contactAddress.houseNr)}
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.number}
              />
              <div>
                <Typography
                  component="label"
                  variant="uiText2"
                  bold={true}
                  maxBreakpoint={Breakpoint.TABLET}
                  htmlFor="contactAddressHouseLetter"
                  className={
                    uiComponentState(form.contactAddress.houseLetter) === UiComponentState.ERROR
                      ? styles.errorLabel
                      : undefined
                  }
                >
                  {t('pages.contactDetailsCommon.houseLetter')}
                </Typography>
                <Typography
                  component="label"
                  variant="uiText2"
                  italic={true}
                  maxBreakpoint={Breakpoint.TABLET}
                  className={styles.optional}
                  htmlFor="contactAddressHouseLetter"
                >
                  {t('pages.contactDetailsEdit.optional')}
                </Typography>
                <Input
                  id="contactAddressHouseLetter"
                  label=""
                  value={form.contactAddress.houseLetter?.value}
                  onChange={houseLetterChanged}
                  uiComponentState={uiComponentState(form.contactAddress.houseLetter)}
                  maxBreakpoint={Breakpoint.TABLET}
                  className={styles.letter}
                />
              </div>
            </div>
            <ErrorMessages fields={[form.contactAddress.houseNr, form.contactAddress.houseLetter]} />
          </div>
          <div className={styles.formRow}>
            <div className={styles.zipCityRow}>
              <Input
                id="contactAddressZip"
                label={t('pages.contactDetailsCommon.zip')}
                value={form.contactAddress.zip.value}
                onChange={zipCodeChanged}
                className={styles.zip}
                uiComponentState={uiComponentState(form.contactAddress.zip)}
                maxBreakpoint={Breakpoint.TABLET}
              />
              <Input
                id="contactAddressCity"
                label={t('pages.contactDetailsCommon.city')}
                value={form.contactAddress.city.value}
                onChange={cityChanged}
                uiComponentState={uiComponentState(form.contactAddress.city)}
                maxBreakpoint={Breakpoint.TABLET}
                className={styles.city}
              />
            </div>
            <ErrorMessages fields={[form.contactAddress.zip, form.contactAddress.city]} />
          </div>
          <div className={styles.formRow}>
            <Typography
              component="label"
              variant="uiText2"
              bold={true}
              maxBreakpoint={Breakpoint.TABLET}
              htmlFor="dwellingUnit"
            >
              {t('pages.contactDetailsEdit.contactAddress.dwellingUnit')}
            </Typography>
            <Typography
              component="label"
              variant="uiText2"
              italic={true}
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.optional}
              htmlFor="dwellingUnit"
            >
              {t('pages.contactDetailsEdit.optional')}
            </Typography>
            <Input
              id="dwellingUnit"
              label=""
              value={form.contactAddress.dwellingUnit?.value}
              onChange={dwellingUnitChanged}
              maxBreakpoint={Breakpoint.TABLET}
            />
            <Typography component="div" variant="uiText2" maxBreakpoint={Breakpoint.TABLET}>
              {t('pages.contactDetailsEdit.contactAddress.dwellingUnitExample')}
            </Typography>
          </div>
        </>
      )}
      {form.contactAddress.type.value === AddressType.POSTBOX && (
        <div className={styles.formRow}>
          <Input
            id="contactAddressPostbox"
            label={t('pages.contactDetailsEdit.contactAddress.postbox')}
            value={form.contactAddress.postbox?.value}
            onChange={postboxChanged}
            uiComponentState={uiComponentState(form.contactAddress.postbox)}
            helpText={helpText(form.contactAddress.postbox)}
            maxBreakpoint={Breakpoint.TABLET}
          />
        </div>
      )}
    </div>
  );
};
