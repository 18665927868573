import React from 'react';

import { Breakpoint } from '../../types/global';
import { InputProps } from './types';
import { MobileMaxInput } from './mobile-max-input/mobile-max-input';
import { TabletMaxInput } from './tablet-max-input/tablet-max-input';
import { DesktopMaxInput } from './desktop-max-input/desktop-max-input';

export const Input: React.FC<InputProps> = (props) => {
  const { maxBreakpoint, ...rest } = props;
  switch (maxBreakpoint) {
    case Breakpoint.MOBILE: {
      return <MobileMaxInput {...rest} />;
    }
    case Breakpoint.TABLET: {
      return <TabletMaxInput {...rest} />;
    }
    case Breakpoint.DESKTOP: {
      return <DesktopMaxInput {...rest} />;
    }
    default: {
      return <DesktopMaxInput {...rest} />;
    }
  }
};
