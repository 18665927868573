import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Input, RadioButton, Toggle, Typography, UiComponentState } from 'app/component-library-wave';

import { ErrorMessages } from 'app/features/form/error-messages';
import { Field, helpText, uiComponentState } from 'app/features/form/form-utils';

import { AddressType, FormModel, validateRequired } from './contact-details-utils';

import styles from './contact-details-form.module.scss';

interface Props {
  values: FormModel;
  handleChange: (
    value: string,
    field: (f: Field<string>) => Partial<FormModel>,
    validator?: (value: string) => string | undefined,
  ) => void;
  validateZipCode: (value?: string) => string | undefined;
  validateHouseNumber: (value?: string) => string | undefined;
  className?: string;
}

export const BillingAddressSection: React.FC<Props> = (props) => {
  const { values: form, handleChange, validateZipCode, validateHouseNumber, className } = props;
  const { t } = useTranslation();

  const streetChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({
          billingAddress: form.billingAddress
            ? {
                ...form.billingAddress,
                street: f,
              }
            : undefined,
        }),
        (v) => validateRequired(v, t('pages.contactDetailsEdit.validation.emptyStreet')),
      ),
    [form],
  );

  const houseNrChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({
          billingAddress: form.billingAddress
            ? {
                ...form.billingAddress,
                houseNr: f,
              }
            : undefined,
        }),
        validateHouseNumber,
      ),
    [form],
  );

  const houseLetterChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({
          billingAddress: form.billingAddress
            ? {
                ...form.billingAddress,
                houseLetter: f,
              }
            : undefined,
        }),
        (v) => (!v || v.match(/^[A-Za-z]$/) ? undefined : t('pages.contactDetailsEdit.validation.invalidHouseLetter')),
      ),
    [form],
  );

  const zipCodeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({
          billingAddress: form.billingAddress ? { ...form.billingAddress, zip: f } : undefined,
        }),
        validateZipCode,
      ),
    [form],
  );

  const cityChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({
          billingAddress: form.billingAddress ? { ...form.billingAddress, city: f } : undefined,
        }),
        (v) => validateRequired(v, t('pages.contactDetailsEdit.validation.emptyCity')),
      ),
    [form],
  );

  const postboxChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(
        e.currentTarget.value,
        (f) => ({
          billingAddress: form.billingAddress ? { ...form.billingAddress, postbox: f } : undefined,
        }),
        (v) => validateRequired(v, t('pages.contactDetailsEdit.validation.emptyPostbox')),
      ),
    [form],
  );

  return (
    <div className={className}>
      <Typography
        component="h2"
        variant="uiText1"
        bold={true}
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.sectionTitle}
      >
        {t('pages.contactDetailsCommon.billingAddress')}
      </Typography>
      <Toggle
        id="sameAsContactAddressToggle"
        label={t('pages.contactDetailsEdit.billingAddress.sameAsContactAddress')}
        isOn={form.syncBillingAddress.value === 'true'}
        toggle={(e) => handleChange(e.currentTarget.checked ? 'true' : 'false', (f) => ({ syncBillingAddress: f }))}
        textOn={t('pages.contactDetailsEdit.yes')}
        textOff={t('pages.contactDetailsEdit.no')}
      />
      {form.syncBillingAddress.value === 'false' && (
        <div data-testid="billing-address-input">
          <div className={styles.formRow}>
            <Typography component="div" variant="uiText2" bold={true} maxBreakpoint={Breakpoint.TABLET}>
              {t('pages.contactDetailsCommon.addressType')}
            </Typography>
            <RadioButton
              options={[
                {
                  label: t('pages.contactDetailsEdit.addressTypeRadio.address'),
                  value: AddressType.ADDRESS,
                },
                {
                  label: t('pages.contactDetailsCommon.postbox'),
                  value: AddressType.POSTBOX,
                },
              ]}
              selectedValue={form.billingAddress?.type.value}
              getSelectedValue={(v) =>
                handleChange(v, (f) => ({
                  billingAddress: form.billingAddress ? { ...form.billingAddress, type: f } : undefined,
                }))
              }
              groupname="billing-address-type"
              uiComponentState={uiComponentState(form.billingAddress?.type)}
              helpText={helpText(form.billingAddress?.type)}
            />
          </div>
          {form.billingAddress?.type.value === AddressType.ADDRESS && (
            <>
              <div className={styles.formRow}>
                <Input
                  id="billingAddressStreet"
                  label={t('pages.contactDetailsCommon.street')}
                  value={form.billingAddress?.street.value}
                  onChange={streetChanged}
                  uiComponentState={uiComponentState(form.billingAddress?.street)}
                  helpText={helpText(form.billingAddress?.street)}
                  maxBreakpoint={Breakpoint.TABLET}
                />
              </div>
              <div className={styles.formRow}>
                <div className={styles.houseRow}>
                  <Input
                    id="billingAddressHouseNr"
                    label={t('pages.contactDetailsCommon.houseNr')}
                    value={form.billingAddress?.houseNr.value}
                    onChange={houseNrChanged}
                    uiComponentState={uiComponentState(form.billingAddress?.houseNr)}
                    maxBreakpoint={Breakpoint.TABLET}
                    className={styles.number}
                  />
                  <div>
                    <Typography
                      component="label"
                      variant="uiText2"
                      bold={true}
                      maxBreakpoint={Breakpoint.TABLET}
                      htmlFor="billingAddressHouseLetter"
                      className={
                        uiComponentState(form.billingAddress.houseLetter) === UiComponentState.ERROR
                          ? styles.errorLabel
                          : undefined
                      }
                    >
                      {t('pages.contactDetailsCommon.houseLetter')}
                    </Typography>
                    <Typography
                      component="label"
                      variant="uiText2"
                      italic={true}
                      maxBreakpoint={Breakpoint.TABLET}
                      className={styles.optional}
                      htmlFor="billingAddressHouseLetter"
                    >
                      {t('pages.contactDetailsEdit.optional')}
                    </Typography>
                    <Input
                      id="billingAddressHouseLetter"
                      label=""
                      value={form.billingAddress?.houseLetter?.value}
                      onChange={houseLetterChanged}
                      uiComponentState={uiComponentState(form.billingAddress?.houseLetter)}
                      maxBreakpoint={Breakpoint.TABLET}
                      className={styles.letter}
                    />
                  </div>
                </div>
                <ErrorMessages fields={[form.billingAddress.houseNr, form.billingAddress.houseLetter]} />
              </div>
              <div className={styles.formRow}>
                <div className={styles.zipCityRow}>
                  <Input
                    id="billingAddressZip"
                    label={t('pages.contactDetailsCommon.zip')}
                    value={form.billingAddress?.zip.value}
                    onChange={zipCodeChanged}
                    uiComponentState={uiComponentState(form.billingAddress?.zip)}
                    maxBreakpoint={Breakpoint.TABLET}
                    className={styles.zip}
                  />
                  <Input
                    id="billingAddressCity"
                    label={t('pages.contactDetailsCommon.city')}
                    value={form.billingAddress?.city.value}
                    onChange={cityChanged}
                    uiComponentState={uiComponentState(form.billingAddress?.city)}
                    maxBreakpoint={Breakpoint.TABLET}
                    className={styles.city}
                  />
                </div>
                <ErrorMessages fields={[form.billingAddress.zip, form.billingAddress.city]} />
              </div>
            </>
          )}
          {form.billingAddress?.type.value === AddressType.POSTBOX && (
            <div className={styles.formRow}>
              <Input
                id="billingAddressPostbox"
                label={t('pages.contactDetailsCommon.postbox')}
                value={form.billingAddress.postbox?.value}
                onChange={postboxChanged}
                uiComponentState={uiComponentState(form.billingAddress.postbox)}
                helpText={helpText(form.billingAddress.postbox)}
                maxBreakpoint={Breakpoint.TABLET}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
