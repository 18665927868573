import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './alert-heading.module.scss';

interface AlertHeadingProps extends HTMLAttributes<HTMLDivElement | HTMLButtonElement> {
  isExpandable: boolean;
  onClick?: () => void;
  ariaExpanded?: boolean;
  alertId: string;
}

export const AlertHeading = (props: AlertHeadingProps) => {
  const { isExpandable, onClick, ariaExpanded, alertId, className, ...rest } = props;

  if (!isExpandable) {
    return (
      <div className={classNames(styles.heading, className)} {...rest}>
        {props.children}
      </div>
    );
  }

  return (
    <button
      onClick={onClick}
      type="button"
      aria-expanded={ariaExpanded}
      aria-controls={alertId}
      className={classNames(styles.heading, styles.clickable, className)}
      {...rest}
    >
      {props.children}
    </button>
  );
};
