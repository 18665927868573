import { Alert, Breakpoint, Button, Typography } from 'app/component-library-wave';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fetchStreamingService, setFetchStatus } from 'app/store/actions/streaming-service-actions';
import { Spinner } from 'app/components/spinner';
import { ContainerFixed } from 'app/components/container';
import { FetchStatus } from 'app/store/root-types';
import { iso8601ToDayFullMonthYearHourMinute } from 'app/utils/date-utils';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import { StreamingServiceNavigationList } from './components/streamingServiceNavigationList';
import { checkCombinedServices } from '../streaming-utils';

import styles from './streaming-service.module.scss';

export const StreamingService: ActiveCustomerLocationPage = (props) => {
  const dispatch = useAppDispatch();
  const { fetchStatus, streamingService } = useAppSelector((state) => state.streaming);

  const { t } = useTranslation();

  const { portalId } = useParams();

  const portalIdUpperCase = (portalId as string).toUpperCase();

  useEffect(() => {
    if (
      fetchStatus === FetchStatus.NOT_STARTED ||
      (streamingService &&
        portalId &&
        streamingService.portalId?.toUpperCase() !== portalIdUpperCase &&
        fetchStatus !== FetchStatus.PENDING)
    ) {
      dispatch(fetchStreamingService({ portalId: portalIdUpperCase, siteId: props.userContext.siteId }));
    }
  }, [dispatch, fetchStatus, portalId, streamingService, props.userContext.siteId]);

  const redirectToStreamingUrl = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: 'REGISTRATION' | 'RECOVERY',
  ) => {
    e.preventDefault();
    dispatch(setFetchStatus(FetchStatus.PENDING));

    minesiderBackendService
      .getStreamingAuthLink(props.userContext.siteId, streamingService!.portalId!, action)
      .then((result) => {
        if (result.url) {
          window.location.assign(result.url);
        } else {
          dispatch(setFetchStatus(FetchStatus.REJECTED));
        }
      })
      .catch(() => {
        dispatch(setFetchStatus(FetchStatus.REJECTED));
      });
  };

  if (fetchStatus === FetchStatus.PENDING) {
    return <Spinner />;
  }

  const renderLockPeriodValue = (tvServiceContent: MinesiderBackend.TvServiceContent) => {
    if (tvServiceContent.portalId !== 'NETFLIX') {
      return t('pages.streamingService.noLockPeriod');
    } else if (tvServiceContent.lockedTo) {
      return t('pages.streamingService.lockPeriodExpiryDate', {
        lockedTo: iso8601ToDayFullMonthYearHourMinute(tvServiceContent.lockedTo),
      });
    } else {
      return t('pages.streamingService.lockPeriodExpired');
    }
  };

  const alternativesValue: string[] = Object.values(
    t(`pages.streamingService.DYNAMIC_KEYS.${checkCombinedServices(portalIdUpperCase)}.activationAlternatives`, {
      returnObjects: true,
      name: streamingService?.displayName,
    }),
  );

  const isRegistrationRequired = !streamingService?.registered && portalId !== 'SUMOBASIS';

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.streamingService}>
        {fetchStatus === FetchStatus.REJECTED && (
          <Alert
            heading={t('pages.streamingService.apiErrorHeading')}
            headingElement="h2"
            alertType="warning"
            isInitiallyExpanded={true}
            isExpandable={false}
            isDismissable={streamingService !== null}
          >
            {streamingService
              ? t('pages.streamingService.apiErrorBody')
              : t('pages.streamingService.apiErrorBodyNoProduct')}
          </Alert>
        )}

        {isRegistrationRequired && (
          <div className={styles.activationAlertContainer}>
            <Alert
              alertType="info"
              heading={`${t('pages.streamingService.activateHeader', { name: streamingService?.displayName })}`}
              headingElement="h2"
              isInitiallyExpanded={true}
              isExpandable={false}
            >
              <Typography component="p" variant="paragraph3" className={styles.activationAlertText}>
                {t(
                  `pages.streamingService.DYNAMIC_KEYS.${checkCombinedServices(portalIdUpperCase)}.activateInformation`,
                  {
                    name: streamingService?.displayName,
                  },
                )}
              </Typography>
              {streamingService?.actions?.includes('REGISTRATION') ? (
                <Button className={styles.activateButton} onClick={(e) => redirectToStreamingUrl(e, 'REGISTRATION')}>
                  {t('pages.streamingService.activateButton', { name: streamingService?.displayName })}
                </Button>
              ) : (
                <>
                  <Typography
                    variant={'uiText2'}
                    component={'strong'}
                    maxBreakpoint={Breakpoint.TABLET}
                    bold={true}
                    className={styles.activationUnavailable}
                  >
                    {t('pages.streamingService.activationUnavailableOutsideHomeNetwork', {
                      name: streamingService?.displayName,
                    })}
                  </Typography>
                  <Typography variant={'uiText2'} component={'p'} maxBreakpoint={Breakpoint.TABLET} bold={true}>
                    {t(
                      `pages.streamingService.DYNAMIC_KEYS.${checkCombinedServices(
                        portalIdUpperCase,
                      )}.toActivateHeader`,
                      {
                        name: streamingService?.displayName,
                      },
                    )}
                  </Typography>

                  <ul>
                    {alternativesValue.map((alternative, index) => (
                      <Typography
                        key={index}
                        variant={'paragraph2'}
                        component={'li'}
                        maxBreakpoint={Breakpoint.TABLET}
                        bold={true}
                      >
                        {alternative}
                      </Typography>
                    ))}
                  </ul>
                </>
              )}
            </Alert>
          </div>
        )}

        {streamingService && (
          <>
            <div className={styles.headingContainer}>
              <div className={styles.logoContainer}>
                <img src={streamingService.image} alt="" className={styles.logo} />
              </div>
              <Typography component="h1" variant="headline5" className={styles.streamingServiceH1}>
                {streamingService.displayName}
              </Typography>
            </div>

            <ul className={styles.productInformation}>
              {streamingService.added && (
                <li>
                  <Typography variant="uiText3" component="span" role="term">
                    {t('pages.streamingService.addedDate')}
                  </Typography>
                  <Typography variant="uiText3" component="span" bold={true} role="definition">
                    {iso8601ToDayFullMonthYearHourMinute(streamingService.added)}
                  </Typography>
                </li>
              )}
              <li>
                <Typography variant="uiText3" component="span" role="term">
                  {t('pages.streamingService.lockPeriod')}
                </Typography>
                <Typography variant="uiText3" component="span" bold={true} role="definition">
                  {renderLockPeriodValue(streamingService)}
                </Typography>
              </li>
            </ul>
            <StreamingServiceNavigationList
              product={streamingService}
              onRecoveryClick={(e) => redirectToStreamingUrl(e, 'RECOVERY')}
            />
          </>
        )}
      </div>
    </ContainerFixed>
  );
};
