import React, { useEffect } from 'react';

import { MainContext } from 'app/store/types/user-context-types';
import { config } from 'app/config/config';
import { createCi360LoginIdHash } from './ci360-utils';

declare global {
  interface Window {
    ci360: (action: string, options: Record<string, unknown>) => void;
  }
}

interface Props {
  context: MainContext;
  ci360?: (action: string, object: any) => void;
}
export const Ci360IdentityTag: React.FC<Props> = (props) => {
  useEffect(() => {
    if (props.ci360) {
      const hash = createCi360LoginIdHash(config.ci360.tenantShortName, config.ci360.tenantId, props.context.crmId);

      props.ci360('attachIdentity', {
        loginId: hash,
        loginEventType: 'customer_id',
      });
    }
  }, [props.context.crmId, props.ci360]);

  return null;
};
