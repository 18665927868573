import React, { PropsWithChildren, ReactElement, useEffect } from 'react';

import { AuthService } from 'app/service/auth/auth-service';
import { Spinner } from 'app/components/spinner';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { clearAuthCookies } from 'app/utils/cookies';
import { clearPersistedState } from '../../store/store-utils';
import { raiseGlobalError } from 'app/store/actions/core-actions';
import { ErrorTypes, FriendlyErrorMessage } from 'app/store/root-types';

interface Props {}

const CheckAuth: React.FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const { sessionExpirationTimestamp } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { children } = props;

  const sessionExpired = !sessionExpirationTimestamp || sessionExpirationTimestamp < Date.now();

  useEffect(() => {
    if (sessionExpired) {
      try {
        clearAuthCookies();
        clearPersistedState();
        AuthService.authorize();
      } catch (e: any) {
        if (e.code && e.code === DOMException.SECURITY_ERR) {
          dispatch(
            raiseGlobalError({
              message: FriendlyErrorMessage.BROWSER_COOKIES_DISABLED,
              type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
            }),
          );
          return;
        }
        dispatch(
          raiseGlobalError({
            message: FriendlyErrorMessage.UKNOWN_ERROR,
            type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
          }),
        );
      }
    }
  }, [sessionExpired]);

  return sessionExpired ? <Spinner marginVertical={true} /> : (children as ReactElement);
};

export { CheckAuth };
