import React from 'react';
import { Card } from 'app/components/card';
import { Typography } from 'app/component-library-wave';
import { FdsButton } from '@lyse-as/formds-react';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { deleteVoipRule } from 'app/store/actions/voip-settings-actions';
import styles from '../voip-common.module.scss';

export type RuleCardEntries = {
  header: string;
  description: string;
}[];

type RuleCardType = {
  entries: RuleCardEntries;
  ruleAppId?: string;
  phone: string;
  deleteButton?: string | null;
};

export const RuleCard = (ruleCard: RuleCardType) => {
  const { entries, deleteButton, phone, ruleAppId } = ruleCard;
  const dispatch = useAppDispatch();

  const deleteRule = () => {
    dispatch(deleteVoipRule(ruleAppId));
  };

  return (
    <Card containerClassName={styles.card} className={styles.card}>
      <Typography component="p" variant="formds-body" bold={true}>
        {entries.map((entry, i) => {
          return (
            <React.Fragment key={entry.header + phone + i}>
              <Typography component="span" className={styles.cardParameter} variant="formds-body" bold={true}>
                {entry.header}
              </Typography>
              <Typography component="span" className={styles.cardParameter} variant="formds-body">
                {entry.description}
              </Typography>
            </React.Fragment>
          );
        })}
      </Typography>
      {deleteButton && (
        <FdsButton variant="secondary" onClick={deleteRule}>
          {deleteButton}
        </FdsButton>
      )}
    </Card>
  );
};
