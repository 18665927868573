import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography as T } from 'app/component-library-wave';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { getEmailAccountResponse } from 'app/store/actions/email-thunks';
import { CustomerPage } from '../page-wrapper';
import { clearEmailForms } from 'app/store/actions/email-actions';

import { Spinner } from 'app/components/spinner';
import { ContainerFixed } from 'app/components/container';
import { EmailAccountsInactive } from 'app/components/email/email-accounts-inactive/email-accounts-inactive';
import { EmailAccountsActive } from 'app/components/email/email-accounts-active/email-accounts-active';
import { EmailInactiveWarning } from 'app/features/email-inactive-warning/email-inactive-warning';

import styles from './email.module.scss';
import commonEmailCss from './email-common.module.scss';

export const Email: CustomerPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchStatus, emailAccounts } = useAppSelector((state) => state.email);

  useEffect(() => {
    dispatch(getEmailAccountResponse());
    dispatch(clearEmailForms());
  }, []);

  return (
    <>
      <EmailInactiveWarning className={styles.inactiveWarningBanner} />

      <ContainerFixed isNarrow={true}>
        <div className={commonEmailCss.mobileEdge}>
          <T variant="headline4" component="h1" className={styles.heading} maxBreakpoint={Breakpoint.TABLET}>
            {t('pages.email.name')}
          </T>
          <T
            component="p"
            variant="paragraph2"
            className={styles.paragraph}
            maxBreakpoint={Breakpoint.TABLET}
            renderAsHTML={true}
          >
            {t('pages.email.generalEmailInformationAsAPaidSubscription')}
          </T>
          <T
            component="p"
            variant="paragraph2"
            className={styles.paragraph}
            maxBreakpoint={Breakpoint.TABLET}
            renderAsHTML={true}
          >
            {t('pages.email.generalEmailAccountInformation')}
          </T>
          <T
            component="p"
            variant="paragraph2"
            maxBreakpoint={Breakpoint.TABLET}
            renderAsHTML={true}
            className={styles.paragraph}
          >
            {t('pages.email.emailAsAPaidSubscription')}
          </T>
          {fetchStatus === FetchStatus.PENDING && <Spinner />}
          {fetchStatus === FetchStatus.FULFILLED && (
            <>
              <EmailAccountsActive emailAccounts={emailAccounts} />
              <EmailAccountsInactive emailAccounts={emailAccounts} />
            </>
          )}
        </div>
      </ContainerFixed>
    </>
  );
};
