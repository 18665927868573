import { Service } from 'app/service/navigation/pages/page-types';

export enum CustomerType {
  PRIVAT = 'Privat',
  BEDRIFT = 'Bedrift',
}

export enum CrmSystem {
  INFONOVA = 'R6',
  NONE = 'NONE',
}
export type Role = Exclude<MinesiderBackend.CrmUser['role'], undefined>;
export type CustomerStatusType = 'NEW' | 'INACTIVE' | 'UNABLE_TO_RETRIEVE' | 'ACTIVE' | 'UNKNOWN';
export const toCustomerType = (customerTypeString?: string): CustomerType => {
  switch (customerTypeString) {
    case 'Bedrift':
    case 'CUSTOMER_ENTERPRISE': {
      return CustomerType.BEDRIFT;
    }
    case 'Privat':
    case 'CUSTOMER_PRIVATE': {
      return CustomerType.PRIVAT;
    }
    default: {
      return CustomerType.PRIVAT;
    }
  }
};

export interface UserCookie {
  customerName: string;
  username: string;
  availableUsernames: string[];
  crmId: string;
  assetId: string;
  partnerId: number;
  partnerName: string;
  partnerLegalName: string;
  customerNumber: string;
  customerCrmId: string;
  sessionTicket: string;
  type: CustomerType;
  crmSystem: string;
}
export interface Customer {
  firstName: string;
  lastName: string;
  billingAccountId: string;
  crmUserId: string;
  partnerId: number;
}
export interface Location {
  servicePointId?: string;
  siteId?: string;
  city: string;
  country: string;
  postalCode: string;
  streetaddress: string;
  dwellingUnitNumber: string;
  services?: Service[];
}
export interface UserContextCookie {
  username: string;
  sessionTicket: string;
  accessToken?: string;
  role?: Role;
  customer: Customer;
  location: Location;
}

export interface AddressItem {
  city: string;
  country: string;
  postalcode: string;
  streetaddress: string;
  streetaddress2?: string;
  dwellingUnitNumber?: string;
  name?: string;
  id: string;
}

export interface CustomerContext {
  firstName: string;
  lastName: string;
  partnerNumber: string;
  partnerName: string;
  partnerLegalName: string;
  partnerWebsite: string;
  customerNumber: string;
  customerCrmId: string;
  customerType: CustomerType;
  status: CustomerStatusType;
  crmId: string;
  crmSystem: string;
  sessionTicket: string;
  role: Role;
  circuitId?: string;
}

export enum ConnectionType {
  FIBER = 'FIBER',
  FIXED_WIRELESS_ACCESS = 'FWA',
}

export interface ActiveCustomerLocationContext extends NewCustomerLocationContext {
  siteId: number;
  siteName: string;
  services: Service[];
  connectionType: ConnectionType;
  equipmentList?: MinesiderBackend.Equipment[];
}

export interface NewCustomerLocationContext extends CustomerContext {
  address: AddressItem;
  servicePointId: string;
}

export interface InactiveCustomerLocationContext extends CustomerContext {
  address: AddressItem;
  servicePointId?: string;
  formerCustomer: boolean;
}

export const isActiveLocation = (context: MainContext): context is ActiveCustomerLocationContext =>
  (context as ActiveCustomerLocationContext).siteId !== undefined;

export const isNewLocation = (context: MainContext): context is ActiveCustomerLocationContext =>
  (context as NewCustomerLocationContext).address !== undefined &&
  (context as ActiveCustomerLocationContext).siteId === undefined;

export const isInactiveCustomer = (context: MainContext): context is InactiveCustomerLocationContext =>
  context &&
  (context as InactiveCustomerLocationContext).status === 'INACTIVE' &&
  (context as InactiveCustomerLocationContext).formerCustomer;

export type CustomerLocationContext =
  | NewCustomerLocationContext
  | ActiveCustomerLocationContext
  | InactiveCustomerLocationContext;

export type MainContext = CustomerLocationContext | CustomerContext;

export interface SelectCustomerLocation {
  customerLocation: CustomerLocationContext;
}
