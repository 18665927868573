import { createAsyncThunk } from '@reduxjs/toolkit';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

export const fetchEquipment = createAsyncThunk<
  MinesiderBackend.EquipmentEnrichment[],
  {
    equipmentList: string[];
    languageKey: string;
  },
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('enrichment/getEquipment', async ({ languageKey, equipmentList }, thunkApi) =>
  minesiderBackendService
    .getEquipment(languageKey, equipmentList)
    .catch((error) => thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse)),
);
