import { createAsyncThunk } from '@reduxjs/toolkit';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

export const fetchPartner = createAsyncThunk<
  MinesiderBackend.CmsPartner,
  {
    partnerNumber: string;
  },
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('partner/fetchPartner', async ({ partnerNumber }, thunkApi) =>
  minesiderBackendService
    .getPartner(partnerNumber)
    .catch((error) => thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse)),
);
