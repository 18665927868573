import { useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography as T } from 'app/component-library-wave';
import { navigationService } from 'app/service/navigation/navigation-service';
import { Page } from 'app/service/navigation/pages/page-types';
import { useAppNavigation } from 'app/utils/navigation-utils';

import { Mail } from 'app/media';

import styles from './email-accounts-active.module.scss';

interface Props {
  emailAccounts?: MinesiderBackend.EmailAccount[];
}

export const EmailAccountsActive = ({ emailAccounts }: Props) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const noAccounts = !emailAccounts || emailAccounts.length === 0;
  const exactlyOneActiveAccount = emailAccounts?.filter((x) => x.active).length === 1;
  const inactiveAccountsOnly =
    emailAccounts?.filter((x) => x.active).length === 0 && emailAccounts?.filter((x) => !x.active).length > 0;

  let activeAccountInformation = '';
  if (noAccounts) {
    activeAccountInformation = t('pages.email.myEmailAccountCreateNewEmailInformation');
  } else if (exactlyOneActiveAccount) {
    activeAccountInformation = t('pages.email.maxOneEmailAccountMessage');
  } else if (inactiveAccountsOnly) {
    activeAccountInformation = t('pages.email.hasInactiveAccountsOnly');
  }

  const heading =
    emailAccounts && emailAccounts?.filter((account) => account.active).length > 1
      ? t('pages.email.myEmailAccountHeadings')
      : t('pages.email.myEmailAccountHeading');
  const common = (
    <>
      <T variant="headline4" component="h2" maxBreakpoint={Breakpoint.TABLET} className={styles.heading}>
        {heading}
      </T>
      <T
        component="p"
        variant="paragraph2"
        maxBreakpoint={Breakpoint.TABLET}
        renderAsHTML={true}
        className={styles.createNewEmailInformation}
        data-testid="activeAccountInformation"
      >
        {activeAccountInformation}
      </T>
    </>
  );

  const goToPage = (page: Page, account?: MinesiderBackend.EmailAccount) => {
    goToPath(navigationService.getEmailPageUrl(page, account));
  };

  if (noAccounts) {
    return (
      <>
        {common}
        <Button
          className={styles.createNewAccountButton}
          type="button"
          onClick={() => {
            goToPage(navigationService.PAGES.emailCreateNew);
          }}
        >
          {t('pages.email.createNewAccountButton')}
        </Button>
      </>
    );
  }

  return (
    <>
      {common}
      <ul className={styles.emailAccountList}>
        {emailAccounts
          .filter((account) => account.active && account.id)
          .map((account) => (
            <li key={account.id} className={styles.emailAccount}>
              <section className={styles.emailAccountContainer}>
                <div className={styles.emailAddressRow}>
                  <Mail aria-label={t('pages.email.emailAddress')} className={styles.mailIcon} />
                  <T variant="uiText2" component="h3" bold={true} className={styles.emailAddress}>
                    {account.email}
                  </T>
                </div>

                <div className={styles.buttonContainer}>
                  <Button
                    buttonType={ButtonType.SECONDARY}
                    type="button"
                    className={styles.accountCtaButton}
                    onClick={() => goToPage(navigationService.PAGES.emailChangePassword, account)}
                  >
                    {t('pages.email.changePassword')}
                  </Button>
                  <Button
                    buttonType={ButtonType.SECONDARY}
                    type="button"
                    className={styles.accountCtaButton}
                    onClick={() => goToPage(navigationService.PAGES.emailChangeSenderName, account)}
                  >
                    {t('pages.email.changeSenderName')}
                  </Button>
                  <Button
                    buttonType={ButtonType.TERTIARY}
                    type="button"
                    className={styles.accountCtaButton}
                    onClick={() => goToPage(navigationService.PAGES.emailDeleteAccount, account)}
                  >
                    {t('pages.email.deleteEmailAccount')}
                  </Button>
                </div>
              </section>
            </li>
          ))}
      </ul>
    </>
  );
};
