import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMedia } from 'app/hooks';
import { CalloutBox } from 'app/features/delivery-status-timeline/components/callout-box/callout-box';
import { DeliveryStatus } from 'app/store/root-types';

// Icons describing completed/upcoming steps
import step0Completed from './images/step0-completed.svg';
import step1Upcoming from './images/step1-upcoming.svg';
import step2Upcoming from './images/step2-upcoming.svg';
import step3Upcoming from './images/step3-upcoming.svg';
import step4Upcoming from './images/step4-upcoming.svg';
import step5Upcoming from './images/step5-upcoming.svg';

// Icons describing the current step
import step1Current from './images/step1-current.svg';
import step2Current from './images/step2-current.svg';
import step3Current from './images/step3-current.svg';
import step4Current from './images/step4-current.svg';
import step5Current from './images/step5-current.svg';

import styles from './milestone.module.scss';

enum ScreenSize {
  Sm,
  Md,
  Lg,
}

interface Props {
  milestoneId: number;
  toggleCaption: () => void;
  activeCaption: boolean;
  activeCaptionId: number | null;
  deliveryStatus: DeliveryStatus;
}

export const Milestone: React.FC<Props> = ({
  milestoneId,
  toggleCaption,
  activeCaption,
  activeCaptionId,
  deliveryStatus,
}: Props) => {
  const mainStep = deliveryStatus.step.mainStep;
  const screenSize = useMedia<ScreenSize>(['(min-width: 768px)'], [ScreenSize.Md], ScreenSize.Sm);
  const { t } = useTranslation();

  const currentProgress = mainStep === milestoneId;

  return (
    <li className={styles.milestone} aria-current={currentProgress ? 'step' : false}>
      <button
        onClick={toggleCaption}
        type="button"
        id={`step-${milestoneId}`}
        className={classNames(styles.button, currentProgress ? styles.currentStep : '')}
        aria-expanded={activeCaption}
        aria-controls={`step-${milestoneId}-call-out`}
        aria-label={t(`pages.deliveryStatus.timeline.DYNAMIC_KEYS.iconHeadings.step${milestoneId}`)}
      >
        <TimelineIcon milestoneId={milestoneId} mainStep={mainStep} />
        <div className={styles.label}>
          <span className={classNames(styles.name, currentProgress ? styles.currentStep : '')}>
            {t(`pages.deliveryStatus.timeline.DYNAMIC_KEYS.iconHeadings.step${milestoneId}`)}
          </span>
          {currentProgress && (
            <span className={styles.position} aria-label={t('pages.deliveryStatus.timeline.common.currentPosition')}>
              {t('pages.deliveryStatus.timeline.common.currentPosition')}
            </span>
          )}
        </div>
      </button>

      {activeCaption && (
        <svg
          className={classNames(
            styles.arrow,
            currentProgress ? styles.currentStep : '',
            styles[`step${mainStep}ArrowColor`],
          )}
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 0L28 24H0L14 0Z" />
        </svg>
      )}
      {activeCaption && activeCaptionId && screenSize < ScreenSize.Md && (
        <CalloutBox activeId={activeCaptionId} deliveryStatus={deliveryStatus} />
      )}
    </li>
  );
};

interface IconIndexType {
  [index: string]: string;
}

interface TimelineProps {
  milestoneId: number;
  mainStep: number;
}

const TimelineIcon: React.FC<TimelineProps> = ({ milestoneId, mainStep }) => {
  const { t } = useTranslation();
  const upcomingStepIcons: IconIndexType = {
    1: step1Upcoming,
    2: step2Upcoming,
    3: step3Upcoming,
    4: step4Upcoming,
    5: step5Upcoming,
  };
  const currentStepIcons: IconIndexType = {
    1: step1Current,
    2: step2Current,
    3: step3Current,
    4: step4Current,
    5: step5Current,
  };
  const stepIsCompleted = mainStep > milestoneId;
  const stepIsCurrent = mainStep === milestoneId;
  const stepIsComing = mainStep < milestoneId;

  const getIconSrc = () => {
    if (stepIsCompleted) {
      return step0Completed;
    }
    if (stepIsCurrent) {
      return currentStepIcons[milestoneId];
    }
    if (stepIsComing) {
      return upcomingStepIcons[milestoneId];
    }

    return '';
  };

  return (
    <img
      className={classNames(styles.icon, stepIsCurrent ? styles.currentStep : '')}
      src={getIconSrc()}
      alt={stepIsCompleted ? t('pages.deliveryStatus.timeline.altText.iconCompleted') : ''}
    />
  );
};
