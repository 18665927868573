import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { Home, Tv, Wifi, Konto, Telephone } from 'app/media';
import { navigationService } from 'app/service/navigation/navigation-service';
import { CustomerType, isActiveLocation, MainContext } from 'app/store/types/user-context-types';
import { Service } from 'app/service/navigation/pages/page-types';

import styles from './main-menu.module.scss';

interface Props {
  context: MainContext;
}

export const MainMenu: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const services = isActiveLocation(props.context) ? props.context.services : [];

  const isEnterpriseCustomer = () => props.context.customerType === CustomerType.BEDRIFT;

  const homePageLinkClassName = (navDataActive: boolean) => {
    const sanitizedPathname = location.pathname.split('/').join('');
    const sanitizedHomeUrl = navigationService.PAGES.home.url.split('/').join('');
    const isActive = navDataActive && sanitizedPathname === sanitizedHomeUrl;
    return isActive ? styles.active : '';
  };

  return (
    <>
      <nav id="main-menu" className={styles.mainMenu} aria-label={t('features.mainMenu.Hovedmeny')}>
        <ul>
          <li>
            <NavLink
              to={navigationService.PAGES.home.url}
              className={(navData) => homePageLinkClassName(navData.isActive)}
            >
              <Home aria-hidden="true" className={styles.fill} />
              <div>
                <span>{t(navigationService.PAGES.home.i18n)}</span>
              </div>
            </NavLink>
          </li>

          {services.includes(Service.Tv) && !isEnterpriseCustomer() && (
            <li>
              <NavLink
                to={navigationService.PAGES.tv.url}
                className={(navData) => (navData.isActive ? styles.active : '')}
              >
                <Tv aria-hidden="true" className={styles.fill} />
                <div>
                  <span>{t(navigationService.PAGES.tv.i18n)}</span>
                </div>
              </NavLink>
            </li>
          )}
          {services.includes(Service.Internet) && (
            <li>
              <NavLink
                to={navigationService.PAGES.internet.url}
                className={(navData) => (navData.isActive ? styles.active : '')}
              >
                <Wifi aria-hidden="true" className={styles.stroke} />
                <div>
                  <span>{t(navigationService.PAGES.internet.i18n)}</span>
                </div>
              </NavLink>
            </li>
          )}
          {isEnterpriseCustomer() && (
            <li>
              <NavLink
                to={navigationService.PAGES.telephone.url}
                className={(navData) => (navData.isActive ? styles.active : '')}
              >
                <Telephone aria-hidden="true" className={styles.fill} />
                <div>
                  <span>{t(navigationService.PAGES.telephone.i18n)}</span>
                </div>
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              to={navigationService.PAGES.account.url}
              className={(navData) => (navData.isActive ? styles.active : '')}
            >
              <Konto aria-hidden="true" className={styles.fill} />
              <div>
                <span>{t(navigationService.PAGES.account.i18n)}</span>
              </div>
            </NavLink>
          </li>
        </ul>
      </nav>
      <Breadcrumbs />
    </>
  );
};
