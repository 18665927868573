import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography, Alert } from 'app/component-library-wave';

import { DeviceType } from 'app/store/root-types';
import { Card } from 'app/components/card';
import { DeviceList, Device } from './device-list/device-list';

import styles from './devices.module.scss';
import { CustomerType } from 'app/store/types/user-context-types';

const mapCustomerNetworkToDeviceList = (customerNetwork: MinesiderBackend.CustomerNetwork): Device[] => {
  const devices = [];
  if (customerNetwork?.equipmentList) {
    customerNetwork?.equipmentList
      .filter((eq) => eq.category === DeviceType.FIXED_WIRELESS_ACCESS_MODEM)
      .forEach((eq) => {
        devices.push({
          id: eq.macAddress,
          type: DeviceType.FIXED_WIRELESS_ACCESS_MODEM,
          name: '',
          macAddress: eq.macAddress,
          model: eq.model,
        });
      });
  }
  if (customerNetwork.installationType !== 'NO_HOMEGATEWAY') {
    const hgw = customerNetwork.hgw;
    devices.push({
      id: hgw.id,
      type: DeviceType.HOME_GATEWAY,
      name: hgw.name,
      modelType: hgw.type,
      macAddress: hgw.macAddress,
    });
  }
  if (customerNetwork?.accessPoints) {
    customerNetwork?.accessPoints.forEach((ap) => {
      devices.push({
        id: ap.id,
        type: DeviceType.ACCESS_POINT,
        name: ap.name || '',
        macAddress: ap.macAddress,
        modelType: ap.deviceType,
      });
    });
  }

  return devices;
};

interface Props {
  customerId: string;
  customerType: CustomerType;
  servicePointId: string;
  networkData: MinesiderBackend.CustomerNetwork;
}
export const Devices = ({ customerId, customerType, servicePointId, networkData }: Props) => {
  const { t } = useTranslation();
  const deviceList = mapCustomerNetworkToDeviceList(networkData);

  if (deviceList.length === 0) {
    return (
      <Alert
        alertType="warning"
        heading={t('pages.network.advanced.wifi.noEquipment')}
        headingElement="strong"
        isExpandable={false}
        role="alert"
      />
    );
  }

  return (
    <div>
      <Typography
        component="h2"
        variant="headline4"
        bold={true}
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.headingText}
      >
        {t('pages.network.advanced.devices.heading')}
      </Typography>
      <Card className={styles.card}>
        <DeviceList
          items={deviceList}
          servicePointId={servicePointId}
          customerId={customerId}
          customerType={customerType}
          networkData={networkData}
        />
      </Card>
    </div>
  );
};
