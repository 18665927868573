import * as React from 'react';

import { AuthService } from 'app/service/auth/auth-service';
import { Spinner } from 'app/components/spinner';

const AuthCallback = () => {
  React.useEffect(() => {
    AuthService.handleAuthorizationCallback();
  }, []);

  return <Spinner marginVertical={true} />;
};

export { AuthCallback };
