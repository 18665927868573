import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, HeadingLevel, Input, Select, Typography } from 'app/component-library-wave';

import { ErrorMessages } from 'app/features/form/error-messages';
import { Field, uiComponentState } from 'app/features/form/form-utils';

import { Card } from 'app/components/card';
import { Helper } from 'app/components/helper/helper';

import { IpAddressWithSeparator } from '../../network-common';
import { FormModel } from './network-settings-utils';

import styles from './network-settings-form.module.scss';

interface Props {
  network: MinesiderBackend.CustomerNetwork;
  form: FormModel;
  handleChangeString: (
    value: string,
    field: (f: Field<string>) => Partial<FormModel>,
    validator?: (value: string) => string | undefined,
  ) => void;
  validateIp: (value: string) => string | undefined;
  validateDhcpLast: (value: string) => string | undefined;
  ipAddressArray: string[];
}

export const DhcpCard: React.FC<Props> = (props) => {
  const { network, form, handleChangeString, validateIp, validateDhcpLast, ipAddressArray } = props;
  const { t } = useTranslation();
  const leaseTimeOptions = (network.hgw.fieldValues.lease.values?.default ?? []).map((value) => ({
    value,
    label: `${value} ${t('pages.network.advanced.settings.sections.dhcp.leaseSuffix', { count: Number(value) })}`,
  }));

  const dhcpFirstChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeString(e.currentTarget.value, (f) => ({ dhcpFirst: f }), validateIp),
    [validateIp],
  );
  const dhcpLastChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeString(e.currentTarget.value, (f) => ({ dhcpLast: f }), validateDhcpLast),
    [validateDhcpLast],
  );
  const leaseTimeChanged = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => handleChangeString(e.currentTarget.value, (f) => ({ leaseTime: f })),
    [],
  );

  return (
    <Card className={styles.card}>
      <div>
        <div className={styles.helpNotice}>
          <Helper
            text={t('pages.network.advanced.settings.sections.dhcp.name')}
            textVariant="uiText2"
            textComponent="h3"
            heading={t('pages.network.advanced.settings.helpTexts.dhcp.title')}
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="paragraph3" component="p">
              {t('pages.network.advanced.settings.helpTexts.dhcp.content')}
            </Typography>
          </Helper>
        </div>
        <Typography
          variant="uiText2"
          component="label"
          bold={true}
          htmlFor="dhcpFirst"
          className={styles.dhcpFirstLabel}
        >
          {t('pages.network.advanced.settings.sections.dhcp.from')}
        </Typography>
        <div className={styles.ipAddress}>
          <IpAddressWithSeparator ipAddressArray={ipAddressArray} numbersToShow={3} />
          <Input
            id="dhcpFirst"
            hideLabel={true}
            label={t('pages.network.advanced.settings.sections.dhcp.labelFromAria')}
            value={form.dhcpFirst.value}
            onChange={dhcpFirstChanged}
            uiComponentState={uiComponentState(form.dhcpFirst)}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.smallInputField}
            data-testid="dhcp-first-input"
            maxLength={3}
          />
        </div>
        <ErrorMessages fields={[form.dhcpFirst]} />
      </div>
      <div>
        <Typography variant="uiText2" component="label" bold={true} htmlFor="dhcpLast" className={styles.dhcpLastLabel}>
          {t('pages.network.advanced.settings.sections.dhcp.to')}
        </Typography>
        <div className={styles.ipAddress}>
          <IpAddressWithSeparator ipAddressArray={ipAddressArray} numbersToShow={3} />
          <Input
            id="dhcpLast"
            hideLabel={true}
            label={t('pages.network.advanced.settings.sections.dhcp.labelToAria')}
            value={form.dhcpLast.value}
            onChange={dhcpLastChanged}
            uiComponentState={uiComponentState(form.dhcpLast)}
            maxBreakpoint={Breakpoint.TABLET}
            className={styles.smallInputField}
            data-testid="dhcp-last-input"
            maxLength={3}
          />
        </div>
        <ErrorMessages fields={[form.dhcpLast]} />
      </div>
      <div>
        <div className={styles.helperLabel}>
          <Helper
            text={t('pages.network.advanced.settings.sections.dhcp.lease')}
            textVariant="uiText2"
            textComponent="label"
            heading={t('pages.network.advanced.settings.helpTexts.lease.title')}
            alertHeadingElement={HeadingLevel.H4}
          >
            <Typography variant="paragraph3" component="p">
              {t('pages.network.advanced.settings.helpTexts.lease.content')}
            </Typography>
          </Helper>
        </div>
        <Select
          id="leaseTime"
          onChange={leaseTimeChanged}
          value={form.leaseTime.value}
          hideLabel={true}
          label={t('pages.network.advanced.settings.sections.dhcp.leaseAriaLabel')}
          options={leaseTimeOptions}
          className={styles.leaseTimeSelect}
        />
      </div>
    </Card>
  );
};
