import { Arrow as ArrowIcon, HelpCircle } from 'app/media';
import { Alert, HeadingLevel, Typography, UiComponentSize } from 'app/component-library-wave';
import { useState } from 'react';
import classNames from 'classnames';

import styles from './network-menu-item.module.scss';

interface Props {
  menuText: string;
  children?: React.ReactNode[];
  helpTextHeader?: string;
  actionElement?: React.ReactNode[];
  hideArrow?: boolean;
  className?: string;
  onClick?: () => void;
}

export const NetworkMenuItem: React.ElementType = ({
  children,
  menuText,
  helpTextHeader,
  actionElement,
  onClick,
  hideArrow,
  className,
}: Props) => {
  const [showHelpAlert, setShowHelpAlert] = useState(false);
  const showButton = children || helpTextHeader;

  const HelpText = () => {
    if (!helpTextHeader || !showHelpAlert) {
      return null;
    }
    return (
      <Alert
        heading={helpTextHeader}
        headingElement={HeadingLevel.H4}
        alertType="info"
        isInitiallyExpanded={true}
        isExpandable={false}
        isDismissable={true}
        hideIcon={true}
        uiComponentSize={UiComponentSize.SMALL}
        onClose={() => setShowHelpAlert(false)}
        className={classNames(styles.helpText)}
      >
        {children}
      </Alert>
    );
  };

  return (
    <div className={classNames(styles.container, hideArrow ? styles.noArrow : null, className)}>
      <div className={styles.headingContainer}>
        <Typography component="h3" variant="uiText1" bold={true} className={styles.menuText} onClick={onClick}>
          {menuText}
        </Typography>
        {showButton && (
          <button
            type="button"
            aria-hidden={!children}
            aria-label={helpTextHeader}
            onClick={() => setShowHelpAlert((current) => !current)}
            aria-expanded={showHelpAlert}
            className={styles.button}
          >
            {showHelpAlert ? (
              <HelpCircle variant="filled" className={styles.fillBlue} />
            ) : (
              <HelpCircle variant="outlined" className={styles.strokeBlue} />
            )}
          </button>
        )}
      </div>

      {actionElement && (
        <div className={styles.description}>
          <button type="button" className={styles.button} onClick={onClick}>
            {actionElement}
          </button>
        </div>
      )}

      {!hideArrow && (
        <div className={styles.arrow} onClick={onClick}>
          <ArrowIcon direction="right" variant="short" />
        </div>
      )}
      <HelpText />
    </div>
  );
};
