import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { fetchPartner } from '../store/actions/partner-thunks';

export const usePartner = () => {
  const dispatch = useAppDispatch();
  const { fetchStatus, data: partner } = useAppSelector((state) => state.partner);
  const partnerNumber = useAppSelector((state) => state.userContext.selectedCustomerLocation?.partnerNumber);

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED && partnerNumber) {
      dispatch(fetchPartner({ partnerNumber }));
    }
  }, [fetchStatus, partnerNumber]);

  return { partner };
};
