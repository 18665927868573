import React from 'react';
import { Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';

import { ContainerFixed } from 'app/components/container';
import { LogoType, PartnerLogo } from 'app/components/partner-logo/partner-logo';
import styles from './delivery-status-partner-logo.module.scss';
import { usePartner } from 'app/hooks/use-partner';

export const DeliveryStatusPartnerLogo: React.FC = () => {
  const { t } = useTranslation();
  const { partner } = usePartner();
  return (
    <ContainerFixed isNarrow={true} className={styles.info}>
      <Typography variant="uiText3" component="p" className={styles.label}>
        {t('pages.deliveryStatus.partnerLabel')}
      </Typography>
      <div className={styles.branding}>
        <PartnerLogo logoType={LogoType.CO_BOX_PARTNER_H} className={styles.logo} alt={partner?.friendlyName} />
      </div>
    </ContainerFixed>
  );
};
