import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, Typography } from 'app/component-library-wave';

import { ContainerFixed } from 'app/components/container';
import { Modal } from 'app/components/modal/';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { createServicePointCustomerDiggingStatus } from 'app/store/actions/delivery-status-thunks';

import styles from './customer-digging-status.module.scss';

interface Props {
  customerDiggingDone: boolean;
}

export const CustomerDiggingStatus: React.FC<Props> = (props) => {
  const [modalToggle, setModalToggle] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerDiggingStatus = props.customerDiggingDone;

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.customerDiggingStatus}>
        {customerDiggingStatus && (
          <Typography className={styles.confirmationText} component="p" variant="paragraph2">
            {t('pages.deliveryStatus.timeline.step3-2.diggingStatus.confirmationText')}
          </Typography>
        )}
        {customerDiggingStatus ? (
          <Button
            onClick={() => dispatch(createServicePointCustomerDiggingStatus(!customerDiggingStatus))}
            className={styles.regretButton}
            buttonType={ButtonType.PRIMARY_B}
          >
            {t('pages.deliveryStatus.timeline.step3-2.diggingStatus.buttons.regret')}
          </Button>
        ) : (
          <Button onClick={() => setModalToggle(!modalToggle)} className={styles.submitButton}>
            {t('pages.deliveryStatus.timeline.step3-2.diggingStatus.buttons.submit')}
          </Button>
        )}
      </div>
      <>
        {modalToggle && (
          <Modal heading={t('pages.deliveryStatus.timeline.step3-2.diggingStatus.confirmationModal.heading')}>
            <Typography className={styles.modalBody} component="p" variant="paragraph2">
              {t('pages.deliveryStatus.timeline.step3-2.diggingStatus.confirmationModal.body')}
            </Typography>
            <div className={styles.modalButtonGroup}>
              <Button
                onClick={() => setModalToggle(false)}
                className={styles.abortButton}
                buttonType={ButtonType.SECONDARY}
              >
                {t('pages.deliveryStatus.timeline.step3-2.diggingStatus.confirmationModal.buttons.abort')}
              </Button>
              <Button
                onClick={() => {
                  dispatch(createServicePointCustomerDiggingStatus(!customerDiggingStatus));
                  setModalToggle(false);
                }}
                className={styles.confirmButton}
                buttonType={ButtonType.PRIMARY_A}
              >
                {t('pages.deliveryStatus.timeline.step3-2.diggingStatus.confirmationModal.buttons.confirm')}
              </Button>
            </div>
          </Modal>
        )}
      </>
    </ContainerFixed>
  );
};
