import React, { useCallback } from 'react';
import { Breakpoint, Input, Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';

import { Field, helpText, uiComponentState } from 'app/features/form/form-utils';

import { PhoneInput } from 'app/components/phone-input';

import { FormModel, validateRequired, isEmpty, isNumber } from './contact-details-utils';

import styles from './contact-details-form.module.scss';

interface Props {
  values: FormModel;
  handleChange: (
    value: string,
    field: (f: Field<string>) => Partial<FormModel>,
    validator?: (value: string) => string | undefined,
  ) => void;
  validateEmail: (value?: string) => string | undefined;
  validateMobileNumber: (value?: string) => string | undefined;
  className?: string;
}

export const ContactDetailsSection: React.FC<Props> = (props) => {
  const { values: form, handleChange, validateEmail, validateMobileNumber, className } = props;
  const { t } = useTranslation();

  const emailAddressChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(e.currentTarget.value, (f) => ({ emailAddress: f }), validateEmail),
    [],
  );

  const mobileNumberChanged = useCallback((code: string, phone: string) => {
    handleChange(
      code,
      (f) => ({ mobileCountryCode: f }),
      (v) => validateRequired(v, t('pages.contactDetailsEdit.validation.invalidMobileNumber')),
    );
    handleChange(phone, (f) => ({ mobileNumber: f }), validateMobileNumber);
  }, []);

  const homePhoneNumberChanged = useCallback((code: string, phone: string) => {
    handleChange(
      code,
      (f) => ({ phoneCountryCode: f }),
      () =>
        !isEmpty(phone) && isEmpty(code) ? t('pages.contactDetailsEdit.validation.invalidHomePhoneNumber') : undefined,
    );
    handleChange(
      phone,
      (f) => ({ phoneNumber: f }),
      () =>
        !isEmpty(phone) && !isNumber(phone)
          ? t('pages.contactDetailsEdit.validation.invalidHomePhoneNumber')
          : undefined,
    );
  }, []);

  return (
    <div className={className}>
      <Typography
        component="h2"
        variant="uiText1"
        bold={true}
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.sectionTitle}
      >
        {t('pages.contactDetailsCommon.contactDetails')}
      </Typography>
      <div className={styles.formRow}>
        <Input
          id="emailAddress"
          label={t('pages.contactDetailsEdit.emailAddress')}
          value={form.emailAddress.value}
          onChange={emailAddressChanged}
          uiComponentState={uiComponentState(form.emailAddress)}
          helpText={helpText(form.emailAddress)}
          maxBreakpoint={Breakpoint.TABLET}
        />
      </div>
      <div className={styles.phoneNumbersRow}>
        <div className={styles.formRow}>
          <PhoneInput
            id="mobile-phone"
            label={t('pages.contactDetailsEdit.mobileNumber')}
            countryCodeLabel={t('pages.contactDetailsEdit.mobileNumberCountryCode')}
            code={form.mobileCountryCode.value}
            phoneNumber={form.mobileNumber.value}
            handleChange={mobileNumberChanged}
            uiComponentState={uiComponentState(form.mobileNumber) ?? uiComponentState(form.mobileCountryCode)}
            helpText={helpText(form.mobileNumber) ?? helpText(form.mobileCountryCode)}
            maxBreakpoint={Breakpoint.MOBILE}
          />
        </div>
        <div className={styles.formRow}>
          <PhoneInput
            id="home-phone"
            label={t('pages.contactDetailsEdit.homePhoneNumber')}
            countryCodeLabel={t('pages.contactDetailsEdit.homePhoneNumberCountryCode')}
            description={t('pages.contactDetailsEdit.optional')}
            code={form.phoneCountryCode?.value}
            phoneNumber={form.phoneNumber?.value}
            handleChange={homePhoneNumberChanged}
            uiComponentState={uiComponentState(form.phoneCountryCode) ?? uiComponentState(form.phoneNumber)}
            helpText={helpText(form.phoneCountryCode) ?? helpText(form.phoneNumber)}
            maxBreakpoint={Breakpoint.MOBILE}
          />
        </div>
      </div>
    </div>
  );
};
