import { ContainerFixed } from 'app/components/container';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { useEffect, useState } from 'react';
import { FetchStatus } from 'app/store/root-types';
import { useParams } from 'react-router-dom';
import { ConfirmStep } from './confirm-step';
import { resetPostponeInvoice } from 'app/store/actions/invoice-actions';
import { RejectedPostpone } from './rejected';
import { Spinner } from 'app/components/spinner';
import styles from './invoice-postpone.module.scss';
import { Confirmed } from './confirmed';

export const InvoicePostpone = () => {
  const {
    invoices,
    postponeState: { fetchStatus },
  } = useAppSelector((state) => state.invoice);
  const { invoiceId } = useParams();
  const currentInvoice = invoices?.find((invoice) => invoice.id === invoiceId);
  const [_, setInvoice] = useState<MinesiderBackend.Invoice>();
  const { goToPath } = useAppNavigation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentInvoice) {
      setInvoice(currentInvoice);
      dispatch(resetPostponeInvoice());
    } else {
      goToPath(navigationService.PAGES.invoice.url);
    }
  }, [invoices]);

  if (!invoiceId) {
    return null;
  }

  return (
    <ContainerFixed isNarrow={true} className={styles.container}>
      {fetchStatus === FetchStatus.PENDING && <Spinner />}
      {fetchStatus === FetchStatus.NOT_STARTED && <ConfirmStep invoiceId={invoiceId} fetchStatus={fetchStatus} />}
      {fetchStatus === FetchStatus.FULFILLED && <Confirmed />}
      {fetchStatus === FetchStatus.REJECTED && <RejectedPostpone />}
    </ContainerFixed>
  );
};
