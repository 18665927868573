import { useTranslation } from 'react-i18next';

import { ContainerFixed } from 'app/components/container';
import { RelocationStatus, RelocationStepId } from 'app/store/slices/relocations-slice';
import { HiddenH1 } from 'app/components/hidden-h1';
import { useAppSelector } from 'app/hooks/redux-thunk';

import { StepsHeader } from './components/steps-header/steps-header';
import { RelocationStepAddress } from './views/address/relocation-step-address';
import { RelocationDate } from './views/date/relocation-date';
import { RelocationSummary } from './views/summary/relocation-summary';
import { RelocationReceipt } from './views/receipt/relocation-receipt';

import styles from './relocation.module.scss';

export const ReLocation = () => {
  const { t } = useTranslation();
  const currentStep = useAppSelector((state) => state.relocation.currentStep);

  let view;
  switch (currentStep) {
    case RelocationStepId.LOCATION:
      view = <RelocationStepAddress />;
      break;
    case RelocationStepId.DATES:
      view = <RelocationDate />;
      break;
    case RelocationStepId.SUMMARY:
      view = <RelocationSummary />;
      break;
    case RelocationStepId.RECEIPT:
      view = <RelocationReceipt />;
      break;
  }

  return (
    <ContainerFixed isNarrow={true}>
      <HiddenH1>{t('pages.relocationForm.name')}</HiddenH1>
      {RelocationStatus.FILLING_OUT && <StepsHeader />}

      <div className={styles.relocation}>{view ? view : <RelocationStepAddress />}</div>
    </ContainerFixed>
  );
};
