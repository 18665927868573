import { useEffect, useState } from 'react';
import { CustomerPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { fetchVoipUsage } from 'app/store/actions/voip-usage-thunks';

import styles from './voip-usage.module.scss';
import { Spinner } from 'app/components/spinner';
import { ContainerFixed } from 'app/components/container';
import { Alert, Button, ButtonType, Select, Typography as T } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';
import {
  getPreviousXPeriodsFrom,
  iso8601ToFullMonthAndYear,
  iso8601ToShortDate,
  iso8601ToTime,
  secondsToDuration,
} from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';

export const VoipUsage: CustomerPage = () => {
  const getDateSubstring = (date?: string) => (date ? date.substring(0, 7) : '');
  const NUMBER_OF_PERIODS = 5;

  const { fetchStatus, data: voipUsage } = useAppSelector((state) => state.voipUsage);
  const [toggleRetry, setToggleRetry] = useState(false);
  const [filteredPeriod, setFilteredPeriod] = useState(getDateSubstring(new Date().toISOString()));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchVoipUsage(filteredPeriod));
  }, [filteredPeriod, toggleRetry]);

  const periodOptions = () =>
    [t('pages.voipUsage.filterByPeriod.options.all'), ...getPreviousXPeriodsFrom(NUMBER_OF_PERIODS)].map(
      (period) => new Option(iso8601ToFullMonthAndYear(period), period),
    );

  const filteredList = () =>
    voipUsage?.filter(
      (usage) => filteredPeriod === '' || getDateSubstring(usage.details?.startTime) === filteredPeriod,
    ) || [];

  const PeriodSelect = () => (
    <Select
      id="period"
      onChange={(e) =>
        setFilteredPeriod(
          t('pages.voipUsage.filterByPeriod.options.all') === e.currentTarget.value ? '' : e.currentTarget.value,
        )
      }
      value={filteredPeriod}
      label={t('pages.voipUsage.filterByPeriod.name')}
      className={styles.periodFilter}
      options={periodOptions()}
      disabled={fetchStatus === FetchStatus.PENDING}
    />
  );

  const SortedUsageList = () =>
    filteredList().length === 0 ? null : (
      <ul>
        {filteredList()
          .sort((a, b) => Number.parseInt(b.details?.id || '0', 10) - Number(parseInt(a.details?.id || '0', 10)))
          .map((usage) => (
            <UsageRecord record={usage} key={usage.details?.id} />
          ))}
      </ul>
    );

  const UsageRecord = ({ record }: { record: MinesiderBackend.VoipUsage }) => (
    <li className={styles.entry}>
      <UsageDetails record={record} />
    </li>
  );

  const UsageDetails = ({ record }: { record: MinesiderBackend.VoipUsage }) => (
    <div className={styles.details}>
      <T variant="uiText2" bold={true} component="div" className={styles.date}>
        {t('pages.voipUsage.history.headings.time')}
        <div className={styles.description}>
          {record.details?.startTime && iso8601ToShortDate(record.details.startTime)}{' '}
          {record.details?.startTime && iso8601ToTime(record.details.startTime)}
        </div>
      </T>
      <T variant="uiText2" bold={true} component="div" className={styles.section}>
        {t('pages.voipUsage.history.headings.from')}
        <div className={styles.description}>{record.originatingPhoneNumber}</div>
      </T>
      <T variant="uiText2" bold={true} component="div" className={styles.section}>
        {t('pages.voipUsage.history.headings.to')}
        <div className={styles.description}>{record.phoneNumber}</div>
      </T>
      <T variant="uiText2" bold={true} component="div" className={styles.section}>
        {t('pages.voipUsage.history.headings.duration')}
        <div className={styles.description}>{secondsToDuration(record.duration || 0)}</div>
      </T>
      <T variant="uiText2" bold={true} component="div" className={styles.section}>
        {t('pages.voipUsage.history.headings.amount')}
        <div className={styles.description}>{formatCurrency(record.details?.taxIncludedPrice?.value || 0)} kr</div>
      </T>
    </div>
  );

  return (
    <ContainerFixed isNarrow={false} className={styles.container}>
      <T variant="headline5" component="h1">
        {t('pages.voipUsage.name')}
      </T>
      <T variant="uiText2" component="p" className={styles.disclaimer}>
        {t('pages.voipUsage.disclaimer')}
      </T>
      <PeriodSelect />
      <>
        {fetchStatus === FetchStatus.PENDING && <Spinner />}
        {fetchStatus === FetchStatus.REJECTED && (
          <Alert
            alertType="warning"
            heading={t('pages.voipUsage.error')}
            headingElement="strong"
            isDismissable={false}
            isExpandable={false}
            isInitiallyExpanded={true}
            role="alert"
          >
            <Button buttonType={ButtonType.PRIMARY_B} onClick={() => setToggleRetry(!toggleRetry)}>
              {t('pages.voipUsage.retry')}
            </Button>
          </Alert>
        )}
        {fetchStatus === FetchStatus.FULFILLED && (
          <>
            {filteredList().length === 0 && (
              <T variant="uiText1" component="div" className={styles.empty}>
                {t('pages.voipUsage.history.empty')}
              </T>
            )}
            <SortedUsageList />
          </>
        )}
      </>
    </ContainerFixed>
  );
};
