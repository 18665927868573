import { ContainerFixed } from 'app/components/container';
import { Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';
import styles from '../voip-common.module.scss';

export const VoipSettingsTitle = () => {
  const { t } = useTranslation();
  return (
    <Typography component="h1" variant="formds-altibox-subtitle-primary" bold={true} className={styles.title}>
      {t('pages.voip.name')}
    </Typography>
  );
};
