import React, { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  children?: React.ReactNode;
}

export const Collapsible: React.FC<Props> = ({ isOpen, children, ...rest }) => (
  <div {...rest}>{isOpen && children}</div>
);
