import { Field } from 'app/features/form/form-utils';

export interface FormModel {
  individualOwner?: {
    dateOfBirth: {
      year: Field<string>;
      month: Field<string>;
      day: Field<string>;
    };
  };
  isEnterprise?: Field<boolean>;
  emailAddress: Field<string>;
  mobileCountryCode: Field<string>;
  mobileNumber: Field<string>;
  phoneCountryCode?: Field<string>;
  phoneNumber?: Field<string>;
  syncBillingAddress: Field<string>;
  billingAddress?: {
    type: Field<string>;
    city: Field<string>;
    zip: Field<string>;
    street: Field<string>;
    houseNr: Field<string>;
    houseLetter?: Field<string>;
    postbox?: Field<string>;
  };
  contactAddress: {
    type: Field<string>;
    city: Field<string>;
    zip: Field<string>;
    street: Field<string>;
    houseNr: Field<string>;
    houseLetter?: Field<string>;
    dwellingUnit?: Field<string>;
    postbox?: Field<string>;
  };
}

export enum AddressType {
  ADDRESS = 'ADDRESS',
  POSTBOX = 'POSTBOX',
}

export const getString = (value: string | undefined) => value ?? '';
export const isNumber = (value: string | undefined) => !!(value ?? '').match(/^\d+$/);
export const isEmpty = (value: string | undefined) => (value ?? '').trim().length === 0;
export const isZipCodeValid = (value: string | undefined) => !!value?.match(/^\d{4}$/);
export const validateRequired = (value: string | undefined, errorMessage: string) =>
  isEmpty(value) ? errorMessage : undefined;

export const toFormModel: (values: MinesiderBackend.CustomerDetails) => FormModel = (values) => {
  const dateOfBirth = values.individualOwner?.dateOfBirth ? new Date(values.individualOwner.dateOfBirth) : undefined;
  const contactAddress = {
    type: { value: values.contactAddress?.postbox ? AddressType.POSTBOX : AddressType.ADDRESS },
    city: { value: getString(values.contactAddress?.city) },
    zip: { value: getString(values.contactAddress?.zip) },
    street: { value: getString(values.contactAddress?.street) },
    houseNr: { value: getString(values.contactAddress?.houseNr?.toString()) },
    houseLetter: { value: getString(values.contactAddress?.houseLetter) },
    dwellingUnit: { value: getString(values.contactAddress?.dwellingUnit) },
    postbox: { value: getString(values.contactAddress?.postbox) },
  };
  const billingAddress = values.syncBillingAddress
    ? contactAddress
    : {
        type: { value: values.billingAddress?.postbox ? AddressType.POSTBOX : AddressType.ADDRESS },
        city: { value: getString(values.billingAddress?.city) },
        zip: { value: getString(values.billingAddress?.zip) },
        street: { value: getString(values.billingAddress?.street) },
        houseNr: { value: getString(values.billingAddress?.houseNr?.toString()) },
        houseLetter: { value: getString(values.billingAddress?.houseLetter) },
        postbox: { value: getString(values.billingAddress?.postbox) },
      };

  return {
    individualOwner: {
      dateOfBirth: {
        year: { value: getString(dateOfBirth?.getFullYear().toString()) },
        month: { value: dateOfBirth ? (dateOfBirth.getMonth() + 1).toString() : '' },
        day: { value: getString(dateOfBirth?.getDate().toString()) },
      },
    },
    isEnterprise: { value: values.isEnterprise === true },
    emailAddress: { value: getString(values.emailAddress) },
    mobileCountryCode: { value: getString(values.mobileCountryCode) },
    mobileNumber: { value: getString(values.mobileNumber) },
    phoneCountryCode: { value: getString(values.phoneCountryCode) },
    phoneNumber: { value: getString(values.phoneNumber) },
    syncBillingAddress: { value: values.syncBillingAddress ? 'true' : 'false' },
    billingAddress: values.billingAddress ? billingAddress : undefined,
    contactAddress,
  };
};

export const toValueModel: (
  form: FormModel,
  initial: MinesiderBackend.CustomerDetails,
) => MinesiderBackend.CustomerDetails = (form, initial) => {
  const syncBillingAddress = form.syncBillingAddress.value === 'true';
  const billingAddress = form.billingAddress
    ? {
        countryCode: initial.billingAddress?.countryCode,
        city: form.billingAddress.city.value,
        zip: form.billingAddress.zip.value,
        street: form.billingAddress.street.value,
        houseNr: Number(form.billingAddress.houseNr.value),
        houseLetter: form.billingAddress.houseLetter?.value,
        dwellingUnit: initial.billingAddress?.dwellingUnit,
        postbox:
          form.billingAddress.type.value === AddressType.POSTBOX ? form.billingAddress.postbox?.value : undefined,
      }
    : undefined;

  const ownderDetails = () => {
    if (initial.isEnterprise) {
      return;
    }
    return {
      individualOwner: {
        firstName: initial.individualOwner?.firstName,
        lastName: initial.individualOwner?.lastName,
        dateOfBirth: initial.individualOwner?.dateOfBirth,
      },
    };
  };

  return {
    customerId: initial.customerId,
    ...ownderDetails(),
    emailAddress: form.emailAddress.value,
    mobileCountryCode: form.mobileCountryCode.value,
    mobileNumber: form.mobileNumber.value,
    phoneCountryCode: form.phoneCountryCode?.value ? form.phoneCountryCode.value : undefined,
    phoneNumber: form.phoneNumber?.value ? form.phoneNumber.value : undefined,
    billingAddress: syncBillingAddress ? undefined : billingAddress,
    syncBillingAddress,
    contactAddress: {
      countryCode: initial.contactAddress?.countryCode,
      city: form.contactAddress.city.value,
      zip: form.contactAddress.zip.value,
      street: form.contactAddress.street.value,
      houseNr: Number(form.contactAddress.houseNr.value),
      houseLetter: form.contactAddress.houseLetter?.value,
      dwellingUnit: form.contactAddress.dwellingUnit?.value ? form.contactAddress.dwellingUnit.value : undefined,
      postbox: form.contactAddress.type.value === AddressType.POSTBOX ? form.contactAddress.postbox?.value : undefined,
    },
  };
};

export const isFormValid = (form: FormModel) => {
  const validBillingAddress =
    form.billingAddress?.type.value === AddressType.ADDRESS
      ? !(
          form.billingAddress?.type.error ||
          form.billingAddress?.street.error ||
          form.billingAddress?.houseNr.error ||
          form.billingAddress?.houseLetter?.error ||
          form.billingAddress?.zip.error ||
          form.billingAddress?.city.error
        )
      : !form.billingAddress?.postbox?.error;
  const validContactAddress =
    form.contactAddress.type.value === AddressType.ADDRESS
      ? !(
          form.contactAddress.type.error ||
          form.contactAddress.street.error ||
          form.contactAddress.houseNr.error ||
          form.contactAddress.houseLetter?.error ||
          form.contactAddress.zip.error ||
          form.contactAddress.city.error
        )
      : !form.contactAddress.postbox?.error;

  const validContactId = form.isEnterprise
    ? true
    : !(
        form.individualOwner?.dateOfBirth.year.error ||
        form.individualOwner?.dateOfBirth.month.error ||
        form.individualOwner?.dateOfBirth.day.error
      );

  return (
    validContactAddress &&
    validBillingAddress &&
    validContactId &&
    !(
      form.emailAddress.error ||
      form.mobileCountryCode.error ||
      form.mobileNumber.error ||
      form.phoneCountryCode?.error
    )
  );
};
