import { ErrorTypes } from 'app/store/root-types';

export class NonRetryableSystemError extends Error {
  // @ts-ignore
  // eslint-disable-next-line
  constructor(...params) {
    super(...params);

    this.name = ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR;
  }
}
