import { createSlice, Slice } from '@reduxjs/toolkit';

import {
  clearGlobalError,
  setGlobalError,
  enableTranslatorMode,
  dismissVerificationPrompt,
} from 'app/store/actions/core-actions';
import { logout } from '../actions/auth-thunks';
import { CoreState } from 'app/store/root-types';
import { changeCustomerLocation } from '../actions/user-context-actions';

const initialState: CoreState = {
  dismissedVerificationPrompt: false,
};

const coreSlice: Slice<CoreState> = createSlice({
  name: 'core',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setGlobalError, (state, action) => {
      state.error = action.payload;
      return state;
    });
    builder.addCase(clearGlobalError, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(enableTranslatorMode, (state) => {
      state.isTranslatorMode = true;
      return state;
    });
    builder.addCase(dismissVerificationPrompt, (state) => {
      state.dismissedVerificationPrompt = true;
    });
    builder.addCase(changeCustomerLocation, (state) => {
      state.dismissedVerificationPrompt = false;
    });
  },
});

const { reducer } = coreSlice;

export { reducer };
