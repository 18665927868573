import classNames from 'classnames';

import { IconMessage } from 'app/components/icon-message';

import { Field, helpText } from './form-utils';

import styles from './error-messages.module.scss';

interface Props<T> {
  fields: (Field<T> | undefined)[];
  className?: string;
}

export const ErrorMessages = <T,>(props: Props<T>) => {
  const { fields, className } = props;
  const messages = fields.map(helpText).filter((error): error is string => !!error);

  if (messages.length === 0) {
    return null;
  }

  return (
    <div className={classNames(styles.errorMessages, className)}>
      {messages.map((message, index) => (
        <IconMessage key={index} status="error" text={message} />
      ))}
    </div>
  );
};
