import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';

import { navigationService } from 'app/service/navigation/navigation-service';

import { Navigation } from 'app/features/navigation';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { menuTv } from 'app/utils/navigation-utils';

import styles from 'app/pages/menu-page-common.module.scss';

const allLinkSections = [
  {
    pages: menuTv,
  },
];

export const Tv: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const customerLocation = props.userContext;
  const personalizedSections = customerLocation
    ? navigationService.getPersonalizedLinkSections(allLinkSections, customerLocation)
    : [];

  return (
    <>
      <Typography
        component="h1"
        variant="headline5"
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.menuPageHeader}
      >
        {t(navigationService.PAGES.tv.i18n)}
      </Typography>

      <div className={styles.container}>
        {personalizedSections.map((section) => (
          <Navigation section={section} key={`key-tv-${section.title}`} />
        ))}
      </div>
    </>
  );
};
