import { useEffect, useState } from 'react';
import { Alert, Breakpoint, Select, Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { getMovieRentalHistory } from 'app/store/actions/movie-rental-history-thunks';
import { FetchStatus } from 'app/store/root-types';

import { formatCurrency } from 'app/utils/currency-utils';
import { CustomerPage } from 'app/pages/page-wrapper';
import { getPreviousXPeriodsFrom, iso8601ToFullMonthAndYear, iso8601ToShortDate } from 'app/utils/date-utils';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';

import styles from './movie-rental-history.module.scss';

export const MovieRentalHistory: CustomerPage = (pageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const getDateSubstring = (date?: string) => (date ? date.substring(0, 7) : '');
  const fetchStatus = useAppSelector((state) => state.movieRentalHistory.fetchStatus);
  const rentedMovies = useAppSelector((state) => state.movieRentalHistory.rentedMovies);
  const NUMBER_OF_PERIODS = 5;
  const customerId = pageProps.userContext.customerCrmId;
  const showSpinner = fetchStatus === FetchStatus.PENDING;
  const showError = fetchStatus === FetchStatus.REJECTED || (fetchStatus === FetchStatus.FULFILLED && !rentedMovies);

  const periodOptions = () =>
    [t('pages.movieRentalHistory.filterByPeriod.option.all'), ...getPreviousXPeriodsFrom(NUMBER_OF_PERIODS)].map(
      (period) => new Option(iso8601ToFullMonthAndYear(period), period),
    );
  const SHOW_ALL = t('pages.movieRentalHistory.filterByPeriod.option.all');
  const [filteredMonth, setFilteredMonth] = useState(SHOW_ALL);
  useEffect(() => {
    dispatch(getMovieRentalHistory(customerId));
  }, []);
  const filterChangeHandler = (selectedMonth: string) => {
    setFilteredMonth(selectedMonth);
  };

  const filteredList =
    rentedMovies?.filter((item) => {
      if (filteredMonth === SHOW_ALL) {
        return true;
      }
      return filteredMonth === '' || getDateSubstring(item.details?.startTime) === filteredMonth;
    }) || [];

  return (
    <ContainerFixed isNarrow={true} className={styles.movieRentalHistoryPage}>
      <div className={styles.information}>
        <Typography component="h1" variant="headline4" maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.movieRentalHistory.name')}
        </Typography>
        <Typography
          component="p"
          variant="paragraph1"
          maxBreakpoint={Breakpoint.TABLET}
          className={styles.informationText}
        >
          {t('pages.movieRentalHistory.description')}
        </Typography>
        <Select
          id="period"
          onChange={(e) => filterChangeHandler(e.currentTarget.value)}
          value={filteredMonth}
          label={t('pages.movieRentalHistory.filterByPeriod.label')}
          options={periodOptions()}
        />
      </div>
      <div className={styles.historyData}>
        {showSpinner && <Spinner />}
        {showError && (
          <Alert
            alertType="warning"
            heading={t('pages.movieRentalHistory.errorMessages.fetchingFailed')}
            headingElement="strong"
            isExpandable={false}
            role="alert"
          />
        )}
        {!showError && !filteredList.length && (
          <Typography variant="uiText1" component="div" className={styles.empty}>
            {t('pages.movieRentalHistory.historyTable.empty')}
          </Typography>
        )}
        {!showError && !showSpinner && filteredList.length > 0 && (
          <>
            <div className={styles.filterTable}>
              <div className={styles.headerRow}>
                <Typography variant="uiText2" bold={true} component="span">
                  {t('pages.movieRentalHistory.historyTable.headings.date')}
                </Typography>
                <Typography variant="uiText2" bold={true} component="span" className={styles.item}>
                  {t('pages.movieRentalHistory.historyTable.headings.description')}
                </Typography>
                <Typography variant="uiText2" bold={true} component="span">
                  {t('pages.movieRentalHistory.historyTable.headings.amount')}
                </Typography>
              </div>
            </div>
            <ul className={styles.filterTable}>
              {filteredList.map((item, index) => (
                <li className={styles.filterRow} key={index}>
                  <Typography variant="uiText2" component="p">
                    {iso8601ToShortDate(item.details?.startTime!)}
                  </Typography>
                  <Typography variant="uiText2" component="p" className={styles.item}>
                    {item.title}
                  </Typography>
                  <Typography variant="uiText2" component="p">
                    {formatCurrency(item.details?.taxIncludedPrice?.value || 0)}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </ContainerFixed>
  );
};
