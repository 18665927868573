import { Alert, Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';

import styles from './customer-service-message.module.scss';

interface CustomerServiceMessageProps {
  displayCannotCombineWifi: boolean;
}

export const CustomerServiceMessage: React.FC<CustomerServiceMessageProps> = ({ displayCannotCombineWifi }) => {
  const { t } = useTranslation();
  return (
    <Alert
      className={styles.alertBox}
      heading={t('pages.network.customerService.title')}
      headingElement="strong"
      isExpandable={false}
      role="status"
      alertType="info"
      isInitiallyExpanded={true}
    >
      <Typography component="p" variant="paragraph3" className={styles.alertText}>
        {t('pages.network.customerService.passwordServiceMode')}
      </Typography>
      {displayCannotCombineWifi && (
        <Typography component="p" variant="paragraph3" className={styles.alertText}>
          {t('pages.network.customerService.combineServiceMode')}
        </Typography>
      )}
    </Alert>
  );
};
