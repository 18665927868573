import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import {
  NetworkProps,
  fetchNetworkSettings,
  setWifiSettings,
  updateWifiAccessPoint,
} from 'app/store/actions/network-thunks';

import { combineConfig } from '../pages/internet/network-common';
import { useEffect, useState } from 'react';
import { FetchStatus } from 'app/store/root-types';

interface AccessPointName {
  id: number;
  name: string;
  inheritFromCpe: boolean;
}

interface AccessPointWifi {
  wifiIds: string[];
  wifiSettings: MinesiderBackend.UpdateWifiNetwork[];
  enableCombined: boolean;
}

enum SaveTrigger {
  combine = 'combine',
  name = 'name',
}

export const useUpdateAccessPoint = () => {
  const { fetchStatus, itemBeingUpdated } = useAppSelector((state) => state.network.updateAccessPoints);
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const [saveTrigger, setSaveTrigger] = useState<SaveTrigger>();

  const dispatch = useAppDispatch();

  const dataMissing = () => {
    // eslint-disable-next-line no-console
    console.warn('No Customer ID or Service point ID found for this session.');
  };

  const updateAccessPointDevice = ({ id, name, inheritFromCpe }: AccessPointName) => {
    if (!selectedCustomerLocation?.customerCrmId || !selectedCustomerLocation.servicePointId) {
      dataMissing();
      return;
    }
    setSaveTrigger(SaveTrigger.name);
    dispatch(
      updateWifiAccessPoint({
        customerId: selectedCustomerLocation?.customerCrmId,
        servicePointId: selectedCustomerLocation?.servicePointId,
        id,
        name,
        inheritFromCpe: inheritFromCpe ?? false,
      }),
    );
  };

  const toggleCombinedWifi = ({ wifiIds, wifiSettings, enableCombined }: AccessPointWifi) => {
    if (!selectedCustomerLocation?.customerCrmId || !selectedCustomerLocation.servicePointId) {
      dataMissing();
      return;
    }

    const wifiNetwork = combineConfig({
      wifiIds,
      newConfig: wifiSettings,
      enableCombined,
    });
    setSaveTrigger(SaveTrigger.combine);
    dispatch(
      setWifiSettings({
        customerId: selectedCustomerLocation.customerCrmId,
        servicePointId: selectedCustomerLocation.servicePointId,
        wifiNetwork,
      }),
    );
  };

  return { fetchStatus, itemBeingUpdated, saveTrigger, updateAccessPointDevice, toggleCombinedWifi };
};

export const useLoadNetworkSettings = (props: NetworkProps) => {
  const { servicePointId, customerId } = props;
  const { fetchStatus } = useAppSelector((state) => state.network);
  const [errorMessage, setToastMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchNetworkSettings({ servicePointId, customerId }));
    }
    if (fetchStatus === FetchStatus.REJECTED) {
      setIsError(true);
      setToastMessage(t('pages.network.settingsError'));
    } else if (fetchStatus === FetchStatus.FULFILLED) {
      setIsError(false);
      setToastMessage(t('pages.network.settingsSaved'));
    }
  }, [fetchStatus, customerId]);

  return { isError, errorMessage };
};
