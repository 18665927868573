import classNames from 'classnames';
import React, { HTMLAttributes, ReactHTML, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Breakpoint, UiComponentSize } from '../../types/global';
import { Typography } from '../typography';
import { AlertHeading } from './alert-heading/alert-heading';

import styles from './alert.module.scss';
import { AlertCritical, AlertDismiss, AlertExpand, AlertInfo, AlertSuccess, AlertWarning } from './icons';

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  heading: string;
  headingElement: keyof ReactHTML;
  alertType: 'info' | 'success' | 'warning' | 'critical';
  isDismissable?: boolean;
  dismissButtonAriaLabel?: string;
  isExpandable?: boolean;
  isInitiallyExpanded?: boolean;
  uiComponentSize?: UiComponentSize.MEDIUM | UiComponentSize.SMALL;
  hideIcon?: boolean;
  onClose?: () => void;
}

export const Alert = ({
  isInitiallyExpanded,
  isDismissable,
  isExpandable,
  alertType,
  heading,
  headingElement,
  dismissButtonAriaLabel,
  uiComponentSize,
  hideIcon,
  onClose,
  children,
  className,
  ...rest
}: AlertProps) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded || false);
  const [alertId] = useState(uuidv4());
  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) {
    return null;
  }

  const headerClasses = classNames(
    styles.heading,
    isDismissable ? styles.halfPaddingRight : null,
    hideIcon && uiComponentSize !== UiComponentSize.SMALL ? styles.withoutIcon : null,
  );

  return (
    <div className={classNames(styles.alertContainer, styles[alertType], className)} {...rest}>
      <div className={styles.expandDismissContainer}>
        <AlertHeading
          isExpandable={isExpandable !== false}
          onClick={() => setIsExpanded(!isExpanded)}
          ariaExpanded={isExpanded}
          alertId={alertId}
          className={headerClasses}
        >
          <div className={styles.col1}>
            {!hideIcon && getIcon(alertType)}
            <Typography
              component={headingElement}
              variant="uiText1"
              bold={true}
              maxBreakpoint={Breakpoint.MOBILE}
              className={styles.headingText}
            >
              {heading}
            </Typography>
          </div>

          {isExpandable !== false && (
            <div>
              <AlertExpand className={isExpanded ? styles.expanded : styles.collapsed} />
            </div>
          )}
        </AlertHeading>

        {isDismissable && (
          <button
            onClick={() => {
              setIsDismissed(true);
              if (onClose) {
                onClose();
              }
            }}
            className={styles.dismiss}
            aria-label={dismissButtonAriaLabel || 'Fjern meldingen fra nettsiden'}
          >
            <AlertDismiss />
          </button>
        )}
      </div>

      {isExpanded && (
        <div
          className={classNames(
            styles.body,
            uiComponentSize === UiComponentSize.SMALL ? styles.uiComponentSizeSmall : null,
          )}
          id={alertId}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const getIcon = (alertType: 'info' | 'success' | 'warning' | 'critical') => {
  switch (alertType) {
    case 'info':
      return <AlertInfo className={styles.icon} />;
    case 'success':
      return <AlertSuccess className={styles.icon} />;
    case 'warning':
      return <AlertWarning className={styles.icon} />;
    case 'critical':
      return <AlertCritical className={styles.icon} />;
  }
};
