import { FdsAlert } from '@lyse-as/formds-react';
import { ContainerFixed } from 'app/components/container';
import { useTranslation } from 'react-i18next';
import styles from '../voip-common.module.scss';

export const VoipError = () => {
  const { t } = useTranslation();
  return (
    <ContainerFixed isNarrow={true} className={styles.section}>
      <FdsAlert variant="error" role="alert">
        {t('pages.voip.errorLoading')}
      </FdsAlert>
    </ContainerFixed>
  );
};
