import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

export const getProduct = createAsyncThunk<
  MinesiderBackend.Product[],
  number,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('products/getProducts', async (siteId: number, thunkApi) =>
  minesiderBackendService
    .getProducts(siteId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);
