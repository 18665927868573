import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, HeadingLevel, Typography as T } from 'app/component-library-wave';
import { useAppSelector } from 'app/hooks/redux-thunk';

import { DeviceType } from 'app/store/root-types';
import { Collapsible } from 'app/components/collapsible';
import { Arrow, GenericWifiPoint, GenericHgw } from 'app/media';
import { Helper } from 'app/components/helper/helper';
import { FixedWirelessAccess } from '../../fixed-wireless-access/fixed-wireless-access';
import { HgwDevice } from './hgw-device';
import { AccessPointDevices } from './access-point-devices';

import styles from './device-list.module.scss';
import { CustomerType } from 'app/store/types/user-context-types';

export interface Props {
  items: Device[];
  servicePointId: string;
  customerId: string;
  customerType: CustomerType;
  networkData: MinesiderBackend.CustomerNetwork;
}
export interface Device {
  id: number;
  type: DeviceType;
  name: string;
  modelType: string;
  macAddress?: string;
}

export const DeviceList = ({ items, customerType, ...rest }: Props) => {
  const { data: equipmentEnrichment } = useAppSelector((state) => state.enrichment.equipment);

  return (
    <ul data-testid="device-list">
      {items.map((item) => (
        <ListItem
          key={item.id}
          item={item}
          customerType={customerType}
          enrichment={equipmentEnrichment?.find((eq) => eq.provSpecificationId === item.modelType)}
          {...rest}
        />
      ))}
    </ul>
  );
};

const ListItem = (props: {
  item: Device;
  enrichment?: MinesiderBackend.EquipmentEnrichment;
  servicePointId: string;
  customerId: string;
  customerType: CustomerType;
  networkData: MinesiderBackend.CustomerNetwork;
}) => {
  const { t } = useTranslation();

  const [collapsibleIsOpen, setCollapsibleIsOpen] = useState(false);
  const { customerId, servicePointId, networkData, enrichment } = props;
  const { type, modelType, id: itemId } = props.item;

  const deviceListTitle =
    props.customerType === CustomerType.BEDRIFT
      ? t('pages.network.advanced.altiboxWifiOveralt.deviceList.enterpriseEquipment')
      : t('pages.network.advanced.altiboxWifiOveralt.deviceList.homeGateway');

  const DeviceIcon = ({ deviceType }: { deviceType: DeviceType }) => {
    if (enrichment?.iconUrl) {
      return <img src={enrichment?.iconUrl} alt={modelType} />;
    }
    switch (deviceType) {
      case DeviceType.HOME_GATEWAY:
        return <GenericHgw />;
      case DeviceType.ACCESS_POINT:
        return <GenericWifiPoint />;
      default:
        return <GenericHgw />;
    }
  };
  return (
    <li className={styles.deviceLi}>
      {type === DeviceType.FIXED_WIRELESS_ACCESS_MODEM && (
        <FixedWirelessAccess customerId={customerId} servicePointId={servicePointId} networkData={networkData} />
      )}
      {type !== DeviceType.FIXED_WIRELESS_ACCESS_MODEM && (
        <>
          <button
            className={styles.imageNameContainer}
            type="button"
            onClick={() => setCollapsibleIsOpen(!collapsibleIsOpen)}
            aria-expanded={collapsibleIsOpen}
            aria-controls={`${itemId}-collapsible`}
          >
            <div className={styles.columnLeft}>
              <DeviceIcon deviceType={type} />
              {type === DeviceType.HOME_GATEWAY && (
                <T component="h3" variant="uiText1" bold={true} maxBreakpoint={Breakpoint.TABLET}>
                  {deviceListTitle}
                </T>
              )}
              {type === DeviceType.ACCESS_POINT && (
                <T
                  component="h3"
                  variant="uiText1"
                  bold={true}
                  maxBreakpoint={Breakpoint.TABLET}
                  className={styles.deviceName}
                >
                  {props.item.name || t('pages.network.advanced.altiboxWifiOveralt.deviceList.accesspointUnnamed')}
                </T>
              )}
            </div>
            <Arrow direction={collapsibleIsOpen ? 'up' : 'down'} variant="short" />
          </button>
          <Collapsible isOpen={collapsibleIsOpen} id={`${props.item.id}-collapsible`}>
            <div className={styles.collapsibleContent}>
              <div className={styles.macAddressHeading}>
                <Helper
                  text={t('pages.network.advanced.altiboxWifiOveralt.deviceList.macAddress')}
                  textVariant="uiText3"
                  textComponent="h3"
                  heading={t('pages.network.advanced.altiboxWifiOveralt.deviceList.macAddressExplainationHeading')}
                  alertHeadingElement={HeadingLevel.H4}
                >
                  <T variant="paragraph3" component="p">
                    {t('pages.network.advanced.altiboxWifiOveralt.deviceList.macAddressExplaination')}
                  </T>
                </Helper>
              </div>
              <T component="div" variant="uiText3" className={styles.macAddress}>
                {props.item.macAddress}
              </T>
              {type === DeviceType.HOME_GATEWAY && <HgwDevice hgwType={modelType} />}
              {type === DeviceType.ACCESS_POINT && <AccessPointDevices item={props.item} />}
            </div>
          </Collapsible>
        </>
      )}
    </li>
  );
};
