import { Field } from 'app/features/form/form-utils';

export interface FormModel {
  bridge: Field<boolean>;
  lanIpAddressThirdPart: Field<string>;
  lanIpAddressLastPart: Field<string>;
  dhcpFirst: Field<string>;
  dhcpLast: Field<string>;
  leaseTime: Field<string>;
  ipv6: Field<boolean>;
  upnp: Field<boolean>;
  dmz: Field<string>;
}

export const getString = (value: string | undefined) => value ?? '';
export const isNumber = (value: string | undefined) => !!(value ?? '').match(/^\d+$/);
export const isEmpty = (value: string | undefined) => (value ?? '').trim().length === 0;
export const isLastOctetValid = (value: string | undefined) =>
  isNumber(value) && Number(value) >= 1 && Number(value) <= 254;
export const isInternalIpThirdOctetValid = (value: string | undefined) =>
  isNumber(value) && Number(value) >= 0 && Number(value) <= 255;
export const trimLeadingZeros = (value: string) => (isNumber(value) ? Number(value).toString() : value);
export const isDhcpPoolRangeValid = (fromValue: string | undefined, toValue: string | undefined) =>
  Number(toValue) - Number(fromValue) < 30;
export const toFormModel: (values: MinesiderBackend.CustomerNetwork) => FormModel = (values) => {
  const ipAddressSplit = values.networkSettings?.routerSettings?.ipAddress?.split('.');
  const firstLeaseTimeOption = values.hgw.fieldValues.lease.values?.default?.[0] ?? '';

  return {
    bridge: { value: values.hgw.bridge ?? false },
    lanIpAddressThirdPart: { value: getString(ipAddressSplit?.[2]) },
    lanIpAddressLastPart: { value: getString(ipAddressSplit?.[3]) },
    dhcpFirst: { value: getString(values.networkSettings?.routerSettings?.dhcpFirst?.split('.')?.[3]) },
    dhcpLast: { value: getString(values.networkSettings?.routerSettings?.dhcpLast?.split('.')?.[3]) },
    leaseTime: { value: values.networkSettings?.routerSettings?.leaseTime?.toString() ?? firstLeaseTimeOption },
    ipv6: { value: values.networkSettings?.ipv6 ?? false },
    upnp: { value: values.networkSettings?.upnp ?? false },
    dmz: { value: getString(values.networkSettings?.dmz?.split('.')?.[3]) },
  };
};

export const toValueModel: (
  form: FormModel,
  initial: MinesiderBackend.CustomerNetwork,
) => MinesiderBackend.UpdateNetwork = (form, initial) => {
  const ipAddressStart = (initial.networkSettings?.routerSettings?.ipAddress?.split('.').slice(0, 2) ?? []).concat(
    form.lanIpAddressThirdPart.value,
  );
  const dmz = isEmpty(form.dmz.value) ? undefined : ipAddressStart.concat(trimLeadingZeros(form.dmz.value)).join('.');

  return {
    bridge: form.bridge.value,
    ipAddress: ipAddressStart.concat(trimLeadingZeros(form.lanIpAddressLastPart.value)).join('.'),
    dhcpFirst: ipAddressStart.concat(trimLeadingZeros(form.dhcpFirst.value)).join('.'),
    dhcpLast: ipAddressStart.concat(trimLeadingZeros(form.dhcpLast.value)).join('.'),
    leaseTime: Number(form.leaseTime.value),
    ipv6: form.ipv6.value,
    upnp: form.upnp.value,
    dmz: form.bridge.value ? initial.networkSettings?.dmz : dmz,
  };
};

export const isFormValid = (form: FormModel) =>
  !(
    form.bridge.error ||
    form.lanIpAddressThirdPart.error ||
    form.lanIpAddressLastPart.error ||
    form.dhcpFirst.error ||
    form.dhcpLast.error ||
    form.leaseTime.error ||
    form.ipv6.error ||
    form.upnp.error ||
    form.dmz.error
  );
