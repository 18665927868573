import { useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography as T } from 'app/component-library-wave';

import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { iso8601ToDayAndFullMonth } from 'app/utils/date-utils';
import { Page } from 'app/service/navigation/pages/page-types';

import styles from './email-accounts-inactive.module.scss';
import { useEffect, useState } from 'react';

interface Props {
  emailAccounts?: MinesiderBackend.EmailAccount[];
}

export const EmailAccountsInactive = ({ emailAccounts }: Props) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();

  const [toPage, setToPage] = useState<Page>();
  const [selectedAccount, setSelectedAccount] = useState<MinesiderBackend.EmailAccount>();

  useEffect(() => {
    if (toPage) {
      goToPath(navigationService.getEmailPageUrl(toPage, selectedAccount));
    }
  }, [toPage, selectedAccount]);

  const hasInactiveAccount = emailAccounts?.find((account) => !account.active) !== undefined;
  if (!hasInactiveAccount) {
    return null;
  }

  const hasActiveAccount = emailAccounts.find((account) => account.active) !== undefined;

  return (
    <>
      <T variant="headline4" component="h2" maxBreakpoint={Breakpoint.TABLET} className={styles.heading}>
        {t('pages.email.myInactiveAccountsHeading')}
      </T>
      <T variant="paragraph2" component="p" maxBreakpoint={Breakpoint.TABLET} renderAsHTML={true}>
        {t('pages.email.myInactiveAccountsInformation')}
      </T>

      <ul className={styles.emailAccountList}>
        {emailAccounts
          .filter((account) => !account.active && account.id)
          .map((account) => (
            <li key={`inactive-${account.id}`} className={styles.emailAccount}>
              <section className={styles.emailAccountContainer}>
                <T variant="uiText2" component="h3" bold={true} className={styles.emailAddress}>
                  {account.email}
                </T>
                {account.deleteDate && (
                  <T variant="uiText3" component="p" renderAsHTML={true} className={styles.terminationDate}>
                    {t('pages.email.inactiveAccountTerminationPending', {
                      terminationDateTime: iso8601ToDayAndFullMonth(account.deleteDate),
                    })}
                  </T>
                )}

                <div className={styles.buttonContainer}>
                  <Button
                    buttonType={ButtonType.SECONDARY}
                    type="button"
                    className={styles.deleteAccountButton}
                    onClick={() => {
                      setToPage(navigationService.PAGES.emailDeleteAccount);
                      setSelectedAccount(account);
                    }}
                  >
                    {t('pages.email.deleteEmailAccount')}
                  </Button>
                  <Button
                    buttonType={ButtonType.PRIMARY_B}
                    type="button"
                    onClick={() => {
                      setToPage(navigationService.PAGES.emailActivateAccount);
                      setSelectedAccount(account);
                    }}
                    disabled={hasActiveAccount}
                  >
                    {t('pages.email.activateAccount')}
                  </Button>
                </div>
                {hasActiveAccount && (
                  <T
                    variant="paragraph2"
                    component="p"
                    maxBreakpoint={Breakpoint.TABLET}
                    className={styles.emailAccountCanNotBeActivated}
                  >
                    {t('pages.email.emailAccountCanNotBeActivated')}
                  </T>
                )}
              </section>
            </li>
          ))}
      </ul>
    </>
  );
};
