import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';

import { useAppSelector } from 'app/hooks/redux-thunk';
import { DeviceType } from 'app/store/root-types';
import { DeviceImage } from 'app/features/device-image/device-image';
import { equipmentGetter } from 'app/utils/device-utils';
import { CustomerServiceMessage } from '../customer-service-message/customer-service-message';

import styles from './awo-intro.module.scss';

interface Props {
  networkData: MinesiderBackend.CustomerNetwork;
}

export const AwoIntro = ({ networkData }: Props) => {
  const { t } = useTranslation();
  const awoEquipment = equipmentGetter(DeviceType.ACCESS_POINT, networkData);
  const equipmentEnrichment = useAppSelector((state) => state.enrichment.equipment.data);
  const { isCsr } = useAppSelector((state) => state.auth);

  const crmEnrichment = equipmentEnrichment?.find((eq) => eq.provSpecificationId === awoEquipment?.specification);

  return (
    <>
      {isCsr && <CustomerServiceMessage displayCannotCombineWifi={true} />}
      {awoEquipment?.specification && (
        <DeviceImage
          className={styles.desktopTwoColumns}
          imageColumn={styles.imageColumn}
          imageContainer={styles.imageContainer}
          equipmentImages={crmEnrichment?.images}
          imageDefaultTextIfNoCaption={crmEnrichment?.friendlyName}
        >
          <div className={styles.order}>
            <Typography component="h2" variant="headline2" className={styles.pageTitle}>
              {t('pages.network.advanced.altiboxWifiOveralt.awoIntroHeading')}
            </Typography>
          </div>
          <div className={styles.textColumn}>
            <Typography component="p" variant="paragraph1" className={styles.awoText}>
              {t('pages.network.advanced.altiboxWifiOveralt.awoIntroDescription')}
            </Typography>
            <Typography variant="uiText2" component="p" bold={true} className={styles.linkText}>
              <a
                href="https://www.altibox.no/komigang/wifi/overalt-installasjon/wifi-punkt-plassering/"
                className={styles.moreInfoLink}
              >
                {t('pages.network.advanced.altiboxWifiOveralt.awoExternalPageLinkText')}
              </a>
            </Typography>
          </div>
        </DeviceImage>
      )}
    </>
  );
};
