import React from 'react';
import {
  LogoAltiboxBedriftHorizontal,
  LogoAltiboxBedriftVertical,
  LogoMineSiderHorizontal,
  LogoMineSiderVertical,
} from 'app/media';

import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './header.module.scss';
import { CustomerType } from 'app/store/types/user-context-types';

export enum Screen {
  Sm,
  Md,
  Lg,
}

interface Props {
  isDesktop: boolean;
}

export const HeaderLogo: React.FC<Props> = ({ isDesktop }) => {
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  let logo;
  if (selectedCustomerLocation?.customerType === CustomerType.BEDRIFT) {
    logo = isDesktop ? LogoAltiboxBedriftHorizontal : LogoAltiboxBedriftVertical;
  } else {
    logo = isDesktop ? LogoMineSiderHorizontal : LogoMineSiderVertical;
  }

  return React.createElement(logo, {
    className: styles.logo,
    title: 'Altibox Mine sider',
    role: 'img',
  });
};
