import { createSlice, Slice } from '@reduxjs/toolkit';
import { startProvAuth } from '../actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { createFirewallRule, updateFirewallRule, deleteFirewallRule } from '../actions/firewall-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';

import { FirewallState, FetchStatus } from '../root-types';

const initialState: FirewallState = {
  createFirewallRule: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  updateFirewallRule: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  deleteFirewallRule: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
};

const firewallSlice: Slice<FirewallState> = createSlice({
  name: 'firewall',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(createFirewallRule.pending, (state) => {
      state.createFirewallRule = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(createFirewallRule.fulfilled, (state) => {
      state.createFirewallRule = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(createFirewallRule.rejected, (state) => {
      state.createFirewallRule = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(updateFirewallRule.pending, (state) => {
      state.updateFirewallRule = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(updateFirewallRule.rejected, (state) => {
      state.updateFirewallRule = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(updateFirewallRule.fulfilled, (state) => {
      state.updateFirewallRule = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(deleteFirewallRule.pending, (state) => {
      state.deleteFirewallRule = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(deleteFirewallRule.rejected, (state) => {
      state.deleteFirewallRule = {
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(deleteFirewallRule.fulfilled, (state) => {
      state.deleteFirewallRule = {
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
  },
});

const { reducer } = firewallSlice;

export { reducer };
