import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

interface UpdateUserParams {
  id: string;
  user: MinesiderBackend.UpdateCrmUser;
}

interface DeleteUserParams {
  user: MinesiderBackend.CrmUser;
  crmCustomerId: string;
}

export const getUsersResponse = createAsyncThunk('users/getUsersResponse', async (crmCustomerId: string) =>
  minesiderBackendService.getUsers(crmCustomerId),
);

export const createUserResponse = createAsyncThunk(
  'users/createUserResponse',
  async (user: MinesiderBackend.CreateCrmUser, thunkApi) => {
    const crmUser = minesiderBackendService.createUser(user);
    crmUser.then(() => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.userCreate.successMessage'),
          alertType: 'success',
          role: 'status',
        }),
      );
    });

    return crmUser;
  },
);

export const updateUserResponse = createAsyncThunk<MinesiderBackend.CrmUser, UpdateUserParams>(
  'users/updateUserResponse',
  async (params, thunkApi) => {
    const crmUser = minesiderBackendService.updateUser(params.id, params.user);
    crmUser.then(() => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.userEdit.successMessage'),
          alertType: 'success',
          role: 'status',
        }),
      );
    });
    return crmUser;
  },
);

export const deleteUserResponse = createAsyncThunk<MinesiderBackend.CrmUser, DeleteUserParams>(
  'users/deleteUserResponse',
  async (params, thunkApi) => {
    return minesiderBackendService.deleteUser(params.user.id, params.crmCustomerId).then(() => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('pages.userDelete.successMessage', { email: params.user.email }),
          alertType: 'success',
          role: 'status',
        }),
      );
      return params.user;
    });
  },
);

export const resendVerificationEmail = createAsyncThunk<void, string>('users/resendVerificationEmail', async (userId) =>
  minesiderBackendService.resendVerificationEmail(userId),
);
