import axios from 'axios';

export const isCancel = (error: any): boolean => error && (axios.isCancel(error) || error.name === 'AbortError');

export const swallowIfCancel = (error: any): void => {
  if (isCancel(error)) {
    return;
  }
  throw error;
};
