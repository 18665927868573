import { useEffect, useState } from 'react';
import { Alert } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { useTranslation } from 'react-i18next';
import { clearUsersStatuses } from 'app/store/actions/users-actions';

import styles from './resend-verification-messages.module.scss';

export const ResendVerificationMessage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [messageTitle, setMessageTitle] = useState('');

  const { fetchStatus } = useAppSelector((state) => state.users.resendVerification);
  useEffect(() => {
    if (fetchStatus === FetchStatus.FULFILLED) {
      setMessageTitle(t('features.primaryUserEmailVerification.verificationMessage'));
    }
    if (fetchStatus === FetchStatus.REJECTED) {
      setMessageTitle(t('features.primaryUserEmailVerification.errorMessage'));
    }
  }, [fetchStatus]);

  const alertType = messageTitle && fetchStatus === FetchStatus.REJECTED ? 'critical' : 'success';
  const alertRole = messageTitle && fetchStatus === FetchStatus.REJECTED ? 'alert' : 'status';
  const RenderAlert = () => {
    if (!messageTitle) {
      return null;
    }
    return (
      <Alert
        alertType={alertType}
        heading={messageTitle}
        headingElement="strong"
        isExpandable={false}
        role={alertRole}
        isDismissable={true}
        onClose={() => dispatch(clearUsersStatuses())}
      />
    );
  };

  return <div className={styles.alertBox}>{RenderAlert()}</div>;
};
