import { createAsyncThunk } from '@reduxjs/toolkit';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

export const fetchVoipLocations = createAsyncThunk('voipSettings/getLocations', async () =>
  minesiderBackendService.getVoipLocations(),
);
export const fetchVoipSettings = createAsyncThunk('voipSettings/getSettings', async (phoneNumber: string) =>
  minesiderBackendService.getVoipSettings(phoneNumber),
);
export const fetchVoipBarringSettings = createAsyncThunk(
  'voipSettings/getBarringSettings',
  async (phoneNumber: string) => minesiderBackendService.getVoipBarringSettings(phoneNumber),
);

export const updateVoipSettings = createAsyncThunk(
  'voipSettings/update',
  async (settings: MinesiderBackend.VoipSettings, thunkApi) =>
    minesiderBackendService
      .updateVoipSettings(settings)
      .then((settings) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'success',
            heading: i18n.t('features.toast.save.success'),
            role: 'status',
          }),
        );
        return settings;
      })
      .catch((error) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'critical',
            heading: i18n.t('features.toast.save.rejected'),
            role: 'alert',
          }),
        );
        return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
      }),
);

export const updateVoipBarringSettings = createAsyncThunk(
  'voipBarringSettings/update',
  async (settings: MinesiderBackend.VoipSelfcareSettings, thunkApi) =>
    minesiderBackendService
      .updateVoipBarringSettings(settings)
      .then((settings) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'success',
            heading: i18n.t('features.toast.save.success'),
            role: 'status',
          }),
        );
        return settings;
      })
      .catch((error) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'critical',
            heading: i18n.t('features.toast.save.rejected'),
            role: 'alert',
          }),
        );
        return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
      }),
);
