import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import { ButtonType, IconWrapper, Theme, UiComponentSize } from '../../types/global';

import styles from './button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  uiComponentSize?: UiComponentSize;
  buttonType?: ButtonType;
  theme?: Theme;
  iconWrapper?: IconWrapper;
  children?: React.ReactNode;
}

export const Button = (props: ButtonProps) => {
  const { disabled, uiComponentSize, buttonType, theme, iconWrapper, ...rest } = props;

  const cssClasses = getButtonClasses(disabled, uiComponentSize, buttonType, props.className, theme);
  const icon =
    iconWrapper &&
    React.cloneElement(iconWrapper.icon, {
      className: classNames(iconWrapper.icon.props.className, styles.icon),
    });
  return (
    <button {...rest} className={cssClasses} disabled={disabled}>
      {iconWrapper && iconWrapper.placeIconBeforeText && icon}
      {props.children && <span className={styles.text}>{props.children}</span>}
      {iconWrapper && !iconWrapper.placeIconBeforeText && icon}
    </button>
  );
};

const getButtonClasses = (
  disabled?: boolean,
  uiComponentSize?: UiComponentSize,
  buttonType?: ButtonType,
  className?: string,
  theme?: Theme,
) => {
  const componentStateClass = disabled ? styles.disabled : null;

  let componentSizeClass;
  switch (uiComponentSize) {
    case UiComponentSize.LARGE:
      componentSizeClass = styles.large;
      break;
    case UiComponentSize.SMALL:
      componentSizeClass = styles.small;
      break;
  }

  let buttonTypeClass;
  switch (buttonType) {
    case ButtonType.PRIMARY_A:
      buttonTypeClass = styles.primaryA;
      break;
    case ButtonType.PRIMARY_B:
      buttonTypeClass = styles.primaryB;
      break;
    case ButtonType.SECONDARY:
      buttonTypeClass = styles.secondary;
      break;
    case ButtonType.TERTIARY:
      buttonTypeClass = styles.tertiary;
      break;
    default:
      buttonTypeClass = styles.primaryA;
  }

  let themeClass;
  switch (theme) {
    case Theme.DARK:
      themeClass = styles.darkTheme;
  }

  const buttonClasses = classNames(
    styles.button,
    buttonTypeClass,
    componentStateClass,
    componentSizeClass,
    className,
    themeClass,
  );
  return buttonClasses;
};
