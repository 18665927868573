import React from 'react';

import styles from './grid.module.scss';
import classNames from 'classnames';

export enum GridWidth {
  FULL,
  WIDE,
  NARROW,
}

interface GridProps {
  width: GridWidth;
  children?: React.ReactNode;
}
export const Grid: React.FC<GridProps> = ({ width, children }) => {
  let widthClass;

  switch (width) {
    case GridWidth.NARROW: {
      widthClass = styles.narrowWidth;
      break;
    }
    case GridWidth.WIDE: {
      widthClass = styles.wideWidth;
      break;
    }
  }

  return (
    <div className={classNames(styles.outerContainer)}>
      <div className={classNames(styles.container, widthClass)}>{children}</div>
    </div>
  );
};
