import { ContainerFixed } from 'app/components/container';
import { VoipForward, VoipSettings } from 'app/store/root-types';
import { formatVoipNumber } from 'app/utils/string-util';
import { Typography } from 'app/component-library-wave';
import { useTranslation } from 'react-i18next';
import { NoRuleCard } from './no-rule-card';
import { RuleCard } from './rule-card';
import { FdsButton } from '@lyse-as/formds-react';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import styles from '../voip-common.module.scss';

export const ForwardingCard = ({
  location,
  settings,
  isEditable,
  phone,
  hideHeader,
}: {
  location: MinesiderBackend.VoipLocation;
  settings: VoipSettings;
  isEditable?: boolean;
  selectedNumber?: string;
  phone: string;
  hideHeader?: boolean;
}) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();

  const numberSettings = settings[phone];
  const rules = (numberSettings?.settings?.forwarding?.rules as VoipForward[]) || [];
  const ruleCards = rules?.map((rule) => {
    const card = [
      {
        header: t('pages.voip.forwarding.incomingFrom'),
        description: rule.callers
          ? rule.callers.map((caller) => formatVoipNumber(caller)).join(', ')
          : t('pages.voip.forwarding.rules.filter.all'),
      },
      {
        header: t('pages.voip.forwarding.forwardTo', {
          toPhoneNumber:
            rule.trigger === 'caller' && rule.callers
              ? rule.callers.map((caller) => formatVoipNumber(caller)).join(', ')
              : t('pages.voip.forwarding.rules.filter.all'),
        }),
        description: formatVoipNumber(rule.forwardTo),
      },
    ];
    return {
      ruleAppId: rule.appGeneratedId,
      card,
    };
  });

  return (
    <ContainerFixed className={styles.sectionContainer} key={phone + location.address}>
      {!hideHeader && (
        <Typography component="h4" variant="formds-lead" className={styles.header} bold={true}>
          {t('pages.voip.forwarding.forwardFor', { voipNumber: formatVoipNumber(phone) })}
        </Typography>
      )}
      {ruleCards.length === 0 && <NoRuleCard message={t('pages.voip.forwarding.emptyList')} key={location.address} />}
      <ul>
        {ruleCards.map((rule, i) => (
          <li key={phone + i}>
            <RuleCard
              entries={rule.card}
              ruleAppId={rule.ruleAppId}
              phone={phone}
              deleteButton={isEditable ? t('pages.voip.forwarding.cta.delete') : null}
            />
          </li>
        ))}
      </ul>
      {isEditable && (
        <FdsButton
          className={styles.newRuleButton}
          variant="secondary"
          onClick={() => goToPath(navigationService.PAGES.voipSettingsForwardingNewRule.url)}
        >
          {t('pages.voip.forwarding.rules.name')}
        </FdsButton>
      )}
    </ContainerFixed>
  );
};
