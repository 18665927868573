import { HTMLAttributes } from 'react';

import styles from './container.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isNarrow?: boolean;
  mode?: ContainerLayoutMode;
}

export enum ContainerLayoutMode {
  Narrow = 'narrow',
  Wide = 'wide',
  Medium = 'medium',
}

export const ContainerFixed = (props: Props) => {
  let containerStyle;
  if (props.mode) {
    switch (props.mode) {
      case ContainerLayoutMode.Narrow:
        containerStyle = styles.narrow;
        break;
      case ContainerLayoutMode.Medium:
        containerStyle = styles.medium;
        break;
    }
  } else {
    containerStyle = props.isNarrow ? styles.narrow : null;
  }

  const { isNarrow, ...divProps } = props;

  return (
    <div {...divProps} className={`${props.className || ''} ${styles.fixed} ${containerStyle}`}>
      {props.children}
    </div>
  );
};
