import React from 'react';
import { Breakpoint, Typography } from 'app/component-library-wave';

import styles from './list.module.scss';

export interface ItemList {
  heading: string;
  items: string[];
}

export const List: React.FC<{ list: ItemList }> = ({ list }) => (
  <>
    <Typography
      className={styles.paragraph}
      component="p"
      variant="paragraph2"
      renderAsHTML={true}
      maxBreakpoint={Breakpoint.TABLET}
    >
      {list.heading}
    </Typography>
    <ul className={styles.list}>
      {list.items.map((item, index) => (
        <li className={styles.item} key={index}>
          {item}
        </li>
      ))}
    </ul>
  </>
);
