import React from 'react';

import styles from './desktop-max-input.module.scss';
import { InnerInputProps } from '../types';
import { InputBase } from '../input-base';

const DesktopMaxInput: React.FC<InnerInputProps> = (props) => {
  return <InputBase styles={styles} {...props} />;
};

export { DesktopMaxInput };
