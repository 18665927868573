import React from 'react';
import { useTranslation } from 'react-i18next';

import { Faq, FaqItemType } from 'app/components/faq';

import styles from './delivery-faq.module.scss';

interface FaqItems {
  [index: string]: FaqItemType[];
}

interface Props {
  currentStep: string;
}

export const DeliveryFaq: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const faqItems: FaqItems = {
    '1-1': [
      {
        question: t('pages.deliveryStatus.faq.step1-1.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step1-1.q&a1.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step1-1.q&a2.question'),
        answers: [t('pages.deliveryStatus.faq.step1-1.q&a2.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step1-1.q&a3.question'),
        answers: [t('pages.deliveryStatus.faq.step1-1.q&a3.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step1-1.q&a4.question'),
        answers: [t('pages.deliveryStatus.faq.step1-1.q&a4.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step1-1.q&a5.question'),
        answers: [t('pages.deliveryStatus.faq.step1-1.q&a5.answer1')],
      },
    ],
    '2-1': [
      {
        question: t('pages.deliveryStatus.faq.step2-1.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step2-1.q&a1.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-1.q&a2.question'),
        answers: [t('pages.deliveryStatus.faq.step2-1.q&a2.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-1.q&a3.question'),
        answers: [t('pages.deliveryStatus.faq.step2-1.q&a3.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-1.q&a4.question'),
        answers: [t('pages.deliveryStatus.faq.step2-1.q&a4.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-1.q&a5.question'),
        answers: [
          t('pages.deliveryStatus.faq.step2-1.q&a5.answer1'),
          t('pages.deliveryStatus.faq.step2-1.q&a5.answer2'),
        ],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-1.q&a6.question'),
        answers: [t('pages.deliveryStatus.faq.step2-1.q&a6.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-1.q&a7.question'),
        answers: [t('pages.deliveryStatus.faq.step2-1.q&a7.answer1')],
      },
    ],
    '2-2': [
      {
        question: t('pages.deliveryStatus.faq.step2-2.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step2-2.q&a1.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-2.q&a2.question'),
        answers: [t('pages.deliveryStatus.faq.step2-2.q&a2.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-2.q&a3.question'),
        answers: [t('pages.deliveryStatus.faq.step2-2.q&a3.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-2.q&a4.question'),
        answers: [t('pages.deliveryStatus.faq.step2-2.q&a4.answer1')],
      },
    ],
    '2-3': [
      {
        question: t('pages.deliveryStatus.faq.step2-3.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step2-3.q&a1.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-3.q&a2.question'),
        answers: [t('pages.deliveryStatus.faq.step2-3.q&a2.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-3.q&a3.question'),
        answers: [t('pages.deliveryStatus.faq.step2-3.q&a3.answer1')],
        links: [
          {
            label: t('pages.deliveryStatus.faq.step2-3.q&a3.link.label'),
            url: t('pages.deliveryStatus.faq.step2-3.q&a3.link.url'),
          },
        ],
      },
      {
        question: t('pages.deliveryStatus.faq.step2-3.q&a4.question'),
        answers: [t('pages.deliveryStatus.faq.step2-3.q&a4.answer1')],
      },
    ],
    '3-1': [
      {
        question: t('pages.deliveryStatus.faq.step3-1.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step3-1.q&a1.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-1.q&a2.question'),
        answers: [t('pages.deliveryStatus.faq.step3-1.q&a2.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-1.q&a3.question'),
        answers: [
          t('pages.deliveryStatus.faq.step3-1.q&a3.answer1'),
          t('pages.deliveryStatus.faq.step3-1.q&a3.answer2'),
        ],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-1.q&a4.question'),
        answers: [t('pages.deliveryStatus.faq.step3-1.q&a4.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-1.q&a5.question'),
        answers: [t('pages.deliveryStatus.faq.step3-1.q&a5.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-1.q&a6.question'),
        answers: [t('pages.deliveryStatus.faq.step3-1.q&a6.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-1.q&a7.question'),
        answers: [t('pages.deliveryStatus.faq.step3-1.q&a7.answer1')],
      },
    ],
    '3-2': [
      {
        question: t('pages.deliveryStatus.faq.step3-2.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step3-2.q&a1.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-2.q&a2.question'),
        answers: [t('pages.deliveryStatus.faq.step3-2.q&a2.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-2.q&a3.question'),
        answers: [t('pages.deliveryStatus.faq.step3-2.q&a3.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-2.q&a4.question'),
        answers: [
          t('pages.deliveryStatus.faq.step3-2.q&a4.answer1'),
          t('pages.deliveryStatus.faq.step3-2.q&a4.answer2'),
        ],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-2.q&a5.question'),
        answers: [t('pages.deliveryStatus.faq.step3-2.q&a5.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-2.q&a6.question'),
        answers: [t('pages.deliveryStatus.faq.step3-2.q&a6.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step3-2.q&a7.question'),
        answers: [t('pages.deliveryStatus.faq.step3-2.q&a7.answer1')],
      },
    ],
    '4-1': [
      {
        question: t('pages.deliveryStatus.faq.step4-1.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step4-1.q&a1.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step4-1.q&a2.question'),
        answers: [t('pages.deliveryStatus.faq.step4-1.q&a2.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step4-1.q&a3.question'),
        answers: [t('pages.deliveryStatus.faq.step4-1.q&a3.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step4-1.q&a4.question'),
        answers: [t('pages.deliveryStatus.faq.step4-1.q&a4.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step4-1.q&a5.question'),
        answers: [t('pages.deliveryStatus.faq.step4-1.q&a5.answer1')],
      },
      {
        question: t('pages.deliveryStatus.faq.step4-1.q&a6.question'),
        answers: [t('pages.deliveryStatus.faq.step4-1.q&a6.answer1')],
      },
    ],
    '5-1': [
      {
        question: t('pages.deliveryStatus.faq.step5-1.q&a1.question'),
        answers: [t('pages.deliveryStatus.faq.step5-1.q&a1.answer1')],
      },
    ],
  };

  return <Faq className={styles.faq} items={faqItems[props.currentStep]} renderAsHTML={true} />;
};
