export async function sha256(message: string): Promise<string> {
  try {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  } catch (error: any) {
    throw new Error(
      "Failed to use the browser's built-in cypto lib to generate sha256" +
        (error.message ? ': ' + error.message : '.'),
    );
  }
}

export const addDayMonthLeadingZero = (value: string): string =>
  value.length < 2 && parseInt(value, 10) < 10 ? `0${value}` : value;

export const displayDateToIso8601 = (displayDate: string) => {
  // displayDate format is "09.06.2021"
  const dateArray = displayDate.trim().split('.');
  if (dateArray.length < 3) {
    return displayDate;
  }
  return toIso8601(dateArray[2], dateArray[1], dateArray[0]);
};

export const toIso8601 = (year: string, month: string, day: string) =>
  `${year}-${addDayMonthLeadingZero(month)}-${addDayMonthLeadingZero(day)}`;

export const formatVoipNumber = (number?: string) => {
  if (!number) {
    return '';
  }

  const countryCodes = () => {
    const codes = ['47', '45'];
    return codes.flatMap((code) => [`00${code}`, `+${code}`]);
  };

  const codes = countryCodes().join('|').replaceAll('+', '\\+');
  const strippedNumber = number.replaceAll(new RegExp(`^(${codes})`, 'gm'), '');
  let rule = /(\d{3})(\d{0,2})/g;

  if (strippedNumber.length > 7) {
    rule = /(\d{2})/g;
  }
  if (strippedNumber.charAt(0) === '8') {
    rule = /(\d{3})(\d{2})(\d{3})/g;
  }
  if (strippedNumber.length < 6) {
    return strippedNumber;
  }
  return strippedNumber.split(rule).filter(Boolean)?.join(' ');
};

export const sanitizePhoneNumberForApi = (phoneNumber: string) => {
  return phoneNumber.replace('+', '00');
};
