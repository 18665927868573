import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { startProvAuth } from 'app/store/actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { clearContactDetailsForm } from 'app/store/actions/contact-details-actions';
import { fetchContactDetails, saveContactDetails } from 'app/store/actions/contact-details-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { FetchStatus, ContactDetailsState } from 'app/store/root-types';

const initialState: ContactDetailsState = {
  fetchStatus: FetchStatus.NOT_STARTED,
  updateContactDetails: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
};

const contactDetailsSlice: Slice<ContactDetailsState> = createSlice({
  name: 'contactDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(fetchContactDetails.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(fetchContactDetails.fulfilled, (state, action: PayloadAction<MinesiderBackend.CustomerDetails>) => {
      state.contactDetails = action.payload;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(fetchContactDetails.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(saveContactDetails.pending, (state) => {
      state.updateContactDetails.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(saveContactDetails.fulfilled, (state) => {
      state.updateContactDetails.fetchStatus = FetchStatus.FULFILLED;

      state.fetchStatus = FetchStatus.NOT_STARTED;
      state.contactDetails = undefined;
    });
    builder.addCase(saveContactDetails.rejected, (state) => {
      state.updateContactDetails.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(clearContactDetailsForm, (state) => {
      state.updateContactDetails = initialState.updateContactDetails;
    });
  },
});

const { reducer } = contactDetailsSlice;

export { reducer };
