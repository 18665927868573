/* "isLocalPart" means first part of the e-mail address only (local-part@domain) */
export const emailAddressIsValid = (email: string, isLocalPart: boolean = false) => {
  // regex from wpbox/static/webapps/altibox/js/altibox/src/validators/validators.js
  const match = isLocalPart ? email.match(/^([a-zA-Z0-9\._-])+$/) : email.match(/^[a-zA-Z0-9._-]+@\w+.\w+/);
  return match !== null;
};

export const emailAccountPasswordLengthIsOk = (password: string) => password.length >= 8;
export const emailAccountPasswordComplexityIsOk = (password: string) => {
  // Need to have three of these four
  const upperCaseRegex = /[A-Z]+/;
  const lowerCaseRegex = /[a-z]+/;
  const numberRegex = /\d+/;
  const specialCaseRegex = /[#?!@$%^&*-]+/;

  const pwComplexityCheck = {
    lowerCase: lowerCaseRegex.test(password),
    upperCase: upperCaseRegex.test(password),
    digits: numberRegex.test(password),
    special: specialCaseRegex.test(password),
  };
  const validRules = Object.entries(pwComplexityCheck).filter((rule) => rule[1]).length;
  return validRules >= 3;
};

export const emailAccountPasswordIsValid = (password: string) =>
  emailAccountPasswordLengthIsOk(password) && emailAccountPasswordComplexityIsOk(password);
