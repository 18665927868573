const PORTAL_IDS_FOR_MAX = ['HBOMAX', 'WBDMAXSTD', 'WBDMAXPREM'];

export const STREAMING_SERVICE_WHITELIST = [
  'NETFLIX',
  'VIAPLAYFILM',
  'VIAPLAYMED',
  'VIAPLAYTOTAL',
  'SUMOBASIS',
  ...PORTAL_IDS_FOR_MAX,
]; // portalId's

export const checkCombinedServices = (portalId?: string) => {
  if (!portalId) {
    return null;
  }
  if (PORTAL_IDS_FOR_MAX.includes(portalId)) {
    return PORTAL_IDS_FOR_MAX[0];
  }
  return portalId;
};
