import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Typography } from 'app/component-library-wave';
import { Arrow } from 'app/media';

import styles from './navigation-link.module.scss';

interface Props {
  to: string;
  text: string;
  direction: 'left' | 'right';
  className?: string;
}

export const NavigationLink: React.FC<Props> = (props) => {
  const { to, text, direction, className } = props;

  return (
    <Link to={to} className={classNames(styles.link, className)}>
      {direction === 'left' && (
        <Arrow direction={direction} variant="long" role="presentation" className={styles.arrowLeft} />
      )}
      <Typography variant="uiText2" component="span" bold={true} className={styles.text}>
        {text}
      </Typography>
      {direction === 'right' && (
        <Arrow direction={direction} variant="long" role="presentation" className={styles.arrowRight} />
      )}
    </Link>
  );
};
