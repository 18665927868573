import { FdsDropdown, FdsDropdownItem } from '@lyse-as/formds-react';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { formatVoipNumber } from 'app/utils/string-util';
import { useTranslation } from 'react-i18next';

interface NumberDropDown {
  selectNumber: (phone: string) => void;
  selectedPhoneNumber: string;
  className?: string;
}

export const NumberDropDown = ({ selectNumber, selectedPhoneNumber, className }: NumberDropDown) => {
  const {
    locations: { data: locationData },
  } = useAppSelector((state) => state.voipSettings);
  const { t } = useTranslation();

  return (
    <FdsDropdown
      className={className}
      name="phonenumber-chooser"
      placeholder={t('pages.voip.chooseNumber')}
      label={t('pages.voip.chooseNumberLabel')}
      onFdsDropdownChange={(e) => {
        if (e.detail.index === 0) {
          return;
        }
        selectNumber(e.detail.value);
      }}
    >
      {locationData?.map((location) =>
        location.phoneNumbers?.map((phone) => (
          <FdsDropdownItem key={phone} value={phone} selected={location.phoneNumbers?.includes(selectedPhoneNumber)}>
            {formatVoipNumber(phone)}
          </FdsDropdownItem>
        )),
      )}
    </FdsDropdown>
  );
};
