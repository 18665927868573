import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';

import { Panel } from 'app/components/panel';
import {
  InfoCircle,
  Tv,
  EnterpriseTv,
  EnterpriseVoip,
  VoipGrey,
  Wifi,
  Ethernet,
  Broadband,
  FixedWirelessAccess,
  ManagedWifi,
  IpVpn,
  MobileAccess,
  MobileBackup,
  CircuitId,
} from 'app/media';
import {
  CustomerLocationContext,
  CustomerType,
  isActiveLocation,
  isInactiveCustomer,
} from 'app/store/types/user-context-types';
import { LocationSiteName } from 'app/components/location-sitename/location-sitename';
import { LocationIcon } from '../location-icon/location-icon';

import styles from './location-panel.module.scss';

interface Props {
  onClick: () => void;
  location: CustomerLocationContext;
}

interface Service {
  i18n: string;
  iconComponent: ReactElement;
  order?: number;
}

interface ServiceMap {
  [index: string]: Service;
}

const privateServiceMap: ServiceMap = {
  INTERNET: {
    i18n: 'services.internet.name',
    iconComponent: <Wifi className={styles.wifi} />,
    order: 0,
  },
  TV: {
    i18n: 'services.tv.name',
    iconComponent: <Tv className={styles.tv} />,
    order: 1,
  },
  VOIP: {
    i18n: 'services.voip.name',
    iconComponent: <VoipGrey />,
    order: 2,
  },
  TA_VOIP: {
    i18n: 'services.ta_voip.name',
    iconComponent: <VoipGrey />,
    order: 3,
  },
};

interface EnterpriseServiceMap {
  [index: string]: Service;
}

const enterpriseServiceMap: EnterpriseServiceMap = {
  INTERNET: {
    i18n: 'enterpriseServices.internet.name',
    iconComponent: <Broadband className={styles.enterpriseServiceIcon} />,
    order: 0,
  },
  TV: {
    i18n: 'enterpriseServices.tv.name',
    iconComponent: <EnterpriseTv className={styles.enterpriseServiceIcon} />,
    order: 1,
  },
  VOIP: {
    i18n: 'enterpriseServices.voip.name',
    iconComponent: <EnterpriseVoip className={styles.enterpriseServiceIcon} />,
    order: 2,
  },
  TA_VOIP: {
    i18n: 'enterpriseServices.voip.name',
    iconComponent: <EnterpriseVoip className={styles.enterpriseServiceIcon} />,
    order: 3,
  },
  MANAGED_WIFI: {
    i18n: 'enterpriseServices.managed_wifi.name',
    iconComponent: <ManagedWifi className={styles.enterpriseServiceIcon} />,
    order: 4,
  },
  ETHERNET_ACCESS: {
    i18n: 'enterpriseServices.ethernet_access.name',
    iconComponent: <Ethernet className={styles.enterpriseServiceIcon} />,
    order: 5,
  },
  ETHERNET_P2P: {
    i18n: 'enterpriseServices.ethernet_p2p.name',
    iconComponent: <Ethernet className={styles.enterpriseServiceIcon} />,
    order: 6,
  },
  OFFNET_ETHERNET_P2P: {
    i18n: 'enterpriseServices.ethernet_p2p.name',
    iconComponent: <Ethernet className={styles.enterpriseServiceIcon} />,
    order: 7,
  },
  IP_VPN: {
    i18n: 'enterpriseServices.ip_vpn.name',
    iconComponent: <IpVpn className={styles.enterpriseServiceIcon} />,
    order: 8,
  },
  OFFNET_IP_VPN: {
    i18n: 'enterpriseServices.ip_vpn.name',
    iconComponent: <IpVpn className={styles.enterpriseServiceIcon} />,
    order: 9,
  },
  MOBILE_ACCESS: {
    i18n: 'enterpriseServices.mobile_access.name',
    iconComponent: <MobileAccess className={styles.enterpriseServiceIcon} />,
    order: 10,
  },
  MOBILE_BACKUP: {
    i18n: 'enterpriseServices.mobile_backup.name',
    iconComponent: <MobileBackup className={styles.enterpriseServiceIcon} />,
    order: 11,
  },
  FWA: {
    i18n: 'enterpriseServices.fwa.name',
    iconComponent: <FixedWirelessAccess className={styles.enterpriseServiceIcon} />,
    order: 12,
  },
};

export const LocationPanel: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { location } = props;
  const isEnterpriseService = location.customerType !== CustomerType.PRIVAT;
  const servicesContainerClassName = isEnterpriseService ? styles.enterpriseServicesContainer : '';
  const serviceMap = isEnterpriseService ? enterpriseServiceMap : privateServiceMap;

  const services: Service[] = isActiveLocation(location)
    ? location.services
        .filter((serviceKey) => Object.keys(serviceMap).includes(serviceKey))
        .map((serviceKey) => serviceMap[serviceKey])
        .sort((a, b) => (a.order ?? Number.MAX_SAFE_INTEGER) - (b.order ?? Number.MAX_SAFE_INTEGER))
    : [];

  const displayCircuitId = location.customerType === CustomerType.BEDRIFT && location.circuitId;

  return (
    <Panel onClick={props.onClick}>
      <div className={styles.addressContainer}>
        <div className={styles.row}>
          <LocationIcon customerType={props.location.customerType} className={styles.house} role="presentation" />
          <Typography
            data-hj-suppress={true}
            className={styles.title}
            variant="headline5"
            component="h4"
            maxBreakpoint={Breakpoint.MOBILE}
          >
            {location.address.streetaddress || t('components.locationPanel.unknownStreetAddress')}
          </Typography>

          {displayCircuitId && (
            <div className={styles.circuitIdContainer}>
              <CircuitId />
              <Typography
                data-hj-suppress={true}
                className={styles.title}
                variant="uiText1"
                component="h4"
                maxBreakpoint={Breakpoint.MOBILE}
              >
                {t('components.locationPanel.circuitId')} {location.circuitId}
              </Typography>
            </div>
          )}
        </div>

        <div className={styles.addressRow}>
          <div className={styles.container}>
            <LocationSiteName location={location} />
          </div>
        </div>
      </div>
      <div className={servicesContainerClassName}>
        {services.map((service) => (
          <ServiceComponent key={service.i18n} service={service} isEnterpriseService={isEnterpriseService} />
        ))}
      </div>
      {(isInactiveCustomer(location) && (
        <div className={styles.container}>
          <InfoCircle variant="filled" role="graphics-symbol" title={t('components.locationPanel.infoIconTitle')} />
          <Typography variant="uiText2" component="p" maxBreakpoint={Breakpoint.TABLET} className={styles.serviceName}>
            {t('components.locationPanel.inactiveLocation')}
          </Typography>
        </div>
      )) ||
        (!isActiveLocation(location) && (
          <div className={styles.container}>
            <InfoCircle variant="filled" role="graphics-symbol" title={t('components.locationPanel.infoIconTitle')} />
            <Typography
              variant="uiText2"
              component="p"
              maxBreakpoint={Breakpoint.TABLET}
              className={styles.serviceName}
            >
              {t('components.locationPanel.newLocation')}
            </Typography>
          </div>
        ))}
    </Panel>
  );
};

interface ServiceProps {
  service: Service;
  isEnterpriseService: boolean;
}

const ServiceComponent: React.FC<ServiceProps> = (props) => {
  const { t } = useTranslation();
  const className = props.isEnterpriseService ? styles.enterpriseServiceContainer : styles.container;

  return (
    <div className={className}>
      <div className={styles.serviceIcon} title="">
        {props.service.iconComponent}
      </div>
      <Typography className={styles.serviceName} variant="uiText2" component="p" maxBreakpoint={Breakpoint.TABLET}>
        {t(props.service.i18n)}
      </Typography>
    </div>
  );
};
