import React from 'react';
import classNames from 'classnames';

import { BaseTypographyProps } from './types';

const TypographyBase: React.FC<BaseTypographyProps> = (props) => {
  const { variant, className, bold, underline, italic, styles, forwardedRef, renderAsHTML, ...rest } = props;

  const componentClassName: string = classNames(
    className,
    styles[variant],
    styles.typography,
    bold ? styles.bold : null,
    underline ? styles.underline : null,
    italic ? styles.italic : null,
  );

  const typographyProps = {
    className: componentClassName,
    ref: forwardedRef,
    ...rest,
  };
  if (renderAsHTML) {
    typographyProps.dangerouslySetInnerHTML = {
      __html: String(props.children),
    };
  }
  const typographyChildren = renderAsHTML ? null : props.children;

  return React.createElement(props.component, typographyProps, typographyChildren);
};

export { TypographyBase };
