import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { getUsersResponse } from 'app/store/actions/users-thunks';
import { FetchStatus } from 'app/store/root-types';
import { CustomerContext } from 'app/store/types/user-context-types';
import { timeout } from 'app/utils/fetch-utils';
import { useEffect, useState } from 'react';

export const useUserInfo = (userContext: CustomerContext, timeToLive?: number) => {
  const dispatch = useAppDispatch();
  const [endOfLife, setEndOfLife] = useState(false);
  const { users, fetchStatus } = useAppSelector((state) => state.users);

  const userNeedToVerify = users?.find((user) => user.role === 'PRIMARY' && user.emailVerified === false);

  useEffect(() => {
    if (fetchStatus !== FetchStatus.NOT_STARTED) {
      return;
    }
    if (!timeToLive) {
      dispatch(getUsersResponse(userContext.customerCrmId));
      return;
    }
    timeout(dispatch(getUsersResponse(userContext.customerCrmId)), timeToLive).catch(() => {
      setEndOfLife(true);
    });
  }, [dispatch, fetchStatus]);

  return { users, userNeedToVerify, timedOut: endOfLife, fetchStatus };
};
