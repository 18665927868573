import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { clearContactDetailsForm } from 'app/store/actions/contact-details-actions';
import { fetchContactDetails } from 'app/store/actions/contact-details-thunks';
import { FetchStatus } from 'app/store/root-types';
import { CustomerContext } from 'app/store/types/user-context-types';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { useEffect } from 'react';

export const useContactDetails = (userContext: CustomerContext) => {
  const dispatch = useAppDispatch();
  const contactDetails = useAppSelector((state) => state.contactDetails.contactDetails);
  const updateContactDetails = useAppSelector((state) => state.contactDetails.updateContactDetails);
  const fetchStatus = useAppSelector((state) => state.contactDetails.fetchStatus);

  const { goToPath } = useAppNavigation();

  const isEnterprise = contactDetails?.isEnterprise === true;
  const displayName = isEnterprise
    ? contactDetails.organizationOwner?.tradingName
    : `${contactDetails?.individualOwner?.firstName} ${contactDetails?.individualOwner?.lastName}`;

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchContactDetails(userContext.customerCrmId));
    }
  }, [dispatch, fetchStatus]);

  useEffect(() => {
    if (updateContactDetails.fetchStatus === FetchStatus.FULFILLED) {
      dispatch(clearContactDetailsForm());
      goToPath(navigationService.PAGES.contactDetails.url);
    }
  }, [dispatch, updateContactDetails]);

  const contactIdentification = () => {
    if (isEnterprise) {
      return contactDetails?.organizationOwner?.businessNumber;
    }
    const dateOfBirth = contactDetails?.individualOwner?.dateOfBirth
      ? new Date(contactDetails.individualOwner.dateOfBirth)
      : undefined;

    if (!dateOfBirth) {
      return undefined;
    }
    return [dateOfBirth.getDate(), dateOfBirth.getMonth() + 1, dateOfBirth.getFullYear()].join('.');
  };

  return {
    displayName,
    isEnterprise,
    fetchStatus,
    displayContactId: contactIdentification(),
    contactDetails,
    updateContactDetails,
  };
};
