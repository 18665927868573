import React from 'react';
import { Breakpoint } from '../../types/global';
import { MobileMaxTypography } from './mobile-max-typography/mobile-max-typography';
import { TabletMaxTypography } from './tablet-max-typography/tablet-max-typography';
import { DesktopMaxTypography } from './desktop-max-typography/desktop-max-typography';
import { TypographyProps } from './types';

const Typography = React.forwardRef<HTMLElement, TypographyProps>((props, ref) => {
  const { maxBreakpoint, ...rest } = props;
  switch (maxBreakpoint) {
    case Breakpoint.MOBILE: {
      return <MobileMaxTypography forwardedRef={ref} {...rest} />;
    }
    case Breakpoint.TABLET: {
      return <TabletMaxTypography forwardedRef={ref} {...rest} />;
    }
    case Breakpoint.DESKTOP: {
      return <DesktopMaxTypography forwardedRef={ref} {...rest} />;
    }
    default: {
      return <DesktopMaxTypography forwardedRef={ref} {...rest} />;
    }
  }
});

export { Typography };
