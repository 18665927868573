import { t } from 'i18next';
import { FdsButton } from '@lyse-as/formds-react';
import { useTranslation } from 'react-i18next';

import { ContainerFixed } from 'app/components/container';
import { ContainerLayoutMode } from 'app/components/container/container';
import { Typography as T } from 'app/component-library-wave';
import { navigationService } from 'app/service/navigation/navigation-service';
import { ParamUtils } from 'app/utils/param-utils';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { InvoiceCustomerServiceLink } from '../invoice-customerservice-link/invoice-customerservice-link';

import styles from './invoice-avtalegiro-receipt.module.scss';

const getHeading = (status: string | null) => {
  switch (status?.toLowerCase()) {
    case 'ok':
      return t('pages.invoice.invoiceAvtaleGiroReceipt.statusOkHeading');
    case 'cancel':
      return t('pages.invoice.invoiceAvtaleGiroReceipt.statusCancelHeading');
    case 'error':
      return t('pages.invoice.invoiceAvtaleGiroReceipt.statusErrorHeading');
    default:
      return '';
  }
};
const getBody = (status: string | null) => {
  switch (status?.toLowerCase()) {
    case 'ok':
      return t('pages.invoice.invoiceAvtaleGiroReceipt.statusOkBody');
    case 'cancel':
      return t('pages.invoice.invoiceAvtaleGiroReceipt.statusCancelBody');
    case 'error':
      return t('pages.invoice.invoiceAvtaleGiroReceipt.statusErrorBody');
    default:
      return 'Ukjent status';
  }
};

export const InvoiceAvtaleGiroReceipt = () => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();

  const status = ParamUtils.getQueryParam('status');

  return (
    <ContainerFixed mode={ContainerLayoutMode.Medium} className={styles.pageContainer}>
      <T variant="formds-altibox-header-tertiary" component="h1" bold={true} className={styles.pageHeading}>
        {getHeading(status)}
      </T>

      <T variant="formds-body" component="p" className={styles.pageBody}>
        {getBody(status)}
      </T>

      <InvoiceCustomerServiceLink />

      <FdsButton iconLeft="arrow_back" role="link" onClick={() => goToPath(navigationService.PAGES.invoice.url)}>
        {t('pages.invoice.invoiceAvtaleGiroReceipt.backButton')}
      </FdsButton>
    </ContainerFixed>
  );
};
