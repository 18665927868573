import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

export const getMovieRentalHistory = createAsyncThunk<
  MinesiderBackend.VodUsage[],
  string,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('Movies/getMovieRentalHistory', async (customerId, thunkApi) =>
  minesiderBackendService
    .getMovieRentalHistory(customerId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);
