import { Typography } from 'app/component-library-wave';
import { FdsButton } from '@lyse-as/formds-react';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { useTranslation } from 'react-i18next';
import { iso8601ToDayAndFullMonth } from 'app/utils/date-utils';
import styles from './invoice-postpone.module.scss';

export const Confirmed = () => {
  const { data } = useAppSelector((state) => state.invoice.postponeState);
  const { t } = useTranslation();

  const handleBack = () => {
    history.back();
  };

  const newDatesPresent = data !== undefined && data.dueDate !== undefined && data.originalDueDate !== undefined;

  return (
    <>
      <Typography component="h1" variant="formds-altibox-subtitle-primary" bold={true}>
        {t('pages.invoice.invoicePostpone.confirmed.name')}
      </Typography>
      <div className={styles.messageContainer}>
        {newDatesPresent && (
          <>
            <Typography component="p" variant="formds-body">
              {t('pages.invoice.invoicePostpone.confirmed.oldDate')}{' '}
              <strong>{iso8601ToDayAndFullMonth(data.originalDueDate!)} </strong>
            </Typography>
            <Typography component="p" variant="formds-body">
              {t('pages.invoice.invoicePostpone.confirmed.newDate')}{' '}
              <strong>{iso8601ToDayAndFullMonth(data.dueDate!)}</strong>
            </Typography>
          </>
        )}
        {!newDatesPresent && (
          <Typography component="p" variant="formds-body">
            {t('pages.invoice.invoicePostpone.confirmed.problem')}
          </Typography>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <FdsButton iconLeft="arrow_back" variant="secondary" onClick={handleBack} fullWidth={true}>
          {t('pages.invoice.invoicePostpone.back')}
        </FdsButton>
      </div>
    </>
  );
};
