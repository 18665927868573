import * as React from 'react';

import { AuthService } from 'app/service/auth/auth-service';

const ProvAuthCallback = (): null => {
  React.useEffect(() => {
    AuthService.handleProvAuthorization();
  }, []);

  return null;
};

export { ProvAuthCallback };
