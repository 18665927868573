import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

// Thunks
export const setPin = createAsyncThunk<
  void,
  MinesiderBackend.SetPinRequest,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('pin/setPin', async (newPin: MinesiderBackend.SetPinRequest, thunkApi) =>
  minesiderBackendService
    .setPin(newPin)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const getPin = createAsyncThunk<
  MinesiderBackend.CustomerPin,
  string,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('pin/getPin', async (customerId: string, thunkApi) =>
  minesiderBackendService
    .getPin(customerId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);
