import { iso8601ToDayAndFullMonth, iso8601ToFullMonthAndYear } from 'app/utils/date-utils';
import { useTranslation } from 'react-i18next';
import { settlementIconStates, SettlementState } from '../settlement-state/settlement-state';
import { CopyButton } from '../copy-button/copy-button';
import { DescriptionList, DescriptionListPair } from 'app/components/description-list/description-list';
import styles from './invoice-overview.module.scss';

interface Props {
  invoice: MinesiderBackend.Invoice;
  paymentInformation?: MinesiderBackend.PaymentInformation;
  showFullDetails?: boolean;
}

export const InvoiceOverview = ({ invoice, showFullDetails, paymentInformation }: Props) => {
  const { t } = useTranslation();

  const overviewList: DescriptionListPair[] = [
    {
      label: {
        value: iso8601ToFullMonthAndYear(invoice.billDate || ''),
        variant: 'formds-common-subtitle-secondary',
      },
      description: {
        value: invoice.taxIncludedSum || '',
        variant: 'formds-common-subtitle-secondary',
      },
    },
    {
      label: {
        value: t('pages.invoice.details.settlementState.status'),
      },
      description: {
        value: settlementIconStates({
          settlementState: invoice.settlementState as SettlementState,
          dueDate: invoice.dueDate,
        }).getIcon(),
      },
    },
    {
      label: {
        value: t('pages.invoice.details.dueDate'),
      },
      description: {
        value: iso8601ToDayAndFullMonth(invoice.dueDate || ''),
      },
    },
    {
      label: {
        value: t('pages.invoice.details.kidNumber'),
      },
      description: {
        value: (
          <>
            {invoice.kidNumber}
            {invoice.kidNumber && (
              <CopyButton value={invoice.kidNumber} title={t('pages.invoice.details.copyKidNumber')} />
            )}
          </>
        ),
      },
    },
    {
      label: {
        value: t('pages.invoice.details.accountNumber'),
      },
      description: {
        value: (
          <>
            {paymentInformation?.partnerBankAccount}
            <CopyButton
              value={paymentInformation?.partnerBankAccount || ''}
              title={t('pages.invoice.details.copyAccountNumber')}
            />
          </>
        ),
      },
    },
  ];

  let detailedList: DescriptionListPair[] = [];

  if (showFullDetails) {
    detailedList = [
      {
        label: {
          value: t('pages.invoiceDetails.number'),
        },
        description: {
          value: invoice.billNumber,
        },
      },
      {
        label: {
          value: t('pages.invoiceDetails.issued'),
        },
        description: {
          value: iso8601ToDayAndFullMonth(invoice.billDate || ''),
        },
      },
      {
        label: {
          value: t('pages.invoiceDetails.paymentMethod'),
        },
        description: {
          value: t(`pages.invoice.DYNAMIC_KEYS.${paymentInformation?.paymentMethod}`),
        },
      },
      {
        label: {
          value: t('pages.invoiceDetails.deliveryMethod'),
        },
        description: {
          value: t(`pages.invoice.DYNAMIC_KEYS.${paymentInformation?.deliveryMethod}`),
        },
      },
    ];
  }

  return <DescriptionList className={styles.container} data={overviewList.concat(detailedList)} />;
};
