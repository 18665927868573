import { useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';

import { navigationService } from 'app/service/navigation/navigation-service';
import { menuAccount } from 'app/utils/navigation-utils';

import { Navigation } from 'app/features/navigation';
import { ContainerFixed } from 'app/components/container';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';

import styles from 'app/pages/menu-page-common.module.scss';

const allLinkSections = [
  {
    pages: menuAccount,
  },
];

export const Account: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();

  const customerLocation = props.userContext;

  const personalizedSections = customerLocation
    ? navigationService.getPersonalizedLinkSections(allLinkSections, customerLocation)
    : [];

  return (
    <>
      <Typography
        component="h1"
        variant="headline5"
        maxBreakpoint={Breakpoint.TABLET}
        className={styles.menuPageHeader}
      >
        {t(navigationService.PAGES.account.i18n)}
      </Typography>

      <ContainerFixed>
        <div className={styles.container}>
          {personalizedSections.map((section, index) => (
            <Navigation section={section} key={`key-account-account-${index}`} />
          ))}
        </div>
      </ContainerFixed>
    </>
  );
};
